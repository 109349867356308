/**
 * @generated SignedSource<<c6d0ed036ae53fd0c5dbf1483bffc8cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountOffersOrderSearchListPagination_data$data = {
  readonly orders: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"OffersTable_orders">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "AccountOffersOrderSearchListPagination_data";
};
export type AccountOffersOrderSearchListPagination_data$key = {
  readonly " $data"?: AccountOffersOrderSearchListPagination_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOffersOrderSearchListPagination_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "orders"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collections"
    },
    {
      "defaultValue": 5,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "filterByOrderRules"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeCriteriaOrders"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeInvalidBids"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isExpired"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maker"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "makerAssetIsPayment"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "offerTypeToggles"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderStatusToggles"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AccountOffersOrderSearchListPaginationQuery.graphql')
    }
  },
  "name": "AccountOffersOrderSearchListPagination_data",
  "selections": [
    {
      "alias": "orders",
      "args": [
        {
          "kind": "Variable",
          "name": "collections",
          "variableName": "collections"
        },
        {
          "kind": "Variable",
          "name": "filterByOrderRules",
          "variableName": "filterByOrderRules"
        },
        {
          "kind": "Variable",
          "name": "includeCriteriaOrders",
          "variableName": "includeCriteriaOrders"
        },
        {
          "kind": "Variable",
          "name": "includeInvalidBids",
          "variableName": "includeInvalidBids"
        },
        {
          "kind": "Variable",
          "name": "isExpired",
          "variableName": "isExpired"
        },
        {
          "kind": "Variable",
          "name": "maker",
          "variableName": "maker"
        },
        {
          "kind": "Variable",
          "name": "makerAssetIsPayment",
          "variableName": "makerAssetIsPayment"
        },
        {
          "kind": "Variable",
          "name": "offerTypeToggles",
          "variableName": "offerTypeToggles"
        },
        {
          "kind": "Variable",
          "name": "orderStatusToggles",
          "variableName": "orderStatusToggles"
        },
        {
          "kind": "Variable",
          "name": "sortAscending",
          "variableName": "sortAscending"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        }
      ],
      "concreteType": "OrderV2TypeConnection",
      "kind": "LinkedField",
      "name": "__AccountOffersOrderSearchListPagination_orders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2TypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "identity",
                      "variableName": "identity"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "OffersTable_orders"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "626ae5f89b0050366c8fd40ec1dbcc44";

export default node;
