import React, { ReactNode, Suspense } from "react"
import {
  Text,
  FlexColumn,
  Flex,
  classNames,
  FlexProps,
  Skeleton,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useFragment } from "react-relay"
import styled from "styled-components"
import type { Placement } from "tippy.js"
import { ErrorFetchingData } from "@/components/common/ErrorFetchingData.react"
import { EMPTY_PRICE_DISPLAY } from "@/constants"
import { Block } from "@/design-system/Block"
import { Tooltip, TooltipOffset } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { OffersTablePriceTooltipContent_item$key } from "@/lib/graphql/__generated__/OffersTablePriceTooltipContent_item.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { bn, displayFiat, quantityDisplay } from "@/lib/helpers/numberUtils"
import { getStatusIndicatorVariant } from "../hooks/useStatusIndicator"
import { OrderStatus } from "../OrderStatus"
import { StatusIndicator } from "../styles"

type Props = {
  item: OffersTablePriceTooltipContent_item$key | null
  children: React.ReactNode
  disabled?: boolean
  placement?: Placement
  offset?: TooltipOffset
}

export const OffersTablePriceTooltip = ({
  item: itemKey,
  disabled,
  placement,
  offset = [-16, 16],
  children,
}: Props) => {
  if (itemKey) {
    return (
      <Tooltip
        animation="fade-vertical"
        className="rounded-xl"
        content={
          <TooltipContainer>
            <ErrorBoundary
              fallback={({ resetError }) => (
                <ErrorFetchingData resetError={resetError} />
              )}
            >
              <Suspense fallback={<OffersTablePriceTooltipSkeleton />}>
                <OffersTablePriceTooltipContent itemKey={itemKey} />
              </Suspense>
            </ErrorBoundary>
          </TooltipContainer>
        }
        contentPadding="0"
        disabled={disabled}
        maxWidth="auto"
        offset={offset}
        touch={false}
        {...(placement ? { placement } : {})}
      >
        <Block as="span">{children}</Block>
      </Tooltip>
    )
  }

  return <Block as="span">{children}</Block>
}

const OffersTablePriceTooltipContent = ({
  itemKey,
}: {
  itemKey: OffersTablePriceTooltipContent_item$key
}) => {
  const itemData = useFragment(
    graphql`
      fragment OffersTablePriceTooltipContent_item on OrderV2Type {
        isValid
        perUnitPriceType {
          usd
          eth
        }
        closedAt
        ...OrderStatus_order
        item {
          __typename
          ... on AssetType {
            collection {
              statsV2 {
                floorPrice {
                  eth
                }
              }
            }
          }
        }
      }
    `,
    itemKey,
  )

  const { item, perUnitPriceType, closedAt, isValid } = itemData

  const t = useTranslate("orders")

  const perUnitPrice = perUnitPriceType

  const closedAtDate = closedAt ? dateFromISO8601(closedAt) : null

  const indicatorStatusVariant = getStatusIndicatorVariant(
    isValid,
    closedAtDate?.toISOString() || null,
  )

  const usdPrice = perUnitPrice.usd
    ? displayFiat(bn(perUnitPrice.usd))
    : undefined

  const getFormattedPercentageDiff = () => {
    if (item.__typename === "AssetType") {
      const statsV2 = item.collection.statsV2
      const hasFloorPrice = statsV2.floorPrice

      if (!hasFloorPrice) {
        return EMPTY_PRICE_DISPLAY
      }

      const rawFloorPrice = statsV2.floorPrice.eth
      const floorPrice = rawFloorPrice ? bn(rawFloorPrice) : undefined
      const ratio =
        perUnitPrice.eth && floorPrice
          ? bn(perUnitPrice.eth).div(floorPrice)
          : undefined
      const diff = ratio?.minus(1)
      const percentageDiff = diff?.times(100)

      const aboveFloorLabel = t("floorPriceDifference.above.label", "above")
      const belowFloorLabel = t("floorPriceDifference.below.label", "below")

      const formattedPercentageDiff = percentageDiff?.isZero()
        ? t("floorPriceDifference.atFloor", "At floor")
        : t(
            "floorPriceDifference.notAtFloor",
            `{{quantity}}% {{percentageDiff}}`,
            {
              quantity: percentageDiff
                ? quantityDisplay(percentageDiff.abs().toFixed(2))
                : 0,
              percentageDiff: percentageDiff?.isNegative()
                ? belowFloorLabel
                : aboveFloorLabel,
            },
          )

      return formattedPercentageDiff
    }

    return EMPTY_PRICE_DISPLAY
  }

  return (
    <FlexColumn>
      <StatusData>
        <Flex className="items-center">
          <StatusIndicator marginRight="8px" variant={indicatorStatusVariant} />

          <Text size="small">
            <OrderStatus orderKey={itemData} showStatus />
          </Text>
        </Flex>
      </StatusData>
      <FlexColumn className="px-4 py-3">
        <UsdData>
          <Text size="small">{usdPrice}</Text>
        </UsdData>
        <FloorDifferenceData>
          <Text size="small">{getFormattedPercentageDiff()}</Text>
        </FloorDifferenceData>
      </FlexColumn>
    </FlexColumn>
  )
}

const StatusData = ({ children }: { children: ReactNode }) => {
  const t = useTranslate("orders")

  return (
    <FlexColumn className="border-b border-level-2 px-4 py-3">
      <ItemContainer>
        <ItemLabelContainer>
          <ItemLabel size="small">
            {t("tooltip.listing.status.header", "Status")}
          </ItemLabel>
        </ItemLabelContainer>
        {children}
      </ItemContainer>
    </FlexColumn>
  )
}

const UsdData = ({ children }: { children: ReactNode }) => {
  const t = useTranslate("orders")

  return (
    <ItemContainer>
      <ItemLabelContainer>
        <ItemLabel size="small">
          {t("tooltip.listing.usd.header", "USD")}
        </ItemLabel>
      </ItemLabelContainer>
      {children}
    </ItemContainer>
  )
}

const FloorDifferenceData = ({ children }: { children: ReactNode }) => {
  const t = useTranslate("orders")

  return (
    <ItemContainer className="mt-1">
      <ItemLabelContainer>
        <ItemLabel size="small">
          {t("tooltip.listing.floorDifference.header", "Floor difference")}
        </ItemLabel>
      </ItemLabelContainer>
      {children}
    </ItemContainer>
  )
}

const OffersTablePriceTooltipSkeleton = () => {
  return (
    <TooltipContainer>
      <FlexColumn>
        <StatusData>
          <Flex className="w-full items-center">
            <Skeleton.Circle className="h-2.5 w-2.5" />
            <Skeleton.Line className="ml-2 h-3 w-full min-w-[40px]" />
          </Flex>
        </StatusData>
        <FlexColumn className="px-4 py-3">
          <UsdData>
            <Skeleton.Line className="h-3 w-full min-w-[40px]" />
          </UsdData>
          <FloorDifferenceData>
            <Skeleton.Line className="h-3 w-full min-w-[40px]" />
          </FloorDifferenceData>
        </FlexColumn>
      </FlexColumn>
    </TooltipContainer>
  )
}

const TooltipContainer = ({ className, ...props }: FlexProps) => (
  <Flex {...props} className={classNames("items-start text-left", className)} />
)

const ItemContainer = ({ className, ...props }: FlexProps) => (
  <Flex
    {...props}
    className={classNames("items-center justify-between", className)}
  />
)

const ItemLabelContainer = styled(Block)`
  min-width: 124px;
  margin-right: 16px;
`

const ItemLabel = styled(Text.Body)`
  font-weight: 600;
`
