import React from "react"
import { Block } from "@/design-system/Block"
import { Select, SelectOption } from "@/design-system/Select"
import { useCurrentDealsSubTab } from "@/features/deals/components/DealsSubTabs.react"
import { useTranslate } from "@/hooks/useTranslate"
import { SwapSortBy } from "@/lib/graphql/__generated__/DealsSearchListPaginationQuery.graphql"

const useDealSortByOptions = (): SelectOption<SwapSortBy>[] => {
  const t = useTranslate("deals")

  return [
    {
      label: t("dealSortBySelect.recent", "Most recent"),
      value: "CREATED_DATE",
    },
    {
      label: t("dealSortBySelect.expiring", "Expiring soon"),
      value: "CLOSED_AT",
    },
  ]
}

type DealSortBySelectProps = {
  sortBy: SwapSortBy | undefined
  setSortBy: (sortBy: SwapSortBy | undefined) => void
}

export const DealSortBySelect = ({
  sortBy,
  setSortBy,
}: DealSortBySelectProps) => {
  const dealSideOptions = useDealSortByOptions()
  const dealsSubTab = useCurrentDealsSubTab()

  if (dealsSubTab !== "active") {
    // Only show sort by for active deals where sort by expiration is relevant
    return null
  }

  return (
    <Block width="160px">
      <Select
        clearable={false}
        options={dealSideOptions}
        readOnly
        value={sortBy ?? ""}
        onSelect={opt => setSortBy(opt?.value || undefined)}
      />
    </Block>
  )
}
