import { useMemo } from "react"
import { PortfolioColumn } from "@/features/account/components/PortfolioTable/types"
import { useTranslate } from "@/hooks/useTranslate"
import { display } from "@/lib/helpers/numberUtils"

type HeaderColumn = {
  column: PortfolioColumn
  header: string
  tooltip?: string
}

export const useHeaderColumns = (
  itemCount: number,
  isOwnerMode: boolean,
): HeaderColumn[] => {
  const t = useTranslate("account")
  return useMemo(
    () => [
      {
        column: "item",
        header: t(
          "portfolio.headerColumn.items",
          {
            0: `${itemCount} items`,
            one: `${itemCount} item`,
            other: `${itemCount} items`,
          },
          { count: itemCount, itemCount: display(itemCount) },
          { forceString: true },
        ),
      },
      {
        column: "floorPrice",
        header: t("portfolio.headerColumn.floorPrice", "Floor price"),
      },
      {
        column: "bestOffer",
        header: t("portfolio.headerColumn.bestOffer", "Best offer"),
      },
      {
        column: "listingPrice",
        header: t("portfolio.headerColumn.listingPrice", "Listing price"),
      },
      {
        column: "cost",
        header: t("portfolio.headerColumn.cost", "Cost"),
      },
      {
        column: "difference",
        header: t(
          "portfolio.headerColumn.listingPriceVersusCostDifference",
          "Difference",
        ),
        tooltip: t(
          "portfolio.headerColumn.tooltip.listingPriceVersusCostDifference",
          "Listing price minus cost",
        ),
      },
      ...(isOwnerMode
        ? [
            {
              column: "options",
              header: "",
            } as const,
          ]
        : []),
    ],
    [isOwnerMode, itemCount, t],
  )
}
