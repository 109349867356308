import React from "react"
import { Block, BlockProps } from "@/design-system/Block"
import { Button, ButtonProps } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { BULK_LISTING_SELECTION_MAX_SIZE } from "@/features/account/components/AccountPage/components/ProfileItemSelectionFooter/constants"
import {
  useAssetContextActions,
  useAssetContextSelectedItems,
} from "@/features/account/hooks/useAssetSelection"
import { useChains } from "@/hooks/useChains"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { getChainSlug } from "@/lib/helpers/chainUtils"
import { getItemSellUrl } from "@/lib/helpers/item"

type Props = {
  overrides?: {
    Block: {
      props: Partial<BlockProps>
    }
  }
} & ButtonProps

export const ListItemsButton = ({ overrides, ...buttonProps }: Props) => {
  const { onBatchSelect } = useAssetContextActions()
  const selectedItems = useAssetContextSelectedItems()

  const t = useTranslate("account")
  const router = useRouter()
  const { getChainName } = useChains()

  const uniqueChains = new Set(selectedItems.map(item => item.chain.identifier))
  const tradingDisabledChainNames = Array.from(
    new Set(
      selectedItems
        .filter(item => !item.chain.isTradingEnabled)
        .map(item => getChainName(item.chain.identifier)),
    ),
  )

  const filteredItems = selectedItems.slice(0, BULK_LISTING_SELECTION_MAX_SIZE)
  const hasCompromised =
    filteredItems.filter(item => item.isCompromised).length > 0

  const numItems = filteredItems.length

  const firstAsset = filteredItems[0]

  const handleClick = () => {
    if (numItems === 1) {
      router.push(getItemSellUrl(firstAsset))
      return
    }

    const chain = getChainSlug(firstAsset.chain.identifier)

    onBatchSelect(filteredItems)

    return router.push(`/bulk-list/${chain}`)
  }

  const getDisabledReason = () => {
    // Disable if there is a bundle in the selection and the count is greater than 1
    const hasBundle = filteredItems.some(
      item => item.__typename === "AssetBundleType",
    )

    if (hasBundle && numItems > 1) {
      return t(
        "selectionFooter.listCta.bundleAndMultipleItems",
        "You can only list a bundle by itself.",
      )
    }

    if (uniqueChains.size > 1) {
      return t(
        "selectionFooter.listCta.multipleChains",
        "You can only list items from the same chain.",
      )
    }

    if (hasCompromised) {
      return t(
        "selectionFooter.listCta.compromised",
        "One or more of these items have been flagged for suspicious activity and cannot be listed using Opensea.",
      )
    }

    if (tradingDisabledChainNames.length > 0) {
      return t(
        "selectionfooter.listCta.listingDisabled",
        {
          "0": "Cannot list items from disabled chains.",
          one: "Listing {{chains}} items is disabled.",
          other: "Listing {{chains}} items are disabled.",
        },
        {
          count: tradingDisabledChainNames.length,
          chains: tradingDisabledChainNames.join(", "),
        },
      )
    }

    return undefined
  }

  const disabledReason = getDisabledReason()

  // Display tooltip if there are more orders than the max limit
  const maxInfoLimit =
    selectedItems.length > BULK_LISTING_SELECTION_MAX_SIZE
      ? t(
          "selectionFooter.listCta.maxInfoLimit",
          "You can only list up to {{count}} items at a time.",
          { count: BULK_LISTING_SELECTION_MAX_SIZE },
        )
      : undefined

  return (
    <Tooltip content={disabledReason ?? maxInfoLimit} maxWidth={200}>
      <Block {...overrides?.Block.props}>
        <Button
          disabled={Boolean(disabledReason)}
          onClick={handleClick}
          {...buttonProps}
        >
          {t(
            "selectionFooter.listCta.itemCount",
            {
              "0": "List {{count}} items",
              one: "List {{count}} item",
              other: "List {{count}} items",
            },
            { count: numItems },
          )}
        </Button>
      </Block>
    </Tooltip>
  )
}
