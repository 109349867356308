import React, { useEffect, useMemo } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { AssetSearchList } from "@/components/search/assets/AssetSearchList.react"
import {
  AssetCardVariant,
  AssetSearchViewNoResults,
} from "@/components/search/assets/AssetSearchView"
import { filterItemsWithInconsistentListings } from "@/components/search/assets/utils/filterItems"
import { useUpdateSearchContext } from "@/components/search/utils/SearchContextProvider.react"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import {
  AccountCreatedAssetSearchListPagination_data$key,
  AccountCreatedAssetSearchListPagination_data$data,
} from "@/lib/graphql/__generated__/AccountCreatedAssetSearchListPagination_data.graphql"
import { AccountCreatedAssetSearchListQuery } from "@/lib/graphql/__generated__/AccountCreatedAssetSearchListQuery.graphql"
import { getNodes, graphql, Node, PageProps } from "@/lib/graphql/graphql"
import { AccountCreatedAssetSearchState } from "./AccountCreatedAssetSearch.react"

export type AccountCreatedSearchItems = Node<
  AccountCreatedAssetSearchListPagination_data$data["searchItems"]
>[]

type Props = {
  cardVariant: AssetCardVariant | undefined
  clear: () => void
  isCurrentUser: boolean
  searchState: AccountCreatedAssetSearchState
}

type PaginationProps = Omit<Props, "isCurrentUser"> & {
  data: AccountCreatedAssetSearchListPagination_data$key | null
}

const LIST_QUERY = graphql`
  query AccountCreatedAssetSearchListQuery(
    $chains: [ChainScalar!]
    $collections: [CollectionSlug!]
    $count: Int!
    $cursor: String
    $identity: IdentityInputType!
    $numericTraits: [TraitRangeType!]
    $paymentAssets: [PaymentAssetSymbol]
    $priceFilter: PriceFilterType
    $query: String
    $resultModel: SearchResultModel
    $sortAscending: Boolean
    $sortBy: SearchSortBy
    $stringTraits: [TraitInputType!]
    $toggles: [SearchToggle!]
    $showContextMenu: Boolean!
  ) {
    ...AccountCreatedAssetSearchListPagination_data
      @arguments(
        count: $count
        cursor: $cursor
        chains: $chains
        collections: $collections
        identity: $identity
        numericTraits: $numericTraits
        paymentAssets: $paymentAssets
        priceFilter: $priceFilter
        query: $query
        resultModel: $resultModel
        sortAscending: $sortAscending
        sortBy: $sortBy
        stringTraits: $stringTraits
        toggles: $toggles
        showContextMenu: $showContextMenu
      )
  }
`

const PAGINATION_FRAGMENT = graphql`
  fragment AccountCreatedAssetSearchListPagination_data on Query
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    chains: { type: "[ChainScalar!]" }
    collections: { type: "[CollectionSlug!]" }
    identity: { type: "IdentityInputType!" }
    numericTraits: { type: "[TraitRangeType!]" }
    paymentAssets: { type: "[PaymentAssetSymbol]" }
    priceFilter: { type: "PriceFilterType" }
    query: { type: "String" }
    resultModel: { type: "SearchResultModel" }
    sortAscending: { type: "Boolean" }
    sortBy: { type: "SearchSortBy" }
    stringTraits: { type: "[TraitInputType!]" }
    toggles: { type: "[SearchToggle!]" }
    showContextMenu: { type: "Boolean!" }
  )
  @refetchable(queryName: "AccountCreatedAssetSearchListPaginationQuery") {
    queriedAt
    searchItems(
      first: $count
      after: $cursor
      chains: $chains
      collections: $collections
      creator: $identity
      numericTraits: $numericTraits
      paymentAssets: $paymentAssets
      priceFilter: $priceFilter
      querystring: $query
      resultType: $resultModel
      sortAscending: $sortAscending
      sortBy: $sortBy
      stringTraits: $stringTraits
      toggles: $toggles
    ) @connection(key: "AccountCreatedAssetSearchListPagination_searchItems") {
      edges {
        node {
          # eslint-disable-next-line relay/unused-fields
          relayId
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...readItemBestAskUsdPrice_item
          ...AssetSearchList_data
            @arguments(
              identity: $identity
              showContextMenu: $showContextMenu
              showQuantity: true
              shouldShowBestBid: true
            )
          # eslint-disable-next-line relay/must-colocate-fragment-spreads used for batch selecting
          ...useAssetSelectionStorage_item
            @arguments(identity: $identity, withBestBid: true)
        }
      }
      totalCount
    }
  }
`

const LazyAccountCreatedAssetSearchList = (props: Props) => {
  const data = useLazyLoadQuery<AccountCreatedAssetSearchListQuery>(
    LIST_QUERY,
    {
      ...props.searchState,
      count: GLOBAL_MAX_PAGE_SIZE,
      showContextMenu: props.isCurrentUser,
    },
  )
  return <AccountCreatedAssetSearchListPagination data={data} {...props} />
}

const AccountCreatedAssetSearchListPagination = ({
  cardVariant,
  data: dataKey,
  clear,
  searchState,
}: PaginationProps) => {
  const { wallet } = useWallet()
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    AccountCreatedAssetSearchListQuery,
    AccountCreatedAssetSearchListPagination_data$key
  >(PAGINATION_FRAGMENT, dataKey)

  const page: PageProps = useMemo(
    () => ({
      loadMore: count => loadNextToLoadMore({ loadNext, count }),
      isLoading: () => isLoadingNext,
      hasMore: () => hasNext,
    }),
    [hasNext, isLoadingNext, loadNext],
  )

  const showContextMenu = wallet.isCurrentIdentity(searchState.identity)

  const searchItems = data?.searchItems
  const totalCount = searchItems?.totalCount
  const lastUpdatedAt = data?.queriedAt

  const filteredData = useMemo(
    () =>
      filterItemsWithInconsistentListings(
        getNodes(searchItems),
        searchState,
        page,
      ),
    [page, searchItems, searchState],
  )

  const updateSearchContext =
    useUpdateSearchContext<AccountCreatedSearchItems[number]>()

  useEffect(() => {
    updateSearchContext({
      totalCount,
      lastUpdatedAt,
      items: filteredData ?? undefined,
    })
  }, [filteredData, lastUpdatedAt, totalCount, updateSearchContext])

  if (totalCount === 0) {
    return (
      <AssetSearchViewNoResults
        query={searchState.query}
        showEmptyView
        onBackClick={clear}
      />
    )
  }
  return (
    <AssetSearchList
      cardVariant={cardVariant}
      data={filteredData}
      page={page}
      pageSize={GLOBAL_MAX_PAGE_SIZE}
      showAssetMediaEditions
      showCollectionName
      showContextMenu={showContextMenu}
      showQuantity
      variant="grid"
    />
  )
}

export const AccountCreatedAssetSearchList = ({
  cardVariant,
  clear,
  isCurrentUser,
  searchState,
}: Props) => (
  <SsrSuspense
    fallback={<AssetSearchList.Skeleton cardVariant={cardVariant} />}
  >
    <LazyAccountCreatedAssetSearchList
      cardVariant={cardVariant}
      clear={clear}
      isCurrentUser={isCurrentUser}
      searchState={searchState}
    />
  </SsrSuspense>
)
