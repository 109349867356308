import React from "react"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import NoticeBanner from "@/components/layout/NoticeBanner.react"
import { useTranslate } from "@/hooks/useTranslate"
import { ApplyForVerificationBanner_data$key } from "@/lib/graphql/__generated__/ApplyForVerificationBanner_data.graphql"

type Props = {
  data: ApplyForVerificationBanner_data$key | null
  isCurrentUser: boolean
}

export const ApplyForVerificationBanner = ({ data, isCurrentUser }: Props) => {
  const t = useTranslate("account")
  const account = useFragment(
    graphql`
      fragment ApplyForVerificationBanner_data on AccountType {
        canViewVerificationForm
        config
        verificationRequest {
          status
        }
      }
    `,
    data,
  )
  if (!account) {
    return null
  }

  const { canViewVerificationForm, config, verificationRequest } = account
  const hasExistingVerificationRequest = !!verificationRequest?.status
  const showVerificationBanner =
    isCurrentUser &&
    config !== "VERIFIED" &&
    !hasExistingVerificationRequest &&
    canViewVerificationForm

  if (!showVerificationBanner) {
    return null
  }

  return (
    <StyledNoticeBanner>
      <div>
        {t(
          "banner.applyText",
          "Your account is now eligible for verification.",
        )}{" "}
        <Link
          className="text-white underline"
          href="/account/settings?tab=verifications"
        >
          {t("banner.applyCTA", "Apply")}
        </Link>
      </div>
    </StyledNoticeBanner>
  )
}

const StyledNoticeBanner = styled(NoticeBanner)`
  justify-content: center;
`
