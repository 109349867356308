/**
 * @generated SignedSource<<be4d9dbbd6499394fa1cc22dc90001a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileActionBar_data$data = {
  readonly address: string;
  readonly names: ReadonlyArray<{
    readonly name: string;
    readonly type: string;
  }>;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"accounts_url">;
  readonly " $fragmentType": "ProfileActionBar_data";
};
export type ProfileActionBar_data$key = {
  readonly " $data"?: ProfileActionBar_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileActionBar_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileActionBar_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "names",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "accounts_url",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserType",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publicUsername",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "2b3caab349a1316d2c651a689dd9c8cf";

export default node;
