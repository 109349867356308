import React, { Suspense } from "react"
import { useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "components/blockchain/BlockchainActionList"
import { BlockchainActionModalContent } from "components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import type {
  CancelItemOrdersActionModalQuery,
  CancelItemOrdersActionModalQuery$variables,
} from "lib/graphql/__generated__/CancelItemOrdersActionModalQuery.graphql"
import { graphql } from "lib/graphql/graphql"

type BaseProps = {
  onEnd: () => unknown
  onError?: (error: Error) => unknown
}

type Props = BaseProps & CancelItemOrdersActionModalQuery$variables

const CancelItemOrdersActionModalBase = ({
  assets,
  bundles,
  side,
  onEnd,
  onError,
}: Props) => {
  const data = useLazyLoadQuery<CancelItemOrdersActionModalQuery>(
    graphql`
      query CancelItemOrdersActionModalQuery(
        $assets: [AssetRelayID!]!
        $bundles: [AssetBundleRelayID!]!
        $side: OrderSide!
      ) {
        blockchain {
          bulkCancelItemOrders(
            assets: $assets
            bundles: $bundles
            side: $side
          ) {
            actions {
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    { assets, bundles, side },
    { fetchPolicy: "network-only" },
  )

  const { actions } = data.blockchain.bulkCancelItemOrders

  return (
    <BlockchainActionList dataKey={actions} onEnd={onEnd} onError={onError} />
  )
}

export const CancelItemOrdersActionModal = (props: Props) => (
  <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
    <CancelItemOrdersActionModalBase {...props} />
  </Suspense>
)
