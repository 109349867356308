import React from "react"
import { Text } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"

export const DealsNoResults = () => {
  const t = useTranslate("deals")

  return (
    <Block
      alignItems="center"
      backgroundColor="components.background.gray1"
      className="rounded-xl border border-level-1"
      display="flex"
      justifyContent="center"
      minHeight="300px"
    >
      <Text.Body weight="semibold">
        {t("manageDeals.empty", "No deals to display")}
      </Text.Body>
    </Block>
  )
}
