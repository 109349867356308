import { useContextSelector } from "use-context-selector"
import { OfferSelectionContext } from "@/features/account/components/AccountPage/tabs/offers/OfferSelectionContextProvider.react"

export const useOfferSelectionCount = () =>
  useContextSelector(OfferSelectionContext, context => context.numSelected)

export const useOfferSelectionCanceledIds = () =>
  useContextSelector(OfferSelectionContext, context => context.canceledIds)

export const useOfferSelectionSelectedOffers = () =>
  useContextSelector(OfferSelectionContext, context => context.selectedOffers)

export const useOfferSelectionIsSelected = () =>
  useContextSelector(OfferSelectionContext, context => context.isSelected)

export const useOfferContextActions = () => {
  const onCanceled = useContextSelector(
    OfferSelectionContext,
    context => context.onCanceled,
  )

  const onClose = useContextSelector(
    OfferSelectionContext,
    context => context.onClose,
  )

  const onDelete = useContextSelector(
    OfferSelectionContext,
    context => context.onDelete,
  )

  const onDeleteAll = useContextSelector(
    OfferSelectionContext,
    context => context.onDeleteAll,
  )

  const onSelect = useContextSelector(
    OfferSelectionContext,
    context => context.onSelect,
  )

  const onBatchSelect = useContextSelector(
    OfferSelectionContext,
    context => context.onBatchSelect,
  )

  return {
    onCanceled,
    onClose,
    onDelete,
    onDeleteAll,
    onSelect,
    onBatchSelect,
  }
}
