import React, { useCallback } from "react"
import {
  CenterAligned,
  FlexEnd,
  Icon,
  Media,
  Separator,
  SpaceBetween,
  Text,
  FlexColumn,
  classNames,
} from "@opensea/ui-kit"
import { isBefore } from "date-fns"
import { graphql, useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { ProfileImage } from "@/components/common/ProfileImage.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { useTrackDealCardClick } from "@/features/deals/analytics"
import { DealLink } from "@/features/deals/components/DealLink"
import { useDealStatus } from "@/features/deals/hooks/useDealStatus"
import { useDealStatusText } from "@/features/deals/hooks/useDealStatusText"
import { isFinalizedDeal } from "@/features/deals/types"
import { useTranslate } from "@/hooks/useTranslate"
import { DealCard_deal$key } from "@/lib/graphql/__generated__/DealCard_deal.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { addressesEqual, truncateAddress } from "@/lib/helpers/address"
import { useFromNowShort, dateFromISO8601 } from "@/lib/helpers/datetime"
import { bn, display } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"
import { DealCardDropdown } from "./DealCardDropdown.react"

type DealCardProps = {
  deal: DealCard_deal$key
  accountAddress: string
}

export const DealCard = ({
  deal: dealDataKey,
  accountAddress,
}: DealCardProps) => {
  const t = useTranslate("deals")
  const trackClick = useTrackDealCardClick()

  const deal = useFragment(
    graphql`
      fragment DealCard_deal on SwapType {
        relayId
        closedAt
        createdDate
        maker {
          address
          displayName
          ...ProfileImage_data
        }
        taker {
          address
          displayName
          ...ProfileImage_data
        }
        makerAssetQuantities(first: 32) {
          edges {
            node {
              quantity
              asset {
                tokenStandard
                decimals
                symbol
                chain {
                  identifier
                }
                ...AssetMedia_asset
                ...StackedAssetMedia_assets
              }
            }
          }
        }
        takerAssetQuantities(first: 32) {
          edges {
            node {
              quantity
              asset {
                tokenStandard
                decimals
                symbol
                chain {
                  identifier
                }
                ...AssetMedia_asset
                ...StackedAssetMedia_assets
              }
            }
          }
        }
        ...DealLink_swap
        ...useDealStatus_swap
      }
    `,
    dealDataKey,
  )

  const connectedAddress = useConnectedAddress()
  const connectedViewer = addressesEqual(connectedAddress, deal.maker.address)
    ? "maker"
    : addressesEqual(connectedAddress, deal.taker.address)
    ? "taker"
    : "public"
  const accountViewer = addressesEqual(accountAddress, deal.maker.address)
    ? "maker"
    : addressesEqual(accountAddress, deal.taker.address)
    ? "taker"
    : "public"

  const formatTimeRelativeToNow = useFromNowShort()

  const makerAssetQuantities = getNodes(deal.makerAssetQuantities)
  const makerAssets = makerAssetQuantities
    .filter(
      makerAssetQuantities =>
        makerAssetQuantities.asset.tokenStandard !== "ERC20",
    )
    .map(({ asset }) => asset)
  const takerAssetQuantities = getNodes(deal.takerAssetQuantities)
  const takerAssets = takerAssetQuantities
    .filter(
      takerAssetQuantities =>
        takerAssetQuantities.asset.tokenStandard !== "ERC20",
    )
    .map(({ asset }) => asset)
  const makerTokens = makerAssetQuantities.filter(
    makerAssetQuantity => makerAssetQuantity.asset.tokenStandard === "ERC20",
  )
  const takerTokens = takerAssetQuantities.filter(
    takerAssetQuantity => takerAssetQuantity.asset.tokenStandard === "ERC20",
  )
  // Subtract 1 from assetCount if there is an ERC20 token in the swap
  const makerNftCount = makerAssets.length
  const takerNftCount = takerAssets.length

  const dealStatus = useDealStatus(deal)
  const dealStatusText = useDealStatusText(dealStatus)
  const showExpiration = dealStatus === "active" || dealStatus === "expired"
  const showDealStatus =
    isFinalizedDeal(dealStatus) || dealStatus === "inactive"
  const expirationTime = deal.closedAt ? dateFromISO8601(deal.closedAt) : null
  const isExpired = deal.closedAt
    ? isBefore(dateFromISO8601(deal.closedAt), new Date())
    : false

  const makerDisplayName =
    deal.maker.displayName ?? truncateAddress(deal.maker.address)
  const takerDisplayName =
    deal.taker.displayName ?? truncateAddress(deal.taker.address)

  const getText = (
    nftCount: number,
    tokens: typeof makerTokens | typeof takerTokens,
  ) =>
    nftCount > 0 && tokens.length > 0
      ? t(
          "dealCard.youGive.itemCountWithToken",
          {
            0: "{{itemCount}} items, {{tokenQuantity}} {{tokenSymbol}}",
            one: "{{itemCount}} item, {{tokenQuantity}} {{tokenSymbol}}",
            other: "{{itemCount}} items, {{tokenQuantity}} {{tokenSymbol}}",
          },
          {
            itemCount: display(nftCount),
            count: nftCount,
            tokenQuantity: display(
              bn(tokens[0].quantity, tokens[0].asset.decimals),
            ),
            tokenSymbol: tokens[0].asset.symbol,
          },
        )
      : nftCount > 0
      ? t(
          "dealCard.youGive.itemCount",
          {
            0: "{{itemCount}} items",
            one: "{{itemCount}} item",
            other: "{{itemCount}} items",
          },
          {
            itemCount: display(nftCount),
            count: nftCount,
          },
        )
      : null
  const makerAssetsText = getText(makerNftCount, makerTokens)
  const takerAssetsText = getText(takerNftCount, takerTokens)

  const onClick = useCallback(() => {
    trackClick({
      dealRelayId: deal.relayId,
      dealStatus,
      viewer: connectedViewer,
    })
  }, [connectedViewer, deal.relayId, dealStatus, trackClick])

  return (
    <DealLink swap={deal} onClick={onClick}>
      <CardContainer
        className="rounded-2xl border border-level-1"
        flexDirection={{ _: "column", lg: "row" }}
      >
        <Media lessThan="lg">
          <ItemsDisplayContainer className="gap-4" padding="24px">
            <>
              <FlexColumn className="max-w-[128px] flex-1 gap-2">
                <StackedAssetMedia assets={makerAssets} variant="large" />
                {makerTokens.length > 0 && (
                  <FlexEnd className="gap-1 rounded-lg bg-component-gray-1 px-2 py-1.5">
                    <Text.Body size="small" weight="semibold">
                      +{" "}
                      {display(
                        bn(
                          makerTokens[0].quantity,
                          makerTokens[0].asset.decimals,
                        ),
                      )}
                    </Text.Body>
                    {makerTokens[0] && (
                      <Block>
                        <AssetMedia asset={makerTokens[0].asset} size={16} />
                      </Block>
                    )}
                  </FlexEnd>
                )}
              </FlexColumn>

              <CenterAligned
                className={classNames(
                  (makerTokens.length > 0 || takerTokens.length > 0) && "pb-10",
                )}
              >
                <Icon size={24} value="handshake" />
              </CenterAligned>

              <FlexColumn
                className="max-w-[128px] flex-1 gap-2"
                data-testid="stacked-asset"
              >
                <StackedAssetMedia assets={takerAssets} variant="large" />
                {takerTokens.length > 0 && (
                  <FlexEnd className="gap-1 rounded-lg bg-component-gray-1 px-2 py-1.5">
                    <Text.Body size="small" weight="semibold">
                      +{" "}
                      {display(
                        bn(
                          takerTokens[0].quantity,
                          takerTokens[0].asset.decimals,
                        ),
                      )}
                    </Text.Body>
                    {takerTokens[0] && (
                      <Block>
                        <AssetMedia asset={takerTokens[0].asset} size={16} />
                      </Block>
                    )}
                  </FlexEnd>
                )}
              </FlexColumn>
            </>
          </ItemsDisplayContainer>

          <FlexColumn className="flex-1 gap-3 px-6 pb-6">
            {accountViewer === "maker" ? (
              <SpaceBetween>
                <Text.Body size="small" weight="semibold">
                  {t("dealCard.to.label", "To")}
                </Text.Body>
                <Text.Body className="text-secondary" size="small">
                  {takerDisplayName}
                </Text.Body>
              </SpaceBetween>
            ) : (
              <SpaceBetween>
                <Text.Body size="small" weight="semibold">
                  {t("dealCard.from.label", "From")}
                </Text.Body>
                <Text.Body className="text-secondary" size="small">
                  {makerDisplayName}
                </Text.Body>
              </SpaceBetween>
            )}
            <Separator />
            <SpaceBetween>
              <Text.Body size="small" weight="semibold">
                {makerDisplayName}
              </Text.Body>
              <Text.Body className="text-secondary" size="small">
                {makerAssetsText}
              </Text.Body>
            </SpaceBetween>
            <Separator />
            <SpaceBetween>
              <Text.Body size="small" weight="semibold">
                {takerDisplayName}
              </Text.Body>
              <Text.Body className="text-secondary" size="small">
                {takerAssetsText}
              </Text.Body>
            </SpaceBetween>
            {showExpiration ? (
              <>
                <Separator />
                <SpaceBetween>
                  <Text.Body size="small" weight="semibold">
                    {isExpired
                      ? t("dealCard.expired.label", "Expired")
                      : t("dealCard.expires.label", "Expires")}
                  </Text.Body>
                  <Text.Body className="text-secondary" size="small">
                    {formatTimeRelativeToNow(
                      deal.closedAt ? dateFromISO8601(deal.closedAt) : null,
                    )}
                  </Text.Body>
                </SpaceBetween>
              </>
            ) : dealStatus === "cancelled" ? (
              <>
                <Separator />
                <SpaceBetween>
                  <Text.Body size="small" weight="semibold">
                    {t("dealCard.canceled.label", "Canceled")}
                  </Text.Body>
                </SpaceBetween>
              </>
            ) : null}
            <Separator />
            <Flex flex="none" height="40px" justifyContent="flex-end">
              <DealCardDropdown
                chain={makerAssets[0].chain.identifier}
                connectedViewer={connectedViewer}
                dealStatus={dealStatus}
                relayId={deal.relayId}
              />
            </Flex>
          </FlexColumn>
        </Media>

        <Media greaterThanOrEqual="lg">
          {mediaClassNames => (
            <>
              <ItemsDisplayContainer className="gap-4 p-6">
                <FlexColumn className="max-w-[128px] flex-1 gap-2">
                  <StackedAssetMedia assets={makerAssets} variant="large" />
                  {makerTokens.length > 0 && (
                    <FlexEnd className="gap-1 rounded-lg bg-component-gray-1 px-2 py-1.5">
                      <Text.Body size="small" weight="semibold">
                        +{" "}
                        {display(
                          bn(
                            makerTokens[0].quantity,
                            makerTokens[0].asset.decimals,
                          ),
                        )}
                      </Text.Body>
                      {makerTokens[0] && (
                        <Block>
                          <AssetMedia asset={makerTokens[0].asset} size={16} />
                        </Block>
                      )}
                    </FlexEnd>
                  )}
                </FlexColumn>

                <CenterAligned
                  className={classNames(
                    (makerTokens.length > 0 || takerTokens.length > 0) &&
                      "pb-10",
                  )}
                >
                  <Icon size={24} value="handshake" />
                </CenterAligned>

                <FlexColumn
                  className="max-w-[128px] flex-1 gap-2"
                  data-testid="stacked-asset"
                >
                  <StackedAssetMedia assets={takerAssets} variant="large" />
                  {takerTokens.length > 0 && (
                    <FlexEnd className="gap-1 rounded-lg bg-component-gray-1 px-2 py-1.5">
                      <Text.Body size="small" weight="semibold">
                        +{" "}
                        {display(
                          bn(
                            takerTokens[0].quantity,
                            takerTokens[0].asset.decimals,
                          ),
                        )}
                      </Text.Body>
                      {takerTokens[0] && (
                        <Block>
                          <AssetMedia asset={takerTokens[0].asset} size={16} />
                        </Block>
                      )}
                    </FlexEnd>
                  )}
                </FlexColumn>
              </ItemsDisplayContainer>
              <FlexColumn className={classNames("flex-1", mediaClassNames)}>
                <Flex
                  alignItems="center"
                  className="border-b border-level-1"
                  flex="1"
                  justifyContent="space-between"
                >
                  <Flex alignItems="center" className="gap-3" padding="24px">
                    <Block height="28px" width="28px">
                      {/* Always show the profile image of the other user. */}
                      <ProfileImage
                        data={
                          accountViewer === "maker" ? deal.taker : deal.maker
                        }
                        size={28}
                      />
                    </Block>
                    <Text.Heading size="small">
                      {accountViewer === "maker"
                        ? t("dealCard.to.title", "To {{takerName}}", {
                            takerName:
                              deal.taker.displayName ??
                              truncateAddress(deal.taker.address),
                          })
                        : t("dealCard.from.title", "From {{makerName}}", {
                            makerName:
                              deal.maker.displayName ??
                              truncateAddress(deal.maker.address),
                          })}
                    </Text.Heading>
                    <Text.Body className="text-secondary" size="small">
                      {formatTimeRelativeToNow(
                        dateFromISO8601(deal.createdDate),
                      )}
                    </Text.Body>
                  </Flex>

                  <Flex height="40px" paddingRight="24px">
                    <DealCardDropdown
                      chain={makerAssets[0].chain.identifier}
                      connectedViewer={connectedViewer}
                      dealStatus={dealStatus}
                      relayId={deal.relayId}
                    />
                  </Flex>
                </Flex>
                <Flex className="gap-2" padding="24px">
                  <Flex className="gap-1">
                    <Text.Body size="small" weight="semibold">
                      {makerDisplayName}
                    </Text.Body>
                    <Text.Body className="text-secondary" size="small">
                      {makerAssetsText}
                    </Text.Body>
                  </Flex>
                  <CenterAligned>
                    <Separator orientation="vertical" />
                  </CenterAligned>
                  <Flex className="gap-1">
                    <Text.Body size="small" weight="semibold">
                      {takerDisplayName}
                    </Text.Body>
                    <Text.Body className="text-secondary" size="small">
                      {takerAssetsText}
                    </Text.Body>
                  </Flex>
                  {showExpiration && (
                    <>
                      <CenterAligned>
                        <Separator orientation="vertical" />
                      </CenterAligned>
                      <Text.Body className="text-secondary" size="small">
                        {dealStatus === "expired"
                          ? t(
                              "dealCard.expired.withTime",
                              "Expired {{relativeTime}}",
                              {
                                relativeTime:
                                  formatTimeRelativeToNow(expirationTime),
                              },
                            )
                          : t(
                              "dealCard.expires.withTime",
                              "Expires {{relativeTime}}",
                              {
                                relativeTime:
                                  formatTimeRelativeToNow(expirationTime),
                              },
                            )}
                      </Text.Body>
                    </>
                  )}
                </Flex>
                {showDealStatus && (
                  <Text.Body
                    className="px-6 pb-6"
                    size="small"
                    weight="semibold"
                  >
                    {dealStatusText}
                  </Text.Body>
                )}
              </FlexColumn>
            </>
          )}
        </Media>
      </CardContainer>
    </DealLink>
  )
}

const CardContainer = styled(Flex)`
  background-color: ${({ theme }) =>
    theme.colors.components.elevation.level1.regular.background};
  border: 1px solid ${({ theme }) => theme.colors.components.border.level1};
  border-radius: ${({ theme }) => theme.borderRadius.xlarge};
  box-shadow: ${({ theme }) =>
    theme.colors.components.elevation.level1.subtle.shadow};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  ${media({
    lg: css`
      border-right: 1px solid
        ${({ theme }) => theme.colors.components.border.level1};
      flex-direction: row;
    `,
  })}

  :hover:not([disabled]),
  :active:not([disabled]) {
    background-color: ${({ theme }) =>
      theme.colors.components.elevation.level2.background};
    box-shadow: ${({ theme }) =>
      theme.colors.components.elevation.level1.regular.shadow};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

const ItemsDisplayContainer = styled(Flex)`
  ${media({
    xs: css`
      justify-content: center;
    `,
    lg: css`
      border-right: 1px solid
        ${({ theme }) => theme.colors.components.border.level1};
      width: 360px;
    `,
  })}
`
