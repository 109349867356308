/**
 * @generated SignedSource<<2d7439a5726ab911a09b95ca84ccaa65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountTrackingContext_account$data = {
  readonly address: string;
  readonly displayName: string | null;
  readonly relayId: string;
  readonly " $fragmentType": "AccountTrackingContext_account";
};
export type AccountTrackingContext_account$key = {
  readonly " $data"?: AccountTrackingContext_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountTrackingContext_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountTrackingContext_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "fbedaa368553850da4278c752d512b86";

export default node;
