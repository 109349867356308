import { graphql } from "relay-runtime"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { useProfileCreatedQuery } from "@/lib/graphql/__generated__/useProfileCreatedQuery.graphql"

type Props = {
  identity?: IdentityInputType
}

export const useProfileCreated = ({ identity }: Props) => {
  const [createdData] = useNoSuspenseLazyLoadQuery<useProfileCreatedQuery>(
    graphql`
      query useProfileCreatedQuery($identity: IdentityInputType!) {
        searchItems(
          creator: $identity
          first: 0
          resultType: ASSETS
          sortBy: CREATED_DATE
        ) {
          totalCount
        }
      }
    `,
    { identity: identity ?? {} },
    { skip: !identity },
  )

  const created = createdData?.searchItems.totalCount
  return created ?? null
}
