import { useContextSelector } from "use-context-selector"
import { AssetSelectionContext } from "../components/AccountPage/components/AssetSelectionContextProvider.react"

export const useIsAssetErc721cDisabled = () => {
  const selectedAssets = useContextSelector(AssetSelectionContext, context =>
    context.selectedItems.filter(item => item.isErc721cFeeEnforced),
  )

  return selectedAssets.length > 1
}
