/* istanbul ignore file */
import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Item } from "@/design-system/Item"
import { PriceText } from "@/design-system/PriceText"
import { useTranslate } from "@/hooks/useTranslate"
import { ProfileOffersCollectionFilterItem_collection$key } from "@/lib/graphql/__generated__/ProfileOffersCollectionFilterItem_collection.graphql"
import {
  MAX_DISPLAYED_DECIMAL_PLACES,
  display,
} from "@/lib/helpers/numberUtils"
import { Overflow } from "../common/Overflow"
import { ProfileCollectionFilterItem } from "./ProfileCollectionFilterItem.react"

type Props = {
  collection: ProfileOffersCollectionFilterItem_collection$key | null
}

const IMAGE_SIZE = 36

export const ProfileOffersCollectionFilterItem = ({
  collection: collectionDataKey,
}: Props) => {
  const t = useTranslate("components")
  const collection = useFragment(
    graphql`
      fragment ProfileOffersCollectionFilterItem_collection on CollectionType
      @argumentDefinitions(owner: { type: "IdentityInputType!" }) {
        imageUrl
        name
        slug
        isVerified
        collectionAssetOwner(identity: $owner) {
          numOffers
          offersMadeValue {
            symbol
            unit
          }
        }
        statsV2 {
          floorPrice {
            unit
            symbol
          }
        }
      }
    `,
    collectionDataKey,
  )

  if (!collection) {
    return null
  }

  const { collectionAssetOwner, imageUrl, name, isVerified, slug, statsV2 } =
    collection

  const itemCount: number = collectionAssetOwner?.numOffers
    ? parseInt(collectionAssetOwner.numOffers)
    : 0
  return (
    <ProfileCollectionFilterItem.Item key={slug}>
      <ProfileCollectionFilterItem.Avatar
        size={IMAGE_SIZE}
        src={imageUrl ?? ""}
      />
      <Item.Content className="mr-0">
        <Item.Title className="flex w-full justify-between">
          <Overflow>
            <Flex>
              <Text className="truncate" size="small">
                {name}
              </Text>
              {isVerified && (
                <VerificationIcon
                  showTooltip={false}
                  size="tiny"
                  verificationStatus="VERIFIED"
                />
              )}
            </Flex>
          </Overflow>
          <PriceText
            className="text-ellipsis whitespace-nowrap pl-2"
            roundBelowDecimals={MAX_DISPLAYED_DECIMAL_PLACES}
            size="small"
            symbol={collectionAssetOwner?.offersMadeValue.symbol}
            unit={collectionAssetOwner?.offersMadeValue.unit}
          />
        </Item.Title>
        <Item.Description
          className="flex w-full justify-between pb-0.5 pt-1 text-secondary"
          size="tiny"
        >
          <span>
            {t("profileCollectionFilter.floorPrice", "Floor: {{price}}", {
              price: (
                <PriceText
                  className="text-ellipsis whitespace-nowrap text-inherit"
                  roundBelowDecimals={MAX_DISPLAYED_DECIMAL_PLACES}
                  size="tiny"
                  symbol={statsV2.floorPrice?.symbol}
                  unit={statsV2.floorPrice?.unit}
                />
              ),
            })}
          </span>
          <span className="text-ellipsis whitespace-nowrap">
            {t(
              "profileCollectionFilter.numOffers",
              {
                0: `${itemCount} Offers`,
                one: `${itemCount} Offer`,
                other: `${itemCount} Offers`,
              },
              { count: itemCount, itemCount: display(itemCount) },
              { forceString: true },
            )}
          </span>
        </Item.Description>
      </Item.Content>
    </ProfileCollectionFilterItem.Item>
  )
}
