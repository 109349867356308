import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Button, ButtonProps } from "@/design-system/Button"
import { PortfolioTableButtonSource } from "@/features/account/components/PortfolioTable/analytics"
import { MakeAssetOfferButton } from "@/features/orders/components/MakeAssetOfferButton.react"
import { PortfolioTableMakeOfferButton_asset$key } from "@/lib/graphql/__generated__/PortfolioTableMakeOfferButton_asset.graphql"

type BaseButtonProps = Omit<ButtonProps, "order">

type Props = {
  asset: PortfolioTableMakeOfferButton_asset$key
  source: PortfolioTableButtonSource
} & BaseButtonProps

const PortfolioTableMakeOfferButtonBase = ({
  asset: assetKey,
  source,
  ...buttonProps
}: Props) => {
  const asset = useFragment(
    graphql`
      fragment PortfolioTableMakeOfferButton_asset on AssetType
      @argumentDefinitions(showOwnerActions: { type: "Boolean!" }) {
        ...MakeAssetOfferButton_asset @skip(if: $showOwnerActions)
      }
    `,
    assetKey,
  )

  return (
    <MakeAssetOfferButton
      asset={asset}
      overrides={{
        Button: {
          props: {
            icon: undefined, // Hide default icon.
            ...buttonProps,
          },
        },
      }}
      source={
        source === "collapsed"
          ? "portfolio table collapsed row"
          : "portfolio table expanded row"
      }
    />
  )
}

const Skeleton = (buttonProps: BaseButtonProps) => {
  return <Button disabled isLoading variant="secondary" {...buttonProps} />
}

export const PortfolioTableMakeOfferButton = Object.assign(
  PortfolioTableMakeOfferButtonBase,
  {
    Skeleton,
  },
)
