/**
 * @generated SignedSource<<9b609d7758e5ff8f74f8a824b385020a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableMakeOfferButton_asset$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MakeAssetOfferButton_asset">;
  readonly " $fragmentType": "PortfolioTableMakeOfferButton_asset";
};
export type PortfolioTableMakeOfferButton_asset$key = {
  readonly " $data"?: PortfolioTableMakeOfferButton_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableMakeOfferButton_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableMakeOfferButton_asset",
  "selections": [
    {
      "condition": "showOwnerActions",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MakeAssetOfferButton_asset"
        }
      ]
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "a016be57683d29b5598953ab6d61a4c3";

export default node;
