import React from "react"
import { Flex, Icon } from "@opensea/ui-kit"
import styled from "styled-components"
import { ChainLogoWithTooltip } from "@/components/common/ChainLogo"
import { TextCopierButton } from "@/components/common/TextCopierButton.react"
import { ETH_ICON_URL } from "@/constants"
import { Avatar } from "@/design-system/Avatar"
import { DropdownItem } from "@/design-system/Dropdown/elements"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { themeVariant } from "@/styles/styleUtils"

export const TextCopy = ({
  text,
  chainIdentifier,
  fontSize,
  label,
  ItemComponent,
}: {
  text: string
  label?: string
  fontSize?: string | number
  chainIdentifier: ChainIdentifier
  ItemComponent: typeof DropdownItem
}) => (
  <CopierContainer text={text}>
    <ItemComponent.Content>
      <Flex className="items-center">
        {chainIdentifier === "ETHEREUM" ? (
          <Avatar
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="CopierContainer--eth-icon"
            size={20}
            src={ETH_ICON_URL}
          />
        ) : (
          <StyledChainLogo chain={chainIdentifier} />
        )}
        <Flex className="ml-3" style={{ fontSize }}>
          {label || text}
        </Flex>
      </Flex>
    </ItemComponent.Content>
    <ItemComponent.Action>
      <Icon className="ml-auto" size={16} value="content_copy" weight={600} />
    </ItemComponent.Action>
  </CopierContainer>
)

const CopierContainer = styled(TextCopierButton)`
  width: 100%;

  .CopierContainer--eth-icon {
    ${themeVariant({
      variants: {
        dark: {
          filter: "brightness(3)",
        },
      },
    })}
  }
`
const StyledChainLogo = styled(ChainLogoWithTooltip)`
  svg {
    fill: inherit;
  }
`
