import React, { ComponentProps, useContext, useEffect } from "react"
import { Media, Separator, UnstyledButton, Text, Flex } from "@opensea/ui-kit"
import { _FragmentRefs } from "relay-runtime"
import styled from "styled-components"
import { AssetContextMenu } from "@/components/common/AssetContextMenu"
import { BottomActionBar } from "@/components/common/BottomActionBar"
import { ChainLogo } from "@/components/common/ChainLogo"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { useSearchContextItems } from "@/components/search/utils/SearchContextProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { linkStyles } from "@/design-system/utils"
import {
  useAssetContextActions,
  useAssetContextSelectedIds,
  useAssetContextSelectedItems,
  useToggleAssetSelection,
} from "@/features/account/hooks/useAssetSelection"
import { readAssetSelectionItem } from "@/features/assets/hooks/useAssetSelectionStorage"
import { useChains } from "@/hooks/useChains"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { first } from "@/lib/helpers/array"
import { AccountPageContext } from "../../tabs/utils"
import { AcceptOffersButton } from "./components/AcceptOffersButton.react"
import { AssetsContextMenu } from "./components/AssetsContextMenu.react"
import { CancelListingsButton } from "./components/CancelListingsButton.react"
import { ItemVisibilityButton } from "./components/ItemVisibilityButton.react"
import { ListItemsButton } from "./components/ListItemsButton.react"
import { TransferButton } from "./components/TransferButton.react"

type Props = {
  reloadSearch: () => unknown
  itemVisibilityAction: ComponentProps<typeof ItemVisibilityButton>["action"]
}

const MORE_OPTIONS_WIDTH_PX = "48px"

export const ProfileItemSelectionFooter = <
  T extends _FragmentRefs<"useAssetSelectionStorage_item">[],
>({
  reloadSearch,
  itemVisibilityAction,
}: Props) => {
  const t = useTranslate("account")
  const { exclude: recentlyHiddenItems } = useContext(AccountPageContext)

  const selectedIds = useAssetContextSelectedIds()
  const selectedItems = useAssetContextSelectedItems()
  const { onDeleteAll, onBatchSelect } = useAssetContextActions()

  const {
    isOpen: isMoreOptionsOpen,
    open: openMoreOptions,
    close: closeMoreOptions,
  } = useIsOpen()
  const { setIsFooterShown } = useAccountOrCollectionPageContext()
  const { getChainName } = useChains()
  const [items] = useSearchContextItems<T>()

  const numItems = selectedIds.length
  const isOpen = numItems > 0

  useEffect(() => {
    setIsFooterShown(isOpen)
  }, [isOpen, setIsFooterShown])

  const singleItem = selectedItems.length === 1 ? selectedItems[0] : null
  const selectedChain = first(selectedItems)?.chain.identifier

  const toggleAssetSelected = useToggleAssetSelection(singleItem)

  const renderSelectAllButton = (useLinkText?: boolean) => {
    const TextComponent = useLinkText ? FauxLinkText : Text
    return (
      <UnstyledButton
        onClick={() => {
          const firstChain = first(selectedItems)?.chain.identifier

          if (items) {
            // Only select items with the first chain for now
            onBatchSelect(
              items
                .map(readAssetSelectionItem)
                .filter(
                  ({ chain, relayId }) =>
                    !recentlyHiddenItems.includes(relayId) &&
                    chain.identifier === firstChain,
                ),
            )
          }
        }}
      >
        <Flex className="items-center">
          <TextComponent size="medium">
            {t("selectionFooter.selectAll", "Select all")}
          </TextComponent>

          {selectedChain && (
            <Tooltip content={getChainName(selectedChain)}>
              <Flex className="ml-2">
                <ChainLogo chain={selectedChain} variant="gray" />
              </Flex>
            </Tooltip>
          )}
        </Flex>
      </UnstyledButton>
    )
  }

  return (
    <>
      {singleItem && (
        <AssetContextMenu
          dataKey={singleItem}
          isMobile
          isOpen={isMoreOptionsOpen}
          source="profile item selection footer"
          toggleAssetSelected={toggleAssetSelected}
          onClose={closeMoreOptions}
        />
      )}

      {selectedItems.length > 1 && (
        <AssetsContextMenu
          isOpen={isMoreOptionsOpen}
          itemVisibilityAction={itemVisibilityAction}
          reloadSearch={reloadSearch}
          title={t("selectionFooter.bottomSheet.title", "More options")}
          onClose={closeMoreOptions}
        />
      )}
      <Media lessThan="md">
        <BottomActionBar className="relative gap-3" isOpen={isOpen}>
          <Button
            icon="more_horiz"
            variant="secondary"
            onClick={openMoreOptions}
          />

          <ListItemsButton
            className="w-full"
            overrides={{
              Block: {
                props: {
                  width: `calc(100% - ${MORE_OPTIONS_WIDTH_PX})`,
                  paddingLeft: "12px",
                },
              },
            }}
          />
        </BottomActionBar>
      </Media>
      <Media greaterThanOrEqual="md">
        <BottomActionBar className="justify-between" isOpen={isOpen}>
          <Flex>
            <Block marginRight="16px">
              <Text.Body weight="semibold">
                {t(
                  "selectionFooter.itemCount",
                  {
                    "0": "{{count}} items",
                    one: "{{count}} item",
                    other: "{{count}} items",
                  },
                  { count: numItems },
                )}
              </Text.Body>
            </Block>

            {renderSelectAllButton(true)}

            <Block height="20px" marginX="12px">
              <Separator orientation="vertical" />
            </Block>
            <UnstyledButton onClick={onDeleteAll}>
              <FauxLinkText>
                {t("selectionFooter.clearSelections", "Clear")}
              </FauxLinkText>
            </UnstyledButton>
          </Flex>

          <Flex className="gap-4">
            <Media greaterThanOrEqual="lg">
              <Flex className="gap-4">
                <CancelListingsButton
                  onListingsCanceled={() => {
                    onDeleteAll()
                  }}
                />
                <ItemVisibilityButton action={itemVisibilityAction} />
                <TransferButton />
              </Flex>
            </Media>
            <Media lessThan="lg">
              <Button
                icon="more_horiz"
                variant="secondary"
                onClick={openMoreOptions}
              />
            </Media>

            <AcceptOffersButton
              onOffersAccepted={() => {
                onDeleteAll()
                reloadSearch()
              }}
            />
            <ListItemsButton />
          </Flex>
        </BottomActionBar>
      </Media>
    </>
  )
}

const FauxLinkText = styled(Text.Body)`
  ${linkStyles}
`
