import React, { ReactNode, useCallback, useMemo, useRef } from "react"
import { UnstyledButton, Text, CenterAligned, Skeleton } from "@opensea/ui-kit"
import { range } from "lodash"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
  useRelayEnvironment,
} from "react-relay"
import { EditListingButton } from "@/components/orders/EditListingButton.react"
import { HoverIcon } from "@/components/orders/HoverIcon.react"
import { removeLocalItemBestAsk } from "@/components/orders/utils"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { Block } from "@/design-system/Block"
import {
  loadNextToLoadMore,
  ScrollingPaginator,
} from "@/design-system/ScrollingPaginator"
import { Table, TableProps } from "@/design-system/Table"
import {
  useCanceledListingRelayIds,
  useCanceledListingsItemRelayIds,
  useUpdatedItemRelayIds,
} from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import {
  StyledTableCell,
  TableHeaderText,
} from "@/features/account/components/PortfolioTable/components/styles"
import {
  MAX_SKELETON_LISTINGS_TO_DISPLAY_COUNT,
  MAX_TRAITS_TO_DISPLAY_COUNT,
  MAX_TRAITS_TO_LOAD_COUNT,
} from "@/features/account/components/PortfolioTable/constants"
import { CancelOrderButton } from "@/features/cancel-orders/components/CancelOrderButton.react"
import {
  PaginationArrows,
  PaginationArrowsSkeleton,
} from "@/features/pagination/components/PaginationArrows.react"
import { usePaginationArrows } from "@/features/pagination/hooks/usePaginationArrows"
import { useTranslate } from "@/hooks/useTranslate"
import { PortfolioTableListingsTable_asset$key } from "@/lib/graphql/__generated__/PortfolioTableListingsTable_asset.graphql"
import { PortfolioTableListingsTable_data$key } from "@/lib/graphql/__generated__/PortfolioTableListingsTable_data.graphql"
import { PortfolioTableListingsTablePaginationQuery } from "@/lib/graphql/__generated__/PortfolioTableListingsTablePaginationQuery.graphql"
import { PortfolioTableListingsTableQuery } from "@/lib/graphql/__generated__/PortfolioTableListingsTableQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { useListingsTableColumns } from "../../hooks/useListingsTableColumns"
import { ListingsColumn } from "../../types"
import { setLocalItemBestAsk } from "../setLocalItemBestAsk"
import { PortfolioTableListingsTableRow } from "./PortfolioTableListingsTableRow.react"

type PortfolioTableListingsTableProps = {
  accountAddress: string
  asset: PortfolioTableListingsTable_asset$key
  isOwnerMode: boolean
  hasArrowPagination?: boolean
}

const LazyPortfolioTableListingsTable = ({
  accountAddress,
  asset: assetKey,
  isOwnerMode,
  hasArrowPagination,
}: PortfolioTableListingsTableProps) => {
  const t = useTranslate("account")
  const asset = useFragment(
    graphql`
      fragment PortfolioTableListingsTable_asset on AssetType {
        ...EditListingButton_item
        relayId
        chain {
          identifier
        }
        assetContract {
          address
        }
        isCurrentlyFungible
        tokenId
      }
    `,
    assetKey,
  )

  const initialData = useLazyLoadQuery<PortfolioTableListingsTableQuery>(
    graphql`
      query PortfolioTableListingsTableQuery(
        $cursor: String
        $count: Int
        $isExpired: Boolean
        $isValid: Boolean
        $maker: IdentityInputType
        $makerArchetype: ArchetypeInputType
        $sortAscending: Boolean
        $sortBy: OrderSortOption
        $showOwnerActions: Boolean!
      ) {
        ...PortfolioTableListingsTable_data
          @arguments(
            cursor: $cursor
            count: $count
            isExpired: $isExpired
            isValid: $isValid
            maker: $maker
            makerArchetype: $makerArchetype
            sortAscending: $sortAscending
            sortBy: $sortBy
            showOwnerActions: $showOwnerActions
          )
      }
    `,
    {
      maker: { address: accountAddress },
      isExpired: false,
      isValid: true,
      sortAscending: true,
      sortBy: "TAKER_ASSETS_USD_PRICE",
      makerArchetype: {
        assetContractAddress: asset.assetContract.address,
        tokenId: asset.tokenId,
        chain: asset.chain.identifier,
      },
      count: hasArrowPagination
        ? MAX_TRAITS_TO_LOAD_COUNT
        : GLOBAL_MAX_PAGE_SIZE,
      showOwnerActions: isOwnerMode,
    },
  )

  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
    isLoadingPrevious,
    loadPrevious,
  } = usePaginationFragment<
    PortfolioTableListingsTablePaginationQuery,
    PortfolioTableListingsTable_data$key
  >(
    graphql`
      fragment PortfolioTableListingsTable_data on Query
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 5 }
        last: { type: "Int" }
        before: { type: "String" }
        isExpired: { type: "Boolean" }
        isValid: { type: "Boolean" }
        maker: { type: "IdentityInputType" }
        makerArchetype: { type: "ArchetypeInputType" }
        sortAscending: { type: "Boolean" }
        sortBy: { type: "OrderSortOption" }
        showOwnerActions: { type: "Boolean!" }
      )
      @refetchable(queryName: "PortfolioTableListingsTablePaginationQuery") {
        orders(
          after: $cursor
          first: $count
          before: $before
          last: $last
          isExpired: $isExpired
          isValid: $isValid
          maker: $maker
          makerArchetype: $makerArchetype
          sortAscending: $sortAscending
          sortBy: $sortBy
        ) @connection(key: "PortfolioTableListingsTable_orders") {
          count
          edges {
            node {
              relayId
              ...setLocalItemBestAsk_order
                @arguments(showOwnerActions: $showOwnerActions)
              ...PortfolioTableListingsTableRow_data
              ...CancelOrderButton_data
              ...EditListingButton_listing
            }
          }
        }
      }
    `,
    initialData,
  )

  const [canceledListingRelayIds, setCanceledListingRelayIds] =
    useCanceledListingRelayIds()
  const [canceledListingsItemRelayIds] = useCanceledListingsItemRelayIds()
  const [updatedItemRelayIds, setUpdatedItemRelayIds] = useUpdatedItemRelayIds()
  const environment = useRelayEnvironment()

  const hasItemChanged = updatedItemRelayIds.includes(asset.relayId)
  React.useEffect(() => {
    if (hasItemChanged) {
      refetch({}, { fetchPolicy: "network-only" })
      setUpdatedItemRelayIds(prevRelayIds =>
        prevRelayIds.filter(relayId => relayId !== asset.relayId),
      )
    }
  }, [hasItemChanged, setUpdatedItemRelayIds, asset.relayId, refetch])

  const hasAllListingsCanceled = canceledListingsItemRelayIds.includes(
    asset.relayId,
  )
  const listings = hasAllListingsCanceled ? [] : getNodes(data.orders)
  const activeListings = listings.filter(
    listing => !canceledListingRelayIds.includes(listing.relayId),
  )
  const { currentPage, getStartIndex, setCurrentPage } = usePaginationArrows()

  const displayedListings = !hasArrowPagination
    ? activeListings
    : activeListings.slice(
        getStartIndex(currentPage),
        getStartIndex(currentPage) + MAX_TRAITS_TO_DISPLAY_COUNT,
      )

  const handleLoadNextPage = useCallback(() => {
    setCurrentPage(prev => prev + 1)
    loadNext(MAX_TRAITS_TO_DISPLAY_COUNT)
  }, [loadNext, setCurrentPage])

  const handleLoadPreviousPage = useCallback(() => {
    setCurrentPage(prev => prev - 1)
    loadPrevious(MAX_TRAITS_TO_DISPLAY_COUNT)
  }, [loadPrevious, setCurrentPage])

  const renderPaginationControls = () => (
    <PaginationArrows
      currentPage={currentPage}
      handleLoadNextPage={handleLoadNextPage}
      handleLoadPreviousPage={handleLoadPreviousPage}
      paddingLeft="16px"
      totalItemCount={data.orders.count}
    />
  )

  const isFungible = asset.isCurrentlyFungible

  const containerRef = useRef<HTMLDivElement>(null)
  if (activeListings.length === 0) {
    return (
      <CenterAligned className="h-full">
        <Text.Body className="text-secondary" size="medium" weight="semibold">
          {t(
            "portfolio.listingsTable.noListingsPlaceholder",
            "This item has no listings from this account.",
          )}
        </Text.Body>
      </CenterAligned>
    )
  }

  if ((hasArrowPagination && isLoadingNext) || isLoadingPrevious) {
    return (
      <TableSkeleton
        hasArrowPagination={hasArrowPagination}
        isFungible={isFungible}
      />
    )
  }

  return (
    <Block maxHeight="100%" overflow="auto" ref={containerRef}>
      <TableOutline excludedColumns={isFungible ? [] : ["quantity"]}>
        {displayedListings.map(listing => (
          <PortfolioTableListingsTableRow
            dataKey={listing}
            isFungible={isFungible}
            key={listing.relayId}
            renderActions={listing => (
              <Block>
                <EditListingButton
                  item={asset}
                  listing={listing}
                  source="item card"
                  trigger={onClick => (
                    <UnstyledButton
                      aria-label={t(
                        "orderManager.editListing.button",
                        "Edit listing",
                      )}
                      onClick={onClick}
                    >
                      <HoverIcon className="p-3" value="edit" />
                    </UnstyledButton>
                  )}
                  onOrdersChanged={() => {
                    setUpdatedItemRelayIds(prevRelayIds =>
                      prevRelayIds.concat([asset.relayId]),
                    )
                  }}
                />
                <CancelOrderButton
                  dataKey={listing}
                  trigger={onClick => (
                    <UnstyledButton
                      aria-label={t("cancelOrder.cta", "Cancel listing")}
                      onClick={onClick}
                    >
                      <HoverIcon className="p-3" value="delete" />
                    </UnstyledButton>
                  )}
                  onOrderCanceled={() => {
                    setCanceledListingRelayIds(prevCanceledRelayIds =>
                      prevCanceledRelayIds.concat([listing.relayId]),
                    )
                    const nonCancelledListings = activeListings.filter(
                      currentListing =>
                        !canceledListingRelayIds
                          .concat([listing.relayId])
                          .includes(currentListing.relayId),
                    )
                    const bestAskV2 = nonCancelledListings[0]
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (bestAskV2) {
                      setLocalItemBestAsk(environment, { listing: bestAskV2 })
                    } else {
                      removeLocalItemBestAsk(environment, [asset.relayId])
                    }
                  }}
                />
              </Block>
            )}
          />
        ))}
      </TableOutline>

      {hasArrowPagination ? (
        renderPaginationControls()
      ) : (
        <ScrollingPaginator
          intersectionOptions={{
            rootMargin: "72px",
            root: containerRef.current,
          }}
          page={{
            hasMore: () => hasNext,
            isLoading: () => isLoadingNext,
            loadMore: (count: number) =>
              loadNextToLoadMore({ loadNext, count }),
          }}
          size={GLOBAL_MAX_PAGE_SIZE}
        />
      )}
    </Block>
  )
}

type TableSkeleton = {
  isFungible?: boolean
  hasArrowPagination?: boolean
}

const TableSkeleton = ({ isFungible, hasArrowPagination }: TableSkeleton) => {
  return (
    <TableOutline excludedColumns={isFungible ? [] : ["quantity"]}>
      {range(MAX_SKELETON_LISTINGS_TO_DISPLAY_COUNT).map(index => (
        <Table.Row key={index}>
          <StyledTableCell>
            <StyledSkeletonLine />
          </StyledTableCell>
          <StyledTableCell>
            <StyledSkeletonLine />
          </StyledTableCell>
          <StyledTableCell>
            <StyledSkeletonLine />
          </StyledTableCell>
          <StyledTableCell>
            <StyledSkeletonLine />
          </StyledTableCell>
          {isFungible && (
            <StyledTableCell>
              <StyledSkeletonLine />
            </StyledTableCell>
          )}
        </Table.Row>
      ))}
      {hasArrowPagination && (
        <Block paddingLeft="16px">
          <PaginationArrowsSkeleton />
        </Block>
      )}
    </TableOutline>
  )
}

export const PortfolioTableListingsTable = Object.assign(
  LazyPortfolioTableListingsTable,
  { Skeleton: TableSkeleton },
)

const TableHeader: TableProps["renderHeader"] = ({ Header, header, index }) => {
  return (
    <Header
      backgroundColor="components.elevation.level3.background"
      key={index}
    >
      {header}
    </Header>
  )
}

const TableOutline = ({
  children,
  excludedColumns = [],
}: {
  excludedColumns?: Array<ListingsColumn>
  children: ReactNode
}) => {
  const columns = useListingsTableColumns().filter(
    column => !excludedColumns.includes(column.column),
  )
  const headers = useMemo(
    () =>
      columns.map((column, index) => (
        <TableHeaderText key={index}>{column.header}</TableHeaderText>
      )),
    [columns],
  )
  const minWidths = useMemo(
    () => columns.map(column => column.minWidth),
    [columns],
  )
  const maxWidths = useMemo(
    () => columns.map(column => column.maxWidth),
    [columns],
  )
  return (
    <Table
      headers={headers}
      maxColumnWidths={maxWidths}
      minColumnWidths={minWidths}
      renderHeader={TableHeader}
    >
      {children}
    </Table>
  )
}

const StyledSkeletonLine = () => <Skeleton.Line className="w-[65%]" />
