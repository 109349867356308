import React, { useCallback, useMemo, useState } from "react"
import {
  UnstyledButton,
  Text,
  Checkbox,
  Flex,
  classNames,
} from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { CollectionCell } from "@/components/collections/CollectionCell.react"
import { Overflow } from "@/components/common/Overflow"
import { ExpirationDate } from "@/components/orders/ExpirationDate"
import { Skeleton } from "@opensea/ui-kit"
import { VirtualizedTable } from "@/design-system/VirtualizedTable"
import { PortfolioTableItemCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableItemCell.react"
import { useOfferSelectionIsSelected } from "@/features/account/hooks/useOfferSelection"
import { OffersTableRow_order$key } from "@/lib/graphql/__generated__/OffersTableRow_order.graphql"
import { dateFromISO8601, useFromNow } from "@/lib/helpers/datetime"
import { OffersTableOptionsCell } from "./cells/OffersTableOptionsCell.react"
import { CHECKBOX_PADDING, COLUMN_CELL_PROPS, ROW_HEIGHT } from "./constants"
import {
  getStatusIndicatorVariant,
  useStatusIndicator,
} from "./hooks/useStatusIndicator"
import { useOffersTableContext } from "./OffersTableContext.react"
import { StatusIndicator } from "./styles"
import { OffersTableDateTooltip } from "./tooltips/OffersTableDateTooltip.react"
import { OffersTablePriceTooltip } from "./tooltips/OffersTablePriceTooltip.react"
import { OffersColumn, OffersViewMode } from "./types"

export type OffersTableRowProps = {
  order: OffersTableRow_order$key | null
  mode: OffersViewMode
  initialSelected?: boolean
  showChain?: boolean
  onSelect: (orderRelayId: string, selected: boolean) => unknown
}

const OffersTableRowBase = ({
  order: orderKey,
  mode,
  onSelect,
  showChain = false,
}: OffersTableRowProps) => {
  const order = useFragment<OffersTableRow_order$key>(
    graphql`
      fragment OffersTableRow_order on OrderV2Type
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        relayId
        item {
          __typename
          ...PortfolioTableItemCell_item @arguments(identity: $identity)
        }
        criteria {
          collection {
            ...CollectionCell_collection
          }
          trait {
            ...CollectionCell_trait
          }
        }
        closedAt
        isValid
        orderType
        openedAt
        remainingQuantityType
        ...OffersTableOptionsCell_order
        ...ExpirationDate_data
        ...OrderPrice
        ...OffersTablePriceTooltipContent_item
      }
    `,
    orderKey,
  )

  const { isDesktop, mobileColumn } = useOffersTableContext()
  const renderFromNow = useFromNow()
  const isOwnerMode = mode === "owner"
  const isSelected = useOfferSelectionIsSelected()
  const selected = order ? isSelected(order.relayId) : false
  const [isMouseHoveringRow, setIsMouseHoveringRow] = useState(false)

  const handleSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
      if (!order) {
        return
      }
      event?.stopPropagation()
      onSelect(order.relayId, !selected)
    },
    [order, onSelect, selected],
  )

  const { statusIndicatorLabels } = useStatusIndicator()

  const offerCell = useMemo(
    () =>
      order ? (
        <>
          {isOwnerMode && (
            <CheckboxTapTarget onClick={handleSelect}>
              <Checkbox checked={selected} size="small" />
            </CheckboxTapTarget>
          )}
          <Flex
            className={classNames("w-full self-center", isOwnerMode && "pr-8")}
          >
            {order.orderType === "CRITERIA" && order.criteria ? (
              <StyledCollectionCell
                collection={order.criteria.collection}
                fullscreenBreakpoint="xs"
                trait={order.criteria.trait}
                variant="small"
              />
            ) : (
              <PortfolioTableItemCell item={order.item} showChain={showChain} />
            )}
          </Flex>
        </>
      ) : (
        <></>
      ),
    [handleSelect, isOwnerMode, order, selected, showChain],
  )

  const { priceCell, statusCell, quantityCell, totalCell, expirationCell } =
    useMemo(() => {
      if (!order) {
        return {}
      }

      const statusIndicatorVariant = getStatusIndicatorVariant(
        order.isValid,
        order.closedAt,
      )

      const priceCell = (
        <OffersTablePriceTooltip item={order}>
          <Flex className="items-center">
            {isDesktop && (
              <StatusIndicator
                marginRight="8px"
                variant={statusIndicatorVariant}
              />
            )}
            <OrderPrice
              color="theme.colors.text.secondary"
              fontWeight={400}
              isInline
              order={order}
              symbolVariant="raw"
              variant="perUnit"
            />
          </Flex>
        </OffersTablePriceTooltip>
      )

      const statusCell = (
        <>
          <StatusIndicator marginRight="8px" variant={statusIndicatorVariant} />
          <Text.Body size="small">
            {statusIndicatorLabels[statusIndicatorVariant]}
          </Text.Body>
        </>
      )
      const quantityCell = order.remainingQuantityType
      const totalCell = (
        <OrderPrice
          color="theme.colors.text.secondary"
          fontWeight={400}
          isInline
          order={order}
          symbolVariant="raw"
        />
      )
      const expirationCell = <ExpirationDate alwaysRelative dataKey={order} />

      return { priceCell, statusCell, quantityCell, totalCell, expirationCell }
    }, [isDesktop, order, statusIndicatorLabels])

  if (!order) {
    return null
  }

  const orderOpenedAt = dateFromISO8601(order.openedAt)
  const dateCreatedCell = (
    <>
      <OffersTableDateTooltip date={orderOpenedAt}>
        <Text.Body size="small">
          <Overflow>{renderFromNow(orderOpenedAt)}</Overflow>
        </Text.Body>
      </OffersTableDateTooltip>
      {isDesktop && isOwnerMode && isMouseHoveringRow && (
        <OffersTableOptionsCell order={order} />
      )}
    </>
  )

  const columns: Record<OffersColumn, React.ReactNode> = {
    offer: offerCell,
    status: statusCell,
    price: priceCell,
    quantity: quantityCell,
    total: totalCell,
    expiration: expirationCell,
    dateCreated: dateCreatedCell,
  }

  const renderCell = (column: OffersColumn) => {
    return (
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS[column]}>
        {columns[column]}
      </VirtualizedTable.Row.Cell>
    )
  }

  return (
    <VirtualizedTable.Row
      minHeight={ROW_HEIGHT}
      showHover={isDesktop}
      onMouseEnter={e => {
        e.stopPropagation()
        setIsMouseHoveringRow(true)
      }}
      onMouseLeave={e => {
        e.stopPropagation()
        setIsMouseHoveringRow(false)
      }}
    >
      {renderCell("offer")}
      {isDesktop ? (
        <>
          {renderCell("price")}
          {renderCell("quantity")}
          {renderCell("total")}
          {renderCell("expiration")}
          {renderCell("dateCreated")}
        </>
      ) : (
        renderCell(mobileColumn)
      )}
    </VirtualizedTable.Row>
  )
}

const OffersTableRowSkeleton = React.memo(function OffersTableRowSkeleton({
  mode,
}: Pick<OffersTableRowProps, "mode">) {
  const { isDesktop } = useOffersTableContext()
  const isOwnerMode = mode === "owner"

  return (
    <VirtualizedTable.Row
      alignItems="center"
      className="w-full"
      gap={16}
      height={ROW_HEIGHT}
      minHeight={ROW_HEIGHT}
    >
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["offer"]}>
        {isOwnerMode && (
          <Flex
            className="items-center"
            style={{ paddingRight: CHECKBOX_PADDING }}
          >
            <Checkbox disabled />
          </Flex>
        )}
        <PortfolioTableItemCell.Skeleton />
      </VirtualizedTable.Row.Cell>
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["price"]}>
        <Skeleton.Line />
      </VirtualizedTable.Row.Cell>
      {isDesktop && (
        <>
          <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["quantity"]}>
            <Skeleton.Line />
          </VirtualizedTable.Row.Cell>
          <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["total"]}>
            <Skeleton.Line />
          </VirtualizedTable.Row.Cell>
          <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["expiration"]}>
            <Skeleton.Line />
          </VirtualizedTable.Row.Cell>
          <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["dateCreated"]}>
            <Skeleton.Line />
          </VirtualizedTable.Row.Cell>
        </>
      )}
    </VirtualizedTable.Row>
  )
})

export const OffersTableRow = Object.assign(OffersTableRowBase, {
  Skeleton: OffersTableRowSkeleton,
})

const CheckboxTapTarget = styled(UnstyledButton)`
  align-items: center;
  display: flex;
  height: 100%;
  padding-right: ${CHECKBOX_PADDING}px;
`

const StyledCollectionCell = styled(CollectionCell)`
  width: 100%;
  padding-right: 48px;
`
