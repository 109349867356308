import { graphql } from "relay-runtime"

export const ProfilePageFeaturedFragment = graphql`
  fragment profilePageQueries_featured on AccountType
  @argumentDefinitions(
    showContextMenu: { type: "Boolean", defaultValue: false }
  ) {
    shelves {
      id
      name
      description
      displayOrder
      shelfAssets {
        id
      }
      ...FeaturedShelfAssets_data @arguments(showContextMenu: $showContextMenu)
      ...FeaturedShelfAssetsCount_shelf
    }
  }
`

export const ProfilePageAccountFragment = graphql`
  fragment profilePageQueries_account on AccountType
  @argumentDefinitions(includePrivateAssetCount: { type: "Boolean!" }) {
    user {
      favoriteAssetCount
    }
    activeDealCount
    privateAssetCount @include(if: $includePrivateAssetCount)
  }
`
