/**
 * @generated SignedSource<<95d510d4e648aba5196419d3dce3b70a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListingSearchQuery$variables = {
  collections?: ReadonlyArray<string> | null;
};
export type ListingSearchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchPills_data">;
};
export type ListingSearchQuery = {
  response: ListingSearchQuery$data;
  variables: ListingSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collections"
  }
],
v1 = {
  "kind": "Variable",
  "name": "collections",
  "variableName": "collections"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingSearchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SearchPills_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingSearchQuery",
    "selections": [
      {
        "alias": "selectedCollections",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "first",
            "value": 25
          },
          {
            "kind": "Literal",
            "name": "includeHidden",
            "value": true
          }
        ],
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05fb6cb8d549cc29b988cfd18a3a6180",
    "id": null,
    "metadata": {},
    "name": "ListingSearchQuery",
    "operationKind": "query",
    "text": "query ListingSearchQuery(\n  $collections: [CollectionSlug!]\n) {\n  ...SearchPills_data_2Kg4Sq\n}\n\nfragment SearchPills_data_2Kg4Sq on Query {\n  selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {\n    edges {\n      node {\n        imageUrl\n        name\n        slug\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6fbf00709ddbbc7cac9740c7db5cfec";

export default node;
