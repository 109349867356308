import React, { useState } from "react"
import { CenterAligned, Icon, Spinner } from "@opensea/ui-kit"
import styled from "styled-components"
import { ImageInput, ImageInputValue } from "@/components/forms/ImageInput"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountHeroBannerMutation } from "@/lib/graphql/__generated__/AccountHeroBannerMutation.graphql"
import { AccountHeroBannerUploadMutation } from "@/lib/graphql/__generated__/AccountHeroBannerUploadMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { uploadFile } from "@/lib/helpers/file"
import { fixAssetHost } from "@/lib/helpers/urls"
import {
  HeroBannerContainer,
  HeroBannerImage,
  HeroBannerOutsideContainer,
} from "../HeroBanner"

type Props = {
  bannerImageUrl?: string
  altText: string
  isEditable: boolean
}

type Status = "done" | "error" | "standby" | "wait"

export function AccountHeroBanner({
  bannerImageUrl,
  altText,
  isEditable,
}: Props) {
  const t = useTranslate("phoenix")
  const { attempt, showErrorMessage, showSuccessMessage } = useToasts()
  const [bannerStatus, setBannerStatus] = useState<Status>("standby")
  const { mutate } = useGraphQL()

  const editBanner = async (
    value: ImageInputValue | undefined,
  ): Promise<void> => {
    const file = value?.file
    if (bannerStatus === "wait" || !file) {
      return
    }
    setBannerStatus("wait")

    try {
      await attempt(async () => {
        const {
          accounts: { uploadBanner },
        } = await mutate<AccountHeroBannerUploadMutation>(
          graphql`
            mutation AccountHeroBannerUploadMutation {
              accounts {
                uploadBanner {
                  url
                  method
                  fields
                  token
                }
              }
            }
          `,
          {},
          { shouldAuthenticate: true },
        )
        await uploadFile(uploadBanner, file)
        await mutate<AccountHeroBannerMutation>(
          graphql`
            mutation AccountHeroBannerMutation($input: AccountMutationInput!) {
              account(input: $input) {
                bannerImageUrl
              }
            }
          `,
          { input: { bannerImageToken: uploadBanner.token } },
          { shouldAuthenticate: true },
        )
        setBannerStatus("done")
        showSuccessMessage(
          t(
            "heroBanner.updateSuccessMessage",
            "Your banner picture has been successfully updated",
          ),
        )
      })
    } catch (error) {
      showErrorMessage(
        t(
          "heroBanner.updateErrorMessage",
          "There was an error while updating your banner picture",
        ),
      )
      setBannerStatus("error")
    }
  }

  // TODO (@auster-eth): reconcile this with PhoenixHeroBanner as this is duplicated
  const img = bannerImageUrl ? (
    <HeroBannerImage
      alt={altText}
      height={600}
      layout="fill"
      objectFit="cover"
      priority
      src={fixAssetHost(bannerImageUrl) ?? ""}
    />
  ) : null

  return (
    <HeroBannerOutsideContainer>
      <HeroBannerContainer>
        {img}
        {isEditable && (
          <>
            <ImageInput
              aria-label={t(
                "info.heroBannerPrompt",
                "Select a profile banner image",
              )}
              overlay={
                <CenterAligned className="h-full justify-center">
                  <Icon className="text-white" value="edit" />
                </CenterAligned>
              }
              variant="overlay"
              zIndex={1}
              onChange={editBanner}
            />
            {bannerStatus === "wait" ? (
              <LoaderContainer>
                <Spinner size="medium" />
              </LoaderContainer>
            ) : null}
          </>
        )}
      </HeroBannerContainer>
    </HeroBannerOutsideContainer>
  )
}

const LoaderContainer = styled(CenterAligned)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
`
