import type { TabNavigationProps } from "@/design-system/TabNavigation/types"
import { useTranslate } from "@/hooks/useTranslate"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { profilePageQueries_account$key } from "@/lib/graphql/__generated__/profilePageQueries_account.graphql"
import { profilePageQueries_featured$key } from "@/lib/graphql/__generated__/profilePageQueries_featured.graphql"
import { useFeaturedShelfCount } from "./useFeaturedShelfCount"
import { useProfileCollected } from "./useProfileCollected"
import { useProfileCreated } from "./useProfileCreated"
import { useProfileDeals } from "./useProfileDeals"
import { useProfileFavorited } from "./useProfileFavorited"
import { useProfileHidden } from "./useProfileHidden"

type Props = {
  featuredKey: profilePageQueries_featured$key | null
  accountKey: profilePageQueries_account$key | null
  isCurrentUser: boolean
  isYourProfile: boolean
  identity?: IdentityInputType
}

export const useProfilePageTabs = ({
  featuredKey,
  accountKey,
  isCurrentUser,
  isYourProfile,
  identity,
}: Props): TabNavigationProps["tabs"] => {
  const t = useTranslate("phoenix")
  const collected = useProfileCollected({ identity })
  const created = useProfileCreated({ identity })
  const featured = useFeaturedShelfCount({
    featuredKey,
    isYourProfile,
  })
  const favorited = useProfileFavorited({ accountKey })
  const dealCount = useProfileDeals({ accountKey })
  const hidden = useProfileHidden({ accountKey })
  const userTabs = isCurrentUser
    ? [
        {
          label: t("account.activeListings.label", "Active listings"),
          id: "listings",
        },
        {
          label: t("account.inactiveListings.label", "Inactive listings"),
          id: "listings-inactive",
        },
        {
          count: hidden,
          label: t("account.hidden.label", "Hidden"),
          id: "private",
        },
      ]
    : []
  const dropdownTabs = [
    {
      label: t("account.offersReceived.label", "Offers received"),
      id: "bids",
    },
    ...userTabs,
  ]

  const featuredTab = featured
    ? [
        {
          label: t("account.featured.label", "Featured"),
          id: "featured",
        },
      ]
    : []

  return [
    ...featuredTab,
    {
      label: t("account.collected.label", "Collected"),
      count: collected,
      id: "collected",
    },
    {
      label: t("account.offersMade.label", "Offers made"),
      id: "offers",
    },
    {
      label: t("account.deals.label", "Deals"),
      id: "deals",
      count: dealCount,
    },
    {
      label: t("account.created.label", "Created"),
      count: created,
      id: "created",
    },
    {
      label: t("account.favorited.label", "Favorited"),
      count: favorited,
      id: "favorites",
    },
    {
      label: t("account.activity.label", "Activity"),
      id: "activity",
    },
    [...dropdownTabs],
  ]
}
