/**
 * @generated SignedSource<<75080d671d092609009a9d57a5fb7dec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableExpandedRow_item$data = {
  readonly __typename: string;
  readonly isCompromised?: boolean;
  readonly isCurrentlyFungible?: boolean;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "PortfolioTableAcceptOfferButton_item" | "PortfolioTableBuyButton_asset" | "PortfolioTableListButton_item" | "PortfolioTableListingsTable_asset" | "PortfolioTableMakeOfferButton_asset" | "PortfolioTableTraitTable_asset" | "asset_url">;
  readonly " $fragmentType": "PortfolioTableExpandedRow_item";
};
export type PortfolioTableExpandedRow_item$key = {
  readonly " $data"?: PortfolioTableExpandedRow_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableExpandedRow_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v1 = {
  "kind": "Variable",
  "name": "showOwnerActions",
  "variableName": "showOwnerActions"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "args": (v2/*: any*/),
  "kind": "FragmentSpread",
  "name": "PortfolioTableMakeOfferButton_asset"
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PortfolioTableTraitTable_asset"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableExpandedRow_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCompromised",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrentlyFungible",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "asset_url",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "showQuantity",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "showRarity",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "AssetMedia_asset"
        },
        {
          "args": (v2/*: any*/),
          "kind": "FragmentSpread",
          "name": "PortfolioTableBuyButton_asset"
        },
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "PortfolioTableAcceptOfferButton_item"
    },
    {
      "args": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "PortfolioTableListButton_item"
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableListingsTable_asset"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "32d9b59f0d7ffb124ce1aa9ed4894b0c";

export default node;
