import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { ButtonProps } from "@/design-system/Button"
import { ItemAddToCartButton } from "@/features/shopping-cart/components/ItemAddToCartButton"
import { PortfolioTableBuyButton_asset$key } from "@/lib/graphql/__generated__/PortfolioTableBuyButton_asset.graphql"

type Props = {
  asset: PortfolioTableBuyButton_asset$key
} & Omit<ButtonProps, "order">

export const PortfolioTableBuyButton = ({
  asset: assetKey,
  ...buttonProps
}: Props) => {
  const asset = useFragment(
    graphql`
      fragment PortfolioTableBuyButton_asset on AssetType
      @argumentDefinitions(showOwnerActions: { type: "Boolean!" }) {
        orderData {
          bestAskV2 {
            ...ItemAddToCartButton_order @skip(if: $showOwnerActions)
          }
        }
      }
    `,
    assetKey,
  )

  const { bestAskV2 } = asset.orderData
  if (!bestAskV2) {
    return null
  }

  return (
    <ItemAddToCartButton
      order={bestAskV2}
      overrides={{
        Button: { props: buttonProps },
        Text: { props: { size: "small" } },
      }}
    />
  )
}
