/**
 * @generated SignedSource<<1ef2e9066856fa05ddb996677a8d1648>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type useProfileCreatedQuery$variables = {
  identity: IdentityInputType;
};
export type useProfileCreatedQuery$data = {
  readonly searchItems: {
    readonly totalCount: number;
  };
};
export type useProfileCreatedQuery = {
  response: useProfileCreatedQuery$data;
  variables: useProfileCreatedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identity"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "creator",
        "variableName": "identity"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 0
      },
      {
        "kind": "Literal",
        "name": "resultType",
        "value": "ASSETS"
      },
      {
        "kind": "Literal",
        "name": "sortBy",
        "value": "CREATED_DATE"
      }
    ],
    "concreteType": "ItemTypeConnection",
    "kind": "LinkedField",
    "name": "searchItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useProfileCreatedQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useProfileCreatedQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e9ef4deef15f2bf672d903b73c53b68f",
    "id": null,
    "metadata": {},
    "name": "useProfileCreatedQuery",
    "operationKind": "query",
    "text": "query useProfileCreatedQuery(\n  $identity: IdentityInputType!\n) {\n  searchItems(creator: $identity, first: 0, resultType: ASSETS, sortBy: CREATED_DATE) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "5992e9c75f94078e37358f5feb4fef45";

export default node;
