import React, { useState } from "react"
import { useUpdateEffect } from "react-use"
import { Link, LinkProps } from "@/components/common/Link"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Tooltip } from "@/design-system/Tooltip"
import { getCreateDealUrl, DEAL_CREATE_URL } from "@/features/deals/urls"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  address: string
  children?: React.ReactNode
} & Omit<LinkProps, "href">

export const ProfileDealLink = ({ children, address, ...props }: Props) => {
  const t = useTranslate("deals")
  const connectedAddress = useConnectedAddress()
  const router = useRouter()
  const [isClicked, setIsClicked] = useState<boolean>(false)

  const url =
    address === connectedAddress
      ? `${DEAL_CREATE_URL}`
      : `${getCreateDealUrl(address)}`

  const handleClick = () => {
    setIsClicked(true)
  }

  useUpdateEffect(() => {
    if (!connectedAddress) {
      return
    }
    if (isClicked) {
      router.push(url)
    }
  }, [connectedAddress, isClicked])

  return (
    <NetworkUnsupportedGate>
      {({ handleIfNotSupported }) => (
        <Tooltip content={t("profileDealLink.valid", "Make a deal")}>
          <span>
            <Link
              {...props}
              disabled={false}
              eventSource="ProfileDealsLink"
              href={connectedAddress ? url : undefined}
              onClick={() => {
                handleIfNotSupported(handleClick)()
              }}
            >
              {children}
            </Link>
          </span>
        </Tooltip>
      )}
    </NetworkUnsupportedGate>
  )
}
