/**
 * @generated SignedSource<<2a720ba3527c3839e0950174621df36b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatedCollections_data$data = ReadonlyArray<{
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCard_data">;
  readonly " $fragmentType": "CreatedCollections_data";
}>;
export type CreatedCollections_data$key = ReadonlyArray<{
  readonly " $data"?: CreatedCollections_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatedCollections_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CreatedCollections_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "timeWindow",
          "value": "ALL_TIME"
        }
      ],
      "kind": "FragmentSpread",
      "name": "HomePageCollectionCard_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "60342092e8c292b89d811bc15694eaa2";

export default node;
