import React from "react"
import { useIsLessThanMd, Text, TextBodyProps } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { Flex } from "@/design-system/Flex"
import {
  formatCollectionFloorPrice,
  formatCollectionVolume,
} from "@/features/rankings/components/RankingsPage/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { HomePageCollectionCardFooter_data$key } from "@/lib/graphql/__generated__/HomePageCollectionCardFooter_data.graphql"
import { StatsTimeWindow } from "@/lib/graphql/__generated__/RankingsPageTopQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { STATS_EMPTY_PRICE_DISPLAY } from "../../../../constants"

type Props = {
  dataKey: HomePageCollectionCardFooter_data$key
  volumeWindow: StatsTimeWindow
}

export const HomePageCollectionCardFooter = ({
  dataKey,
  volumeWindow,
}: Props) => {
  const collection = useFragment(
    graphql`
      fragment HomePageCollectionCardFooter_data on CollectionType
      @argumentDefinitions(timeWindow: { type: "StatsTimeWindow" }) {
        windowCollectionStats(statsTimeWindow: $timeWindow) {
          floorPrice {
            unit
            symbol
          }
          volume {
            unit
            symbol
          }
        }
      }
    `,
    dataKey,
  )

  const { volume, floorPrice } = collection.windowCollectionStats

  const t = useTranslate("components")
  const floorPriceTitle = t("statsFooter.floor", "Floor")
  const oneDayVolumeTitle = t("statsFooter.oneDayVolume", "24h volume")
  const sevenDayVolumeTitle = t("statsFooter.sevenDayVolume", "7 day volume")
  const totalVolumeTile = t("statsFooter.totalVolume", "Total volume")

  const floorPriceText =
    floorPrice && floorPrice.unit
      ? formatCollectionFloorPrice(floorPrice.unit) + ` ${floorPrice.symbol}`
      : STATS_EMPTY_PRICE_DISPLAY
  const volumeText =
    formatCollectionVolume(bn(volume.unit)) + ` ${volume.symbol}`
  const isMedium = !useIsLessThanMd()

  return (
    <FooterContainer>
      <StatContainer>
        <StatHeader color="secondary">
          <Overflow>{floorPriceTitle}</Overflow>
        </StatHeader>
        <Stat color="primary" size={isMedium ? "medium" : "small"}>
          <Overflow>{floorPriceText}</Overflow>
        </Stat>
      </StatContainer>
      <StatContainer>
        <StatHeader className="pl-2" color="secondary">
          <Overflow>
            {volumeWindow === "ONE_DAY"
              ? oneDayVolumeTitle
              : volumeWindow === "SEVEN_DAY"
              ? sevenDayVolumeTitle
              : totalVolumeTile}
          </Overflow>
        </StatHeader>
        <Stat
          className="pl-2"
          color="primary"
          size={isMedium ? "medium" : "small"}
        >
          <Overflow>{volumeText}</Overflow>
        </Stat>
      </StatContainer>
    </FooterContainer>
  )
}

const StatHeader = ({ size, ...props }: TextBodyProps) => (
  <Text.Body {...props} size={size ?? "small"} />
)

const Stat = ({ weight, ...props }: TextBodyProps) => (
  <Text.Body {...props} weight={weight ?? "semibold"} />
)

const StatContainer = styled(Flex)`
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
`

const FooterContainer = styled(Flex)`
  width: 100%;
`
