import React from "react"
import { Button, ButtonProps } from "@/design-system/Button"
import {
  useAssetContextActions,
  useAssetContextSelectedItems,
} from "@/features/account/hooks/useAssetSelection"
import { useAssetVisibilityActions } from "@/features/assets/hooks/useAssetVisibilityActions"
import { useTranslate } from "@/hooks/useTranslate"
import { TriggerProps } from "./AssetsContextMenu.react"

type Props = {
  action: "hide" | "unhide"
  onClick?: () => unknown
} & ButtonProps &
  TriggerProps

export const ItemVisibilityButton = ({
  trigger,
  action,
  onClick,
  ...buttonProps
}: Props) => {
  const t = useTranslate("account")
  const { hideAssets, unhideAssets } = useAssetVisibilityActions()
  const selectedItems = useAssetContextSelectedItems()
  const { onDeleteAll } = useAssetContextActions()

  const isHideMode = action === "hide"

  const handleClick = async () => {
    const assets = selectedItems.map(a => a.relayId)
    if (isHideMode) {
      await hideAssets(assets)
    } else {
      await unhideAssets(assets)
    }
    onDeleteAll()
    onClick?.()
  }

  const hideIcon = isHideMode ? "visibility_off" : "visibility"
  const hideText = isHideMode
    ? t("visibilityCta.hide", "Hide")
    : t("visibilityCta.unhide", "Unhide")

  return trigger ? (
    trigger({
      onClick: handleClick,
      icon: hideIcon,
      content: hideText,
    })
  ) : (
    <Button
      aria-label={
        isHideMode
          ? t("visibilityCta.hide", "Hide")
          : t("visibilityCta.unhide", "Unhide")
      }
      variant="secondary"
      onClick={handleClick}
      // Manually spreading this due to Typescript issues with ButtonIconProps when spreading buttonProps
      {...{ icon: hideIcon }}
      {...buttonProps}
    >
      {hideText}
    </Button>
  )
}
