/**
 * @generated SignedSource<<f75044cfb1ba8c86f7bfc70b2b82aef5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListButton_item$data = {
  readonly __typename: string;
  readonly chain: {
    readonly isTradingEnabled: boolean;
  };
  readonly isCurrentlyFungible?: boolean;
  readonly isListable?: boolean;
  readonly orderData: {
    readonly bestAskForOwner: {
      readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListButton_bestAskV2">;
    } | null;
    readonly bestAskV2: {
      readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListButton_bestAskV2">;
    } | null;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateListingButton_item" | "EditListingButton_item" | "itemEvents_data">;
  readonly " $fragmentType": "PortfolioTableListButton_item";
};
export type PortfolioTableListButton_item$key = {
  readonly " $data"?: PortfolioTableListButton_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListButton_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "showOwnerActions",
        "variableName": "showOwnerActions"
      }
    ],
    "kind": "FragmentSpread",
    "name": "PortfolioTableListButton_bestAskV2"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListButton_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTradingEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": "bestAskForOwner",
          "args": [
            {
              "kind": "Variable",
              "name": "byAddress",
              "variableName": "identity"
            }
          ],
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrentlyFungible",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isListable",
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_data",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateListingButton_item"
    },
    {
      "condition": "showOwnerActions",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditListingButton_item"
        }
      ]
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "f39e10de4dcfae8b6055e3c4db0b1e47";

export default node;
