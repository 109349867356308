/**
 * @generated SignedSource<<43e51920139eaf788fa22d76b6973b78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OrderInvalidationReason = "ACCOUNT_DISABLED" | "ASSET_DISABLED" | "ASSET_OWNERSHIP_UPDATE" | "ASSET_REQUIRES_APPROVAL" | "CANCEL_TRANSACTION" | "COLLECTION_DISABLED" | "ERC20_APPROVAL_REVOKED" | "EXECUTION_REVERTED_ERROR" | "EXPIRED" | "GASLESS_CANCEL" | "INSUFFICIENT_BALANCE" | "MATCH_ORDERS_ERROR" | "NOT_EXECUTABLE" | "ON_CHAIN_VALIDATION_ERROR" | "ORDER_MALFORMED" | "PREMATCHED" | "TRAIT_OFFERS_DISABLED" | "TRANSACTION_SUBMISSION_ERROR" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAnalyticsOfferEvent_data$data = {
  readonly closedAt: string | null;
  readonly invalidationReason: OrderInvalidationReason | null;
  readonly isValid: boolean;
  readonly orderType: OrderV2OrderType;
  readonly " $fragmentType": "useAnalyticsOfferEvent_data";
};
export type useAnalyticsOfferEvent_data$key = {
  readonly " $data"?: useAnalyticsOfferEvent_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAnalyticsOfferEvent_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useAnalyticsOfferEvent_data"
};

(node as any).hash = "30fdab0fa4a55329b4014a2453134619";

export default node;
