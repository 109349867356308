/**
 * @generated SignedSource<<68454ce98791f78ec2ef5fc374d1c086>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setLocalItemBestAsk_order$data = {
  readonly item: {
    readonly relayId: string;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListButton_bestAskV2" | "PortfolioTableListingCell_bestAskV2">;
  readonly " $fragmentType": "setLocalItemBestAsk_order";
};
export type setLocalItemBestAsk_order$key = {
  readonly " $data"?: setLocalItemBestAsk_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"setLocalItemBestAsk_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "setLocalItemBestAsk_order"
};

(node as any).hash = "d7190ca658dbafd50238f885bc782974";

export default node;
