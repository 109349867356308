import React from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { PAGE_THUMBNAIL_SIZE } from "@/components/layout/AccountOrCollectionPage/constants"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { Lightbox } from "@/design-system/Lightbox"
import { Skeleton } from "@opensea/ui-kit"
import { shouldUseSeadnUrl } from "@/lib/helpers/urls"
import { media } from "@/styles/styleUtils"

type Props = {
  imageUrl: string
  altText: string
  variant: "collection" | "profile"
}

export function CollectionProfileHeaderThumb({
  imageUrl,
  variant,
  altText,
}: Props) {
  if (!imageUrl) {
    return (
      <Container $imageVariant={variant}>
        <Skeleton.Block />
      </Container>
    )
  }

  const isSeadnUrl = shouldUseSeadnUrl(imageUrl)
  const thumbnailSize = isSeadnUrl ? undefined : PAGE_THUMBNAIL_SIZE * 2
  const fullSize = isSeadnUrl ? undefined : 0

  return (
    <Lightbox
      trigger={open => (
        <Container
          $imageVariant={variant}
          display="inline-flex"
          overflow="hidden"
        >
          <TriggerButton $imageVariant={variant} onClick={open}>
            <ImageContainer
              $imageVariant={variant}
              alt={altText}
              height={thumbnailSize}
              layout="fill"
              objectFit="cover"
              priority
              sizes={`${PAGE_THUMBNAIL_SIZE}px`}
              src={imageUrl}
              width={thumbnailSize}
            />
          </TriggerButton>
        </Container>
      )}
    >
      <Block className="relative w-full" height="100%">
        <ImageContainer
          $imageVariant={variant}
          alt={altText}
          height={fullSize}
          layout="fill"
          objectFit="cover"
          sizes="100%"
          src={imageUrl}
          width={fullSize}
        />
      </Block>
    </Lightbox>
  )
}

const ImageContainer = styled(Image)<{ $imageVariant: Props["variant"] }>`
  border-radius: ${props =>
    props.$imageVariant === "profile"
      ? props.theme.borderRadius.circle
      : props.theme.borderRadius.default};
`

const TriggerButton = styled(UnstyledButton)<{
  $imageVariant: Props["variant"]
}>`
  border-radius: ${props =>
    props.$imageVariant === "profile"
      ? props.theme.borderRadius.circle
      : "none"};
  width: 100%;
  height: 100%;
`

export const Container = styled(Block)<{
  $imageVariant: Props["variant"]
}>`
  width: 90px;
  height: 90px;
  flex-basis: 90px;

  ${media({
    sm: css`
      width: 120px;
      height: 120px;
      flex-basis: 120px;
    `,
    lg: css`
      width: 180px;
      height: 180px;
      flex-basis: 180px;
    `,
  })}

  // TODO(@auster-eth): Handle dark theme box-shadow
  box-shadow: ${props =>
    props.theme.colors.components.elevation.level1.regular.shadow};
  border: 4px solid ${props => props.theme.colors.base1};
  ${media({
    md: css`
      border: 6px solid ${props => props.theme.colors.base1};
    `,
  })}
  border-radius: ${props =>
    props.$imageVariant === "profile"
      ? props.theme.borderRadius.circle
      : props.theme.borderRadius.pill};
  position: relative;
  z-index: 1;
  background: ${props => props.theme.colors.base1};
`
