import React from "react"
import { graphql } from "relay-runtime"
import { FilterBar } from "@/components/layout/AccountOrCollectionPage/components/FilterBar"
import { getFilterCount } from "@/components/search/assets/AssetSearchFilter"
import { AssetSearchLayout } from "@/components/search/assets/AssetSearchLayout/AssetSearchLayout.react"
import SearchStatusDropdown from "@/components/search/assets/SearchStatusDropdown.react"
import OfferSearchFilter from "@/components/search/orders/OfferSearchFilter.react"
import OrderSearchSortDropdown from "@/components/search/orders/OrderSearchSortDropdown.react"
import {
  useOfferTypeItems,
  useOrderStatusItems,
} from "@/components/search/orders/utils"
import { SearchPills } from "@/components/search/SearchPills.react"
import { ToggleFilterButton } from "@/components/search/ToggleFilterButton.react"
import { ProfileOfferSelectionFooter } from "@/features/account/components/AccountPage/tabs/offers/ProfileOfferSelectionFooter"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { Search, useSearch } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountOffersOrderSearchCollectionQuery } from "@/lib/graphql/__generated__/AccountOffersOrderSearchCollectionQuery.graphql"
import { RequiredKeys } from "@/lib/helpers/type"
import { AccountOffersOrderSearchList } from "./AccountOffersOrderSearchList.react"

export type AccountOffersOrderSearchState = RequiredKeys<
  Pick<
    Search,
    | "collection"
    | "sortAscending"
    | "orderSortBy"
    | "collections"
    | "orderStatusToggles"
    | "offerTypeToggles"
  >
> &
  Required<Pick<Search, "identity">>

type Props = {
  isCurrentUser: boolean
  defaultState: AccountOffersOrderSearchState
  initialState: Partial<AccountOffersOrderSearchState>
  fixedState: Partial<AccountOffersOrderSearchState>
  path: string
}

export const AccountOffersOrderSearch = ({
  defaultState,
  initialState,
  fixedState,
  path,
  isCurrentUser,
}: Props) => {
  const t = useTranslate("account")
  const { searchState, clear, update } = useSearch({
    defaultState,
    initialState,
    fixedState,
    path,
  })

  const defaultStatusItems = useOrderStatusItems()
  const defaultTypeItems = useOfferTypeItems()
  const filterCount = getFilterCount(searchState, {
    includeCollectionFilter: true,
  })
  const [collectionData] =
    useNoSuspenseLazyLoadQuery<AccountOffersOrderSearchCollectionQuery>(
      graphql`
        query AccountOffersOrderSearchCollectionQuery(
          $collections: [CollectionSlug!]
          $collection: CollectionSlug!
        ) {
          ...SearchPills_data @arguments(collections: $collections)
          ...AssetSearchFilter_data @arguments(collection: $collection)
        }
      `,
      {
        collections: searchState.collections,
        collection:
          searchState.collections?.[0] ?? searchState.collection ?? "",
      },
      { skip: !searchState.collections?.length && !searchState.collection },
    )

  return (
    <AssetSearchLayout
      assets={
        <AccountOffersOrderSearchList
          isCurrentUser={isCurrentUser}
          searchState={searchState}
          setSort={update}
        />
      }
      filter={
        <OfferSearchFilter
          clear={clear}
          setState={update}
          state={searchState}
        />
      }
      footer={<ProfileOfferSelectionFooter />}
      header={
        <FilterBar
          filterToggle={<ToggleFilterButton numFiltersApplied={filterCount} />}
          shadowVariant="none"
          sortDropdown={
            <OrderSearchSortDropdown
              searchState={searchState}
              setSort={update}
              showDesktop={false}
            />
          }
          statusDropdown={
            <SearchStatusDropdown
              filters={searchState.orderStatusToggles || []}
              possibleFilterItems={defaultStatusItems}
              setFilters={orderStatusToggles => update({ orderStatusToggles })}
              title={t("orderSearchFilter.status.title", "Status")}
            />
          }
          typeDropdown={
            <SearchStatusDropdown
              filters={searchState.offerTypeToggles || []}
              possibleFilterItems={defaultTypeItems}
              setFilters={offerTypeToggles => update({ offerTypeToggles })}
              title={t("orderSearchFilter.offerType.title", "Type")}
            />
          }
        />
      }
      pills={
        <SearchPills
          clear={clear}
          collectionFilter={searchState.collections}
          dataKey={collectionData}
          offerTypeFilter={searchState.offerTypeToggles}
          orderStatusFilter={searchState.orderStatusToggles}
          update={update}
        />
      }
    />
  )
}
