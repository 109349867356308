/**
 * @generated SignedSource<<88743d36bd08f21eb8ed6c38d98ba025>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OffersTable_orders$data = ReadonlyArray<{
  readonly __typename: "OrderV2Type";
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"OffersTableRow_order" | "useAnalyticsOfferEvent_data" | "useOfferSelectionState_order">;
  readonly " $fragmentType": "OffersTable_orders";
}>;
export type OffersTable_orders$key = ReadonlyArray<{
  readonly " $data"?: OffersTable_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"OffersTable_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderType",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OffersTable_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        }
      ],
      "kind": "FragmentSpread",
      "name": "OffersTableRow_order"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useOfferSelectionState_order",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancelOrdersButton_data"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useAnalyticsOfferEvent_data",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invalidationReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isValid",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "b01ec46a5315d6d903f942b8ce49ddc8";

export default node;
