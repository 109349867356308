import { Icon } from "@opensea/ui-kit"
import styled from "styled-components"
import { variant } from "styled-system"
import { Block } from "@/design-system/Block"
import { themeVariant } from "@/styles/styleUtils"
import { StatusIndicatorType } from "./types"

export const StatusIndicator = styled(Block).attrs<{
  variant: StatusIndicatorType
}>({
  borderRadius: "circle",
  flexShrink: 0,
  height: "8px",
  width: "8px",
})<{ variant: StatusIndicatorType }>`
  ${props =>
    variant({
      variants: {
        expiring: { background: props.theme.colors.starFish },
        valid: { background: props.theme.colors.seaGrass },
        invalid: { background: props.theme.colors.redBase },
      },
    })}
`

export const OptionIcon = styled(Icon)`
  padding: 12px;
  ${props =>
    themeVariant({
      variants: {
        dark: {
          color: props.theme.colors.white,
        },
        light: {
          color: props.theme.colors.black,
        },
      },
    })}
`
