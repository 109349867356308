import React, { useCallback } from "react"
import { graphql, useFragment } from "react-relay"
import { CreateListingButton } from "@/components/orders/CreateListingButton"
import { EditListingButton } from "@/components/orders/EditListingButton.react"
import { ButtonProps } from "@/design-system/Button"
import {
  useCanceledListingsItemRelayIds,
  useUpdatedItemRelayIds,
} from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { PortfolioTableButtonSource } from "@/features/account/components/PortfolioTable/analytics"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { PortfolioTableListButton_bestAskV2$key } from "@/lib/graphql/__generated__/PortfolioTableListButton_bestAskV2.graphql"
import { PortfolioTableListButton_item$key } from "@/lib/graphql/__generated__/PortfolioTableListButton_item.graphql"

type Props = {
  isIcon?: boolean
  item: PortfolioTableListButton_item$key
  accountAddress: string
  source: PortfolioTableButtonSource
} & Omit<ButtonProps, "href" | "onClick" | "order">

export const PortfolioTableListButton = ({
  isIcon,
  item: assetKey,
  accountAddress,
  source,
  ...buttonProps
}: Props) => {
  const [canceledListingsItemRelayIds] = useCanceledListingsItemRelayIds()
  const [_, setUpdatedItemRelayIds] = useUpdatedItemRelayIds()
  const item = useFragment<PortfolioTableListButton_item$key>(
    graphql`
      fragment PortfolioTableListButton_item on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        showOwnerActions: { type: "Boolean!" }
      ) {
        __typename
        relayId
        chain {
          isTradingEnabled
        }
        orderData {
          bestAskV2 {
            ...PortfolioTableListButton_bestAskV2
              @arguments(showOwnerActions: $showOwnerActions)
          }
          bestAskForOwner: bestAskV2(byAddress: $identity) {
            ...PortfolioTableListButton_bestAskV2
              @arguments(showOwnerActions: $showOwnerActions)
          }
        }

        ... on AssetType {
          isCurrentlyFungible
          isListable
        }

        # eslint-disable-next-line relay/must-colocate-fragment-spreads used by tracking methods
        ...itemEvents_data
        ...CreateListingButton_item
        ...EditListingButton_item @include(if: $showOwnerActions)
      }
    `,
    assetKey,
  )

  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()
  const bestAsk = useFragment<PortfolioTableListButton_bestAskV2$key>(
    graphql`
      fragment PortfolioTableListButton_bestAskV2 on OrderV2Type
      @argumentDefinitions(showOwnerActions: { type: "Boolean!" }) {
        ...EditListingButton_listing @include(if: $showOwnerActions)
        maker {
          address
        }
        orderType
      }
    `,
    canceledListingsItemRelayIds.includes(item.relayId)
      ? null
      : ownerListingPriceEnabled
      ? item.orderData.bestAskForOwner
      : item.orderData.bestAskV2,
  )

  const onListingChange = useCallback(
    (orderItemRelayId: string) => {
      if (orderItemRelayId) {
        // Liveness: Signal account profile my listings to refetch
        setUpdatedItemRelayIds(prevRelayIds =>
          prevRelayIds.concat([orderItemRelayId]),
        )
      }
    },
    [setUpdatedItemRelayIds],
  )

  const { __typename, isCurrentlyFungible } = item
  const isTradingEnabled = item.chain.isTradingEnabled

  const isAsset = __typename === "AssetType"
  if (isAsset && !item.isListable) {
    return null
  }

  const listingSource =
    source === "collapsed"
      ? "portfolio table collapsed row"
      : "portfolio table expanded row"

  const isBestAskByAccount = bestAsk?.maker.address === accountAddress
  if (isAsset && isTradingEnabled && (!bestAsk || !isBestAskByAccount)) {
    return (
      <CreateListingButton
        isIcon={isIcon}
        item={item}
        source={listingSource}
        onListingCreated={onListingChange}
        {...buttonProps}
      />
    )
  }

  // Cannot edit these types of listings.
  if (
    (isAsset && isCurrentlyFungible) ||
    !bestAsk ||
    bestAsk.orderType !== "BASIC"
  ) {
    return null
  }

  return (
    <EditListingButton
      isIcon={isIcon}
      item={item}
      listing={bestAsk}
      source={listingSource}
      onOrdersChanged={() => onListingChange(item.relayId)}
      {...buttonProps}
    />
  )
}
