import React from "react"
import { Flex, Media } from "@opensea/ui-kit"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { IconButton } from "@/design-system/IconButton"
import { Tooltip } from "@/design-system/Tooltip"

export type SocialBarItem = {
  url?: string | null
  icon: JSX.Element
  tooltip: React.ReactNode
}

export type SocialBarProps = {
  items: SocialBarItem[]
  renderIcon?: (icon: SocialBarItem["icon"]) => React.ReactNode
  withDivider?: boolean
}

export const SocialBar = ({
  items,
  renderIcon,
  withDivider = true,
}: SocialBarProps) => {
  return (
    <>
      <Media greaterThanOrEqual="md">
        <Flex className="items-center">
          <Flex className="gap-1">
            {items.map(
              ({ url, icon, tooltip }) =>
                url && (
                  <Tooltip content={tooltip} hideOnClick key={url}>
                    <Link href={url}>
                      {renderIcon ? (
                        renderIcon(icon)
                      ) : (
                        <IconButton>{icon}</IconButton>
                      )}
                    </Link>
                  </Tooltip>
                ),
            )}
          </Flex>
          {items.length > 0 && withDivider && <Divider />}
        </Flex>
      </Media>
    </>
  )
}

const Divider = styled(Block)`
  margin-left: 10px;
  margin-right: 10px;
  height: 24px;
  width: 1px;
  background-color: ${props =>
    props.theme.type === "dark"
      ? props.theme.colors.ash
      : props.theme.colors.fog};
`
