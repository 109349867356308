import React from "react"
import { Block, BlockProps } from "@/design-system/Block"
import { Button, ButtonProps } from "@/design-system/Button"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { useAssetContextSelectedItems } from "@/features/account/hooks/useAssetSelection"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { getAssetUrl } from "@/lib/helpers/asset"
import { getChainSlug } from "@/lib/helpers/chainUtils"
import { TriggerProps } from "./AssetsContextMenu.react"
import { useIsAssetErc721cDisabled } from "@/features/account/hooks/useAssetDisabled"

const TRANSFER_MAX_SIZE = 50

type Props = {
  overrides?: {
    Block?: {
      props: Partial<BlockProps>
    }
    Tooltip?: {
      props: Partial<TooltipProps>
    }
  }
} & ButtonProps &
  TriggerProps

export const TransferButton = ({
  trigger,
  overrides,
  ...buttonProps
}: Props) => {
  const selectedItems = useAssetContextSelectedItems()

  const t = useTranslate("account")
  const router = useRouter()

  // TODO: Support bundles by collecting all of the assets in the bundle
  const filteredItems = selectedItems
    .filter(({ __typename }) => __typename === "AssetType")
    .slice(0, TRANSFER_MAX_SIZE)

  const hasCompromised =
    filteredItems.filter(item => item.isCompromised).length > 0

  const numItems = filteredItems.length

  const firstAsset = filteredItems[0]

  const handleClick = () => {
    if (numItems > 1) {
      router.push({
        pathname: `/bundle/${getChainSlug(
          firstAsset.chain.identifier,
        )}/transfer`,
        query: {
          assets: filteredItems.map(asset => asset.relayId),
        },
      })
    } else {
      router.push(getAssetUrl(firstAsset, "transfer"))
    }
  }

  const getDisabledReason = () => {
    const isErc721cFeeEnforcedDisabled = useIsAssetErc721cDisabled()
    if (isErc721cFeeEnforcedDisabled) {
      return t(
        "selectionFooter.transferCta.erc721cFeeEnforced",
        "You can only transfer one ERC721c fee enforced item at a time.",
      )
    }

    const uniqueChains = new Set(
      selectedItems.map(item => item.chain.identifier),
    )

    if (uniqueChains.size > 1) {
      return t(
        "selectionFooter.transferCta.multipleChains",
        "You can only transfer items from the same chain.",
      )
    }

    if (hasCompromised) {
      return t(
        "selectionFooter.transferCta.compromised",
        "One or more of these items have been flagged for suspicious activity and cannot be transferred using Opensea.",
      )
    }

    return undefined
  }

  const disabledReason = getDisabledReason()

  // Display tooltip if there are more orders than the max limit
  const maxInfoLimit =
    selectedItems.length > TRANSFER_MAX_SIZE
      ? t(
          "selectionFooter.transferCta.maxInfoLimit",
          "You can only transfer up to {{count}} items at a time.",
          { count: TRANSFER_MAX_SIZE },
        )
      : undefined

  const transferText = t("selectionFooter.transferCta.transfer", "Transfer")
  const transferIcon = "send"

  return trigger ? (
    trigger({
      onClick: handleClick,
      icon: transferIcon,
      content: transferText,
      disabled: Boolean(disabledReason),
    })
  ) : (
    <Tooltip
      content={disabledReason ?? maxInfoLimit}
      maxWidth={200}
      {...overrides?.Tooltip?.props}
    >
      <Block {...overrides?.Block?.props}>
        <Button
          aria-label={t("selectionFooter.transferCta.transfer", "Transfer")}
          disabled={Boolean(disabledReason)}
          variant="secondary"
          onClick={handleClick}
          // Manually spreading this due to Typescript issues with ButtonIconProps when spreading buttonProps
          {...{ icon: transferIcon }}
          {...buttonProps}
        >
          {transferText}
        </Button>
      </Block>
    </Tooltip>
  )
}
