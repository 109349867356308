import { useMemo } from "react"
import { useTranslate } from "@/hooks/useTranslate"

export const useActivityPriceHistoryTimeRangeOptions = () => {
  const t = useTranslate("collection")

  // Options are defined monotonically increasing in value
  const fixedOptions = useMemo(
    () =>
      [
        {
          label: t("analytics.dateRange.lastHour", "Last hour"),
          value: "1",
        },
        {
          label: t("analytics.dateRange.last6Hours", "Last 6 hours"),
          value: "6",
        },
        {
          label: t("analytics.dateRange.lastDay", "Last 24 hours"),
          value: "24",
        },
        {
          label: t("analytics.dateRange.last7Days", "Last 7 days"),
          value: "168",
        },
        {
          label: t("analytics.dateRange.last30Days", "Last 30 days"),
          value: "720",
        },
        {
          label: t("analytics.dateRange.last90Days", "Last 90 days"),
          value: "2160",
        },
      ] as const,
    [t],
  )

  return fixedOptions
}
