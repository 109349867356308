/**
 * @generated SignedSource<<6f0c35461c261ec97f4428187a6adbfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTable_items$data = ReadonlyArray<{
  readonly __typename: string;
  readonly ownership?: {
    readonly isPrivate: boolean;
    readonly quantity: string;
  } | null;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableRow_item" | "itemEvents_dataV2" | "useAssetSelectionStorage_item">;
  readonly " $fragmentType": "PortfolioTable_items";
}>;
export type PortfolioTable_items$key = ReadonlyArray<{
  readonly " $data"?: PortfolioTable_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTable_items">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identity"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifier",
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  }
],
v7 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountType",
    "kind": "LinkedField",
    "name": "maker",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderV2Type",
  "kind": "LinkedField",
  "name": "bestAskV2",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "kind": "InlineDataFragmentSpread",
  "name": "asset_url",
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    (v0/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PortfolioTable_items",
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            (v3/*: any*/)
          ],
          "concreteType": "AssetOwnershipDataType",
          "kind": "LinkedField",
          "name": "ownership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isPrivate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "args": [
        (v3/*: any*/),
        {
          "kind": "Variable",
          "name": "showOwnerActions",
          "variableName": "showOwnerActions"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PortfolioTableRow_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useAssetSelectionStorage_item",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTradingEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isErc721cFeeEnforced",
              "storageKey": null
            },
            {
              "condition": "withBestBid",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderV2Type",
                  "kind": "LinkedField",
                  "name": "bestAllTypeBid",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ]
            },
            {
              "condition": "withBestAsk",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ESOrderDataType",
                  "kind": "LinkedField",
                  "name": "orderData",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    {
                      "alias": "bestAskForOwner",
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "byAddress",
                          "variableName": "identity"
                        }
                      ],
                      "concreteType": "OrderV2Type",
                      "kind": "LinkedField",
                      "name": "bestAskV2",
                      "plural": false,
                      "selections": (v7/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            },
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCompromised",
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ESOrderDataType",
              "kind": "LinkedField",
              "name": "orderData",
              "plural": false,
              "selections": [
                {
                  "condition": "withBestAsk",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    (v8/*: any*/)
                  ]
                },
                {
                  "condition": "withBestBid",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderV2Type",
                      "kind": "LinkedField",
                      "name": "bestBidV2",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "item_sellUrl",
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v12/*: any*/)
              ],
              "type": "AssetType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                (v11/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 18
                    }
                  ],
                  "concreteType": "AssetQuantityTypeConnection",
                  "kind": "LinkedField",
                  "name": "assetQuantities",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetQuantityType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AssetType",
                              "kind": "LinkedField",
                              "name": "asset",
                              "plural": false,
                              "selections": (v5/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "assetQuantities(first:18)"
                }
              ],
              "type": "AssetBundleType",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetContextMenu_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancelItemOrdersButton_items"
        }
      ],
      "args": [
        (v3/*: any*/),
        {
          "kind": "Literal",
          "name": "withBestAsk",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withBestBid",
          "value": true
        }
      ],
      "argumentDefinitions": [
        (v0/*: any*/),
        {
          "defaultValue": false,
          "kind": "LocalArgument",
          "name": "withBestAsk"
        },
        {
          "defaultValue": false,
          "kind": "LocalArgument",
          "name": "withBestBid"
        }
      ]
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_dataV2",
      "selections": [
        (v2/*: any*/),
        (v11/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v10/*: any*/),
            (v9/*: any*/)
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "3d2e25530aaf5bfab12afea6b05b7c0e";

export default node;
