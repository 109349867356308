import React, { useState, useEffect } from "react"
import { graphql, useFragment } from "react-relay"
import { AccountHeroBanner } from "@/components/layout/AccountOrCollectionPage/components/AccountHeroBanner/"
import { AccountOrCollectionPageHeader } from "@/components/layout/AccountOrCollectionPage/components/AccountOrCollectionPageHeader"
import { AccountOrCollectionPageLayout } from "@/components/layout/AccountOrCollectionPage/components/AccountOrCollectionPageLayout"
import { AccountOrCollectionProfileImage } from "@/components/layout/AccountOrCollectionPage/components/AccountOrCollectionProfileImage"
import { SocialBar } from "@/components/layout/AccountOrCollectionPage/components/SocialBar"
import { Subheader } from "@/components/layout/AccountOrCollectionPage/components/Subheader"
import { AccountOrCollectionPageContextProvider } from "@/components/layout/AccountOrCollectionPage/utils/AccountOrCollectionPageContextProvider.react"
import { CampaignAnnouncementModal } from "@/components/modals/CampaignAnnouncementModal.react"
import { AccountBannedPage } from "@/components/pages/AccountBannedPage"
import { ErrorPage } from "@/components/pages/ErrorPage"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useTheme } from "@/design-system/Context"
import { Skeleton } from "@opensea/ui-kit"
import { ProfileListingsContextProvider } from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { AccountPageContext } from "@/features/account/components/AccountPage/tabs/utils"
import { useAssetContextActions } from "@/features/account/hooks/useAssetSelection"
import { AccountTab } from "@/features/account/types"
import { useSocialLinksData } from "@/features/account/utils/socialLinks"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/AccountTrackingContext.react"
import { PageTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/PageTrackingContext/PageTrackingContext.react"
import { IdentityInputType } from "@/lib/graphql/__generated__/ActivityAccountPageQuery.graphql"
import { ProfileContainer_data$key } from "@/lib/graphql/__generated__/ProfileContainer_data.graphql"
import { ApplyForVerificationBanner } from "../../ApplyForVerificationBanner"
import { ProfilePageMetadata } from "../components/ProfileMetadata"
import { AssetSelectionContextProvider } from "./AssetSelectionContextProvider.react"
import { ProfileActionBar } from "./ProfileActionBar"
import { ProfileInfo } from "./ProfileInfo"
import { ProfileTabs } from "./ProfileTabs.react"

// NOTE(@laurafiuza): useAssetContextActions hook won't work
// properly if it's called in the same component that renders
// AssetSelectionContextProvider. It must be a child.
export const ProfileContainer = (props: Props) => {
  return (
    <AssetSelectionContextProvider>
      <ProfileListingsContextProvider>
        <Profile {...props} />
      </ProfileListingsContextProvider>
    </AssetSelectionContextProvider>
  )
}

type Props = {
  data: ProfileContainer_data$key | null
  initialSidebarOpen: boolean
  isYourProfile: boolean
  isCurrentUser: boolean
  tabContent: React.ReactNode
  name?: string | null
  identifier?: string
  initialAccountOnPageLoad: IdentityInputType
  tab: AccountTab
}

const PATHS_TO_PERSIST_SELECTION = ["bulk-list", "account"]

const Profile = ({
  data: dataKey,
  initialSidebarOpen,
  isYourProfile,
  isCurrentUser,
  tabContent,
  name,
  identifier,
  initialAccountOnPageLoad,
  tab,
}: Props) => {
  const data = useFragment(
    // TODO(@laurafiuza): Fix non-colocated fragments and unused fields below.
    // Currently added to the relayLinterExceptions file
    graphql`
      fragment ProfileContainer_data on Query
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        collection: { type: "CollectionSlug" }
        includePrivateAssetCount: { type: "Boolean!" }
      ) {
        account(identity: $identity) {
          address
          bannerImageUrl
          displayName
          user {
            username
            publicUsername
          }
          metadata {
            isBanned
            isDisabled
          }
          ...AccountOrCollectionPageHeader_account
          ...ProfileInfo_data
          ...Subheader_account
          ...ApplyForVerificationBanner_data
          ...ProfileActionBar_data
          ...AccountOrCollectionProfileImage_data
          ...socialLinksData
          ...profilePageQueries_account
            @arguments(includePrivateAssetCount: $includePrivateAssetCount)
          ...profilePageQueries_featured @arguments(showContextMenu: false)
          ...AccountTrackingContext_account
        }
        ...CampaignAnnouncementModal_data
        ...ProfileMetadata_data
          @arguments(identity: $identity, collection: $collection)
      }
    `,
    dataKey,
  )
  const t = useTranslate("phoenix")
  const [exclude, setExclude] = useState<string[]>([])
  const connectedAddress = useConnectedAddress()
  const router = useRouter()
  const { theme } = useTheme()
  const fill = theme === "light" ? "charcoal" : "fog"
  const { onDeleteAll } = useAssetContextActions()

  useEffect(() => {
    const clearSelectionOnRouteChange = (url: string) => {
      // Clear selection if navigating out of account page (with exception of bulk list which uses existing asset selection)
      const shouldClearSelection = PATHS_TO_PERSIST_SELECTION.every(
        path => !url.startsWith(`/${path}/`),
      )
      if (shouldClearSelection) {
        onDeleteAll()
      }
    }

    router.events.on("routeChangeStart", clearSelectionOnRouteChange)

    return () => {
      router.events.off("routeChangeStart", clearSelectionOnRouteChange)
    }
  }, [onDeleteAll, router.events])

  useEffect(() => {
    // Clear selected assets if viewer is not viewing their own account
    if (!isCurrentUser) {
      onDeleteAll()
    }
  }, [isCurrentUser, onDeleteAll])

  // TODO(@laurafiuza): colocate this info into child fragment
  const socialLinks = useSocialLinksData(data?.account ?? null, fill, 20)
  const socialBarData = socialLinks.map(sl => ({
    url: sl.href,
    icon: sl.icon,
    tooltip: sl.tooltip,
  }))

  useEffect(() => {
    // Reload the account page if the user switches wallets while on their
    // account page. Otherwise, the page will be from their previous wallet.
    if (
      isYourProfile &&
      connectedAddress &&
      connectedAddress !== initialAccountOnPageLoad.address
    ) {
      router.push(router.asPath)
    }
  }, [connectedAddress, isYourProfile, initialAccountOnPageLoad, router])

  useEffect(() => {
    // NOTE(@laurafiuza): Redirects from previously used paradigm
    // "/account?tab=activity" becomes "/account/activity".
    // Previously used underscores become hyphens.
    const pageTab = router.query["tab"] as AccountTab
    if (pageTab) {
      const newTabName = pageTab.replace(/_/g, "-")
      router.push(`/${identifier ?? "account"}/${newTabName}`)
    }
  }, [identifier, isCurrentUser, router, tab])

  const isListViewEnabled = tab === "collected"

  if (
    data?.account?.metadata?.isBanned ||
    data?.account?.metadata?.isDisabled
  ) {
    if (isCurrentUser) {
      return <AccountBannedPage />
    } else {
      return <ErrorPage statusCode={404} />
    }
  }

  return (
    <AccountTrackingContextProvider account={data?.account ?? null}>
      <PageTrackingContextProvider data={data} name="Account" tab={tab}>
        <AccountOrCollectionPageContextProvider
          initialSidebarOpen={initialSidebarOpen}
          isListViewEnabled={isListViewEnabled}
        >
          <OpenSeaPage hideFooter>
            <ProfilePageMetadata
              data={data ?? null}
              isYourProfile={isYourProfile}
              name={name}
            />
            <AccountPageContext.Provider value={{ exclude, setExclude }}>
              <AccountOrCollectionPageLayout
                actionBar={
                  <ProfileActionBar
                    data={data?.account ?? null}
                    isCurrentUser={isCurrentUser}
                    socialData={socialBarData}
                  />
                }
                banner={
                  <>
                    <ApplyForVerificationBanner
                      data={data?.account ?? null}
                      isCurrentUser={isCurrentUser}
                    />
                    <AccountHeroBanner
                      altText={
                        data?.account?.displayName
                          ? `${data.account.displayName} ${t(
                              "profile.bannerSuffix",
                              "banner",
                            )}`
                          : t("profile.profileBannerTitle", "Profile banner")
                      }
                      bannerImageUrl={
                        data?.account?.bannerImageUrl ?? undefined
                      }
                      isEditable={isCurrentUser}
                    />
                  </>
                }
                content={tabContent}
                header={
                  <AccountOrCollectionPageHeader
                    accountDataKey={data?.account ?? null}
                    collectionDataKey={null}
                    isLoading={!data}
                  />
                }
                image={
                  <AccountOrCollectionProfileImage
                    data={data?.account ?? null}
                    editable={isCurrentUser}
                  />
                }
                info={<ProfileInfo data={data?.account ?? null} />}
                socialBar={
                  data ? (
                    <SocialBar items={socialBarData} />
                  ) : (
                    <Skeleton.Row className="ml-3">
                      <Skeleton.Line className="h-11 w-[200px]" />
                    </Skeleton.Row>
                  )
                }
                subheader={
                  <Subheader
                    accountDataKey={data?.account ?? null}
                    collectionDataKey={null}
                    isLoading={!data}
                  />
                }
                tabs={
                  <ProfileTabs
                    accountIdentifier={identifier}
                    accountKey={data?.account ?? null}
                    featuredKey={data?.account ?? null}
                    identity={initialAccountOnPageLoad}
                    isCurrentUser={isCurrentUser}
                    isLoading={!data}
                    isYourProfile={isYourProfile}
                  />
                }
              />
            </AccountPageContext.Provider>
            <CampaignAnnouncementModal dataKey={data ?? null} />
          </OpenSeaPage>
        </AccountOrCollectionPageContextProvider>
      </PageTrackingContextProvider>
    </AccountTrackingContextProvider>
  )
}
