import { OrderSortOption } from "@/lib/graphql/__generated__/AccountOffersOrderSearchListQuery.graphql"

export type OffersColumn =
  | "offer"
  | "status"
  | "price"
  | "quantity"
  | "total"
  | "expiration"
  | "dateCreated"

export type SortableOffersColumn = Extract<
  OffersColumn,
  "price" | "quantity" | "total" | "expiration" | "dateCreated"
>
const SORTABLE_COLUMNS: SortableOffersColumn[] = [
  "price",
  "quantity",
  "total",
  "expiration",
  "dateCreated",
]

export const isSortableOffersColumn = (
  column: OffersColumn,
): column is SortableOffersColumn =>
  SORTABLE_COLUMNS.includes(column as SortableOffersColumn)

const MOBILE_COLUMNS: Extract<
  OffersColumn,
  "price" | "quantity" | "expiration" | "status" | "dateCreated"
>[] = ["price", "quantity", "expiration", "status", "dateCreated"]
export type MobileOffersColumn = (typeof MOBILE_COLUMNS)[number]

export const isMobileOffersColumn = (
  column: OffersColumn,
): column is MobileOffersColumn =>
  MOBILE_COLUMNS.includes(column as MobileOffersColumn)

export type OffersViewMode = "owner" | "public"
export type OffersSelectedState = "none" | "some" | "all"

export type OrderSort = {
  sortAscending: boolean
  orderSortBy: OrderSortOption
}

export type StatusIndicatorType = "expiring" | "valid" | "invalid"
