import React, { useCallback, useMemo } from "react"
import { Icon } from "@opensea/ui-kit"
import { useCopyToClipboard } from "react-use"
import { CancelSwapsActionModal } from "@/components/blockchain/swaps/CancelSwapsActionModal"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { IS_SERVER } from "@/constants/environment"
import { Dropdown } from "@/design-system/Dropdown"
import { Flex } from "@/design-system/Flex"
import { IconButton } from "@/design-system/IconButton"
import { PopoverContentProps } from "@/design-system/Popover"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  ViewDealProperties,
  useTrackDealCardCancelClick,
  useTrackViewDealCancelCompleted,
  useTrackDealCardCopyLinkClick,
} from "../analytics"
import { DealStatus, DealViewer } from "../types"
import { getDealUrl } from "../urls"

type DealCardDropdownProps = {
  chain: ChainIdentifier
  relayId: string
  connectedViewer: DealViewer
  dealStatus: DealStatus
}

export const DealCardDropdown = ({
  chain,
  relayId,
  connectedViewer,
  dealStatus,
}: DealCardDropdownProps) => {
  const t = useTranslate("deals")
  const router = useRouter()
  const [_, copy] = useCopyToClipboard()
  const { openModal } = useGlobalModal()
  const trackCancelClick = useTrackDealCardCancelClick()
  const trackCancelCompleted = useTrackViewDealCancelCompleted()
  const trackCopyLinkClick = useTrackDealCardCopyLinkClick()
  const { origin } = useRouter()

  const { showSuccessMessage } = useToasts()

  const properties = useMemo<ViewDealProperties>(
    () => ({
      dealRelayId: relayId,
      dealStatus,
      viewer: connectedViewer,
    }),
    [relayId, dealStatus, connectedViewer],
  )

  const handleClick = useCallback(
    (close: PopoverContentProps["close"]) => {
      close()
      openModal(
        close => (
          <CancelSwapsActionModal
            swaps={[relayId]}
            onEnd={() => {
              close()
              trackCancelCompleted(properties)
              router.push(`/account/deals/canceled`)
            }}
          />
        ),
        {},
      )
    },
    [openModal, router, relayId, trackCancelCompleted, properties],
  )
  return (
    <Dropdown
      appendTo={IS_SERVER ? undefined : document.body}
      content={({ List, Item, close }) => (
        <List>
          {connectedViewer === "maker" && dealStatus === "active" && (
            <NetworkUnsupportedGate chainIdentifier={chain} shouldAuthenticate>
              {({ handleIfNotSupported }) => (
                <Item
                  onClick={e => {
                    e.stopPropagation()
                    trackCancelClick(properties)
                    handleIfNotSupported(() => {
                      handleClick(close)
                    })()
                  }}
                >
                  <Item.Avatar icon="cancel" />
                  <Item.Content>
                    <Item.Title>
                      {t("dealCard.dropdown.cancel", "Cancel deal")}
                    </Item.Title>
                  </Item.Content>
                </Item>
              )}
            </NetworkUnsupportedGate>
          )}
          <Item
            onClick={e => {
              e.stopPropagation()
              copy(`${origin}${getDealUrl(relayId)}`)
              showSuccessMessage(
                t("dealCard.dropdown.linkCopiedSuccessfully", "Link copied!"),
              )
              trackCopyLinkClick(properties)
              close()
            }}
          >
            <Item.Avatar icon="link" />
            <Item.Content>
              <Item.Title>
                {t("dealCard.dropdown.copyLink", "Copy link")}
              </Item.Title>
            </Item.Content>
          </Item>
        </List>
      )}
      hideOnScroll
    >
      <Flex>
        <IconButton
          aria-label={t("dealCard.dropdown.moreDropdown", "More dropdown")}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Icon value="more_horiz" />
        </IconButton>
      </Flex>
    </Dropdown>
  )
}
