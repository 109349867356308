/**
 * @generated SignedSource<<a34e8258d2248adf15351728c2de4985>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profilePageQueries_featured$data = {
  readonly shelves: ReadonlyArray<{
    readonly description: string | null;
    readonly displayOrder: number;
    readonly id: string;
    readonly name: string;
    readonly shelfAssets: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly " $fragmentSpreads": FragmentRefs<"FeaturedShelfAssetsCount_shelf" | "FeaturedShelfAssets_data">;
  }>;
  readonly " $fragmentType": "profilePageQueries_featured";
};
export type profilePageQueries_featured$key = {
  readonly " $data"?: profilePageQueries_featured$data;
  readonly " $fragmentSpreads": FragmentRefs<"profilePageQueries_featured">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "profilePageQueries_featured",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountShelfType",
      "kind": "LinkedField",
      "name": "shelves",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayOrder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountShelfAssetType",
          "kind": "LinkedField",
          "name": "shelfAssets",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "showContextMenu",
              "variableName": "showContextMenu"
            }
          ],
          "kind": "FragmentSpread",
          "name": "FeaturedShelfAssets_data"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "FeaturedShelfAssetsCount_shelf",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountShelfAssetType",
              "kind": "LinkedField",
              "name": "shelfAssets",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "4cdc008b10a0e06091a276d247ecb363";

export default node;
