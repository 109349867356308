import React, { ReactNode, useCallback, useMemo, useState } from "react"
import { noop } from "lodash"
import { createContext } from "use-context-selector"
import {
  StoredOffer,
  useOfferSelectionState,
} from "@/features/account/components/OffersTable/hooks/useOfferSelectionState"

type ContextType = {
  selectedOffers: readonly StoredOffer[]
  canceledIds: readonly string[]
  numSelected: number
  onCanceled: (offerRelayIds: string[]) => unknown
  onClose: () => unknown
  onDelete: (offerRelayIds: string[]) => unknown
  onDeleteAll: () => unknown
  onSelect: (offer: StoredOffer) => unknown
  onBatchSelect: (offers: StoredOffer[]) => unknown
  isSelected: (offerRelayId: string) => boolean
}

export const OfferSelectionContext = createContext<ContextType>({
  selectedOffers: [],
  canceledIds: [],
  numSelected: 0,
  onCanceled: noop,
  onClose: noop,
  onDelete: noop,
  onDeleteAll: noop,
  onSelect: noop,
  onBatchSelect: noop,
  isSelected: () => false,
})

type ProviderProps = {
  children: ReactNode
}

export const OfferSelectionContextProvider = ({ children }: ProviderProps) => {
  const {
    selectedItems: selectedOffers,
    batchSelect,
    select,
    unselect,
    unselectAll,
    clear,
  } = useOfferSelectionState()

  const [canceledIds, setCanceledIds] = useState<string[]>([])

  const onCanceled = useCallback(
    (ids: string[]) => {
      unselect(ids)
      setCanceledIds(prevIds => prevIds.concat(ids))
    },
    [unselect],
  )

  const onClose = useCallback(() => clear(), [clear])

  const onBatchSelect = useCallback(
    (offers: StoredOffer[]) => {
      batchSelect(offers)
    },
    [batchSelect],
  )

  const onDelete = useCallback(
    (itemRelayIds: string[]) => unselect(itemRelayIds),
    [unselect],
  )

  const numSelected = useMemo(() => selectedOffers.length, [selectedOffers])
  const isSelected = useCallback(
    (offerRelayId: string) =>
      selectedOffers.some(offer => offer.relayId === offerRelayId),
    [selectedOffers],
  )

  const value = useMemo(
    () => ({
      selectedOffers,
      canceledIds,
      numSelected,
      onCanceled,
      onClose,
      onDelete,
      onDeleteAll: unselectAll,
      onSelect: select,
      onBatchSelect,
      isSelected,
    }),
    [
      selectedOffers,
      canceledIds,
      numSelected,
      onCanceled,
      onClose,
      onDelete,
      unselectAll,
      select,
      onBatchSelect,
      isSelected,
    ],
  )

  return (
    <OfferSelectionContext.Provider value={value}>
      {children}
    </OfferSelectionContext.Provider>
  )
}
