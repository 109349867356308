import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useIsHydrated } from "@opensea/ui-kit"
import { noop } from "lodash"
import {
  FetchPolicy,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay"
import { AssetSearchViewNoResults } from "@/components/search/assets/AssetSearchView"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { OffersTable } from "@/features/account/components/OffersTable/OffersTable"
import { OrderSort } from "@/features/account/components/OffersTable/types"
import { useOfferSelectionCanceledIds } from "@/features/account/hooks/useOfferSelection"
import { useMountEffect } from "@/hooks/useMountEffect"
import { AccountOffersOrderSearchListPagination_data$key } from "@/lib/graphql/__generated__/AccountOffersOrderSearchListPagination_data.graphql"
import {
  AccountOffersOrderSearchListQuery,
  AccountOffersOrderSearchListQuery$variables,
} from "@/lib/graphql/__generated__/AccountOffersOrderSearchListQuery.graphql"
import { clearCache } from "@/lib/graphql/environment/middlewares/cacheMiddleware"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { AccountOffersOrderSearchState } from "./AccountOffersOrderSearch.react"

type Props = {
  searchState: AccountOffersOrderSearchState
  setSort: (sort: OrderSort) => unknown
  isCurrentUser: boolean
}

type PaginationProps = Props & {
  data: AccountOffersOrderSearchListPagination_data$key | null
  variables: AccountOffersOrderSearchListQuery$variables
}

const LIST_QUERY = graphql`
  query AccountOffersOrderSearchListQuery(
    $filterByOrderRules: Boolean = false
    $isExpired: Boolean
    $makerAssetIsPayment: Boolean
    $collections: [CollectionSlug!]
    $identity: IdentityInputType!
    $sortAscending: Boolean
    $includeInvalidBids: Boolean
    $sortBy: OrderSortOption
    $maker: IdentityInputType
    $orderStatusToggles: [OrderStatusToggle!]
    $offerTypeToggles: [OfferTypeToggle!]
    $includeCriteriaOrders: Boolean = false
    $cursor: String
    $count: Int
  ) {
    ...AccountOffersOrderSearchListPagination_data
      @arguments(
        filterByOrderRules: $filterByOrderRules
        isExpired: $isExpired
        identity: $identity
        makerAssetIsPayment: $makerAssetIsPayment
        collections: $collections
        sortAscending: $sortAscending
        sortBy: $sortBy
        orderStatusToggles: $orderStatusToggles
        offerTypeToggles: $offerTypeToggles
        maker: $maker
        includeInvalidBids: $includeInvalidBids
        includeCriteriaOrders: $includeCriteriaOrders
        cursor: $cursor
        count: $count
      )
  }
`

const PAGINATION_FRAGMENT = graphql`
  fragment AccountOffersOrderSearchListPagination_data on Query
  @argumentDefinitions(
    filterByOrderRules: { type: "Boolean", defaultValue: false }
    identity: { type: "IdentityInputType!" }
    isExpired: { type: "Boolean" }
    makerAssetIsPayment: { type: "Boolean" }
    collections: { type: "[CollectionSlug!]" }
    includeInvalidBids: { type: "Boolean" }
    sortAscending: { type: "Boolean" }
    sortBy: { type: "OrderSortOption" }
    orderStatusToggles: { type: "[OrderStatusToggle!]" }
    offerTypeToggles: { type: "[OfferTypeToggle!]" }
    maker: { type: "IdentityInputType" }
    includeCriteriaOrders: { type: "Boolean", defaultValue: false }
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 5 }
  )
  @refetchable(queryName: "AccountOffersOrderSearchListPaginationQuery") {
    orders(
      after: $cursor
      first: $count
      filterByOrderRules: $filterByOrderRules
      isExpired: $isExpired
      makerAssetIsPayment: $makerAssetIsPayment
      collections: $collections
      sortAscending: $sortAscending
      includeInvalidBids: $includeInvalidBids
      orderStatusToggles: $orderStatusToggles
      offerTypeToggles: $offerTypeToggles
      sortBy: $sortBy
      maker: $maker
      includeCriteriaOrders: $includeCriteriaOrders
    ) @connection(key: "AccountOffersOrderSearchListPagination_orders") {
      edges {
        node {
          ...OffersTable_orders @arguments(identity: $identity)
        }
      }
      count
    }
  }
`

const LazyAccountOffersOrderSearchList = (props: Props) => {
  const variables = {
    filterByOrderRules: false,
    isExpired: false,
    makerAssetIsPayment: true,
    collections: props.searchState.collections || undefined,
    sortAscending: props.searchState.sortAscending,
    sortBy: props.searchState.orderSortBy || "OPENED_AT",
    maker: props.searchState.identity,
    includeCriteriaOrders: true,
    includeInvalidBids: props.isCurrentUser,
    identity: props.searchState.identity,
    orderStatusToggles: props.searchState.orderStatusToggles,
    offerTypeToggles: props.searchState.offerTypeToggles,
    count: GLOBAL_MAX_PAGE_SIZE,
  }

  const [fetchPolicyState, setFetchPolicyState] = useState<
    FetchPolicy | undefined
  >("network-only")

  useMountEffect(() => {
    setFetchPolicyState(undefined)
  })

  const data = useLazyLoadQuery<AccountOffersOrderSearchListQuery>(
    LIST_QUERY,
    variables,
    { fetchPolicy: fetchPolicyState },
  )
  return (
    <AccountOffersOrderSearchListPagination
      data={data}
      variables={variables}
      {...props}
    />
  )
}

const AccountOffersOrderSearchListPagination = ({
  data: dataKey,
  variables,
  ...props
}: PaginationProps) => {
  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment<
      AccountOffersOrderSearchListQuery,
      AccountOffersOrderSearchListPagination_data$key
    >(PAGINATION_FRAGMENT, dataKey)

  const { searchState } = props
  const canceledIds = useOfferSelectionCanceledIds()
  const loadMore = useCallback(() => loadNext(GLOBAL_MAX_PAGE_SIZE), [loadNext])
  const orders = getNodes(data?.orders)
  const totalCount = data?.orders.count || 0

  const handleRefetch = useCallback(() => {
    clearCache()
    refetch(variables, { fetchPolicy: "network-only" })
  }, [refetch, variables])

  const refRefetch = useRef(handleRefetch)
  useEffect(() => {
    refRefetch.current = handleRefetch
  }, [handleRefetch])

  useEffect(() => {
    if (canceledIds.length) {
      refRefetch.current()
    }
  }, [canceledIds.length])

  if (totalCount === 0 || !orders.length) {
    return <AssetSearchViewNoResults query="" onBackClick={noop} />
  }

  return (
    <OffersTable
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadMore}
      mode={props.isCurrentUser ? "owner" : "public"}
      orders={orders}
      selectedSort={
        searchState.orderSortBy && searchState.sortAscending !== undefined
          ? {
              orderSortBy: searchState.orderSortBy,
              sortAscending: searchState.sortAscending,
            }
          : undefined
      }
      totalItemCount={totalCount}
      onSort={props.setSort}
    />
  )
}

export const AccountOffersOrderSearchList = ({
  searchState,
  setSort,
  isCurrentUser,
}: Props) => {
  const isHydrated = useIsHydrated()

  const skeleton = (
    <OffersTable.Skeleton
      mode={isCurrentUser ? "owner" : "public"}
      totalItemCount={GLOBAL_MAX_PAGE_SIZE}
    />
  )
  if (!isHydrated) {
    return skeleton
  }

  return (
    <Suspense fallback={skeleton}>
      <LazyAccountOffersOrderSearchList
        isCurrentUser={isCurrentUser}
        searchState={searchState}
        setSort={setSort}
      />
    </Suspense>
  )
}
