import React from "react"
import { useActiveTab } from "@/components/layout/AccountOrCollectionPage/hooks/useActiveTab"
import { useFeaturedShelfCount } from "@/components/layout/AccountOrCollectionPage/hooks/useFeaturedShelfCount"
import { useProfilePageTabs } from "@/components/layout/AccountOrCollectionPage/hooks/useProfilePageTabs"
import { StickyTabNavigation } from "@/design-system/TabNavigation"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { profilePageQueries_account$key } from "@/lib/graphql/__generated__/profilePageQueries_account.graphql"
import { profilePageQueries_featured$key } from "@/lib/graphql/__generated__/profilePageQueries_featured.graphql"

type ProfileTabsProps = {
  featuredKey: profilePageQueries_featured$key | null
  accountKey: profilePageQueries_account$key | null
  isCurrentUser: boolean
  isLoading: boolean
  accountIdentifier: string | undefined
  identity?: IdentityInputType
  // This just means the default /account page
  isYourProfile: boolean
}

export const ProfileTabs = ({
  featuredKey,
  accountKey,
  isCurrentUser,
  isYourProfile,
  isLoading,
  accountIdentifier,
  identity,
}: ProfileTabsProps) => {
  const tabs = useProfilePageTabs({
    featuredKey,
    accountKey,
    isCurrentUser,
    isYourProfile,
    identity,
  })
  const featuredCount = useFeaturedShelfCount({ featuredKey, isYourProfile })
  const currentTab = useActiveTab({
    featuredCount: featuredCount ?? undefined,
    isProfilePage: true,
    isYourProfile,
  })
  return (
    <StickyTabNavigation
      baseUrl={`/${accountIdentifier ?? "account"}`}
      currentTab={currentTab}
      isLoading={isLoading}
      isProfilePage
      tabs={tabs}
    />
  )
}
