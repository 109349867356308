import React from "react"
import { MapNonNullable } from "@/lib/helpers/type"
import { CollectionFilter } from "../CollectionFilter.react"
import SearchFilter from "../SearchFilter.react"
import { OrderSearchVariables } from "./types"

type Props = {
  clear: () => unknown
  state: MapNonNullable<OrderSearchVariables>
  setState: (state: Partial<MapNonNullable<OrderSearchVariables>>) => unknown
  renderFn?: (content: JSX.Element) => JSX.Element
}

const OrderSearchFilter = ({ clear, state, setState, renderFn }: Props) => {
  const content = (
    <CollectionFilter
      filters={{ collections: state.collections }}
      scopeQueryByAssetOwner={false}
      selectedSlugs={state.collections || []}
      setSlugs={collections =>
        setState({
          collections: collections ? collections : [],
        })
      }
    />
  )

  if (renderFn) {
    return renderFn(content)
  }

  return (
    <SearchFilter clear={clear} fullscreenBreakpoint="md">
      {content}
    </SearchFilter>
  )
}

export default OrderSearchFilter
