import { graphql } from "relay-runtime"
import { useSelectionState } from "@/hooks/useSelectionState"
import { useOfferSelectionState_order$data } from "@/lib/graphql/__generated__/useOfferSelectionState_order.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

export type StoredOffer = Omit<
  useOfferSelectionState_order$data,
  " $fragmentType"
>

export const useOfferSelectionState = () => {
  return useSelectionState<StoredOffer>({ selectedItems: [] })
}

export const readOfferSelectionItem = inlineFragmentize<
  useOfferSelectionState_order$data,
  StoredOffer
>(
  graphql`
    fragment useOfferSelectionState_order on OrderV2Type @inline {
      relayId
      orderType
      item {
        chain {
          identifier
        }
      }
      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...CancelOrdersButton_data
    }
  `,
  order => ({
    ...order,
    relayId: order.relayId,
    orderType: order.orderType,
    item: {
      chain: {
        identifier: order.item.chain.identifier,
      },
    },
  }),
)
