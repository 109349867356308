import React from "react"
import { Media, Checkbox, Flex } from "@opensea/ui-kit"
import { IS_SERVER } from "@/constants/environment"
import { useTheme } from "@/design-system/Context"
import { DropdownProps } from "@/design-system/Dropdown"
import { SelectOption } from "@/design-system/Select"
import { SearchSortBy } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import THEMES from "@/styles/themes"
import { Item } from "../featured-filter"
import { StyledSelect } from "./styles"

type Props<T> = {
  setFilters: (filters: ReadonlyArray<T>) => unknown
  filters: ReadonlyArray<T>
  possibleFilterItems: ReadonlyArray<Item<T>>
  title: string
}

type SortBySelectOption = SelectOption<SearchSortBy> & {
  sortAscending?: boolean
}

const SearchStatusDropdown = <T,>({
  setFilters,
  filters,
  possibleFilterItems,
  title,
}: Props<T>) => {
  const { theme } = useTheme()
  const handleToggleFilter = (toggledFilter: T) => {
    const isCurrentlySelected = filters.includes(toggledFilter)
    const newFilters = new Set(filters)
    if (isCurrentlySelected) {
      newFilters.delete(toggledFilter)
    } else {
      newFilters.add(toggledFilter)
    }
    setFilters(Array.from(newFilters))
  }

  const items = possibleFilterItems.map(({ filter, label }: Item<T>) => {
    return {
      id: label,
      isSelected: filters.includes(filter),
      value: label,
      label,
      onChange: () => handleToggleFilter(filter),
      startEnhancer: (
        <Flex className="items-center">
          <Flex className="mr-4 items-center">
            <Checkbox checked={filters.includes(filter)} id={label} />
          </Flex>
        </Flex>
      ),
    }
  })

  let appendTo: DropdownProps<SortBySelectOption>["appendTo"]

  if (!IS_SERVER) {
    appendTo = "parent"
  }

  const select = (
    <StyledSelect
      clearable={false}
      options={items}
      overrides={{
        Dropdown: {
          props: {
            appendTo,
            popperOptions: {
              modifiers: [{ name: "flip", enabled: false }],
            },
          },
        },
      }}
      placeholder={title}
      readOnly
      style={{
        fontWeight: 600,
        fontSize: 16,
        color:
          theme === "light"
            ? THEMES.light.colors.text.primary
            : THEMES.dark.colors.text.primary,
        padding: "12px 12px 12px 16px",
      }}
      onSelect={(option?: SelectOption<string> & { onChange?: () => void }) =>
        option?.onChange?.()
      }
    />
  )

  return <Media greaterThanOrEqual="lg">{select}</Media>
}

export default SearchStatusDropdown
