import React, { useCallback } from "react"
import { useFragment } from "react-relay"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Button } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { CancelOrdersConfirmationModal } from "@/features/cancel-orders/components/CancelOrdersConfirmationModal.react"
import { useIsGaslessCancellationEnabled } from "@/features/cancel-orders/hooks/useIsGaslessCancellationEnabled"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOrdersButton_data$key } from "@/lib/graphql/__generated__/CancelOrdersButton_data.graphql"
import { OrderSide } from "@/lib/graphql/__generated__/OrderType.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { CancelOrdersActionModal } from "./CancelOrdersActionModal/CancelOrdersActionModal.react"

const CANCEL_ORDERS_MAX_SIZE = 30

type CancelOrdersButtonProps = {
  orders: CancelOrdersButton_data$key
  side: OrderSide
  disabled?: boolean
  onOrdersCanceled?: (relayIds: string[]) => unknown
  trigger?: ({
    disabled,
    open,
  }: {
    disabled: boolean
    open: () => unknown
  }) => React.ReactNode
}

export const CancelOrdersButton = ({
  orders: ordersKey,
  side,
  disabled,
  onOrdersCanceled,
  trigger,
}: CancelOrdersButtonProps) => {
  const t = useTranslate("orders")
  const { isEnabledForCollectionOffers, isEnabledForOffers } =
    useIsGaslessCancellationEnabled()
  const { openModal } = useGlobalModal()
  const { showErrorMessage } = useToasts()

  const orders = useFragment<CancelOrdersButton_data$key>(
    graphql`
      fragment CancelOrdersButton_data on OrderV2Type @relay(plural: true) {
        relayId
        item {
          __typename
          chain {
            identifier
          }
          ...CancelOrdersConfirmationModal_items
        }
        orderType
        side
      }
    `,
    ordersKey,
  )

  const modalType = orders[0]?.orderType === "CRITERIA" ? "collection" : "asset"
  const handleClick = useCallback(() => {
    const orderRelayIds = orders.map(order => order.relayId)

    const gaslessCancelEligibleOrders =
      (isEnabledForCollectionOffers &&
        orders.some(order => order.orderType === "CRITERIA")) ||
      (isEnabledForOffers && orders.some(order => order.side === "BID"))

    // There is a built-in confirmation modal in the gasless cancel orders flow.
    const renderCancelOrdersActionModal = (close: () => void) => (
      <CancelOrdersActionModal
        gaslessCancelEligibleOrders={gaslessCancelEligibleOrders}
        orders={orderRelayIds}
        onEnd={() => {
          close()
          onOrdersCanceled?.(orderRelayIds)
        }}
        onError={(error?: Error) => {
          showErrorMessage(
            error?.message ||
              t("cancelOrder.error", "Something went wrong while cancelling."),
          )
          close()
        }}
      />
    )

    openModal(
      close =>
        gaslessCancelEligibleOrders ? (
          renderCancelOrdersActionModal(close)
        ) : (
          <CancelOrdersConfirmationModal
            items={orders.map(order => order.item)}
            renderNext={() => renderCancelOrdersActionModal(close)}
            side={side}
            type={modalType}
          />
        ),
      {
        position: "centered",
        size: "medium",
      },
    )
  }, [
    isEnabledForCollectionOffers,
    isEnabledForOffers,
    modalType,
    onOrdersCanceled,
    openModal,
    orders,
    showErrorMessage,
    side,
    t,
  ])

  const connectedAddress = useConnectedAddress()
  if (!connectedAddress) {
    // Hide button if there is no active account
    return null
  }

  const chain = orders[0]?.item?.chain?.identifier
  const orderType = orders[0]?.orderType

  const disabledReason = (() => {
    const uniqueChains = new Set(
      orders.map(order => order.item.chain.identifier),
    )
    if (uniqueChains.size > 1) {
      return side === "ASK"
        ? t(
            "selectionFooter.cancelOrdersCta.multipleChains.listings",
            "You can only cancel listings from the same chain.",
          )
        : orderType === "ENGLISH"
        ? t(
            "selectionFooter.cancelOrdersCta.multipleChains.bids",
            "You can only cancel bids from the same chain.",
          )
        : t(
            "selectionFooter.cancelOrdersCta.multipleChains.offers",
            "You can only cancel offers from the same chain.",
          )
    }

    if (orders.length === 0) {
      return t("selectionFooter.cancelOrdersCta.noOrders", "None selected.")
    }

    if (orders.length > CANCEL_ORDERS_MAX_SIZE) {
      return side === "ASK"
        ? t(
            "selectionFooter.cancelOrdersCta.maxInfoLimit.listings",
            "You can only cancel up to {{count}} listings at once.",
            { count: CANCEL_ORDERS_MAX_SIZE },
          )
        : orderType === "ENGLISH"
        ? t(
            "selectionFooter.cancelOrdersCta.maxInfoLimit.bids",
            "You can only cancel {{count}} bids at once.",
            { count: CANCEL_ORDERS_MAX_SIZE },
          )
        : t(
            "selectionFooter.cancelOrdersCta.maxInfoLimit.offers",
            "You can only cancel {{count}} offers at once.",
            { count: CANCEL_ORDERS_MAX_SIZE },
          )
    }

    return null
  })()

  const finalDisabled = Boolean(disabledReason || disabled)

  return (
    <Tooltip content={disabledReason} disabled={!finalDisabled} maxWidth={200}>
      <span>
        <NetworkUnsupportedGate chainIdentifier={chain} shouldAuthenticate>
          {({ handleIfNotSupported }) =>
            trigger ? (
              trigger({
                disabled: finalDisabled,
                open: handleIfNotSupported(handleClick),
              })
            ) : (
              <Button
                disabled={finalDisabled}
                variant="secondary"
                onClick={handleIfNotSupported(handleClick)}
              >
                {t("cancelOrder.cta", "Cancel")}
              </Button>
            )
          }
        </NetworkUnsupportedGate>
      </span>
    </Tooltip>
  )
}
