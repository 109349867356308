import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { ChainLogo } from "@/components/common/ChainLogo"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { IS_SERVER } from "@/constants/environment"
import { Item } from "@/design-system/Item"
import { Skeleton } from "@opensea/ui-kit"
import { Tooltip } from "@/design-system/Tooltip"
import { CHECKBOX_PADDING } from "@/features/account/components/PortfolioTable/constants"
import { PortfolioTableItemCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableItemCell_item.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { getAssetDisplayName } from "@/lib/helpers/asset"
import { getItemUrl } from "@/lib/helpers/item"
import { bn, shortSymbolDisplay } from "@/lib/helpers/numberUtils"
import { PortfolioTableItemCellTooltip } from "../tooltips/PortfolioTableItemCellTooltip.react"

type Props = {
  item: PortfolioTableItemCell_item$key
  showChain?: boolean
}

const ASSET_IMAGE_SIZE_PX = 36

const PortfolioTableItemCellBase = ({
  item: itemKey,
  showChain = false,
}: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableItemCell_item on ItemType
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        __typename
        chain {
          displayName
          identifier
        }
        ...PortfolioTableItemCellTooltip_item
        ... on AssetType {
          ownedQuantity(identity: $identity)
          assetContract {
            ...CollectionLink_assetContract
          }
          assetCollection: collection {
            ...CollectionLink_collection
          }
          ...AssetMedia_asset
          ...asset_display_name
        }
        ... on AssetBundleType {
          displayName
          bundleCollection: collection {
            ...CollectionLink_collection
          }
          assetQuantities(first: 18) {
            edges {
              node {
                asset {
                  ...StackedAssetMedia_assets
                }
              }
            }
          }
        }
        ...item_url
      }
    `,
    itemKey,
  )
  const {
    __typename,
    assetContract,
    assetCollection,
    bundleCollection,
    chain,
    ownedQuantity,
  } = item

  const isAsset = __typename === "AssetType"
  const isBundle = __typename === "AssetBundleType"

  const bundleAssets = isBundle
    ? getNodes(item.assetQuantities).map(aq => aq.asset)
    : []
  const collection = isAsset
    ? assetCollection
    : isBundle
    ? bundleCollection
    : null

  return (
    <PortfolioTableItemCellTooltip item={item} placement="bottom-start">
      <Item variant="unstyled">
        <StyledItemAvatar className="relative" size={ASSET_IMAGE_SIZE_PX}>
          {isAsset ? (
            <StyledAssetMedia asset={item} size={ASSET_IMAGE_SIZE_PX} />
          ) : isBundle ? (
            <StackedAssetMedia assets={bundleAssets} variant="xxxsmall" />
          ) : (
            <AssetMediaSkeleton />
          )}
        </StyledItemAvatar>
        <Item.Content className="mr-0">
          <Item.Title>
            <Text.Body asChild size="small" weight="semibold">
              <Link
                href={getItemUrl(item)}
                variant="subtle"
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
              >
                <Overflow className="text-primary" lines={1}>
                  {isAsset ? (
                    <>
                      {getAssetDisplayName(item)}{" "}
                      {ownedQuantity && bn(ownedQuantity).gt(1) && (
                        <Text.Body className="text-secondary" size="tiny">
                          x{shortSymbolDisplay(ownedQuantity)}
                        </Text.Body>
                      )}
                    </>
                  ) : isBundle ? (
                    <>
                      {item.displayName}{" "}
                      {bundleAssets.length && (
                        <Text.Body className="text-secondary" size="tiny">
                          x{shortSymbolDisplay(bundleAssets.length)}
                        </Text.Body>
                      )}
                    </>
                  ) : null}
                </Overflow>
              </Link>
            </Text.Body>
          </Item.Title>
          <Item.Description className="flex w-full items-center">
            {showChain && (
              <Tooltip
                appendTo={IS_SERVER ? undefined : document.body}
                content={chain.displayName}
              >
                <Flex className="mr-1.5 h-4 w-[22px] items-center border-r border-r-level-2">
                  <ChainLogo
                    chain={chain.identifier}
                    variant="gray"
                    width={16}
                  />
                </Flex>
              </Tooltip>
            )}
            {collection && (
              <Text.Body className="w-full text-secondary" size="tiny">
                <CollectionLink
                  assetContract={assetContract}
                  collection={collection}
                  isTiny
                  linkStyle={{
                    color: "inherit",
                    fontSize: "inherit",
                    fontWeight: "inherit",
                  }}
                  tabIndex={-1}
                  onClick={e => e.stopPropagation()}
                />
              </Text.Body>
            )}
          </Item.Description>
        </Item.Content>
      </Item>
    </PortfolioTableItemCellTooltip>
  )
}

const PortfolioTableItemCellSkeleton = () => {
  return (
    <Item variant="unstyled">
      <StyledItemAvatar size={ASSET_IMAGE_SIZE_PX}>
        <AssetMediaSkeleton />
      </StyledItemAvatar>
      <Item.Content className="mr-4">
        <Item.Title className="w-3/4">
          <Skeleton.Line className="h-[14px]" />
        </Item.Title>
        <Item.Description className="mt-0.5 w-1/2">
          <Skeleton.Line className="h-3" />
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export const PortfolioTableItemCell = Object.assign(
  PortfolioTableItemCellBase,
  {
    Skeleton: PortfolioTableItemCellSkeleton,
  },
)

const StyledItemAvatar = styled(Item.Avatar).attrs({
  style: {
    marginRight: CHECKBOX_PADDING,
    overflow: "visible",
  },
})`
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-radius: ${props => props.theme.borderRadius.default};
`

const StyledAssetMedia = styled(AssetMedia)`
  // Minus 1 to ensure no whitespace between container border and image edges.
  border-radius: calc(${props => props.theme.borderRadius.default} - 1px);
`

const AssetMediaSkeleton = styled(Skeleton.Block).attrs({
  height: ASSET_IMAGE_SIZE_PX,
  width: ASSET_IMAGE_SIZE_PX,
})``
