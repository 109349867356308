import React from "react"
import { Text } from "@opensea/ui-kit"
import { graphql } from "react-relay"
import type { _FragmentRefs } from "relay-runtime"
import { useVerifiedOwnershipTitle } from "@/hooks/useCopy"
import type { socialLinksData$data } from "@/lib/graphql/__generated__/socialLinksData.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { FillColor, getSocialIcon } from "@/lib/helpers/icons"
import { getSocialAccountUrl } from "@/lib/helpers/socialUrls"
import { hasHTTPS } from "@/lib/helpers/urls"

type SocialLink = {
  type: "Twitter" | "Instagram" | "Website"
  username?: string
  href: string
  icon: JSX.Element
  tooltip: React.ReactNode
  description?: string
}

const fetchSocialAccounts = inlineFragmentize<socialLinksData$data>(
  graphql`
    fragment socialLinksData on AccountType @inline {
      metadata {
        instagramUsername
        twitterUsername
        websiteUrl
      }
      connectedTwitterUsername
      connectedInstagramUsername
    }
  `,
  identifiers => identifiers,
)

export const useSocialLinksData = (
  ref: socialLinksData$data | _FragmentRefs<"socialLinksData"> | null,
  fill?: FillColor,
  size = 24,
) => {
  const verifiedTwitterTitle = useVerifiedOwnershipTitle()
  if (!ref) {
    return []
  }
  const { metadata, connectedTwitterUsername, connectedInstagramUsername } =
    fetchSocialAccounts(ref)
  const { instagramUsername, twitterUsername, websiteUrl } = metadata ?? {}
  const socialLinks: SocialLink[] = []

  const resolvedTwitterUsername = connectedTwitterUsername
    ? connectedTwitterUsername
    : twitterUsername

  const showConnectedInstagram = !!connectedInstagramUsername

  const resolvedInstagramUsername = showConnectedInstagram
    ? connectedInstagramUsername
    : instagramUsername

  if (websiteUrl) {
    socialLinks.push({
      type: "Website",
      href:
        websiteUrl && !hasHTTPS(websiteUrl)
          ? `https://${websiteUrl}`
          : websiteUrl,
      icon: getSocialIcon({
        name: "website",
        width: size,
        height: size,
        fill,
      }),
      tooltip: "Website",
    })
  }
  if (resolvedInstagramUsername) {
    socialLinks.push({
      type: "Instagram",
      username: resolvedInstagramUsername,
      href: getSocialAccountUrl("instagram", resolvedInstagramUsername),
      icon: getSocialIcon({
        name: "instagram",
        width: size,
        height: size,
        fill,
      }),
      tooltip: showConnectedInstagram ? (
        <>
          <div>Instagram</div>
          <Text color="secondary" size="small" weight="semibold">
            {verifiedTwitterTitle}
          </Text>
        </>
      ) : (
        <div>Instagram</div>
      ),
      description: showConnectedInstagram ? verifiedTwitterTitle : "",
    })
  }

  if (resolvedTwitterUsername) {
    socialLinks.push({
      type: "Twitter",
      username: resolvedTwitterUsername,
      href: getSocialAccountUrl("twitter", resolvedTwitterUsername),
      icon: getSocialIcon({
        name: "twitter",
        width: size,
        height: size,
        fill,
      }),
      tooltip: (
        <>
          <div>Twitter</div>
          {connectedTwitterUsername && (
            <Text color="secondary" size="small" weight="semibold">
              {verifiedTwitterTitle}
            </Text>
          )}
        </>
      ),
      description: connectedTwitterUsername ? verifiedTwitterTitle : "",
    })
  }

  return socialLinks
}
