/**
 * @generated SignedSource<<46f750d94a33a55ce3186f48b6723d8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OffersTableRow_order$data = {
  readonly closedAt: string | null;
  readonly criteria: {
    readonly collection: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_collection">;
    };
    readonly trait: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionCell_trait">;
    } | null;
  } | null;
  readonly isValid: boolean;
  readonly item: {
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableItemCell_item">;
  };
  readonly openedAt: string;
  readonly orderType: OrderV2OrderType;
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExpirationDate_data" | "OffersTableOptionsCell_order" | "OffersTablePriceTooltipContent_item" | "OrderPrice">;
  readonly " $fragmentType": "OffersTableRow_order";
};
export type OffersTableRow_order$key = {
  readonly " $data"?: OffersTableRow_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OffersTableRow_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OffersTableRow_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "identity",
              "variableName": "identity"
            }
          ],
          "kind": "FragmentSpread",
          "name": "PortfolioTableItemCell_item"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderCriteriaType",
      "kind": "LinkedField",
      "name": "criteria",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionCell_collection"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TraitType",
          "kind": "LinkedField",
          "name": "trait",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionCell_trait"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isValid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OffersTableOptionsCell_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpirationDate_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OffersTablePriceTooltipContent_item"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "f9e58a7651d3df7053ffac32d4e62e09";

export default node;
