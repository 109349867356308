import React, { useCallback, useState } from "react"
import { Icon, UnstyledButton, Spinner } from "@opensea/ui-kit"
import styled, {
  css,
  DefaultTheme,
  StyledComponentProps,
} from "styled-components"
import { captureNoncriticalError } from "@/lib/sentry"
import { Flex } from "../Flex"

export type SortDirection = "asc" | "desc"

type SortIndicatorProps = {
  direction?: SortDirection
  loading?: boolean
}

const SortIndicator = ({ direction, loading }: SortIndicatorProps) => {
  const icon = direction === "asc" ? "expand_less" : "expand_more"
  return loading ? (
    <Spinner />
  ) : direction === undefined ? (
    <Icon className="cursor-pointer" size={16} value="unfold_more" />
  ) : (
    <Icon className="cursor-pointer" size={20} value={icon} />
  )
}

export type SortableHeaderProps = {
  clearable?: boolean
  header: React.ReactNode
  initialSortDirection?: SortDirection
  sort?: (direction?: SortDirection) => Promise<void> | void
  sortDirection?: SortDirection
  sortIndicatorHideMode?: "remove" | "hide"
  buttonProps?: StyledComponentProps<
    typeof UnstyledButton,
    DefaultTheme,
    Record<string, unknown>,
    ""
  >
}

const getOppositeDirection = (direction?: SortDirection) =>
  direction === "desc" ? "asc" : "desc"

export const SortableHeader = ({
  buttonProps,
  clearable,
  header,
  initialSortDirection,
  sort,
  sortDirection,
  sortIndicatorHideMode = "hide",
}: SortableHeaderProps) => {
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(async () => {
    if (!sort) {
      return
    }

    const newDirection =
      clearable &&
      sortDirection &&
      sortDirection === getOppositeDirection(initialSortDirection)
        ? undefined
        : getOppositeDirection(sortDirection)

    setLoading(true)
    try {
      await sort(newDirection)
    } catch (error) {
      captureNoncriticalError(error)
    } finally {
      setLoading(false)
    }
  }, [clearable, initialSortDirection, sort, sortDirection])

  if (!sort) {
    return <>{header}</>
  }

  return (
    <HeaderButton
      $selected={
        sortIndicatorHideMode === "hide" || sortDirection !== undefined
      }
      {...buttonProps}
      onClick={handleClick}
    >
      {header}
      {(sortIndicatorHideMode === "remove" || sortDirection !== undefined) && (
        <Flex marginLeft="2px">
          <SortIndicator direction={sortDirection} loading={loading} />
        </Flex>
      )}
    </HeaderButton>
  )
}

const HeaderButton = styled(UnstyledButton)<{ $selected: boolean }>`
  color: ${props => props.theme.colors.text.secondary};
  :hover {
    color: ${props => props.theme.colors.text.primary};
  }

  ${({ theme, $selected }) =>
    $selected &&
    css`
      color: ${theme.colors.text.primary};
    `}
`
