import React from "react"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import {
  ListingSearch,
  ListingSearchState,
} from "@/components/search/orders/ListingSearch.react"
import { OrdersPanelTitle } from "@/components/search/orders/OrdersPanelTitle.react"
import { ActiveListings } from "@/features/orders/Listings"
import { useTranslate } from "@/hooks/useTranslate"
import { ListingsAccountPageQuery } from "@/lib/graphql/__generated__/ListingsAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "./utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  searchState: ListingSearchState
}

export const ListingsPage: GraphQLNextPage<ListingsAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  searchState,
}) => {
  const t = useTranslate("phoenix")

  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="listings"
      tabContent={
        <ListingSearch
          defaultState={{ collections: undefined }}
          fixedState={{ identity: searchState.identity }}
          height="calc(100vh - 240px)"
          initialState={searchState}
          panelIcon="playlist_add_check"
          path={path}
          title={
            <OrdersPanelTitle
              title={t("profile.activeListingsTitle", "Active listings")}
            />
          }
        >
          {searchState => <ActiveListings {...searchState} />}
        </ListingSearch>
      }
    />
  )
}

const query = graphql`
  query ListingsAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

ListingsPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
  },
  async (
    { identifier },
    context,
  ): Promise<GraphQLInitialProps<ListingsAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables, path } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        tab: "listings",
      })

    const { includePrivateAssetCount, identity, collection, collections } =
      variables

    const pageVariables = {
      includePrivateAssetCount,
      identity,
      collection,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      variables: pageVariables,
      searchState: {
        identity,
        collections,
      },
    }
  },
)
