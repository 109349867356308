import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { AccountCollectionsPagination } from "@/components/accounts/AccountCollectionsPagination.react"
import {
  AccountCollectionsQuery,
  AccountCollectionsQuery$variables,
} from "@/lib/graphql/__generated__/AccountCollectionsQuery.graphql"

type Props = AccountCollectionsQuery$variables

export const AccountCollections = (props: Props) => {
  const data = useLazyLoadQuery<AccountCollectionsQuery>(
    graphql`
      query AccountCollectionsQuery($creator: IdentityInputType!) {
        ...AccountCollectionsPagination_data @arguments(creator: $creator)
      }
    `,
    { ...props },
  )
  return <AccountCollectionsPagination data={data} />
}
