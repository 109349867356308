import React, { useEffect } from "react"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { useRouter } from "@/hooks/useRouter"
import { PrivateAccountPageQuery } from "@/lib/graphql/__generated__/PrivateAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../../components/ProfileContainer"
import { cachePolicy, getInitialPropsAccountPageQuery } from "../utils"
import {
  AccountPrivateAssetSearch,
  AccountPrivateAssetSearchState,
} from "./AccountPrivateAssetSearch.react"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  assetCardVariant?: AssetCardVariant
  searchState: AccountPrivateAssetSearchState
}

export const PrivatePage: GraphQLNextPage<PrivateAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  assetCardVariant,
  searchState,
}) => {
  const identity = searchState.identity
  const router = useRouter()

  useEffect(() => {
    // TODO (web-platform): This should be done SSR (getServerSideProps)
    if (!isCurrentUser) {
      router.push(`/${identifier}`)
    }
  })

  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="private"
      tabContent={
        <AccountPrivateAssetSearch
          assetCardVariant={assetCardVariant}
          defaultState={{
            chains: undefined,
            collection: undefined,
            collections: undefined,
            sortAscending: undefined,
            sortBy: "LAST_TRANSFER_DATE",
            query: undefined,
            isAutoHidden: undefined,
            identity,
            toggles: undefined,
          }}
          fixedState={{ identity }}
          initialState={searchState}
          path={path}
        />
      }
    />
  )
}

const query = graphql`
  query PrivateAccountPageQuery($identity: IdentityInputType!) {
    ...ProfileContainer_data
      @arguments(identity: $identity, includePrivateAssetCount: true)
  }
`

PrivatePage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
    search: QP.Optional(QP.Search),
  },
  async (
    { identifier, search },
    context,
  ): Promise<GraphQLInitialProps<PrivateAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables, path, assetCardVariant } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        search,
        tab: "private",
      })

    const { identity, collection, collections, chains } = variables

    const searchState: AccountPrivateAssetSearchState = {
      chains,
      collection,
      collections,
      identity,
      query: search?.query,
      sortAscending: search?.sortAscending,
      isAutoHidden: search?.isAutoHidden,
      sortBy: "LAST_TRANSFER_DATE",
      toggles: search?.toggles,
    }

    const appInitialProps = await getAppInitialProps(context, {
      variables: {
        identity,
      },
      query,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      assetCardVariant,
      variables: {
        identity,
      },
      searchState,
    }
  },
)
