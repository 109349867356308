import React from "react"
import { Flex, SpaceBetween, Text } from "@opensea/ui-kit"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { useTranslate } from "@/hooks/useTranslate"

export const OrdersPanelTitle = ({
  title,
  extra,
}: {
  title: string
  extra?: React.ReactNode
}) => {
  const t = useTranslate("components")
  return (
    <SpaceBetween className="w-full">
      <Flex className="items-center">
        <Text weight="semibold">{title} </Text>
        {extra}
      </Flex>
      <Link href="/account/settings?tab=support&cancelall=true">
        <Flex className="items-center">
          {t("ordersPanelTitle.cancel.title", "Cancel all listings and offers")}
          <InfoIcon
            overrides={{
              Button: {
                style: { marginLeft: "8px" },
              },
              Icon: {
                className: "text-blue-3",
              },
            }}
            tooltipContent={t(
              "ordersPanelTitle.cancel.tooltip",
              "Cancelling all Ethereum listings and offers together saves more gas than cancelling a single transaction.",
            )}
          />
        </Flex>
      </Link>
    </SpaceBetween>
  )
}
