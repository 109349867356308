/**
 * @generated SignedSource<<796ce8e50a1345af1eccd55e34116eb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListingsTable_asset$data = {
  readonly assetContract: {
    readonly address: string;
  };
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly isCurrentlyFungible: boolean;
  readonly relayId: string;
  readonly tokenId: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditListingButton_item">;
  readonly " $fragmentType": "PortfolioTableListingsTable_asset";
};
export type PortfolioTableListingsTable_asset$key = {
  readonly " $data"?: PortfolioTableListingsTable_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingsTable_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListingsTable_asset",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditListingButton_item"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetContractType",
      "kind": "LinkedField",
      "name": "assetContract",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentlyFungible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenId",
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "7d7cf7309ca5be2186fe848ceeb1ab53";

export default node;
