import React from "react"
import { Media, UnstyledButton, Text, Flex, Skeleton } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import AccountBadge from "@/components/common/AccountBadge.react"
import { CollectionStatusModal } from "@/components/modals/CollectionStatusModal.react"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountOrCollectionPageHeader_account$key } from "@/lib/graphql/__generated__/AccountOrCollectionPageHeader_account.graphql"
import { AccountOrCollectionPageHeader_collection$key } from "@/lib/graphql/__generated__/AccountOrCollectionPageHeader_collection.graphql"
import { verificationStatusHasBadge } from "@/lib/helpers/verification"

type Props = {
  accountDataKey: AccountOrCollectionPageHeader_account$key | null
  collectionDataKey: AccountOrCollectionPageHeader_collection$key | null
  isLoading?: boolean
}

export function AccountOrCollectionPageHeader({
  accountDataKey,
  collectionDataKey,
  isLoading,
}: Props) {
  const t = useTranslate("phoenix")
  const accountData = useFragment(
    graphql`
      fragment AccountOrCollectionPageHeader_account on AccountType {
        config
        displayName
        isCompromised
      }
    `,
    accountDataKey,
  )

  const collectionData = useFragment(
    graphql`
      fragment AccountOrCollectionPageHeader_collection on CollectionType {
        name
        verificationStatus
      }
    `,
    collectionDataKey,
  )

  if (isLoading) {
    return (
      <Skeleton.Row>
        <Media greaterThanOrEqual="sm">
          <Skeleton.Line className="h-14 w-[500px]" />
        </Media>
        <Media lessThan="sm">
          <Flex className="mt-2">
            <Skeleton.Line className="h-[34px] w-[200px]" />
          </Flex>
        </Media>
      </Skeleton.Row>
    )
  }

  const headerText = accountDataKey
    ? accountData?.displayName ?? t("profile.unnamed", "Unnamed")
    : collectionData?.name ?? ""

  let badge = (!!accountData?.config ||
    accountData?.isCompromised !== undefined) && (
    <ProfileAccountBadge
      config={accountData.config}
      isCompromised={!!accountData.isCompromised}
      variant="medium"
    />
  )

  if (!badge) {
    badge = !!collectionData?.verificationStatus &&
      verificationStatusHasBadge(collectionData.verificationStatus) && (
        <Modal
          trigger={open => (
            <Block
              display="inline-flex"
              marginBottom="4px"
              verticalAlign="middle"
            >
              <UnstyledButton onClick={open}>
                <VerificationIcon
                  size="medium"
                  verificationStatus={
                    collectionData.verificationStatus as "VERIFIED" | "MINTABLE"
                  }
                />
              </UnstyledButton>
            </Block>
          )}
        >
          <CollectionStatusModal
            verificationStatus={collectionData.verificationStatus}
          />
        </Modal>
      )
  }

  return (
    <Flex
      className="items-center gap-1 break-words"
      data-testid="phoenix-header"
    >
      <Media lessThan="sm">
        <Text.Heading asChild className="w-full" size="medium">
          <h1>
            {headerText}
            {badge}
          </h1>
        </Text.Heading>
      </Media>
      <Media greaterThanOrEqual="sm">
        <Text.Display asChild className="w-full" size="medium">
          <h1>
            {headerText}
            {badge}
          </h1>
        </Text.Display>
      </Media>
    </Flex>
  )
}

const ProfileAccountBadge = styled(AccountBadge)`
  display: inline-flex;
  vertical-align: middle;
`
