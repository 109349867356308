/**
 * @generated SignedSource<<2f4670656d6db3633ec26c1b5ca4995b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActiveListings_data$data = {
  readonly getAccount: {
    readonly activeListings: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"CancelOrderButton_data" | "OrdersTableRow_data">;
        } | null;
      } | null>;
    };
  };
  readonly " $fragmentType": "ActiveListings_data";
};
export type ActiveListings_data$key = {
  readonly " $data"?: ActiveListings_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveListings_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "getAccount",
  "activeListings"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collections"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ActiveListingsPaginationQuery.graphql')
    }
  },
  "name": "ActiveListings_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "identity"
        }
      ],
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "getAccount",
      "plural": false,
      "selections": [
        {
          "alias": "activeListings",
          "args": [
            {
              "kind": "Variable",
              "name": "collections",
              "variableName": "collections"
            }
          ],
          "concreteType": "OrderV2TypeConnection",
          "kind": "LinkedField",
          "name": "__ActiveListings_activeListings_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderV2Type",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "OrdersTableRow_data"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CancelOrderButton_data"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "60947b5cc31f16e3e0fe3fa7b03651ac";

export default node;
