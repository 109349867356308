import { FragmentRef, graphql } from "relay-runtime"
import {
  MobileOffersColumn,
  OffersColumn,
  StatusIndicatorType,
} from "@/features/account/components/OffersTable/types"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import {
  OrderInvalidationReason,
  OrderV2OrderType,
  useAnalyticsOfferEvent_data$data,
} from "@/lib/graphql/__generated__/useAnalyticsOfferEvent_data.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getStatusIndicatorVariant } from "./useStatusIndicator"

type ActionType = "click" | "completed"
type TriggerType = "icon" | "text_button"

type SelectOffersEvent = {
  selected: boolean
  selectedCount: number
  totalItemCount: number
}

type CancelEvent = {
  actionType: ActionType
}

type BulkCancelEvent = {
  selectedCount: number
  actionType: ActionType
  triggerType: TriggerType
}

type SelectColumnEvent = {
  column: MobileOffersColumn
}

type SortColumnEvent = {
  column: OffersColumn
  isDirectionAsc: boolean
}

type Offer = {
  orderType: OrderV2OrderType
  invalidationReason: OrderInvalidationReason | null
  status: StatusIndicatorType
}

type AnalyticsOffer = useAnalyticsOfferEvent_data$data

const getOfferAnalyticsParams = (offer: AnalyticsOffer): Offer => {
  const status = getStatusIndicatorVariant(offer.isValid, offer.closedAt)
  return {
    orderType: offer.orderType,
    invalidationReason: offer.invalidationReason,
    status,
  }
}

const readOffer = inlineFragmentize<AnalyticsOffer, Offer>(
  graphql`
    fragment useAnalyticsOfferEvent_data on OrderV2Type @inline {
      orderType
      invalidationReason
      closedAt
      isValid
    }
  `,
  getOfferAnalyticsParams,
)

export const useAnalytics = () => {
  const useOfferTrackingFn = <T>(name: string) => {
    const offerTrackingFn = useTrackingFn<Offer & T>(name)
    return (offer: FragmentRef<AnalyticsOffer>, params: T) => {
      offerTrackingFn({ ...readOffer(offer), ...params })
    }
  }

  const trackCancelOffer = useOfferTrackingFn<CancelEvent>(
    "cancel offers table",
  )

  const trackSelectOffersTableRow = useOfferTrackingFn<SelectOffersEvent>(
    "select offers table row",
  )

  const trackBulkCancelOffers = useTrackingFn<BulkCancelEvent>(
    "bulk cancel offers table",
  )

  const trackSelectAllOffersTableRows = useTrackingFn<SelectOffersEvent>(
    "select all offers table rows",
  )

  const trackSelectOffersTableMobileColumn = useTrackingFn<SelectColumnEvent>(
    "select offers table mobile column",
  )

  const trackSortOffersTableColumn = useTrackingFn<SortColumnEvent>(
    "sort offers table column",
  )

  return {
    trackCancelOffer,
    trackBulkCancelOffers,
    trackSelectAllOffersTableRows,
    trackSelectOffersTableRow,
    trackSelectOffersTableMobileColumn,
    trackSortOffersTableColumn,
  }
}
