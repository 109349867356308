/**
 * @generated SignedSource<<6ef06cdfcd7b5a34bb998f835b60b7f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListingsTableRow_data$data = {
  readonly item: {
    readonly __typename: string;
  };
  readonly perUnitPriceType: {
    readonly symbol: string;
    readonly unit: string;
    readonly usd: string;
  };
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExpirationDate_data">;
  readonly " $fragmentType": "PortfolioTableListingsTableRow_data";
};
export type PortfolioTableListingsTableRow_data$key = {
  readonly " $data"?: PortfolioTableListingsTableRow_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingsTableRow_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListingsTableRow_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpirationDate_data"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "782b81878fdb3d9d9cff8132f59e38db";

export default node;
