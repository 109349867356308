import { useRouter } from "@/hooks/useRouter"

export const useActiveTab = ({
  featuredCount = 0,
  isProfilePage,
  isYourProfile,
}: {
  featuredCount?: number
  isProfilePage: boolean
  isYourProfile: boolean
}) => {
  const router = useRouter()
  const splitPathname = router.pathname.split("/")
  const pageTab = splitPathname.length > 2 ? splitPathname[2] : undefined

  if (isProfilePage) {
    if (pageTab) {
      return pageTab
    }

    // If you're on your own profile page, always show collected first.
    if (isYourProfile) {
      return "collected"
    }

    // Otherwise, if we are on other people's profile pages, show featured first if it's available.
    if (featuredCount) {
      return "featured"
    }

    return "collected"
  }

  return pageTab
}
