import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { PriceText } from "@/design-system/PriceText"
import { PortfolioTableFloorPriceCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableFloorPriceCell_item.graphql"

type Props = {
  item: PortfolioTableFloorPriceCell_item$key
}

export const PortfolioTableFloorPriceCell = ({ item: itemKey }: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableFloorPriceCell_item on ItemType {
        __typename
        ... on AssetType {
          assetCollection: collection {
            statsV2 {
              floorPrice {
                unit
                symbol
              }
            }
          }
        }
        ... on AssetBundleType {
          bundleCollection: collection {
            statsV2 {
              floorPrice {
                unit
                symbol
              }
            }
          }
        }
      }
    `,
    itemKey,
  )
  const { __typename } = item

  const collection =
    __typename === "AssetType"
      ? item.assetCollection
      : __typename === "AssetBundleType"
      ? item.bundleCollection
      : null

  return (
    <PriceText
      subtleEmpty
      symbol={collection?.statsV2.floorPrice?.symbol}
      unit={collection?.statsV2.floorPrice?.unit}
    />
  )
}
