import React, { useCallback } from "react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block, BlockProps } from "@/design-system/Block"
import { Button, ButtonProps } from "@/design-system/Button"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { TriggerProps } from "@/features/account/components/AccountPage/components/ProfileItemSelectionFooter/components/AssetsContextMenu.react"
import { useCanceledListingsItemRelayIds } from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { useAssetContextSelectedItems } from "@/features/account/hooks/useAssetSelection"
import { CancelItemOrdersButton } from "@/features/cancel-item-orders"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"

const BULK_CANCEL_LISTINGS_SELECTION_MAX_SIZE = 30

type Props = {
  overrides?: {
    Block: {
      props: Partial<BlockProps>
    }
    Tooltip: {
      props: Partial<TooltipProps>
    }
  }
  onListingsCanceled?: () => unknown
} & ButtonProps &
  TriggerProps

export const CancelListingsButton = ({
  overrides,
  trigger,
  onListingsCanceled,
  onClick,
  ...buttonProps
}: Props) => {
  const t = useTranslate("account")
  const { wallet } = useWallet()
  const selectedItems = useAssetContextSelectedItems()
  const [canceledListingsItemRelayIds, setCanceledListingsItemRelayIds] =
    useCanceledListingsItemRelayIds()
  const accountAddress = wallet.getActiveAccountKey()?.address

  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()
  const itemsWithListings = selectedItems.filter(item => {
    // Ensures the user cannot try to cancel the listings for items that they
    // had just canceled all listings for. This is because the item best ask is
    // not reliably refreshed immediately after canceling the listings.
    if (canceledListingsItemRelayIds.includes(item.relayId)) {
      return false
    }
    return ownerListingPriceEnabled
      ? item.orderData?.bestAskForOwner?.relayId
      : item.orderData?.bestAskV2?.relayId
  })

  const handleListingsCanceled = useCallback(() => {
    setCanceledListingsItemRelayIds(prevUpdatedItemRelayIds => [
      ...prevUpdatedItemRelayIds,
      ...itemsWithListings.map(item => item.relayId),
    ])
    onListingsCanceled?.()
  }, [itemsWithListings, onListingsCanceled, setCanceledListingsItemRelayIds])

  if (itemsWithListings.length === 0) {
    return null
  }

  const disabledReason = (() => {
    const uniqueChains = new Set(
      selectedItems.map(item => item.chain.identifier),
    )
    if (uniqueChains.size > 1) {
      return t(
        "selectionFooter.cancelListingsCta.multipleChains",
        "You can only cancel listings for items from the same chain.",
      )
    }
    if (itemsWithListings.length > BULK_CANCEL_LISTINGS_SELECTION_MAX_SIZE) {
      return t(
        "selectionFooter.acceptCta.maxInfoLimit",
        "You can only cancel listings for up to {{count}} items at a time.",
        { count: BULK_CANCEL_LISTINGS_SELECTION_MAX_SIZE },
      )
    }

    // This can occur for ERC-1155s since we show a listing price for them
    // because we cannot yet confidently say that the user has listed the item
    // since we can only read the bestAsk.
    const hasItemsWithoutOwnListings = itemsWithListings.some(item => {
      const bestAsk = ownerListingPriceEnabled
        ? item.orderData?.bestAskForOwner
        : item.orderData?.bestAskV2
      return bestAsk?.maker.address !== accountAddress
    })
    if (hasItemsWithoutOwnListings) {
      return t(
        "selectionFooter.acceptCta.notOwnListings",
        "You can only cancel listings for items that you have listed.",
        { count: BULK_CANCEL_LISTINGS_SELECTION_MAX_SIZE },
      )
    }

    return undefined
  })()

  const content = t(
    "selectionFooter.cancelListingsCta.text",
    "Cancel all listings",
  )

  return (
    <Tooltip
      content={disabledReason}
      maxWidth={200}
      {...overrides?.Tooltip.props}
    >
      <Block {...overrides?.Block.props}>
        <CancelItemOrdersButton
          items={itemsWithListings}
          side="ASK"
          trigger={onTriggerClick =>
            trigger ? (
              trigger({
                content,
                disabled: !!disabledReason,
                icon: "cancel",
                onClick: (e: React.MouseEvent<HTMLElement>) => {
                  onTriggerClick()
                  onClick?.(e as React.MouseEvent<HTMLButtonElement>)
                },
              })
            ) : (
              <Button
                className="px-3"
                disabled={!!disabledReason}
                variant="secondary"
                onClick={e => {
                  onTriggerClick()
                  onClick?.(e as React.MouseEvent<HTMLButtonElement>)
                }}
                // Manually spreading this due to Typescript issues with ButtonIconProps when spreading buttonProps
                {...{
                  icon: "cancel",
                }}
                {...buttonProps}
              >
                {content}
              </Button>
            )
          }
          onOrdersCanceled={handleListingsCanceled}
        />
      </Block>
    </Tooltip>
  )
}
