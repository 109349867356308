import React from "react"
import styled from "styled-components"
import { Image } from "@/design-system/Image"
import { Maybe } from "@/lib/helpers/type"
import { fixAssetHost } from "@/lib/helpers/urls"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  bannerImageUrl?: Maybe<string>
  altText: string
  height?: number
  // 0 - 100% h/w ratio
  aspectRatio?: number
}

export const HeroBannerImage = styled(Image)`
  position: relative;
  right: 0;
  left: 0;
  border-radius: 0;
`

export const HeroBannerOutsideContainer = styled.div<{
  $height?: number
}>`
  position: relative;
  max-height: ${props => (props.$height ? `${props.$height}px` : "320px")};
  overflow: hidden;
`

export const HeroBannerContainer = styled.div<{
  $aspectRatio?: number
}>`
  height: 0;
  // 1:4 aspect ratio
  padding-bottom: ${props =>
    props.$aspectRatio ? `${props.$aspectRatio}%` : "25%"};
  position: relative;

  ${props =>
    themeVariant({
      variants: {
        light: {
          backgroundColor: "#E5E8EB50", // fog with alpha of 50
        },
        dark: {
          backgroundColor: props.theme.colors.onyx,
        },
      },
    })}
`

export function HeroBanner({
  altText,
  aspectRatio,
  bannerImageUrl,
  height,
}: Props) {
  const img = bannerImageUrl ? (
    <HeroBannerImage
      alt={altText}
      height={600}
      layout="fill"
      objectFit="cover"
      priority
      src={fixAssetHost(bannerImageUrl) ?? ""}
    />
  ) : null

  return (
    <HeroBannerOutsideContainer $height={height}>
      <HeroBannerContainer $aspectRatio={aspectRatio}>
        {img}
      </HeroBannerContainer>
    </HeroBannerOutsideContainer>
  )
}
