import React from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import { Table } from "@/design-system/Table"
import { CancelOrderButton } from "@/features/cancel-orders/components/CancelOrderButton.react"
import { InactiveListings_data$key } from "@/lib/graphql/__generated__/InactiveListings_data.graphql"
import { InactiveListingsPaginationQuery } from "@/lib/graphql/__generated__/InactiveListingsPaginationQuery.graphql"
import { InactiveListingsQuery } from "@/lib/graphql/__generated__/InactiveListingsQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { OrdersTable, useOrdersTableHeaders } from "../OrdersTable"
import { NoListings } from "./NoListings.react"

const PAGE_SIZE = 12
const INACTIVE_LISTINGS_TABLE_MAX_HEIGHT = "calc(100vh - 348px)"

type InactiveListingsProps = {
  collections?: ReadonlyArray<string>
}

const LazyInactiveListings = ({ collections }: InactiveListingsProps) => {
  const { wallet } = useWallet()

  const initialData = useLazyLoadQuery<InactiveListingsQuery>(
    graphql`
      query InactiveListingsQuery(
        $identity: AddressScalar!
        $collections: [CollectionSlug!]
        $count: Int!
        $cursor: String
      ) {
        ...InactiveListings_data
          @arguments(
            identity: $identity
            collections: $collections
            count: $count
            cursor: $cursor
          )
      }
    `,
    {
      identity: wallet.getActiveAddressStrict(),
      collections,
      count: PAGE_SIZE,
    },
    { fetchPolicy: "network-only" },
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment<
      InactiveListingsPaginationQuery,
      InactiveListings_data$key
    >(
      graphql`
        fragment InactiveListings_data on Query
        @argumentDefinitions(
          identity: { type: "AddressScalar!" }
          collections: { type: "[CollectionSlug!]" }
          cursor: { type: "String" }
          count: { type: "Int!" }
        )
        @refetchable(queryName: "InactiveListingsPaginationQuery") {
          getAccount(address: $identity) {
            inactiveListings(
              collections: $collections
              first: $count
              after: $cursor
            ) @connection(key: "InactiveListings_inactiveListings") {
              edges {
                node {
                  id
                  ...OrdersTableRow_data
                  ...CancelOrderButton_data
                }
              }
            }
          }
        }
      `,
      initialData,
    )

  return (
    <OrdersTable
      maxHeight={INACTIVE_LISTINGS_TABLE_MAX_HEIGHT}
      orders={getNodes(data.getAccount.inactiveListings)}
      page={{
        hasMore: () => hasNext,
        isLoading: () => isLoadingNext,
        loadMore: count => loadNextToLoadMore({ loadNext, count }),
      }}
      renderActions={order => {
        return (
          <CancelOrderButton
            dataKey={order}
            onOrderCanceled={() => {
              refetch({}, { fetchPolicy: "network-only" })
            }}
          />
        )
      }}
      renderEmpty={() => <NoListings />}
    />
  )
}

export const InactiveListings = (props: InactiveListingsProps) => {
  const headers = useOrdersTableHeaders()
  return (
    <SsrSuspense
      fallback={
        <Table.Skeleton
          headers={headers}
          maxHeight={INACTIVE_LISTINGS_TABLE_MAX_HEIGHT}
          rowHeight="55px"
        />
      }
    >
      <LazyInactiveListings {...props} />
    </SsrSuspense>
  )
}
