/**
 * @generated SignedSource<<3d0bcbae64cc4278f5e5241a2540dd37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderInvalidationReason = "ACCOUNT_DISABLED" | "ASSET_DISABLED" | "ASSET_OWNERSHIP_UPDATE" | "ASSET_REQUIRES_APPROVAL" | "CANCEL_TRANSACTION" | "COLLECTION_DISABLED" | "ERC20_APPROVAL_REVOKED" | "EXECUTION_REVERTED_ERROR" | "EXPIRED" | "GASLESS_CANCEL" | "INSUFFICIENT_BALANCE" | "MATCH_ORDERS_ERROR" | "NOT_EXECUTABLE" | "ON_CHAIN_VALIDATION_ERROR" | "ORDER_MALFORMED" | "PREMATCHED" | "TRAIT_OFFERS_DISABLED" | "TRANSACTION_SUBMISSION_ERROR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OffersTableOptionsCell_order$data = {
  readonly invalidationReason: OrderInvalidationReason | null;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CancelOrderButton_data" | "useAnalyticsOfferEvent_data">;
  readonly " $fragmentType": "OffersTableOptionsCell_order";
};
export type OffersTableOptionsCell_order$key = {
  readonly " $data"?: OffersTableOptionsCell_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OffersTableOptionsCell_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invalidationReason",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OffersTableOptionsCell_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelOrderButton_data"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useAnalyticsOfferEvent_data",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderType",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isValid",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "f3b7e0eeaa75639c5baa51e65f004d76";

export default node;
