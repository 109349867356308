import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react"
import { useIsLessThanBreakpoint } from "@opensea/ui-kit"
import { noop } from "lodash"
import { DESKTOP_BREAKPOINT } from "@/features/account/components/OffersTable/constants"
import { MobileOffersColumn } from "@/features/account/components/OffersTable/types"
import { useAnalytics } from "./hooks/useAnalytics"

type OffersTableContext = {
  isDesktop: boolean
  mobileColumn: MobileOffersColumn
  setMobileColumn: (column: MobileOffersColumn) => unknown
}

const DEFAULT_CONTEXT: OffersTableContext = {
  isDesktop: true,
  mobileColumn: "price",
  setMobileColumn: noop,
}

const OffersTableContext = createContext(DEFAULT_CONTEXT)

type Props = {
  children: React.ReactNode
}

export const OffersTableContextProvider = ({ children }: Props) => {
  const { trackSelectOffersTableMobileColumn } = useAnalytics()
  const isDesktop = !useIsLessThanBreakpoint(DESKTOP_BREAKPOINT)
  const [mobileColumn, setMobileColumn] = useState<MobileOffersColumn>(
    DEFAULT_CONTEXT.mobileColumn,
  )

  const setMobileColumnWithTracking = useCallback(
    (column: MobileOffersColumn) => {
      trackSelectOffersTableMobileColumn({ column })
      setMobileColumn(column)
    },
    [trackSelectOffersTableMobileColumn],
  )

  const value = useMemo(
    () => ({
      isDesktop,
      mobileColumn,
      setMobileColumn: setMobileColumnWithTracking,
    }),
    [isDesktop, mobileColumn, setMobileColumnWithTracking],
  )

  return (
    <OffersTableContext.Provider value={value}>
      {children}
    </OffersTableContext.Provider>
  )
}

export const useOffersTableContext = () => useContext(OffersTableContext)
