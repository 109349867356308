import React from "react"
import { Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { PriceText } from "@/design-system/PriceText"
import { PortfolioTableBestOfferCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableBestOfferCell_item.graphql"

type Props = {
  item: PortfolioTableBestOfferCell_item$key
}

export const PortfolioTableBestOfferCell = ({ item: itemKey }: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableBestOfferCell_item on ItemType {
        __typename
        ... on AssetType {
          bestAllTypeBid {
            perUnitPriceType {
              unit
              symbol
            }
          }
        }
        ... on AssetBundleType {
          orderData {
            bestBidV2 {
              priceType {
                unit
                symbol
              }
            }
          }
        }
      }
    `,
    itemKey,
  )

  const bestOfferPrice =
    item.__typename === "AssetType"
      ? item.bestAllTypeBid?.perUnitPriceType
      : item.__typename === "AssetBundleType"
      ? item.orderData.bestBidV2?.priceType
      : null

  return (
    <Flex className="w-full items-center justify-end md:justify-start">
      <PriceText
        subtleEmpty
        symbol={bestOfferPrice?.symbol}
        unit={bestOfferPrice?.unit}
      />
    </Flex>
  )
}
