import React, { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { SsrSuspense } from "components/common/SsrSuspense.react"
import { Button, ButtonProps } from "design-system/Button"
import { CancelOrdersConfirmationModal } from "features/cancel-orders/components/CancelOrdersConfirmationModal.react"
import { useGlobalModal } from "hooks/useGlobalModal"
import { useTranslate } from "hooks/useTranslate"
import { useTrackingFn } from "lib/analytics/hooks/useTrackingFn"
import { CancelItemOrdersButton_items$key } from "lib/graphql/__generated__/CancelItemOrdersButton_items.graphql"
import { OrderSide } from "lib/graphql/__generated__/OrderType.graphql"
import { CancelItemOrdersActionModal } from "./CancelItemOrdersActionModal.react"

type Props = {
  children?: React.ReactNode
  className?: string
  items: CancelItemOrdersButton_items$key
  side: OrderSide
  trigger?: (onClick: () => unknown) => React.ReactNode
  onOrdersCanceled: () => unknown
} & Omit<ButtonProps, "href" | "onClick" | "order">

const LazyCancelItemOrdersButton = ({
  children,
  className,
  items: itemsKey,
  side,
  trigger,
  onOrdersCanceled,
  ...buttonProps
}: Props) => {
  const t = useTranslate("orders")
  const { openModal } = useGlobalModal()
  const items = useFragment<CancelItemOrdersButton_items$key>(
    graphql`
      fragment CancelItemOrdersButton_items on ItemType @relay(plural: true) {
        __typename
        chain {
          identifier
        }
        ... on AssetType {
          relayId
        }
        ... on AssetBundleType {
          relayId
        }
        ...CancelOrdersConfirmationModal_items
      }
    `,
    itemsKey,
  )

  const trackCancelItemOrdersClick = useTrackingFn<{
    itemCount: number
    side: string
  }>("click cancel item orders button")

  const handleOnClick = useCallback(() => {
    const assetIds = items
      .filter(item => item.__typename === "AssetType")
      .map(item => item.relayId)
      .filter((relayId): relayId is string => !!relayId)
    const bundleIds = items
      .filter(item => item.__typename === "AssetBundleType")
      .map(item => item.relayId)
      .filter((relayId): relayId is string => !!relayId)

    trackCancelItemOrdersClick({
      itemCount: assetIds.length + bundleIds.length,
      side,
    })

    openModal(
      close => (
        <CancelOrdersConfirmationModal
          items={items}
          renderNext={() => (
            <CancelItemOrdersActionModal
              assets={assetIds}
              bundles={bundleIds}
              side={side}
              onEnd={() => {
                onOrdersCanceled()
                close()
              }}
            />
          )}
          side={side}
          type="asset"
        />
      ),
      { size: "medium" },
    )
  }, [items, onOrdersCanceled, openModal, side, trackCancelItemOrdersClick])

  return (
    <NetworkUnsupportedGate
      chainIdentifier={items[0]?.chain?.identifier}
      shouldAuthenticate
    >
      {({ handleIfNotSupported }) =>
        trigger ? (
          trigger(handleIfNotSupported(handleOnClick))
        ) : (
          <Button
            className={className}
            onClick={handleIfNotSupported(handleOnClick)}
            {...buttonProps}
          >
            {children ?? t("cancelItemOrdersButton.button", "Cancel orders")}
          </Button>
        )
      }
    </NetworkUnsupportedGate>
  )
}

export const CancelItemOrdersButton = (props: Props) => (
  <SsrSuspense fallback={null}>
    <LazyCancelItemOrdersButton {...props} />
  </SsrSuspense>
)
