import React from "react"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { getAccountLocaleUrl } from "@/features/account/utils/path"
import { OffersAccountPageQuery } from "@/lib/graphql/__generated__/OffersAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "../utils"
import {
  AccountOffersOrderSearch,
  AccountOffersOrderSearchState,
} from "./AccountOffersOrderSearch.react"
import { OfferSelectionContextProvider } from "./OfferSelectionContextProvider.react"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  searchState: AccountOffersOrderSearchState
}

export const OffersPage: GraphQLNextPage<OffersAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  searchState,
}) => {
  const identity = searchState.identity

  return (
    <OfferSelectionContextProvider>
      <ProfileContainer
        data={data}
        identifier={identifier}
        initialAccountOnPageLoad={variables.identity}
        initialSidebarOpen={false}
        isCurrentUser={isCurrentUser}
        isYourProfile={isYourProfile}
        name={variables.identity.name}
        tab="offers"
        tabContent={
          <AccountOffersOrderSearch
            defaultState={{
              collections: undefined,
              collection: undefined,
              identity,
              sortAscending: searchState.sortAscending,
              orderSortBy: searchState.orderSortBy,
              orderStatusToggles: undefined,
              offerTypeToggles: undefined,
            }}
            fixedState={{ identity }}
            initialState={searchState}
            isCurrentUser={isCurrentUser}
            path={path}
          />
        }
      />
    </OfferSelectionContextProvider>
  )
}

const query = graphql`
  query OffersAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    collection(collection: $collection) {
      __typename
    }
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

OffersPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
    search: QP.Optional(QP.Search),
  },
  async (
    { identifier, search },
    context,
  ): Promise<GraphQLInitialProps<OffersAccountPageQuery, Props>> => {
    const {
      isCurrentUser,
      isYourProfile,
      path,
      variables,
      sortAscending,
      orderSortBy,
    } = await getInitialPropsAccountPageQuery({
      context,
      identifier,
      search,
      tab: "offers",
    })

    const { includePrivateAssetCount, identity, collection, collections } =
      variables

    const searchState: AccountOffersOrderSearchState = {
      collection,
      collections,
      identity,
      sortAscending,
      orderSortBy,
      orderStatusToggles: search?.orderStatusToggles,
      offerTypeToggles: search?.offerTypeToggles,
    }

    const pageVariables = {
      includePrivateAssetCount,
      identity,
      collection,
    }

    const accountUrl = getAccountLocaleUrl(identifier, context.locale)

    const appInitialProps = await getAppInitialProps<OffersAccountPageQuery>(
      context,
      {
        query,
        variables: pageVariables,
        cachePolicy,
        redirectUrl: data => {
          const notFound = !!(
            data !== null &&
            collection &&
            data.collection === null
          )
          return notFound ? accountUrl + "/offers" : null
        },
      },
    )

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      variables: pageVariables,
      searchState,
    }
  },
)
