import React from "react"
import { FlexEnd, FlexColumn, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { ItemCell } from "@/components/assets/ItemCell"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { ExpirationDate } from "@/components/orders/ExpirationDate"
import { FloorPriceDifference } from "@/components/orders/FloorPriceDifference.react"
import { Block } from "@/design-system/Block"
import { Table } from "@/design-system/Table"
import { OrdersTableRow_data$key } from "@/lib/graphql/__generated__/OrdersTableRow_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn, MAX_DISPLAYED_DECIMAL_PLACES } from "@/lib/helpers/numberUtils"
import { OrdersTableProps } from "./OrdersTable.react"

const OrdersTableRowDataFragment = graphql`
  fragment OrdersTableRow_data on OrderV2Type {
    id
    ...OrderPrice
    ...OrderUsdPrice
    item {
      __typename
      ...ItemCell_data
      ...FloorPriceDifference_item
    }
    remainingQuantityType
    perUnitPriceType {
      ...FloorPriceDifference_perUnitPrice
    }
    ...ExpirationDate_data
  }
`

export type OrdersTableRowData = OrdersTableRow_data$key & {
  id: string
}

type OrdersTableRowProps<T extends OrdersTableRowData> = Pick<
  OrdersTableProps<T>,
  "renderActions"
> & {
  dataKey: T
}

export const OrdersTableRow = <T extends OrdersTableRowData>({
  dataKey,
  renderActions,
}: OrdersTableRowProps<T>) => {
  const data = useFragment<OrdersTableRow_data$key>(
    OrdersTableRowDataFragment,
    dataKey,
  )
  const { id, item, remainingQuantityType } = data

  if (item.__typename === "%other") {
    throw new Error("Unknown item type")
  }

  return (
    <Table.Row key={id}>
      <Table.Cell>
        <ItemCell item={item} quantity={bn(remainingQuantityType)} />
      </Table.Cell>
      <Table.Cell>
        <FlexColumn>
          <Block>
            <OrderPrice
              maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
              order={data}
              variant="perUnit"
            />
          </Block>
          <Block>
            <Text color="secondary" size="tiny" weight="semibold">
              <OrderUsdPrice color="inherit" order={data} variant="perUnit" />
            </Text>
          </Block>
        </FlexColumn>
      </Table.Cell>
      <Table.Cell>
        <FloorPriceDifference
          itemDataKey={data.item}
          perUnitPriceDataKey={data.perUnitPriceType}
        />
      </Table.Cell>
      <Table.Cell>
        <ExpirationDate dataKey={data} />
      </Table.Cell>
      {renderActions && (
        <Table.Cell className="sticky right-0">
          <FlexEnd>{renderActions(dataKey)}</FlexEnd>
        </Table.Cell>
      )}
    </Table.Row>
  )
}
