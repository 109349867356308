import React, { useCallback, useEffect, useState } from "react"
import { useIsLessThanLg } from "@opensea/ui-kit"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import { AssetCardVariantToggle } from "@/components/layout/AccountOrCollectionPage/components/AssetCardVariantToggle"
import { FilterBar } from "@/components/layout/AccountOrCollectionPage/components/FilterBar"
import { useStickyTop } from "@/components/layout/AccountOrCollectionPage/hooks/useStickyTop"
import AssetSearchChainDropdown from "@/components/search/assets/AssetSearchChainDropdown.react"
import AssetSearchFilter, {
  getFilterCount,
  useDefaultStatusItems,
} from "@/components/search/assets/AssetSearchFilter"
import { AssetSearchLayout } from "@/components/search/assets/AssetSearchLayout/AssetSearchLayout.react"
import { AssetSearchRefreshBar } from "@/components/search/assets/AssetSearchRefreshBar"
import { AssetSearchTraitAndQueryInput } from "@/components/search/assets/AssetSearchTraitAndQueryInput"
import {
  AssetCardVariant,
  mergeTraitFilterState,
  updateCardVariantCookie,
} from "@/components/search/assets/AssetSearchView"
import { PhoenixAssetSearchSortDropdown } from "@/components/search/assets/AssetSearchView/elements"
import SearchStatusDropdown from "@/components/search/assets/SearchStatusDropdown.react"
import { SearchPills } from "@/components/search/SearchPills.react"
import { ToggleFilterButton } from "@/components/search/ToggleFilterButton.react"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { AssetCardVariantDropdown } from "@/features/account/components/AssetCardVariant/AssetCardVariantDropdown.react"
import { AutoHiddenBanner } from "@/features/account/components/AutoHiddenBanner"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { Search, useSearch } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSearch } from "@/lib/analytics/events/searchEvents"
import { AccountCollectedAssetSearchAccountQuery } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchAccountQuery.graphql"
import { AccountCollectedAssetSearchCollectionQuery } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchCollectionQuery.graphql"
import { RequiredKeys } from "@/lib/helpers/type"
import { ProfileItemSelectionFooter } from "../../components/ProfileItemSelectionFooter"
import { ASSET_SEARCH_ACCOUNT_SORTS } from "../Default.react"
import {
  AccountCollectedSearchItems,
  AccountCollectedAssetSearchList,
} from "./AccountCollectedAssetSearchList.react"

export type AccountCollectedAssetSearchState = RequiredKeys<
  Pick<
    Search,
    | "chains"
    | "collection"
    | "collections"
    | "numericTraits"
    | "paymentAssets"
    | "priceFilter"
    | "query"
    | "resultModel"
    | "sortAscending"
    | "sortBy"
    | "stringTraits"
    | "toggles"
  >
> &
  Required<Pick<Search, "identity">>

type Props = {
  defaultState: AccountCollectedAssetSearchState
  initialState: Partial<AccountCollectedAssetSearchState>
  fixedState: Partial<AccountCollectedAssetSearchState>
  assetCardVariant: AssetCardVariant | undefined
  path: string
}

export const AccountCollectedAssetSearch = ({
  defaultState,
  initialState,
  fixedState,
  assetCardVariant: initialAssetCardVariant,
  path,
}: Props) => {
  const { wallet } = useWallet()
  const t = useTranslate("account")

  const { searchState, clear, update } = useSearch({
    defaultState,
    initialState,
    fixedState,
    path,
  })

  const isOwnerView = wallet.isCurrentIdentity(searchState.identity)
  const defaultStatusItems = useDefaultStatusItems()

  const filterCount = getFilterCount(searchState, {
    includeCollectionFilter: true,
  })
  const [assetCardVariant, setAssetCardVariant] = useState(
    initialAssetCardVariant,
  )
  useUpdateEffect(() =>
    updateCardVariantCookie("account_table_view_supported", assetCardVariant),
  )

  const [accountData] =
    useNoSuspenseLazyLoadQuery<AccountCollectedAssetSearchAccountQuery>(
      graphql`
        query AccountCollectedAssetSearchAccountQuery(
          $identity: IdentityInputType!
        ) {
          account(identity: $identity) {
            address
            showAutoHiddenBanner
          }
        }
      `,
      { identity: searchState.identity },
    )

  const [collectionData] =
    useNoSuspenseLazyLoadQuery<AccountCollectedAssetSearchCollectionQuery>(
      graphql`
        query AccountCollectedAssetSearchCollectionQuery(
          $collections: [CollectionSlug!]
          $collection: CollectionSlug!
        ) {
          ...SearchPills_data @arguments(collections: $collections)
          ...AssetSearchFilter_data @arguments(collection: $collection)
        }
      `,
      {
        collections: searchState.collections,
        collection:
          searchState.collections?.[0] ?? searchState.collection ?? "",
      },
      { skip: !searchState.collections?.length && !searchState.collection },
    )

  const setStringTrait = useCallback(
    (name: string, values?: ReadonlyArray<string>) => {
      const traits = [
        ...(searchState.stringTraits?.filter(t => t.name !== name) ?? []),
        ...(values ? [{ name, values }] : []),
      ]
      return update({
        stringTraits: traits.length ? traits : undefined,
      })
    },
    [searchState.stringTraits, update],
  )

  const onSelectTrait = (trait: SelectedTrait) => {
    if (!trait) {
      return
    }
    mergeTraitFilterState(searchState, update, trait.key, trait.value)
  }

  useEffect(() => {
    trackSearch({
      type: "AssetSearch",
      path: window.location.pathname,
      queryString: window.location.search,
      pageIndex: 0,
      itemDisplayVariant: assetCardVariant ?? "unset",
      ...searchState,
    })
  }, [assetCardVariant, searchState])

  const showAutoHiddenBanner =
    isOwnerView && accountData?.account?.showAutoHiddenBanner

  const isLargeViewport = !useIsLessThanLg()
  const stickyTop = useStickyTop()

  return (
    <AssetSearchLayout
      assets={
        <AccountCollectedAssetSearchList
          accountAddress={accountData?.account?.address ?? ""}
          cardVariant={assetCardVariant}
          clear={clear}
          isOwnerView={isOwnerView}
          searchState={searchState}
          setSort={update}
          stickyTop={stickyTop}
        />
      }
      banner={showAutoHiddenBanner && <AutoHiddenBanner />}
      counts={
        assetCardVariant !== "list-view" ? (
          <AssetSearchRefreshBar
            collection={null}
            onRefresh={() => update(searchState)}
          />
        ) : undefined
      }
      filter={
        <AssetSearchFilter
          clear={clear}
          dataKey={
            searchState.collections?.length === 1 ? collectionData : null
          }
          includeChainFilter
          includeCollectionFilter={false}
          includeProfileCollectionFilter
          setChains={chains => update({ chains })}
          setCollectionSlugs={collections =>
            update({
              collection: collections ? collections[0] : undefined,
              collections: collections ? collections : [],
            })
          }
          setNumericTrait={(name, range) =>
            update({
              numericTraits: [
                ...(searchState.numericTraits?.filter(t => t.name !== name) ||
                  []),
                ...(range ? [{ name, ranges: [range] }] : []),
              ],
            })
          }
          setPaymentAssets={(paymentAssets?: ReadonlyArray<string>) =>
            update({ paymentAssets })
          }
          setPriceFilter={priceFilter =>
            update({ priceFilter: priceFilter || undefined })
          }
          setStringTrait={setStringTrait}
          setToggles={toggles => update({ toggles })}
          showChainFilterOnMobileOnly
          showStatusFilterOnMobileOnly
          state={searchState}
        />
      }
      footer={
        <ProfileItemSelectionFooter<AccountCollectedSearchItems>
          itemVisibilityAction="hide"
          reloadSearch={update}
        />
      }
      header={
        <FilterBar
          assetCardVariantToggle={
            isLargeViewport ? (
              <AssetCardVariantToggle
                currentCardVariant={assetCardVariant}
                setCurrentCardVariant={setAssetCardVariant}
              />
            ) : (
              <AssetCardVariantDropdown
                currentCardVariant={assetCardVariant}
                setCurrentCardVariant={setAssetCardVariant}
              />
            )
          }
          chainDropdown={
            <AssetSearchChainDropdown
              activeChains={searchState.chains || []}
              setChains={chains => update({ chains })}
            />
          }
          filterToggle={<ToggleFilterButton numFiltersApplied={filterCount} />}
          search={
            <AssetSearchTraitAndQueryInput
              collection={
                searchState.collections?.length === 1
                  ? searchState.collections[0]
                  : undefined
              }
              onEnter={query => update({ query: `${query}` })}
              onSelectTrait={onSelectTrait}
            />
          }
          shadowVariant="none"
          sortDropdown={
            <PhoenixAssetSearchSortDropdown
              fullWidth
              searchState={searchState}
              setSort={update}
              sortOptions={ASSET_SEARCH_ACCOUNT_SORTS}
            />
          }
          statusDropdown={
            <SearchStatusDropdown
              filters={searchState.toggles || []}
              possibleFilterItems={defaultStatusItems}
              setFilters={toggles => update({ toggles })}
              title={t("assetSearchFilter.status.title", "Status")}
            />
          }
        />
      }
      pills={
        <SearchPills
          chainFilter={searchState.chains}
          clear={clear}
          collectionFilter={searchState.collections}
          dataKey={collectionData}
          featuredFilter={searchState.toggles}
          numericTraitFilter={searchState.numericTraits}
          paymentFilter={searchState.paymentAssets}
          priceFilter={searchState.priceFilter}
          queryFilter={searchState.query}
          stringTraitFilter={searchState.stringTraits}
          update={update}
        />
      }
    />
  )
}
