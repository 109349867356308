import React from "react"
import { Media } from "@opensea/ui-kit"
import { graphql, useLazyLoadQuery } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Flex } from "@/design-system/Flex"
import {
  DealsSubTabs,
  useCurrentDealsSubTab,
} from "@/features/deals/components/DealsSubTabs.react"
import { IdentityInputType } from "@/lib/graphql/__generated__/DealsSearchListQuery.graphql"
import { DealsTabContentQuery } from "@/lib/graphql/__generated__/DealsTabContentQuery.graphql"
import { DealsSearchList } from "./DealsSearchList.react"

type Props = {
  identity: IdentityInputType
}

export const DealsTabContent = ({ identity }: Props) => {
  const status = useCurrentDealsSubTab()
  return (
    <Flex marginBottom="100px" marginTop="40px">
      <SsrSuspense fallback={null}>
        <Media greaterThanOrEqual="lg">
          <LazyDealsTabContent identity={identity} />
        </Media>
      </SsrSuspense>
      <DealsSearchList identity={identity} status={status} />
    </Flex>
  )
}

const LazyDealsTabContent = ({ identity }: Props) => {
  const data = useLazyLoadQuery<DealsTabContentQuery>(
    graphql`
      query DealsTabContentQuery($identity: IdentityInputType!) {
        account(identity: $identity) {
          __typename
          ...DealsSubTabs_account
        }
      }
    `,
    { identity },
  )
  if (!data.account?.__typename) {
    return null
  }
  return <DealsSubTabs account={data.account} />
}
