import { graphql } from "relay-runtime"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { useProfileCollectedQuery } from "@/lib/graphql/__generated__/useProfileCollectedQuery.graphql"

type Props = {
  identity?: IdentityInputType
}

export const useProfileCollected = ({ identity }: Props) => {
  const [collectedData] = useNoSuspenseLazyLoadQuery<useProfileCollectedQuery>(
    graphql`
      query useProfileCollectedQuery($identity: IdentityInputType!) {
        searchItems(identity: $identity, first: 0) {
          totalCount
        }
      }
    `,
    { identity: identity ?? {} },
    { skip: !identity },
  )
  const collected = collectedData?.searchItems.totalCount
  return collected ?? null
}
