import React from "react"
import { useFragment } from "react-relay"
import { ActionBar } from "@/components/layout/AccountOrCollectionPage/components/ActionBar"
import { SocialBarItem } from "@/components/layout/AccountOrCollectionPage/components/SocialBar"
import { ProfileActionBar_data$key } from "@/lib/graphql/__generated__/ProfileActionBar_data.graphql"
import { graphql } from "@/lib/graphql/graphql"

type Props = {
  data: ProfileActionBar_data$key | null
  socialData: SocialBarItem[]
  isCurrentUser?: boolean
}

export const ProfileActionBar = ({
  data: dataKey,
  socialData,
  isCurrentUser,
}: Props) => {
  const data = useFragment(
    /* eslint-disable relay/must-colocate-fragment-spreads */
    graphql`
      fragment ProfileActionBar_data on AccountType {
        relayId
        address
        names {
          name
          type
        }
        ...accounts_url
      }
    `,
    dataKey,
  )

  if (!data) {
    return null
  }

  const ens = data.names.find(({ type }) => type === "ens")?.name

  return (
    <ActionBar
      address={data.address}
      ens={ens}
      isCurrentUser={isCurrentUser}
      relayId={data.relayId}
      shareData={data}
      socialData={socialData}
      toggleMoreDropdown={() => null}
      variant="profile"
    />
  )
}
