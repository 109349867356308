/**
 * @generated SignedSource<<59c2b88a068d49c3bfa4f09c684c813c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableOptionsCell_item$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetContextMenu_data" | "PortfolioTableAcceptOfferButton_item" | "PortfolioTableListButton_item" | "itemEvents_dataV2">;
  readonly " $fragmentType": "PortfolioTableOptionsCell_item";
};
export type PortfolioTableOptionsCell_item$key = {
  readonly " $data"?: PortfolioTableOptionsCell_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableOptionsCell_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableOptionsCell_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "condition": "showOwnerActions",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AssetContextMenu_data"
            }
          ]
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "PortfolioTableAcceptOfferButton_item"
    },
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "showOwnerActions",
          "variableName": "showOwnerActions"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PortfolioTableListButton_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_dataV2",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "identifier",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "346ad966e82cb0d3dd97a4d360ab501d";

export default node;
