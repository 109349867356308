import React, { Suspense } from "react"
import { Flex, FlexColumn, ToggleButtonGroup } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { Link } from "@/components/common/Link"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { PortfolioTableAcceptOfferButton } from "@/features/account/components/PortfolioTable/components/buttons/PortfolioTableAcceptOfferButton.react"
import { PortfolioTableBuyButton } from "@/features/account/components/PortfolioTable/components/buttons/PortfolioTableBuyButton.react"
import { PortfolioTableListButton } from "@/features/account/components/PortfolioTable/components/buttons/PortfolioTableListButton.react"
import { PortfolioTableMakeOfferButton } from "@/features/account/components/PortfolioTable/components/buttons/PortfolioTableMakeOfferButton.react"
import { PortfolioTableTraitTable } from "@/features/account/components/PortfolioTable/components/trait-table/PortfolioTableTraitTable.react"
import { EXPANDED_ROW_HEIGHT } from "@/features/account/components/PortfolioTable/constants"
import { useTranslate } from "@/hooks/useTranslate"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import { PortfolioTableExpandedRow_item$key } from "@/lib/graphql/__generated__/PortfolioTableExpandedRow_item.graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import { PortfolioTableListingsTable } from "./listings-table/PortfolioTableListingsTable.react"
import { usePortfolioTableListingsTableContext } from "./listings-table/PortfolioTableListingsTableContext.react"

const IMAGE_SIZE_PX = 180

type Props = {
  item: PortfolioTableExpandedRow_item$key
  accountAddress: string
  isOwnerMode: boolean
}

export type TabOption = "traits" | "listings"

export const PortfolioTableExpandedRow = ({
  item: itemKey,
  accountAddress,
  isOwnerMode,
}: Props) => {
  const t = useTranslate("account")
  const item = useFragment(
    graphql`
      fragment PortfolioTableExpandedRow_item on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        showOwnerActions: { type: "Boolean!" }
      ) {
        __typename
        relayId
        ... on AssetType {
          isCompromised
          isCurrentlyFungible
          ...asset_url
          ...AssetMedia_asset
            @arguments(
              identity: $identity
              showQuantity: true
              showRarity: true
            )
          ...PortfolioTableBuyButton_asset
            @arguments(showOwnerActions: $showOwnerActions)
          ...PortfolioTableMakeOfferButton_asset
            @arguments(showOwnerActions: $showOwnerActions)
          ...PortfolioTableTraitTable_asset
        }
        ...PortfolioTableAcceptOfferButton_item @arguments(identity: $identity)
        ...PortfolioTableListButton_item
          @arguments(identity: $identity, showOwnerActions: $showOwnerActions)
        ...PortfolioTableMakeOfferButton_asset
          @arguments(showOwnerActions: $showOwnerActions)
        ...PortfolioTableTraitTable_asset
        ...PortfolioTableListingsTable_asset
      }
    `,
    itemKey,
  )
  const { selectedTabs, setSelectedTabs } =
    usePortfolioTableListingsTableContext()
  const selectedTab = selectedTabs.get(item.relayId) || "traits"
  const setTab = (tab: TabOption) => {
    setSelectedTabs(
      prevSelectedTabs => new Map(prevSelectedTabs.set(item.relayId, tab)),
    )
  }

  const { wallet } = useWallet()
  if (item.__typename !== "AssetType") {
    return null
  }

  const showPortfolioTableMyListings = wallet.isCurrentIdentity({
    address: accountAddress,
  })
  const isFlagged = item.isCompromised

  return (
    <SourceTrackingContextProvider source="PortfolioTableExpandedRow">
      <Flex
        className="inline-flex w-full px-4 py-6"
        style={{ maxHeight: EXPANDED_ROW_HEIGHT }}
      >
        <FlexColumn
          className="mr-7 flex-none"
          style={{ width: `${IMAGE_SIZE_PX}px` }}
        >
          <StyledImageContainer
            as={Link}
            className="relative"
            href={getAssetUrl(item)}
          >
            <StyledAssetMedia
              asset={item}
              rarityIndicator="solid"
              showAssetMediaEditions
              size={IMAGE_SIZE_PX}
              width={IMAGE_SIZE_PX}
            />
          </StyledImageContainer>
          {isFlagged ? null : isOwnerMode ? (
            <>
              <PortfolioTableListButton
                accountAddress={accountAddress}
                className="mb-3"
                item={item}
                size="small"
                source="expanded"
              />
              <PortfolioTableAcceptOfferButton
                item={item}
                size="small"
                source="expanded"
              >
                {t("portfolio.button.acceptBestOffer", "Accept best offer")}
              </PortfolioTableAcceptOfferButton>
            </>
          ) : (
            <>
              <PortfolioTableBuyButton
                asset={item}
                className="mb-3 w-full"
                size="small"
              />
              <PortfolioTableMakeOfferButton
                asset={item}
                size="small"
                source="expanded"
              />
            </>
          )}
        </FlexColumn>
        <Flex className="h-full flex-1 flex-col">
          {showPortfolioTableMyListings && (
            <Block marginBottom="24px">
              <ToggleButtonGroup value={selectedTab} onValueChange={setTab}>
                <ToggleButtonGroup.Button value="traits">
                  {t("portfolio.button.traits", "Traits")}
                </ToggleButtonGroup.Button>
                <ToggleButtonGroup.Button value="listings">
                  {t("portfolio.button.myListings", "My Listings")}
                </ToggleButtonGroup.Button>
              </ToggleButtonGroup>
            </Block>
          )}
          {selectedTab === "traits" && (
            <Suspense fallback={<PortfolioTableTraitTable.Skeleton />}>
              <PortfolioTableTraitTable asset={item} />
            </Suspense>
          )}
          {selectedTab === "listings" && (
            <Suspense
              fallback={
                <PortfolioTableListingsTable.Skeleton
                  isFungible={item.isCurrentlyFungible}
                />
              }
            >
              <PortfolioTableListingsTable
                accountAddress={accountAddress}
                asset={item}
                hasArrowPagination
                isOwnerMode={isOwnerMode}
              />
            </Suspense>
          )}
        </Flex>
      </Flex>
    </SourceTrackingContextProvider>
  )
}

const StyledImageContainer = styled(Block)`
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-radius: ${props => props.theme.borderRadius.default};
  height: ${IMAGE_SIZE_PX}px;
  margin-bottom: 16px;
  overflow: hidden;
  width: ${IMAGE_SIZE_PX}px;
`

const StyledAssetMedia = styled(AssetMedia)`
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.12);
    transition: transform 0.4s;
  }
`
