/**
 * @generated SignedSource<<a530b74be565eb46cd22abdc6f05d56a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountPrivateAssetSearchListPagination_data$data = {
  readonly queriedAt: string;
  readonly searchItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"AssetSearchList_data" | "useAssetSelectionStorage_item">;
      };
    } | null>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "AccountPrivateAssetSearchListPagination_data";
};
export type AccountPrivateAssetSearchListPagination_data$key = {
  readonly " $data"?: AccountPrivateAssetSearchListPagination_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountPrivateAssetSearchListPagination_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identity"
},
v1 = [
  "searchItems"
],
v2 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifier",
  "storageKey": null
},
v6 = [
  (v4/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  }
],
v8 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountType",
    "kind": "LinkedField",
    "name": "maker",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderV2Type",
  "kind": "LinkedField",
  "name": "bestAskV2",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "kind": "InlineDataFragmentSpread",
  "name": "asset_url",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetContractType",
      "kind": "LinkedField",
      "name": "assetContract",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenId",
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chains"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collections"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    (v0/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAutoHidden"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v1/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v1/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AccountPrivateAssetSearchListPaginationQuery.graphql')
    }
  },
  "name": "AccountPrivateAssetSearchListPagination_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "queriedAt",
      "storageKey": null
    },
    {
      "alias": "searchItems",
      "args": [
        {
          "kind": "Variable",
          "name": "chains",
          "variableName": "chains"
        },
        {
          "kind": "Variable",
          "name": "collections",
          "variableName": "collections"
        },
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "isAutoHidden",
          "variableName": "isAutoHidden"
        },
        {
          "kind": "Literal",
          "name": "isPrivate",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "querystring",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "sortAscending",
          "variableName": "sortAscending"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        }
      ],
      "concreteType": "ItemTypeConnection",
      "kind": "LinkedField",
      "name": "__AccountPrivateAssetSearchListPagination_searchItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": [
                    (v2/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "showBestAskForOwner",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "showContextMenu",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "showQuantity",
                      "value": true
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "AssetSearchList_data"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useAssetSelectionStorage_item",
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ChainType",
                      "kind": "LinkedField",
                      "name": "chain",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isTradingEnabled",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isErc721cFeeEnforced",
                          "storageKey": null
                        },
                        {
                          "condition": "withBestBid",
                          "kind": "Condition",
                          "passingValue": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "OrderV2Type",
                              "kind": "LinkedField",
                              "name": "bestAllTypeBid",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ]
                        },
                        {
                          "condition": "withBestAsk",
                          "kind": "Condition",
                          "passingValue": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ESOrderDataType",
                              "kind": "LinkedField",
                              "name": "orderData",
                              "plural": false,
                              "selections": [
                                (v9/*: any*/),
                                {
                                  "alias": "bestAskForOwner",
                                  "args": [
                                    {
                                      "kind": "Variable",
                                      "name": "byAddress",
                                      "variableName": "identity"
                                    }
                                  ],
                                  "concreteType": "OrderV2Type",
                                  "kind": "LinkedField",
                                  "name": "bestAskV2",
                                  "plural": false,
                                  "selections": (v8/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ]
                        },
                        (v11/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isCompromised",
                          "storageKey": null
                        }
                      ],
                      "type": "AssetType",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ESOrderDataType",
                          "kind": "LinkedField",
                          "name": "orderData",
                          "plural": false,
                          "selections": [
                            {
                              "condition": "withBestAsk",
                              "kind": "Condition",
                              "passingValue": true,
                              "selections": [
                                (v9/*: any*/)
                              ]
                            },
                            {
                              "condition": "withBestBid",
                              "kind": "Condition",
                              "passingValue": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "OrderV2Type",
                                  "kind": "LinkedField",
                                  "name": "bestBidV2",
                                  "plural": false,
                                  "selections": (v6/*: any*/),
                                  "storageKey": null
                                }
                              ]
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "AssetBundleType",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "item_sellUrl",
                      "selections": [
                        (v3/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v11/*: any*/)
                          ],
                          "type": "AssetType",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "slug",
                              "storageKey": null
                            },
                            (v10/*: any*/),
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "first",
                                  "value": 18
                                }
                              ],
                              "concreteType": "AssetQuantityTypeConnection",
                              "kind": "LinkedField",
                              "name": "assetQuantities",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AssetQuantityTypeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "AssetQuantityType",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "AssetType",
                                          "kind": "LinkedField",
                                          "name": "asset",
                                          "plural": false,
                                          "selections": (v6/*: any*/),
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "assetQuantities(first:18)"
                            }
                          ],
                          "type": "AssetBundleType",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AssetContextMenu_data"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CancelItemOrdersButton_items"
                    }
                  ],
                  "args": [
                    (v2/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "withBestAsk",
                      "value": true
                    }
                  ],
                  "argumentDefinitions": [
                    (v0/*: any*/),
                    {
                      "defaultValue": false,
                      "kind": "LocalArgument",
                      "name": "withBestAsk"
                    },
                    {
                      "defaultValue": false,
                      "kind": "LocalArgument",
                      "name": "withBestBid"
                    }
                  ]
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cdfca30df7ace3b7c070620d0962ef39";

export default node;
