import React, { useCallback } from "react"
import { useFragment, useRelayEnvironment } from "react-relay"
import { commitLocalUpdate, graphql } from "relay-runtime"
import { AcceptOfferButton } from "@/components/trade/AcceptOfferButton"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { ButtonProps } from "@/design-system/Button"
import { PortfolioTableButtonSource } from "@/features/account/components/PortfolioTable/analytics"
import { decrementOwnedQuantity } from "@/features/live-updates/utils"
import { PortfolioTableAcceptOfferButton_item$key } from "@/lib/graphql/__generated__/PortfolioTableAcceptOfferButton_item.graphql"

type Props = {
  isIcon?: boolean
  item: PortfolioTableAcceptOfferButton_item$key
  source: PortfolioTableButtonSource
  children?: React.ReactNode
} & Omit<ButtonProps, "href" | "onClick" | "order">

export const PortfolioTableAcceptOfferButton = ({
  isIcon,
  item: itemKey,
  children,
  source,
  ...buttonProps
}: Props) => {
  const connectedAddress = useConnectedAddress()
  const item = useFragment(
    graphql`
      fragment PortfolioTableAcceptOfferButton_item on ItemType
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        __typename
        relayId
        ... on AssetType {
          bestAllTypeBid {
            ...AcceptOfferButton_order @arguments(identity: $identity)
          }
          ...AcceptOfferButton_asset @arguments(identity: $identity)
        }
        ... on AssetBundleType {
          orderData {
            bestBidV2 {
              ...AcceptOfferButton_order @arguments(identity: $identity)
            }
          }
        }
      }
    `,
    itemKey,
  )

  const isAsset = item.__typename === "AssetType"
  const isBundle = item.__typename === "AssetBundleType"
  const bestOffer = isAsset
    ? item.bestAllTypeBid
    : isBundle
    ? item.orderData?.bestBidV2
    : null

  const environment = useRelayEnvironment()
  const onComplete = useCallback(() => {
    commitLocalUpdate(environment, store =>
      decrementOwnedQuantity(store, item.relayId, connectedAddress),
    )
  }, [item.relayId, connectedAddress, environment])

  if (!bestOffer) {
    return null
  }

  return (
    <AcceptOfferButton
      criteriaAsset={isAsset ? item : undefined}
      isIcon={isIcon}
      order={bestOffer}
      source={
        source === "collapsed"
          ? "portfolio table collapsed row"
          : "portfolio table expanded row"
      }
      {...buttonProps}
      onComplete={onComplete}
    >
      {children}
    </AcceptOfferButton>
  )
}
