import React from "react"
import { Text } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { useChart } from "@/design-system/charts/ChartPanel"
import { useTranslate } from "@/hooks/useTranslate"

export const ActivityResetZoomButton = () => {
  const t = useTranslate("collection")
  const { isZoomed, resetZoom } = useChart()

  // if not zoomed then don't show the reset button
  if (!isZoomed) {
    return <></>
  }

  return (
    <Button className="w-full" variant="secondary" onClick={resetZoom}>
      <Text.Body size="small" weight="semibold">
        {t("activity.priceHistory.resetZoom", "Reset zoom")}
      </Text.Body>
    </Button>
  )
}
