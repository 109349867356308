/**
 * @generated SignedSource<<8535c2be74893e3e3cffcf959ccaad9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileOffersCollectionFilterItem_collection$data = {
  readonly collectionAssetOwner: {
    readonly numOffers: string;
    readonly offersMadeValue: {
      readonly symbol: string;
      readonly unit: string;
    };
  } | null;
  readonly imageUrl: string | null;
  readonly isVerified: boolean;
  readonly name: string;
  readonly slug: string;
  readonly statsV2: {
    readonly floorPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  };
  readonly " $fragmentType": "ProfileOffersCollectionFilterItem_collection";
};
export type ProfileOffersCollectionFilterItem_collection$key = {
  readonly " $data"?: ProfileOffersCollectionFilterItem_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileOffersCollectionFilterItem_collection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "owner"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileOffersCollectionFilterItem_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "owner"
        }
      ],
      "concreteType": "CollectionAssetOwnerType",
      "kind": "LinkedField",
      "name": "collectionAssetOwner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numOffers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "offersMadeValue",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "floorPrice",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "039bf533a439f71aababb68069f56378";

export default node;
