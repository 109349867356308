import React, { MouseEvent, useCallback, useMemo, useState } from "react"
import { UnstyledButton, Checkbox, Flex } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Skeleton } from "@opensea/ui-kit"
import { VirtualizedTable } from "@/design-system/VirtualizedTable"
import { PortfolioTableBestOfferCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableBestOfferCell.react"
import { PortfolioTableCostCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableCostCell.react"
import { PortfolioTableDifferenceCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableDifferenceCell.react"
import { PortfolioTableFloorPriceCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableFloorPriceCell.react"
import { PortfolioTableItemCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableItemCell.react"
import { PortfolioTableListingCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableListingCell.react"
import { PortfolioTableOptionsCell } from "@/features/account/components/PortfolioTable/components/cells/PortfolioTableOptionsCell.react"
import { usePortfolioTableContext } from "@/features/account/components/PortfolioTable/components/PortfolioTableContext.react"
import { PortfolioTableExpandedRow } from "@/features/account/components/PortfolioTable/components/PortfolioTableExpandedRow.react"
import {
  CHECKBOX_PADDING,
  COLLAPSED_ROW_HEIGHT,
  COLUMN_CELL_PROPS,
} from "@/features/account/components/PortfolioTable/constants"
import { PortfolioViewMode } from "@/features/account/components/PortfolioTable/types"
import { useIsAssetSelected } from "@/features/account/hooks/useAssetSelection"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import { PortfolioTableRow_item$key } from "@/lib/graphql/__generated__/PortfolioTableRow_item.graphql"

export type PortfolioTableRowProps = {
  item: PortfolioTableRow_item$key | null
  accountAddress: string
  mode: PortfolioViewMode
  expanded: boolean
  showChain?: boolean
  onClick?: (assetRelayId: string) => unknown
  onSelect?: (assetRelayId: string, selected: boolean) => unknown
}

const PortfolioTableRowBase = React.memo(function PortfolioTableRowBase({
  item: itemKey,
  accountAddress,
  expanded = false,
  mode,
  showChain = false,
  onClick,
  onSelect,
}: PortfolioTableRowProps) {
  const item = useFragment(
    graphql`
      fragment PortfolioTableRow_item on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        showOwnerActions: { type: "Boolean!" }
      ) {
        __typename
        relayId

        ...PortfolioTableItemCell_item @arguments(identity: $identity)
        ...PortfolioTableFloorPriceCell_item
        ...PortfolioTableBestOfferCell_item
        ...PortfolioTableListingCell_item @arguments(identity: $identity)
        ...PortfolioTableCostCell_item
        ...PortfolioTableDifferenceCell_item @arguments(identity: $identity)
        ...PortfolioTableOptionsCell_item
          @arguments(identity: $identity, showOwnerActions: $showOwnerActions)
        ...PortfolioTableExpandedRow_item
          @arguments(identity: $identity, showOwnerActions: $showOwnerActions)
      }
    `,
    itemKey,
  )

  const [isMouseHoveringRow, setIsMouseHoveringRow] = useState(false)

  const { isDesktop, mobileColumn } = usePortfolioTableContext()

  const selected = useIsAssetSelected(item?.relayId)

  const isOwnerMode = mode === "owner"
  const handleClick = useCallback(() => {
    if (!item || !onClick) {
      return
    }
    onClick(item.relayId)
  }, [item, onClick])

  const handleSelect = useCallback(
    (e: MouseEvent | undefined) => {
      if (!item) {
        return
      }
      e?.stopPropagation()
      onSelect?.(item.relayId, !selected)
    },
    [item, onSelect, selected],
  )

  const itemCell = useMemo(
    () =>
      item ? (
        <VirtualizedTable.Row.Cell
          overflow="hidden"
          paddingRight="16px"
          {...COLUMN_CELL_PROPS["item"]}
        >
          {isOwnerMode && (
            <CheckboxTapTarget onClick={handleSelect}>
              <Checkbox checked={selected} size="small" />
            </CheckboxTapTarget>
          )}
          <PortfolioTableItemCell item={item} showChain={showChain} />
        </VirtualizedTable.Row.Cell>
      ) : (
        <></>
      ),
    [handleSelect, isOwnerMode, item, selected, showChain],
  )

  const {
    floorPriceCell,
    bestOfferCell,
    listingPriceCell,
    costCell,
    differenceCell,
  } = useMemo(() => {
    if (!item) {
      return {}
    }
    const floorPriceCell = (
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["floorPrice"]}>
        <PortfolioTableFloorPriceCell item={item} />
      </VirtualizedTable.Row.Cell>
    )
    const bestOfferCell = (
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["bestOffer"]}>
        <PortfolioTableBestOfferCell item={item} />
      </VirtualizedTable.Row.Cell>
    )
    const listingPriceCell = (
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["listingPrice"]}>
        <PortfolioTableListingCell item={item} />
      </VirtualizedTable.Row.Cell>
    )
    const costCell = (
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["cost"]}>
        <PortfolioTableCostCell item={item} />
      </VirtualizedTable.Row.Cell>
    )
    const differenceCell = (
      <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["difference"]}>
        <PortfolioTableDifferenceCell item={item} />
      </VirtualizedTable.Row.Cell>
    )
    return {
      floorPriceCell,
      bestOfferCell,
      listingPriceCell,
      costCell,
      differenceCell,
    }
  }, [item])

  if (!item) {
    return null
  }

  const { __typename } = item

  const isAsset = __typename === "AssetType"

  const selectedMobileCell = (() => {
    switch (mobileColumn) {
      case "floorPrice":
        return floorPriceCell
      case "bestOffer":
        return bestOfferCell
      case "listingPrice":
        return listingPriceCell
      case "cost":
        return costCell
      case "difference":
        return differenceCell
    }
    return null
  })()

  const supportsExpandedRow = isAsset && isDesktop

  return (
    <VirtualizedTable.Row
      alignItems="center"
      className="w-full"
      gap={16}
      minHeight={COLLAPSED_ROW_HEIGHT}
      more={
        supportsExpandedRow &&
        expanded && (
          <PortfolioTableExpandedRow
            accountAddress={accountAddress}
            isOwnerMode={isOwnerMode}
            item={item}
          />
        )
      }
      onClick={supportsExpandedRow ? handleClick : undefined}
      onMouseEnter={e => {
        e.stopPropagation()
        setIsMouseHoveringRow(true)
      }}
      onMouseLeave={e => {
        e.stopPropagation()
        setIsMouseHoveringRow(false)
      }}
    >
      <SourceTrackingContextProvider source="PortfolioTableRow">
        {itemCell}
        {isDesktop ? (
          <>
            {floorPriceCell}
            {bestOfferCell}
            {listingPriceCell}
            {costCell}
            {differenceCell}

            {isOwnerMode && (
              <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["options"]}>
                <PortfolioTableOptionsCell
                  accountAddress={accountAddress}
                  isMouseHoveringRow={isMouseHoveringRow}
                  item={item}
                  onSelect={() => handleSelect(undefined)}
                />
              </VirtualizedTable.Row.Cell>
            )}
          </>
        ) : (
          selectedMobileCell
        )}
      </SourceTrackingContextProvider>
    </VirtualizedTable.Row>
  )
})

const PortfolioTableRowSkeleton = React.memo(
  function PortfolioTableRowSkeleton({
    mode,
  }: Pick<PortfolioTableRowProps, "mode">) {
    const { isDesktop } = usePortfolioTableContext()
    const isOwnerMode = mode === "owner"

    return (
      <VirtualizedTable.Row
        alignItems="center"
        className="w-full"
        gap={16}
        height={COLLAPSED_ROW_HEIGHT}
        minHeight={COLLAPSED_ROW_HEIGHT}
      >
        <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["item"]}>
          {isOwnerMode && (
            <Flex
              className="items-center"
              style={{ paddingRight: CHECKBOX_PADDING }}
            >
              <Checkbox disabled />
            </Flex>
          )}
          <PortfolioTableItemCell.Skeleton />
        </VirtualizedTable.Row.Cell>
        <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["floorPrice"]}>
          <Skeleton.Line />
        </VirtualizedTable.Row.Cell>
        {isDesktop && (
          <>
            <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["bestOffer"]}>
              <Skeleton.Line />
            </VirtualizedTable.Row.Cell>
            <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["listingPrice"]}>
              <Skeleton.Line />
            </VirtualizedTable.Row.Cell>
            <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["cost"]}>
              <Skeleton.Line />
            </VirtualizedTable.Row.Cell>
            <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["difference"]}>
              <Skeleton.Line />
            </VirtualizedTable.Row.Cell>
            {isOwnerMode && (
              <VirtualizedTable.Row.Cell {...COLUMN_CELL_PROPS["options"]}>
                <PortfolioTableOptionsCell.Skeleton />
              </VirtualizedTable.Row.Cell>
            )}
          </>
        )}
      </VirtualizedTable.Row>
    )
  },
)

export const PortfolioTableRow = Object.assign(PortfolioTableRowBase, {
  Skeleton: PortfolioTableRowSkeleton,
})

const CheckboxTapTarget = styled(UnstyledButton)`
  align-items: center;
  display: flex;
  height: 100%;
  padding-right: ${CHECKBOX_PADDING}px;
`
