import { useIsLessThanLg } from "@opensea/ui-kit"
import { FILTER_BAR_HEIGHT } from "@/components/layout/AccountOrCollectionPage/constants"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { NAV_HEIGHT_PX } from "@/styles/variables"

export function useStickyTop() {
  // TODO(MRKT-3042): Figure out better solution than hardcoding this here.
  const { contentTopOffset } = useAccountOrCollectionPageContext()
  const isLargeViewport = !useIsLessThanLg()
  const stickyTop =
    (isLargeViewport ? contentTopOffset : NAV_HEIGHT_PX + FILTER_BAR_HEIGHT) - 2

  return stickyTop
}
