import { graphql, useFragment } from "react-relay"
import { DealStatus } from "@/features/deals/types"
import { useDealStatus_swap$key } from "@/lib/graphql/__generated__/useDealStatus_swap.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"

export const useDealStatus = (
  swapKey: useDealStatus_swap$key | undefined,
): DealStatus => {
  const deal = useFragment(
    graphql`
      fragment useDealStatus_swap on SwapType {
        closedAt
        isCancelled
        isFilled
        isInactive
      }
    `,
    swapKey ?? null,
  )
  if (!deal) {
    return "creating"
  }

  const now = new Date()
  // Missing closedAt should never really happen.
  const closedAtDate = deal.closedAt ? dateFromISO8601(deal.closedAt) : now
  const isExpired = closedAtDate <= now

  return deal.isInactive
    ? "inactive"
    : deal.isFilled
    ? "accepted"
    : deal.isCancelled
    ? "cancelled"
    : isExpired
    ? "expired"
    : "active"
}
