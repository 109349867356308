/**
 * @generated SignedSource<<d02125fdefe844e086468ecf479e22cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountOrCollectionPageHeader_account$data = {
  readonly config: Config | null;
  readonly displayName: string | null;
  readonly isCompromised: boolean;
  readonly " $fragmentType": "AccountOrCollectionPageHeader_account";
};
export type AccountOrCollectionPageHeader_account$key = {
  readonly " $data"?: AccountOrCollectionPageHeader_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOrCollectionPageHeader_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOrCollectionPageHeader_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCompromised",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "ed87567f80ee3ae546b3705d6eae75ec";

export default node;
