import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { PriceText } from "@/design-system/PriceText"
import { PortfolioTableCostCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableCostCell_item.graphql"

type Props = {
  item: PortfolioTableCostCell_item$key
}

export const PortfolioTableCostCell = ({ item: itemKey }: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableCostCell_item on ItemType {
        __typename
        lastCostEvent {
          transaction {
            blockExplorerLink
          }
        }
        pnl {
          costPrice {
            unit
            symbol
          }
        }
      }
    `,
    itemKey,
  )
  const { symbol, unit } = item.pnl?.costPrice ?? {}

  const { blockExplorerLink } = item.lastCostEvent?.transaction ?? {}

  if (blockExplorerLink) {
    return (
      <Link
        href={blockExplorerLink}
        variant="subtle"
        onClick={e => e.stopPropagation()}
      >
        <SubtleHoverablePriceText symbol={symbol} unit={unit} />
      </Link>
    )
  }
  return <PriceText subtleEmpty symbol={symbol} unit={unit} />
}

const SubtleHoverablePriceText = styled(PriceText)`
  &:hover {
    color: inherit;
  }
`
