import React from "react"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { getAccountLocaleUrl } from "@/features/account/utils/path"
import { CollectedAccountPageQuery } from "@/lib/graphql/__generated__/CollectedAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../../components/ProfileContainer"
import { cachePolicy, getInitialPropsAccountPageQuery } from "../utils"
import {
  AccountCollectedAssetSearch,
  AccountCollectedAssetSearchState,
} from "./AccountCollectedAssetSearch.react"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  assetCardVariant?: AssetCardVariant
  searchState: AccountCollectedAssetSearchState
}

export const CollectedPage: GraphQLNextPage<
  CollectedAccountPageQuery,
  Props
> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  assetCardVariant,
  searchState,
}) => {
  const identity = searchState.identity
  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="collected"
      tabContent={
        <AccountCollectedAssetSearch
          assetCardVariant={assetCardVariant}
          defaultState={{
            chains: undefined,
            collection: undefined,
            collections: undefined,
            numericTraits: undefined,
            paymentAssets: undefined,
            priceFilter: undefined,
            resultModel: isCurrentUser ? undefined : "ASSETS",
            sortAscending: searchState.sortAscending,
            sortBy: searchState.sortBy,
            stringTraits: undefined,
            toggles: undefined,
            query: undefined,
            identity,
          }}
          fixedState={{ identity }}
          initialState={searchState}
          path={path}
        />
      }
    />
  )
}

const query = graphql`
  query CollectedAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    collectionSlug: collection(collection: $collection) {
      __typename
    }
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

CollectedPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
    search: QP.Optional(QP.Search),
  },
  async (
    { identifier, search },
    context,
  ): Promise<GraphQLInitialProps<CollectedAccountPageQuery, Props>> => {
    const {
      isCurrentUser,
      isYourProfile,
      variables,
      path,
      assetCardVariant,
      sortBy,
      sortAscending,
    } = await getInitialPropsAccountPageQuery({
      context,
      identifier,
      tableViewSupported: true,
      search,
      tab: "collected",
    })

    const {
      includePrivateAssetCount,
      identity,
      collection,
      collections,
      chains,
    } = variables

    const searchState: AccountCollectedAssetSearchState = {
      chains,
      collection,
      collections,
      identity,
      numericTraits: search?.numericTraits,
      paymentAssets: search?.paymentAssets,
      priceFilter: search?.priceFilter,
      query: search?.query,
      resultModel:
        search?.resultModel ?? (isCurrentUser ? undefined : "ASSETS"),
      sortAscending,
      sortBy,
      stringTraits: search?.stringTraits,
      toggles: search?.toggles,
    }

    const pageVariables = {
      identity,
      includePrivateAssetCount,
      collection,
    }

    const accountUrl = getAccountLocaleUrl(identifier, context.locale)

    const appInitialProps = await getAppInitialProps<CollectedAccountPageQuery>(
      context,
      {
        variables: pageVariables,
        query,
        cachePolicy,
        redirectUrl: data => {
          const notFound = !!(
            data !== null &&
            collection &&
            data.collectionSlug === null
          )
          return notFound ? accountUrl + "/collected" : null
        },
      },
    )

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      assetCardVariant,
      variables: pageVariables,
      searchState,
    }
  },
)
