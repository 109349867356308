import React, { useCallback, useMemo } from "react"
import { Media, useIsLessThanSm } from "@opensea/ui-kit"
import { IS_SERVER } from "@/constants/environment"
import { Button } from "@/design-system/Button"
import { DropdownProps } from "@/design-system/Dropdown"
import { Select, SelectOption } from "@/design-system/Select"
import { MobileDropdown } from "@/features/account/components/MobileDropdown"
import { useIsOpen } from "@/hooks/useIsOpen"
import { Search } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { OrderSortOption } from "@/lib/graphql/__generated__/AccountOffersOrderSearchListPaginationQuery.graphql"
import { OfferSortOption } from "./types"

type Sort = {
  sortAscending: boolean
  orderSortBy: OrderSortOption
}

const DEFAULT_SORTS: Sort[] = [
  { sortAscending: true, orderSortBy: "PRICE" },
  { sortAscending: false, orderSortBy: "PRICE" },
  { sortAscending: true, orderSortBy: "REMAINING_QUANTITY" },
  { sortAscending: false, orderSortBy: "REMAINING_QUANTITY" },
  { sortAscending: true, orderSortBy: "TOTAL_PRICE" },
  { sortAscending: false, orderSortBy: "TOTAL_PRICE" },
  { sortAscending: true, orderSortBy: "CLOSED_AT" },
  { sortAscending: false, orderSortBy: "CLOSED_AT" },
  { sortAscending: true, orderSortBy: "OPENED_AT" },
  { sortAscending: false, orderSortBy: "OPENED_AT" },
]

type Props = {
  showDesktop: boolean
  setSort: (sort: Sort) => unknown
  searchState?: Pick<Search, "sortAscending" | "orderSortBy">
  style?: React.CSSProperties
  sortOptions?: Sort[]
}

type SortBySelectOption = SelectOption<OrderSortOption> & {
  sortAscending?: boolean
}

const OrderSearchSortDropdown = ({
  showDesktop = true,
  setSort,
  searchState,
  style,
  sortOptions = DEFAULT_SORTS,
}: Props) => {
  const t = useTranslate("components")
  const optionsSupported: SortBySelectOption[] = useMemo(() => {
    const sortLabels: Record<OfferSortOption, [string, string]> = {
      OPENED_AT: [
        t("orderSearchSortDropdown.dateCreated.asc", "Oldest"),
        t("orderSearchSortDropdown.dateCreated.desc", "Newest"),
      ],
      CLOSED_AT: [
        t("orderSearchSortDropdown.expiration.asc", "Expiring soon"),
        t("orderSearchSortDropdown.expiration.desc", "Expiring last"),
      ],
      REMAINING_QUANTITY: [
        t("orderSearchSortDropdown.quantity.asc", "Lowest quantity"),
        t("orderSearchSortDropdown.quantity.desc", "Highest quantity"),
      ],
      TOTAL_PRICE: [
        t("orderSearchSortDropdown.total.asc", "Lowest total"),
        t("orderSearchSortDropdown.total.desc", "Highest total"),
      ],
      PRICE: [
        t("orderSearchSortDropdown.price.asc", "Lowest price"),
        t("orderSearchSortDropdown.price.desc", "Highest price"),
      ],
    }
    return Object.entries(sortLabels).flatMap(([orderSortBy, labels]) =>
      labels.map(
        (label, index): SortBySelectOption => ({
          label,
          key: label,
          value: orderSortBy as OrderSortOption,
          sortAscending: index === 0,
        }),
      ),
    )
  }, [t])

  const optionsShown: SortBySelectOption[] = useMemo(() => {
    const mapToSortOption = (sort: Sort): SortBySelectOption | undefined => {
      const { orderSortBy, sortAscending } = sort
      return optionsSupported.find(
        option =>
          option.value === orderSortBy &&
          option.sortAscending === sortAscending,
      )
    }

    return sortOptions
      .map(mapToSortOption)
      .filter((option): option is SortBySelectOption => option !== undefined)
  }, [optionsSupported, sortOptions])

  const isMobile = useIsLessThanSm()

  const setSortWithOption = useCallback(
    (option: SortBySelectOption) => {
      setSort({
        orderSortBy: option.value,
        sortAscending: option.sortAscending ?? false,
      })
    },
    [setSort],
  )

  let appendTo: DropdownProps<SortBySelectOption>["appendTo"]

  if (!IS_SERVER) {
    appendTo = "parent"
  }

  const select = (
    <Select
      clearable={false}
      matcher={(option, value) =>
        option.value === value &&
        option.sortAscending === Boolean(searchState?.sortAscending)
      }
      maxHeight={isMobile ? "337px" : "initial"}
      options={optionsShown}
      optionsSupported={optionsSupported}
      overrides={{
        Dropdown: {
          props: {
            appendTo,
            popperOptions: {
              modifiers: [{ name: "flip", enabled: false }],
            },
          },
        },
      }}
      placeholder={t("orderSearchSortDropdown.sort", "Sort")}
      readOnly
      style={style}
      value={searchState?.orderSortBy}
      onSelect={option => {
        if (option) {
          setSortWithOption(option)
        }
      }}
    />
  )

  const { isOpen, open, close } = useIsOpen()
  return (
    <>
      {showDesktop && <Media greaterThanOrEqual="lg">{select}</Media>}
      <Media lessThan="lg">
        <Button
          aria-label={t("orderSearchSortDropdown.sort", "Sort")}
          className="h-full"
          data-testid="order-search-sort-dropdown-trigger"
          icon="swap_vert"
          variant="secondary"
          onClick={open}
        />
        <MobileDropdown
          isOpen={isOpen}
          title={t("orderSearchSortDropdown.sort", "Sort")}
          onClose={close}
        >
          <MobileDropdown.Select
            isSelected={option =>
              searchState?.sortAscending === option.sortAscending &&
              searchState?.orderSortBy === option.value
            }
            keyName={option => `${option.value}-${option.sortAscending}`}
            options={optionsShown}
            onSelect={option => {
              setSortWithOption(option)
              close()
            }}
          />
        </MobileDropdown>
      </Media>
    </>
  )
}

export default OrderSearchSortDropdown
