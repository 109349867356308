import { SwapState } from "@/lib/graphql/__generated__/DealsSearchListQuery.graphql"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { DealSearchStatus } from "./types"

export const getDealStateFromStatus = (
  status: DealSearchStatus,
): SwapState | null => {
  switch (status) {
    case "all":
      return null
    case "active":
      return "ACTIVE"
    case "accepted":
      return "ACCEPTED"
    case "canceled":
      return "CANCELLED"
    case "inactive":
      return "INACTIVE"
    case "expired":
      return "EXPIRED"
    default:
      throw new UnreachableCaseError(status)
  }
}
