import React from "react"
import { Flex, Media, Text, Skeleton } from "@opensea/ui-kit"
import { format } from "date-fns"
import { graphql, useFragment } from "react-relay"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { AddressPill } from "@/components/common/AddressPill"
import { MONTH_YEAR_FORMAT_STRING } from "@/constants/datetime"
import { useTranslate } from "@/hooks/useTranslate"
import { Subheader_account$key } from "@/lib/graphql/__generated__/Subheader_account.graphql"
import { Subheader_collection$key } from "@/lib/graphql/__generated__/Subheader_collection.graphql"
import { isSafelisted } from "@/lib/helpers/verification"

type Props = {
  accountDataKey: Subheader_account$key | null
  collectionDataKey: Subheader_collection$key | null
  isLoading: boolean
}

export function Subheader({
  accountDataKey,
  collectionDataKey,
  isLoading,
}: Props) {
  const t = useTranslate("phoenix")
  const accountData = useFragment(
    graphql`
      fragment Subheader_account on AccountType {
        user {
          dateJoined
        }
        ...AddressPill_data
      }
    `,
    accountDataKey,
  )

  const collectionData = useFragment(
    graphql`
      fragment Subheader_collection on CollectionType {
        owner {
          ...AccountLink_data
        }
        verificationStatus
      }
    `,
    collectionDataKey,
  )

  if (isLoading) {
    return (
      <Skeleton.Row>
        <Flex className="my-4">
          <Media greaterThanOrEqual="sm">
            <Skeleton.Line className="h-8 w-[500px]" />
          </Media>
          <Media lessThan="sm">
            <Skeleton.Line className="h-8 w-[200px]" />
          </Media>
        </Flex>
      </Skeleton.Row>
    )
  }

  const showAccountLink =
    collectionData?.owner && isSafelisted(collectionData.verificationStatus)

  const joinedText = accountData?.user?.dateJoined
    ? `${t("profile.joined", "Joined")} ${format(
        new Date(accountData.user.dateJoined),
        MONTH_YEAR_FORMAT_STRING,
      )}`
    : ""

  return (
    <Flex className="flex-col">
      <Flex className="items-center">
        {showAccountLink && (
          <Flex className="mr-4 mt-2">
            <AccountLink
              dataKey={collectionData.owner}
              iconSize={32}
              tab="created"
              variant="no-image"
            />
          </Flex>
        )}
        {/** NOTE(@auster-eth): Address pill could render null depending on data, leave spacing to addressPill component */}
        <Media greaterThanOrEqual="sm">
          <Flex className="items-center">
            {accountData && (
              <Flex className="mr-1.5">
                <AddressPill data={accountData} />
              </Flex>
            )}
            <Text asChild color="secondary">
              <div>{joinedText}</div>
            </Text>
          </Flex>
        </Media>
      </Flex>
      <Media lessThan="sm">
        <Text asChild color="secondary" size="small">
          <div>{joinedText}</div>
        </Text>
      </Media>
    </Flex>
  )
}
