import React, { ComponentProps } from "react"
import { MaterialIcon } from "@opensea/ui-kit"
import { MobileDropdown } from "@/features/account/components/MobileDropdown"
import {
  useAssetContextActions,
  useAssetContextSelectedItems,
} from "@/features/account/hooks/useAssetSelection"
import { AcceptOffersButton } from "./AcceptOffersButton.react"
import { CancelListingsButton } from "./CancelListingsButton.react"
import { ItemVisibilityButton } from "./ItemVisibilityButton.react"
import { TransferButton } from "./TransferButton.react"

type TriggerArgs = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon: MaterialIcon
  content: string | JSX.Element
  disabled?: boolean
}

export type TriggerProps = {
  trigger?: ({ onClick, icon, content, disabled }: TriggerArgs) => JSX.Element
}

type Props = {
  isOpen: boolean
  onClose: () => void
  title: string
  itemVisibilityAction: ComponentProps<typeof ItemVisibilityButton>["action"]
  reloadSearch: () => unknown
}
export const AssetsContextMenu = ({
  itemVisibilityAction,
  isOpen,
  onClose,
  title,
  reloadSearch,
}: Props) => {
  const selectedItems = useAssetContextSelectedItems()
  const { onDeleteAll } = useAssetContextActions()

  const isAcceptOffersButtonShown = Boolean(
    selectedItems.filter(
      item =>
        item.bestAllTypeBid?.relayId || item.orderData?.bestBidV2?.relayId,
    ).length,
  )

  const renderItem = ({
    onClick,
    icon,
    content,
    disabled = false,
    extraClassNames,
    onClose,
  }: TriggerArgs & {
    extraClassNames?: string
    onClose?: () => void
  }) => {
    return (
      <MobileDropdown.Item
        className={extraClassNames}
        disabled={disabled}
        onClick={e => {
          onClose?.()
          onClick?.(e as React.MouseEvent<HTMLButtonElement>)
        }}
      >
        <MobileDropdown.Item.Avatar icon={icon} />
        <MobileDropdown.Item.Content>{content}</MobileDropdown.Item.Content>
      </MobileDropdown.Item>
    )
  }
  return (
    <MobileDropdown isOpen={isOpen} title={title} onClose={onClose}>
      <MobileDropdown.List>
        <ItemVisibilityButton
          action={itemVisibilityAction}
          trigger={({ ...triggerProps }) =>
            renderItem({
              ...triggerProps,
              onClose,
            })
          }
        />
        <TransferButton
          trigger={({ ...triggerProps }) =>
            renderItem({
              extraClassNames: isAcceptOffersButtonShown
                ? "MobileDropdown-group-last"
                : undefined,
              ...triggerProps,
              onClose,
            })
          }
        />

        {isAcceptOffersButtonShown && (
          <>
            <MobileDropdown.Divider />
            <AcceptOffersButton
              trigger={({ ...triggerProps }) =>
                renderItem({
                  ...triggerProps,
                })
              }
              onOffersAccepted={() => {
                onDeleteAll()
                reloadSearch()
                onClose()
              }}
            />
          </>
        )}

        <CancelListingsButton
          trigger={({ ...triggerProps }) =>
            renderItem({
              extraClassNames: "MobileDropdown-group-last",
              ...triggerProps,
              onClose,
            })
          }
          onListingsCanceled={() => {
            onDeleteAll()
            onClose()
          }}
        />
      </MobileDropdown.List>
    </MobileDropdown>
  )
}
