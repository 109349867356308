import React from "react"
import { Button, ButtonProps } from "@/design-system/Button"
import { useAnalytics } from "@/features/account/components/OffersTable/hooks/useAnalytics"
import {
  useOfferContextActions,
  useOfferSelectionSelectedOffers,
} from "@/features/account/hooks/useOfferSelection"
import { CancelOrdersButton } from "@/features/cancel-orders/components/CancelOrdersButton.react"
import { useTranslate } from "@/hooks/useTranslate"

type Props = { iconButton?: boolean } & Omit<ButtonProps, "onClick">

export const CancelOffersButton = ({ iconButton = false, ...props }: Props) => {
  const t = useTranslate("account")
  const selectedOffers = useOfferSelectionSelectedOffers()
  const { onCanceled } = useOfferContextActions()
  const { trackBulkCancelOffers } = useAnalytics()

  const handleOnClick = (open: () => unknown) => {
    open()
    trackBulkCancelOffers({
      actionType: "click",
      selectedCount: selectedOffers.length,
      triggerType: iconButton ? "icon" : "text_button",
    })
  }
  return (
    <CancelOrdersButton
      orders={selectedOffers}
      side="BID"
      trigger={({ disabled, open }) =>
        iconButton ? (
          <Button
            icon="cancel"
            variant="secondary"
            onClick={() => {
              handleOnClick(open)
            }}
          />
        ) : (
          <Button
            disabled={disabled}
            onClick={() => handleOnClick(open)}
            {...props}
          >
            {t(
              "offers.cancel.cta",
              {
                0: "Cancel {{count}} offers",
                one: "Cancel {{count}} offer",
                other: "Cancel {{count}} offers",
              },
              {
                count: selectedOffers.length,
              },
            )}
          </Button>
        )
      }
      onOrdersCanceled={relayIds => {
        onCanceled(relayIds)
        trackBulkCancelOffers({
          actionType: "completed",
          selectedCount: selectedOffers.length,
          triggerType: iconButton ? "icon" : "text_button",
        })
      }}
    />
  )
}
