import React from "react"
import { Block } from "@/design-system/Block"
import { Select, SelectOption } from "@/design-system/Select"
import { useTranslate } from "@/hooks/useTranslate"
import { SwapSide } from "@/lib/graphql/__generated__/DealsSearchListPaginationQuery.graphql"

const useDealSideOptions = (): SelectOption<SwapSide | "">[] => {
  const t = useTranslate("deals")

  return [
    {
      label: t("dealSideSelect.all", "All deals"),
      value: "",
    },
    {
      label: t("dealSideSelect.sent", "Deals sent"),
      value: "MAKER",
    },
    {
      label: t("dealSideSelect.received", "Deals received"),
      value: "TAKER",
    },
  ]
}

type DealSideSelectProps = {
  side: SwapSide | undefined
  setSide: (side: SwapSide | undefined) => void
}

export const DealSideSelect = ({ side, setSide }: DealSideSelectProps) => {
  const dealSideOptions = useDealSideOptions()
  return (
    <Block width="168px">
      <Select
        clearable={false}
        options={dealSideOptions}
        readOnly
        value={side ?? ""}
        onSelect={opt => setSide(opt?.value || undefined)}
      />
    </Block>
  )
}
