import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { Image } from "@/design-system/Image"
import { useTranslate } from "@/hooks/useTranslate"
import { NO_ASKS_YET_IMG } from "../../../constants"

type NoListingsProps = {
  text?: string
}

export const NoListings = ({ text }: NoListingsProps) => {
  const t = useTranslate("orders")
  const defaultText = t("noListings.default.body", "No listings yet")
  return (
    <CenterAligned className="p-4">
      <Image alt="" height={100} src={NO_ASKS_YET_IMG} width={135} />
      <Text asChild color="primary">
        <p>{t("noListings.body", `{{text}}`, { text: text || defaultText })}</p>
      </Text>
    </CenterAligned>
  )
}
