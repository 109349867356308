import React from "react"
import {
  CenterAligned,
  Flex,
  Icon,
  IconProps,
  Media,
  useIsLessThanLg,
  useIsLessThanMd,
} from "@opensea/ui-kit"
import { _FragmentRefs } from "relay-runtime"
import styled from "styled-components"
import { CollectionWatchlistButton } from "@/components/collections/CollectionWatchlistButton.react"
import { Share } from "@/components/common/Share.react"
import { TextCopy } from "@/components/common/TextCopy"
import { SocialBarItem } from "@/components/layout/AccountOrCollectionPage/components/SocialBar"
import { FeaturedIcon } from "@/components/svgs/FeaturedIcon.react"
import { IS_SERVER } from "@/constants/environment"
import { Z_INDEX } from "@/constants/zIndex"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Dropdown } from "@/design-system/Dropdown"
import { IconButton } from "@/design-system/IconButton"
import { Tooltip } from "@/design-system/Tooltip"
import { ProfileDealLink } from "@/features/deals/components/ProfileDealLink"
import { ReportModal } from "@/features/report/components/ReportModal"
import {
  trackSubmitAccountReport,
  trackSubmitCollectionReport,
} from "@/features/report/utils/analytics"
import { useIsSSFEnabled } from "@/hooks/useFlag"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { accounts_url$data } from "@/lib/graphql/__generated__/accounts_url.graphql"
import type { collection_url$data } from "@/lib/graphql/__generated__/collection_url.graphql"
import { CollectionWatchlistButton_data$key } from "@/lib/graphql/__generated__/CollectionWatchlistButton_data.graphql"
import { getAccountUrl } from "@/lib/helpers/accounts"
import { truncateAddress } from "@/lib/helpers/address"
import { getDefaultChainForAddress } from "@/lib/helpers/chainUtils"
import {
  getCollectionAssetCreateUrl,
  getCollectionEditUrl,
  getCollectionRoyaltiesUrl,
  getCollectionUrl,
} from "@/lib/helpers/collection"

type ActionBarProps = {
  collectionWatchlistDataKey?: CollectionWatchlistButton_data$key
  toggleMoreDropdown: () => void
  relayId: string
  isEditable?: boolean
  socialData: SocialBarItem[]
} & (
  | {
      variant: "profile"
      address: string
      ens?: string
      shareData: accounts_url$data | _FragmentRefs<"accounts_url"> | null
      slug?: never
      isMintable?: never
      collectionUrlDataKey?: never
      isCurrentUser?: boolean
    }
  | {
      variant: "collection"
      slug: string
      address?: never
      ens?: never
      shareData?: never
      isMintable?: boolean
      collectionUrlDataKey:
        | collection_url$data
        | _FragmentRefs<"collection_url">
        | null
      isCurrentUser?: never
    }
)

export const ActionBar = ({
  collectionWatchlistDataKey,
  collectionUrlDataKey,
  socialData,
  shareData,
  toggleMoreDropdown,
  slug,
  relayId,
  variant,
  address,
  ens,
  isEditable,
  isMintable,
  isCurrentUser,
}: ActionBarProps) => {
  const t = useTranslate("phoenix")
  const {
    isOpen: reportModalOpen,
    close: closeReportModal,
    open: openReportModal,
  } = useIsOpen()

  // Inverted logic here b/c we need min-width for proper breakpoint behavior
  const isDesktopLayout = !useIsLessThanLg()
  const isTabletLayout = !useIsLessThanMd()
  const connectedAddress = useConnectedAddress()
  const isSSFEnabled = useIsSSFEnabled()
  const defaultChain = getDefaultChainForAddress(address)

  return (
    <>
      <Flex
        className="relative mt-4 sm:mt-0"
        style={{ zIndex: Z_INDEX.PHOENIX_HEADER }}
      >
        <Flex className="items-center">
          <Media greaterThanOrEqual="md">
            {collectionWatchlistDataKey && variant === "collection" && (
              <Block marginRight="4px">
                <CollectionWatchlistButton
                  data={collectionWatchlistDataKey}
                  slug={slug}
                />
              </Block>
            )}
          </Media>
          {variant == "profile" && connectedAddress !== address && (
            <ProfileDealLink address={address}>
              <IconButton>
                <StyledIcon value="handshake" />
              </IconButton>
            </ProfileDealLink>
          )}

          {(shareData || collectionUrlDataKey) && (
            <Share
              object={shareData ? "account" : "collection"}
              url={
                shareData
                  ? getAccountUrl(shareData)
                  : collectionUrlDataKey
                  ? getCollectionUrl(collectionUrlDataKey)
                  : ""
              }
            >
              <Tooltip
                content={t("actionBar.tooltip.share", "Share")}
                hideOnClick
                touch={false}
              >
                <Block marginRight="4px">
                  <IconButton aria-label={t("actionBar.share", "Share")}>
                    <ShareIcon value="ios_share" />
                  </IconButton>
                </Block>
              </Tooltip>
            </Share>
          )}
        </Flex>
        <Dropdown
          appendTo={IS_SERVER ? undefined : document.body}
          content={({ List, Item, close }) => (
            <List>
              {!isTabletLayout && address && (
                <>
                  <Item>
                    <SectionHeader>
                      {t("actionBar.addressesHeader", "ADDRESSES")}
                    </SectionHeader>
                  </Item>
                  {ens && (
                    <Item>
                      {/* This doesn't work w/ the `marginRight` prop instead */}
                      <Item.Content style={{ marginRight: 0 }}>
                        <TextCopy
                          ItemComponent={Item}
                          chainIdentifier="ETHEREUM"
                          fontSize="14px"
                          text={ens}
                        />
                      </Item.Content>
                    </Item>
                  )}
                  <Item>
                    {/* This doesn't work w/ the `marginRight` prop instead */}
                    <Item.Content style={{ marginRight: 0 }}>
                      <TextCopy
                        ItemComponent={Item}
                        chainIdentifier={defaultChain}
                        fontSize="14px"
                        label={truncateAddress(address)}
                        text={address}
                      />
                    </Item.Content>
                  </Item>
                </>
              )}
              {!isDesktopLayout && (
                <>
                  {socialData.length > 0 && (
                    <Item>
                      <SectionHeader>
                        {t("actionBar.links", "LINKS")}
                      </SectionHeader>
                    </Item>
                  )}
                  {socialData.map(({ url, icon, tooltip }) => (
                    <Item href={url ?? undefined} key={url}>
                      <Item.Avatar>{icon}</Item.Avatar>
                      <Item.Content>
                        <Item.Title>{tooltip}</Item.Title>
                      </Item.Content>
                    </Item>
                  ))}
                  <Item>
                    <SectionHeader>{t("actionBar.more", "MORE")}</SectionHeader>
                  </Item>
                </>
              )}
              {variant === "profile" && isCurrentUser && (
                <>
                  <Item href="/account/settings">
                    <Item.Avatar icon="settings" />
                    <Item.Content>
                      <Item.Title>
                        {t("actionBar.settings", "Settings")}
                      </Item.Title>
                    </Item.Content>
                  </Item>
                  <Media greaterThanOrEqual="lg">
                    <Item href="/account/settings?tab=featured">
                      <Item.Content>
                        <Flex>
                          <Flex className="mr-4">
                            <FeaturedIcon />
                          </Flex>
                          <CenterAligned>
                            <Item.Title>
                              {t("actionBar.featuredItems", "Featured items")}
                            </Item.Title>
                          </CenterAligned>
                        </Flex>
                      </Item.Content>
                    </Item>
                  </Media>
                </>
              )}
              {isEditable && collectionUrlDataKey && (
                <>
                  <Item href={getCollectionEditUrl(collectionUrlDataKey)}>
                    <Item.Avatar icon="edit" />
                    <Item.Content>
                      <Item.Title>
                        {t("actionBar.editCollection", "Edit collection")}
                      </Item.Title>
                    </Item.Content>
                  </Item>
                  <Item href={getCollectionRoyaltiesUrl(collectionUrlDataKey)}>
                    <Item.Avatar icon="attach_money" />
                    <Item.Content>
                      <Item.Title>
                        {t("actionBar.viewEarnings", "View earnings")}
                      </Item.Title>
                    </Item.Content>
                  </Item>
                  {isSSFEnabled && isMintable && (
                    <Item
                      href={getCollectionAssetCreateUrl(collectionUrlDataKey)}
                    >
                      <Item.Avatar icon="add" />
                      <Item.Content>
                        <Item.Title>
                          {t("actionBar.addItem", "Add item")}
                        </Item.Title>
                      </Item.Content>
                    </Item>
                  )}
                </>
              )}
              {collectionWatchlistDataKey && variant === "collection" && (
                <Media lessThan="lg">
                  <CollectionWatchlistButton
                    data={collectionWatchlistDataKey}
                    slug={slug}
                    variant="option"
                  />
                </Media>
              )}
              <Item
                onClick={() => {
                  openReportModal()
                  close()
                }}
              >
                <Item.Avatar icon="flag" />
                <Item.Content>
                  <Item.Title>{t("actionBar.report", "Report")}</Item.Title>
                </Item.Content>
              </Item>
            </List>
          )}
          hideOnScroll
          placement="bottom-end"
        >
          <Flex className="items-center">
            <IconButton
              aria-label={t("actionBar.moreDropdownLabel", "More dropdown")}
              onClick={toggleMoreDropdown}
            >
              <MoreIcon value="more_horiz" />
            </IconButton>
          </Flex>
        </Dropdown>
      </Flex>
      <ReportModal
        isOpen={reportModalOpen}
        subject={
          variant === "collection"
            ? { collection: relayId }
            : { account: relayId }
        }
        onClose={closeReportModal}
        onSubmit={({ additionalComments, originalCreatorUrl, reason }) => {
          if (variant === "collection") {
            trackSubmitCollectionReport({
              slug,
              additionalComments,
              originalCreatorUrl,
              reason,
            })
          }

          if (variant === "profile") {
            trackSubmitAccountReport({
              address,
              additionalComments,
              originalCreatorUrl,
              reason,
            })
          }
        }}
      />
    </>
  )
}

const SectionHeader = styled(Block)`
  font-size: 12px;
  color: ${props => props.theme.colors.text.secondary};
`

const MoreIcon = styled(Icon).attrs<IconProps>({
  size: 24,
})`
  margin-right: 0px;
  font-weight: 600;
`

const ShareIcon = styled(Icon).attrs<IconProps>({
  size: 20,
  variant: "outlined",
})`
  margin-right: 0px;
  font-weight: 600;
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};
  margin-right: 0px;
  font-weight: 600;
`
