import React from "react"
import { Media } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { css } from "styled-components"
import {
  CAROUSEL_RESPONSIVE_CONFIG_DEFAULT,
  Carousel,
} from "@/design-system/Carousel"
import { HomePageCollectionCard } from "@/features/home/components/HomePageShelf/HomePageCollectionCard.react"
import { CreatedCollections_data$key } from "@/lib/graphql/__generated__/CreatedCollections_data.graphql"

const CAROUSEL_RESPONSIVE_CONFIG = {
  ...CAROUSEL_RESPONSIVE_CONFIG_DEFAULT,
  default: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  sm: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    spaceBetween: 16,
  },
  md: {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 16,
  },
  lg: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xl: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xxl: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xxxl: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
  },
}

type Props = {
  collections: CreatedCollections_data$key
}

export const CreatedCollections = ({ collections: collectionsKey }: Props) => {
  const collectionsData = useFragment(
    graphql`
      fragment CreatedCollections_data on CollectionType @relay(plural: true) {
        relayId
        ...HomePageCollectionCard_data @arguments(timeWindow: ALL_TIME)
      }
    `,
    collectionsKey,
  )

  const slides = collectionsData.map((collection, idx) => {
    return {
      id: collection.relayId,
      collection: (
        <HomePageCollectionCard
          collection={collection}
          eventParams={{
            idx,
          }}
          eventSource="CreatedCollections"
          hasShadow={false}
          volumeWindow="ALL_TIME"
        />
      ),
    }
  })

  return (
    <>
      <Media lessThan="sm">
        <Carousel
          centeredSlides
          centeredSlidesBounds
          enableArrowControls={false}
          enableFreeScroll
          enableMousewheel
          id="created-collections-carousel-mobile"
          overrides={{
            containerStyles: css`
              margin-top: 24px;
              margin-bottom: 24px;
            `,
          }}
          renderSlide={({ collection }) => collection}
          responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG}
          showScrollbar={false}
          slides={slides}
          speed={500}
        />
      </Media>
      <Media greaterThanOrEqual="sm">
        <Carousel
          enableFreeScroll
          enableMousewheel
          id="created-collections-carousel"
          overrides={{
            containerStyles: css`
              margin-top: 24px;
              margin-bottom: 24px;
            `,
          }}
          renderSlide={({ collection }) => collection}
          responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG}
          showScrollbar={false}
          slides={slides}
          speed={500}
        />
      </Media>
    </>
  )
}
