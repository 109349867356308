/**
 * @generated SignedSource<<d06ee46ede363f55b2b16b4b79e7c4dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type socialLinksData$data = {
  readonly connectedInstagramUsername: string | null;
  readonly connectedTwitterUsername: string | null;
  readonly metadata: {
    readonly instagramUsername: string | null;
    readonly twitterUsername: string | null;
    readonly websiteUrl: string | null;
  } | null;
  readonly " $fragmentType": "socialLinksData";
};
export type socialLinksData$key = {
  readonly " $data"?: socialLinksData$data;
  readonly " $fragmentSpreads": FragmentRefs<"socialLinksData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "socialLinksData"
};

(node as any).hash = "ad937749c24a94a7c574f14d82bf9029";

export default node;
