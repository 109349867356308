import React, { useMemo } from "react"
import { Media, Text, classNames } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { DealSearchStatus } from "@/features/deals/manage-deals/types"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { DealsSubTabs_account$key } from "@/lib/graphql/__generated__/DealsSubTabs_account.graphql"
import { getAccountUrl } from "@/lib/helpers/accounts"
import { addressesEqual } from "@/lib/helpers/address"

type Props = {
  account: DealsSubTabs_account$key
}

type SubTabProps = {
  isActive: boolean
  label: string
  path: string
}

const DEFAULT_SUB_TAB: DealSearchStatus = "active"

export const useCurrentDealsSubTab = (): DealSearchStatus => {
  // TODO: Maybe make this more robust with storing actual status in context.
  const { asPathWithoutQuery } = useRouter()
  return useMemo(
    () =>
      asPathWithoutQuery.includes("/deals/all")
        ? "all"
        : asPathWithoutQuery.includes("/deals/active")
        ? "active"
        : asPathWithoutQuery.includes("/deals/accepted")
        ? "accepted"
        : asPathWithoutQuery.includes("/deals/canceled")
        ? "canceled"
        : asPathWithoutQuery.includes("/deals/inactive")
        ? "inactive"
        : asPathWithoutQuery.includes("/deals/expired")
        ? "expired"
        : DEFAULT_SUB_TAB,
    [asPathWithoutQuery],
  )
}
export const DealsSubTabs = ({ account: accountKey }: Props) => {
  const t = useTranslate("deals")
  const connectedAddress = useConnectedAddress()
  const account = useFragment<DealsSubTabs_account$key>(
    graphql`
      fragment DealsSubTabs_account on AccountType {
        address
        ...accounts_url
      }
    `,
    accountKey,
  )
  const currentSubTab = useCurrentDealsSubTab()

  const accountDealsUrl =
    connectedAddress && addressesEqual(connectedAddress, account.address)
      ? "/account/deals"
      : getAccountUrl(account, { tab: "deals" })

  return (
    <StyledFlex
      className="w-full gap-x-3 gap-y-4 lg:w-[200px] xl:w-[350px]"
      flexDirection={{ _: "row", lg: "column" }}
      marginLeft={{ _: "-14px", lg: "0px", xl: "0px" }}
      marginRight={{ _: "-24px", lg: "0px", xl: "0px" }}
      maxWidth="100vw"
      paddingLeft={{ _: "12px", lg: "0px", xl: "0px" }}
      width={{ _: "100%", lg: "200px", xl: "350px" }}
    >
      <SubTab
        isActive={currentSubTab === "all"}
        label={t("manageDeals.subTabs.all", "All")}
        path={`${accountDealsUrl}/all`}
      />
      <SubTab
        isActive={currentSubTab === "active"}
        label={t("manageDeals.subTabs.active", "Active")}
        path={`${accountDealsUrl}/active`}
      />
      <SubTab
        isActive={currentSubTab === "accepted"}
        label={t("manageDeals.subTabs.accepted", "Accepted")}
        path={`${accountDealsUrl}/accepted`}
      />
      <SubTab
        isActive={currentSubTab === "canceled"}
        label={t("manageDeals.subTabs.canceled", "Canceled")}
        path={`${accountDealsUrl}/canceled`}
      />
      <SubTab
        isActive={currentSubTab === "inactive"}
        label={t("manageDeals.subTabs.inactive", "Inactive")}
        path={`${accountDealsUrl}/inactive`}
      />
      <SubTab
        isActive={currentSubTab === "expired"}
        label={t("manageDeals.subTabs.expired", "Expired")}
        path={`${accountDealsUrl}/expired`}
      />
    </StyledFlex>
  )
}

const SubTab = ({ isActive, label, path }: SubTabProps) => {
  return (
    <Link href={path}>
      <Media lessThan="lg">
        <Block
          backgroundColor={isActive ? "components.background.gray1" : "none"}
          className={classNames(
            "rounded-xl border-level-1",
            isActive ? "border" : "border-0",
          )}
          padding="8px 16px"
        >
          <Text.Body
            className={isActive ? "text-primary" : "text-secondary"}
            size="small"
            weight={isActive ? "semibold" : undefined}
          >
            {label}
          </Text.Body>
        </Block>
      </Media>
      <Media greaterThanOrEqual="lg">
        <Block
          backgroundColor={isActive ? "components.background.gray1" : "none"}
          className={classNames(
            "rounded-xl border-level-1",
            isActive ? "border" : "border-0",
          )}
          display="inline-block"
          padding="12px 24px"
        >
          <Text.Body
            className={isActive ? "text-primary" : "text-secondary"}
            size="medium"
            weight={isActive ? "semibold" : undefined}
          >
            {label}
          </Text.Body>
        </Block>
      </Media>
    </Link>
  )
}

const StyledFlex = styled(Flex)`
  overflow: auto;
  // Hide scrollbar in Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
  // Hide scrollbar in Firefox
  scrollbar-width: none;
  -ms-overflow-style: none;
`
