import React from "react"
import { useIsLessThanMd, Text, classNames } from "@opensea/ui-kit"
import { BottomActionBar } from "@/components/common/BottomActionBar"
import { useOfferSelectionCount } from "@/features/account/hooks/useOfferSelection"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOffersButton } from "./CancelOffersButton.react"

export const ProfileOfferSelectionFooter = () => {
  const selectedCount = useOfferSelectionCount()
  const t = useTranslate("account")
  const isOpen = selectedCount > 0
  const isMobile = useIsLessThanMd()
  return (
    <BottomActionBar
      className={classNames("w-full items-center justify-between")}
      isOpen={isOpen}
    >
      {/* Center aligns middle text on mobile and cancel button on desktop. */}
      {/* Matches width of cancel offers button on mobile */}
      <span className="w-[44px]"></span>
      {isMobile && (
        <Text.Body
          className="flex-1 text-center"
          size="small"
          weight="semibold"
        >
          {t(
            "offers.selection.title",
            {
              0: "{{count}} offers selected",
              one: "{{count}} offer selected",
              other: "{{count}} offers selected",
            },
            {
              count: selectedCount,
            },
          )}
        </Text.Body>
      )}
      <CancelOffersButton className="w-full md:w-auto" iconButton={isMobile} />
    </BottomActionBar>
  )
}
