import React from "react"
import { graphql } from "react-relay"
import { AccountCollections } from "@/components/accounts/AccountCollections.react"
import { getAppInitialProps } from "@/components/app/initialProps"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { CreatedAccountPageQuery } from "@/lib/graphql/__generated__/CreatedAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../../components/ProfileContainer"
import { cachePolicy, getInitialPropsAccountPageQuery } from "../utils"
import {
  AccountCreatedAssetSearch,
  AccountCreatedAssetSearchState,
} from "./AccountCreatedAssetSearch.react"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  assetCardVariant?: AssetCardVariant
  searchState: AccountCreatedAssetSearchState
}

export const CreatedPage: GraphQLNextPage<CreatedAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  assetCardVariant,
  searchState,
}) => {
  const identity = searchState.identity

  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="created"
      tabContent={
        <>
          <SsrSuspense fallback={null}>
            <AccountCollections creator={variables.identity} />
          </SsrSuspense>
          <AccountCreatedAssetSearch
            assetCardVariant={assetCardVariant}
            defaultState={{
              chains: undefined,
              collection: undefined,
              collections: undefined,
              numericTraits: undefined,
              paymentAssets: undefined,
              priceFilter: undefined,
              resultModel: "ASSETS",
              sortAscending: undefined,
              sortBy: "CREATED_DATE",
              stringTraits: undefined,
              toggles: undefined,
              query: undefined,
              identity,
            }}
            initialState={searchState}
            isCurrentUser={isCurrentUser}
            path={path}
          />
        </>
      }
    />
  )
}

const query = graphql`
  query CreatedAccountPageQuery(
    $identity: IdentityInputType!
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

CreatedPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
    search: QP.Optional(QP.Search),
  },
  async (
    { identifier, search },
    context,
  ): Promise<GraphQLInitialProps<CreatedAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables, path, assetCardVariant } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        search,
        tab: "created",
      })

    const {
      includePrivateAssetCount,
      identity,
      collection,
      collections,
      chains,
    } = variables

    const pageVariables = {
      identity,
      includePrivateAssetCount,
    }

    const searchState: AccountCreatedAssetSearchState = {
      chains,
      collection,
      collections,
      identity,
      numericTraits: search?.numericTraits,
      paymentAssets: search?.paymentAssets,
      priceFilter: search?.priceFilter,
      query: search?.query,
      resultModel: search?.resultModel ?? "ASSETS",
      sortAscending: search?.sortAscending,
      sortBy: search?.sortBy ?? "CREATED_DATE",
      stringTraits: search?.stringTraits,
      toggles: search?.toggles,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      assetCardVariant,
      variables: pageVariables,
      searchState,
    }
  },
)
