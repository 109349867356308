import { useMemo } from "react"
import { ListingsColumn } from "@/features/account/components/PortfolioTable/types"
import { useTranslate } from "@/hooks/useTranslate"

type HeaderColumn = {
  column: ListingsColumn
  header: string
  minWidth?: "auto" | number
  maxWidth: "auto" | number
}

export const useListingsTableColumns = (): HeaderColumn[] => {
  const t = useTranslate("account")
  return useMemo(
    () => [
      {
        column: "listingPrice",
        header: t("orders.ordersTable.listingPrice", "Listing price"),
        minWidth: 150,
        maxWidth: "auto",
      },
      {
        column: "usdPrice",
        header: t("orders.ordersTable.usdPrice", "USD price"),
        minWidth: 100,
        maxWidth: "auto",
      },
      {
        column: "quantity",
        header: t("orders.ordersTable.quantity", "Quantity"),
        minWidth: 100,
        maxWidth: "auto",
      },
      {
        column: "expiration",
        header: t("orders.ordersTable.expiration", "Expiration"),
        minWidth: 100,
        maxWidth: "auto",
      },
      {
        column: "actions",
        header: "",
        minWidth: 100,
        maxWidth: "auto",
      },
    ],
    [t],
  )
}
