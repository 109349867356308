/**
 * @generated SignedSource<<2f7689fb99d0a717fda8549973cec109>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type AccountCollectionsPaginationQuery$variables = {
  creator: IdentityInputType;
  cursor?: string | null;
  first?: number | null;
};
export type AccountCollectionsPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountCollectionsPagination_data">;
};
export type AccountCollectionsPaginationQuery = {
  response: AccountCollectionsPaginationQuery$data;
  variables: AccountCollectionsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": 12,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "collectionCreator",
    "variableName": "creator"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalItemCount",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCollectionsPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "creator",
            "variableName": "creator"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AccountCollectionsPagination_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountCollectionsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collectionsByCreator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relayId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verificationStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionStatsV2Type",
                    "kind": "LinkedField",
                    "name": "statsV2",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSupply",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "dropv2",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mintedItemCount",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "Drop721LimitedEditionType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "Drop1155LimitedEditionType",
                        "abstractKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "statsTimeWindow",
                        "value": "ALL_TIME"
                      }
                    ],
                    "concreteType": "WindowCollectionStatsType",
                    "kind": "LinkedField",
                    "name": "windowCollectionStats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "floorPrice",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "volume",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "windowCollectionStats(statsTimeWindow:\"ALL_TIME\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "collectionCreator"
        ],
        "handle": "connection",
        "key": "AccountCollections_collectionsByCreator",
        "kind": "LinkedHandle",
        "name": "collectionsByCreator"
      }
    ]
  },
  "params": {
    "cacheID": "d9a0bf9e3b48c066870125046a00cad1",
    "id": null,
    "metadata": {},
    "name": "AccountCollectionsPaginationQuery",
    "operationKind": "query",
    "text": "query AccountCollectionsPaginationQuery(\n  $creator: IdentityInputType!\n  $cursor: String\n  $first: Int = 12\n) {\n  ...AccountCollectionsPagination_data_2769L9\n}\n\nfragment AccountCollectionsPagination_data_2769L9 on Query {\n  collectionsByCreator(collectionCreator: $creator, first: $first, after: $cursor) {\n    edges {\n      node {\n        ...CreatedCollections_data\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CreatedCollections_data on CollectionType {\n  relayId\n  ...HomePageCollectionCard_data_49z4c6\n}\n\nfragment HomePageCollectionCardFooter_data_49z4c6 on CollectionType {\n  windowCollectionStats(statsTimeWindow: ALL_TIME) {\n    floorPrice {\n      unit\n      symbol\n    }\n    volume {\n      unit\n      symbol\n    }\n  }\n}\n\nfragment HomePageCollectionCard_data_49z4c6 on CollectionType {\n  banner\n  name\n  verificationStatus\n  ...useDropState_data\n  ...HomePageCollectionCardFooter_data_49z4c6\n  ...collection_url\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n\nfragment useDropState_data on CollectionType {\n  statsV2 {\n    totalSupply\n  }\n  dropv2 {\n    __typename\n    stages {\n      __typename\n      startTime\n      endTime\n      id\n    }\n    mintedItemCount\n    ... on Drop721LimitedEditionType {\n      totalItemCount\n    }\n    ... on Drop1155LimitedEditionType {\n      totalItemCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0995bfe86c8fb5a45e84ef4c71ce128d";

export default node;
