import React from "react"
import { Media, Checkbox, Flex } from "@opensea/ui-kit"
import styled from "styled-components"
import { IS_SERVER } from "@/constants/environment"
import { useTheme } from "@/design-system/Context"
import { DropdownProps } from "@/design-system/Dropdown"
import { Image } from "@/design-system/Image"
import { SelectOption } from "@/design-system/Select"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { SearchSortBy } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import THEMES from "@/styles/themes"
import { StyledSelect } from "./styles"

type Props = {
  activeChains: ReadonlyArray<ChainIdentifier>
  allowMultichain?: boolean
  setChains: (values?: ReadonlyArray<ChainIdentifier>) => unknown
}

type SortBySelectOption = SelectOption<SearchSortBy> & {
  sortAscending?: boolean
}

const AssetSearchChainDropdown = ({
  activeChains,
  allowMultichain = true,
  setChains,
}: Props) => {
  const { theme } = useTheme()
  const t = useTranslate("components")
  const { chains, getChainName, getChainLogo, isChainFiltersEnabled } =
    useChains()

  const getUpdatedChains = (chain: ChainIdentifier) => {
    if (activeChains.includes(chain)) {
      return activeChains.filter((c: ChainIdentifier) => c !== chain)
    }
    if (allowMultichain) {
      return [...activeChains, chain]
    }
    return [chain]
  }

  const items = chains
    .filter(chain => isChainFiltersEnabled(chain))
    .map(chain => {
      return {
        id: chain,
        isSelected: activeChains.includes(chain),
        value: chain,
        label: getChainName(chain),
        startEnhancer: (
          <Flex className="items-center">
            <Flex className="mr-4 items-center">
              <Checkbox checked={activeChains.includes(chain)} id={chain} />
            </Flex>
            <Flex className="mr-4 items-center">
              <RoundImage
                alt={getChainName(chain)}
                height={24}
                objectFit="cover"
                src={getChainLogo(chain)}
                width={24}
              />
            </Flex>
          </Flex>
        ),
        onChange: () => {
          const newChains = getUpdatedChains(chain)
          setChains(newChains.length ? newChains : undefined)
        },
      }
    })

  let appendTo: DropdownProps<SortBySelectOption>["appendTo"]

  if (!IS_SERVER) {
    appendTo = "parent"
  }

  const select = (
    <StyledSelect
      clearable={false}
      options={items}
      overrides={{
        Dropdown: {
          props: {
            appendTo,
            popperOptions: {
              modifiers: [{ name: "flip", enabled: false }],
            },
          },
        },
      }}
      placeholder={t("chainFilter.title", "Chains")}
      readOnly
      style={{
        fontWeight: 600,
        fontSize: 16,
        color:
          theme === "light"
            ? THEMES.light.colors.text.primary
            : THEMES.dark.colors.text.primary,
        padding: "12px 12px 12px 16px",
      }}
      onSelect={(option?: SelectOption<string> & { onChange?: () => void }) =>
        option?.onChange?.()
      }
    />
  )

  return <Media greaterThanOrEqual="lg">{select}</Media>
}

export default AssetSearchChainDropdown

const RoundImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.circle};
`
