import React from "react"
import { Flex, UnstyledButton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Tooltip } from "@/design-system/Tooltip"
import { useOfferContextActions } from "@/features/account/hooks/useOfferSelection"
import { CancelOrderButton } from "@/features/cancel-orders/components/CancelOrderButton.react"
import { useTranslate } from "@/hooks/useTranslate"
import { OffersTableOptionsCell_order$key } from "@/lib/graphql/__generated__/OffersTableOptionsCell_order.graphql"
import { useAnalytics } from "../hooks/useAnalytics"
import { OptionIcon } from "../styles"

type Props = {
  order: OffersTableOptionsCell_order$key
}

export const OffersTableOptionsCell = ({ order: orderKey }: Props) => {
  const t = useTranslate("account")
  const { trackCancelOffer } = useAnalytics()
  const { onCanceled } = useOfferContextActions()
  const order = useFragment(
    graphql`
      fragment OffersTableOptionsCell_order on OrderV2Type {
        relayId
        invalidationReason
        ...CancelOrderButton_data
        ...useAnalyticsOfferEvent_data
      }
    `,
    orderKey,
  )

  return (
    <Flex className="absolute right-0 rounded-xl pr-1">
      <CancelOrderButton
        dataKey={order}
        gaslessCancelEligibleOrders={
          order.invalidationReason !== "GASLESS_CANCEL"
        }
        trigger={onClick => (
          <Tooltip
            content={t("offersTable.options.cancelOffer", "Cancel offer")}
          >
            <UnstyledButton
              aria-label={t("offersTable.options.cancelOffer", "Cancel offer")}
              onClick={() => {
                onClick()
                trackCancelOffer(order, {
                  actionType: "click",
                })
              }}
            >
              <OptionIcon value="cancel" />
            </UnstyledButton>
          </Tooltip>
        )}
        onOrderCanceled={() => {
          onCanceled([order.relayId])
          trackCancelOffer(order, { actionType: "completed" })
        }}
      />
    </Flex>
  )
}
