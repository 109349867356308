import React from "react"
import { Media, Container, Flex } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"

type Props = {
  banner?: React.ReactNode | null
  image: React.ReactNode
  header: React.ReactNode
  subheader?: React.ReactNode
  info?: React.ReactNode
  inspiredBy?: React.ReactNode
  socialBar?: React.ReactNode
  tabs?: React.ReactNode
  content: React.ReactNode
  actionBar?: React.ReactNode
  footer?: React.ReactNode
  fullBleedContent?: boolean
}

export function AccountOrCollectionPageLayout({
  image,
  header,
  subheader,
  socialBar,
  content,
  banner,
  info,
  inspiredBy,
  tabs,
  actionBar,
  footer,
  fullBleedContent,
}: Props) {
  return (
    <Flex className="w-full flex-col">
      {banner}
      <Container>
        <>
          <Flex className="items-center">
            <Block
              marginBottom="16px"
              marginTop={
                banner ? { _: "-36px", sm: "-86px", lg: "-156px" } : "16px"
              }
            >
              {image}
            </Block>
            <Block marginLeft="auto">
              <Media lessThan="sm">
                <Flex className="items-center gap-1">
                  {socialBar}
                  {actionBar}
                </Flex>
              </Media>
            </Block>
          </Flex>
        </>
      </Container>
      <Flex className="w-full bg-base-1">
        <Container>
          <Flex className="w-full items-start">
            <Block
              maxWidth={{
                _: "90%",
                sm: "80%",
                lg: "60%",
              }}
              minWidth="0px"
            >
              {header}
            </Block>
            <Block marginLeft="auto">
              <Media greaterThanOrEqual="sm">
                <Flex className="items-center">
                  {socialBar}
                  {actionBar}
                </Flex>
              </Media>
            </Block>
          </Flex>
        </Container>
      </Flex>
      <Block>
        {subheader && <Container className="sm:-mt-1.5">{subheader}</Container>}
      </Block>
      <Container className="relative pt-0">
        <Block>
          {inspiredBy && <Block marginTop="8px">{inspiredBy}</Block>}
          {info && <Block>{info}</Block>}
          {tabs}
          {!fullBleedContent && content}
        </Block>
      </Container>
      {fullBleedContent && content}
      {footer}
    </Flex>
  )
}
