import { useMemo } from "react"
import { useTranslate } from "@/hooks/useTranslate"
import { display } from "@/lib/helpers/numberUtils"
import { OffersColumn } from "../types"

type HeaderColumn = {
  column: OffersColumn
  header: string
}

export const useHeaderColumns = (itemCount: number): HeaderColumn[] => {
  const t = useTranslate("account")
  return useMemo(
    () => [
      {
        column: "offer",
        header: t(
          "offersTable.headerColumn.offer",
          {
            0: `${itemCount} Offers`,
            one: `${itemCount} Offer`,
            other: `${itemCount} Offers`,
          },
          { count: itemCount, itemCount: display(itemCount) },
          { forceString: true },
        ),
      },
      {
        column: "price",
        header: t("offersTable.headerColumn.price", "Price"),
      },
      {
        column: "quantity",
        header: t("offersTable.headerColumn.quantity", "Quantity"),
      },
      {
        column: "total",
        header: t("offersTable.headerColumn.total", "Total"),
      },
      {
        column: "expiration",
        header: t("offersTable.headerColumn.expiration", "Expiration"),
      },
      {
        column: "status",
        header: t("offersTable.headerColumn.status", "Status"),
      },
      {
        column: "dateCreated",
        header: t("offersTable.headerColumn.dateCreated", "Date created"),
      },
    ],
    [itemCount, t],
  )
}
