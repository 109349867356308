import { useState } from "react"
import {
  SwapSide,
  SwapSortBy,
} from "@/lib/graphql/__generated__/DealsSearchListPaginationQuery.graphql"

type DealSearchState = {
  side: SwapSide | undefined
  sortBy: SwapSortBy
  sortAscending: boolean
}

export const useDealSearchState = () => {
  const [searchState, setSearchState] = useState<DealSearchState>({
    side: undefined,
    sortBy: "CREATED_DATE",
    sortAscending: false,
  })

  const updateSearchState = (
    newState: Partial<Omit<DealSearchState, "sortAscending">>,
  ) => {
    setSearchState(prevState => {
      return {
        ...prevState,
        ...newState,
        sortAscending: getSortAscendingFromSortBy(
          newState.sortBy ?? prevState.sortBy,
        ),
      }
    })
  }

  return {
    searchState,
    updateSearchState,
  }
}

const getSortAscendingFromSortBy = (sortBy: SwapSortBy) => {
  switch (sortBy) {
    case "CREATED_DATE":
      return false
    case "CLOSED_AT":
      return true
    default:
      return false
  }
}
