import React from "react"
import { Flex, Icon, UnstyledButton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { AssetContextMenu } from "@/components/common/AssetContextMenu"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { PortfolioTableOptionsCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableOptionsCell_item.graphql"
import { PortfolioTableAcceptOfferButton } from "../buttons/PortfolioTableAcceptOfferButton.react"
import { PortfolioTableListButton } from "../buttons/PortfolioTableListButton.react"

type Props = {
  accountAddress?: string
  isMouseHoveringRow?: boolean
  item: PortfolioTableOptionsCell_item$key
  onSelect: () => unknown
}

const PortfolioTableOptionsCellBase = ({
  accountAddress,
  isMouseHoveringRow,
  item: itemKey,
  onSelect,
}: Props) => {
  const t = useTranslate("account")
  const item = useFragment(
    graphql`
      fragment PortfolioTableOptionsCell_item on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        showOwnerActions: { type: "Boolean!" }
      ) {
        __typename
        ... on AssetType {
          ...AssetContextMenu_data @include(if: $showOwnerActions)
        }
        ...PortfolioTableAcceptOfferButton_item @arguments(identity: $identity)
        ...PortfolioTableListButton_item
          @arguments(identity: $identity, showOwnerActions: $showOwnerActions)
        # eslint-disable-next-line relay/must-colocate-fragment-spreads used by tracking methods
        ...itemEvents_dataV2
      }
    `,
    itemKey,
  )

  if (item.__typename !== "AssetType") {
    return <PortfolioTableOptionsCellSkeleton />
  }
  return (
    <Flex className="w-full justify-end">
      {isMouseHoveringRow && accountAddress && (
        <Flex className="mr-2 justify-end">
          <PortfolioTableAcceptOfferButton
            isIcon
            item={item}
            source="collapsed"
          />
          <PortfolioTableListButton
            accountAddress={accountAddress}
            className="ml-4"
            isIcon
            item={item}
            source="collapsed"
          />
        </Flex>
      )}
      <AssetContextMenu
        dataKey={item}
        placement="left-end"
        source="portfolio table collapsed row"
        toggleAssetSelected={onSelect}
      >
        <Tooltip content={t("options", "More options")}>
          <OptionsButton
            aria-label={t("portfolio.options.button.label", "More options")}
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
            }}
          >
            <Icon size={24} value="more_vert" />
          </OptionsButton>
        </Tooltip>
      </AssetContextMenu>
    </Flex>
  )
}

const PortfolioTableOptionsCellSkeleton = () => (
  <Flex className="w-full justify-end">
    <OptionsButton disabled>
      <Icon size={24} value="price_check" />
    </OptionsButton>
    <OptionsButton disabled>
      <Icon size={24} value="sell" />
    </OptionsButton>
    <OptionsButton disabled>
      <Icon size={24} value="more_vert" />
    </OptionsButton>
  </Flex>
)

export const PortfolioTableOptionsCell = Object.assign(
  PortfolioTableOptionsCellBase,
  {
    Skeleton: PortfolioTableOptionsCellSkeleton,
  },
)

const OptionsButton = styled(UnstyledButton)`
  height: 40px;
  width: 40px;

  :disabled {
    opacity: 0.4;
  }
`
