import React, { useCallback, useContext, useMemo, useState } from "react"
import { noop } from "lodash"
import { graphql, useFragment } from "react-relay"
import { Sort } from "@/components/search/assets/AssetSearchSortDropdown.react"
import { useClearSearchContext } from "@/components/search/utils/SearchContextProvider.react"
import {
  TableRowRenderProps,
  VirtualizedTable,
  VirtualizedTablePaginationProps,
  VirtualizedTableProps,
} from "@/design-system/VirtualizedTable"
import { VirtualizedTableHeaderProps } from "@/design-system/VirtualizedTable/components"
import { AccountPageContext } from "@/features/account/components/AccountPage/tabs/utils"
import {
  trackClickPortfolioTableRow,
  trackSelectAllPortfolioTableRows,
  trackSelectPortfolioTableRow,
} from "@/features/account/components/PortfolioTable/analytics"
import { PortfolioTableContextProvider } from "@/features/account/components/PortfolioTable/components/PortfolioTableContext.react"
import { PortfolioTableHeader } from "@/features/account/components/PortfolioTable/components/PortfolioTableHeader.react"
import {
  PortfolioTableRow,
  PortfolioTableRowProps,
} from "@/features/account/components/PortfolioTable/components/PortfolioTableRow.react"
import {
  COLLAPSED_ROW_HEIGHT,
  ITEM_HEIGHT_ESTIMATE,
} from "@/features/account/components/PortfolioTable/constants"
import {
  PortfolioViewMode,
  SortablePortfolioColumn,
} from "@/features/account/components/PortfolioTable/types"
import {
  useAssetContextActions,
  useAssetSelectionState,
  useGetAssetContextSelectedIdsCount,
} from "@/features/account/hooks/useAssetSelection"
import { readAssetSelectionItem } from "@/features/assets/hooks/useAssetSelectionStorage"
import { useMountEffect } from "@/hooks/useMountEffect"
import { SearchSortBy } from "@/lib/graphql/__generated__/AssetSearchListPaginationQuery.graphql"
import { PortfolioTable_items$key } from "@/lib/graphql/__generated__/PortfolioTable_items.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { PartiallyOptional } from "@/lib/helpers/type"
import { PortfolioTableListingsTableContextProvider } from "./listings-table/PortfolioTableListingsTableContext.react"

type Item = PartiallyOptional<PortfolioTableRowProps, "item"> & {
  itemKey: string | undefined
}

type Props = {
  items: PortfolioTable_items$key | null
  accountAddress: string
  mode: PortfolioViewMode
  pageSize: number
  totalItemCount: number
  selectedSort?: Sort
  onSort: (sort: Sort) => unknown
} & Pick<VirtualizedTableHeaderProps, "stickyTop"> &
  Partial<Pick<VirtualizedTableProps<Item>, "itemHeightEstimate">> &
  Omit<VirtualizedTablePaginationProps, "paginationThreshold">

const COLUMN_TO_SORT_BY_MAP: Record<SortablePortfolioColumn, SearchSortBy> = {
  listingPrice: "UNIT_PRICE",
  bestOffer: "BEST_BID",
  floorPrice: "FLOOR_PRICE",
}

const SORT_BY_TO_COLUMN_MAP: Partial<
  Record<SearchSortBy, SortablePortfolioColumn>
> = {
  UNIT_PRICE: "listingPrice",
  BEST_BID: "bestOffer",
  FLOOR_PRICE: "floorPrice",
}

const RenderRow = ({ item }: TableRowRenderProps<Item>) => {
  return item.item ? (
    <PortfolioTableRow item={item.item} {...item} />
  ) : (
    <PortfolioTableRow.Skeleton mode={item.mode} />
  )
}

const PortfolioTableBase = ({
  items: itemsKey,
  accountAddress,
  selectedSort,
  itemHeightEstimate = ITEM_HEIGHT_ESTIMATE,
  mode,
  pageSize,
  stickyTop,
  totalItemCount,
  hasNext,
  isLoadingNext,
  loadNext,
  onSort,
}: Props) => {
  const data = useFragment(
    graphql`
      fragment PortfolioTable_items on ItemType
      @relay(plural: true)
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        showOwnerActions: { type: "Boolean!" }
      ) {
        __typename
        relayId
        ... on AssetType {
          ownership(identity: $identity) {
            isPrivate
            quantity
          }
        }
        ...PortfolioTableRow_item
          @arguments(identity: $identity, showOwnerActions: $showOwnerActions)
        ...useAssetSelectionStorage_item
          @arguments(identity: $identity, withBestAsk: true, withBestBid: true)
        # eslint-disable-next-line relay/must-colocate-fragment-spreads used by tracking methods
        ...itemEvents_dataV2
      }
    `,
    itemsKey,
  )

  const { exclude: recentlyHiddenItems } = useContext(AccountPageContext)
  const isHiddenItem = useCallback(
    (itemRelayId: string) => recentlyHiddenItems.includes(itemRelayId),
    [recentlyHiddenItems],
  )

  const skeletonCount = useMemo(() => {
    if (!data) {
      return pageSize
    }
    const itemCount = data.length
    const remainingItemCount = totalItemCount - itemCount
    return hasNext ? Math.min(remainingItemCount, pageSize) : 0
  }, [data, pageSize, hasNext, totalItemCount])

  // Start paginating when the user scrolls within the last 2 pages of data.
  // Also tries to load at least 3 pages of data on component load.
  const paginationThreshold = pageSize * 2 + skeletonCount

  const [expandedRowRelayIds, setExpandedRowRelayIds] = useState<Set<string>>(
    new Set(),
  )

  const isExpanded = useCallback(
    (relayId: string) => expandedRowRelayIds.has(relayId),
    [expandedRowRelayIds],
  )

  const toggleExpandedRow = useCallback(
    (relayId: string) =>
      setExpandedRowRelayIds(prev => {
        const updated = new Set(prev)
        if (prev.has(relayId)) {
          updated.delete(relayId)
        } else {
          updated.add(relayId)
        }
        return updated
      }),
    [],
  )

  const handleRowClick = useCallback(
    (relayId: string) => {
      const item = data?.find(item => item.relayId === relayId)
      if (!item) {
        return
      }

      const willExpand = !isExpanded(relayId)
      toggleExpandedRow(relayId)
      trackClickPortfolioTableRow(item, {
        expanded: willExpand,
      })
    },
    [data, isExpanded, toggleExpandedRow],
  )

  const { onBatchSelect, onDelete, onDeleteAll, onSelect } =
    useAssetContextActions()

  const selectedState = useAssetSelectionState(totalItemCount)
  const getSelectedItemsCount = useGetAssetContextSelectedIdsCount()

  const handleRowSelect = useCallback(
    (relayId: string, selected: boolean) => {
      const item = data?.find(item => item.relayId === relayId)
      if (!item) {
        return
      }

      if (selected) {
        onSelect(readAssetSelectionItem(item))
      } else {
        onDelete([relayId])
      }
      trackSelectPortfolioTableRow(item, {
        selected,
        selectedCount: getSelectedItemsCount(),
        totalItemCount,
      })
    },
    [data, getSelectedItemsCount, onDelete, onSelect, totalItemCount],
  )

  const handleSelectAll = useCallback(
    (selected: boolean) => {
      if (!data) {
        return
      }
      if (selected) {
        onBatchSelect(
          data
            .filter(item => !isHiddenItem(item.relayId))
            .map(readAssetSelectionItem),
        )
      } else {
        onDeleteAll()
      }
      trackSelectAllPortfolioTableRows({
        selected,
        selectedCount: getSelectedItemsCount(),
        totalItemCount,
      })
    },
    [
      data,
      onBatchSelect,
      onDeleteAll,
      getSelectedItemsCount,
      totalItemCount,
      isHiddenItem,
    ],
  )

  const handleSort = useCallback(
    (column: SortablePortfolioColumn, ascending: boolean) => {
      onSort({
        sortBy: COLUMN_TO_SORT_BY_MAP[column],
        sortAscending: ascending,
      })
    },
    [onSort],
  )

  const itemKey = useCallback((item: Item | undefined) => item?.itemKey, [])

  const selectedSortColumn = selectedSort?.sortBy
    ? SORT_BY_TO_COLUMN_MAP[selectedSort.sortBy]
    : undefined
  const convertedSort =
    selectedSort && selectedSortColumn
      ? {
          column: selectedSortColumn,
          ascending: selectedSort.sortAscending,
        }
      : undefined

  const items = useMemo(() => {
    const length = (data?.length ?? 0) + skeletonCount
    return Array(length)
      .fill(undefined)
      .map((_, i) => {
        const item = data ? data[i] : undefined

        return {
          accountAddress,
          item,
          expanded: item ? isExpanded(item.relayId) : false,
          mode,
          showChain: selectedState !== "none",
          onClick: handleRowClick,
          onSelect: handleRowSelect,
          itemKey: item?.relayId,
        }
      })
      .filter(({ item }) => {
        const itemRelayId = item?.relayId || ""
        if (isHiddenItem(itemRelayId)) {
          return false
        }

        if (item?.__typename === "AssetType") {
          const isPrivate = Boolean(item.ownership?.isPrivate)
          const isOwner = bn(item.ownership?.quantity ?? "0").gt(0)
          return !isPrivate && isOwner
        }
        return true
      })
  }, [
    data,
    skeletonCount,
    isExpanded,
    mode,
    selectedState,
    handleRowClick,
    handleRowSelect,
    isHiddenItem,
    accountAddress,
  ])

  if (!items.length) {
    return null
  }

  return (
    <PortfolioTableContextProvider>
      <PortfolioTableListingsTableContextProvider>
        <VirtualizedTable
          className="min-h-full"
          hasNext={hasNext}
          header={
            <PortfolioTableHeader
              itemCount={
                data?.length ? totalItemCount - recentlyHiddenItems.length : 0
              }
              mode={mode}
              selectedSort={convertedSort}
              selectedState={selectedState}
              stickyTop={stickyTop}
              onSelectAll={handleSelectAll}
              onSort={handleSort}
            />
          }
          isLoadingNext={isLoadingNext}
          itemHeightEstimate={itemHeightEstimate}
          itemKey={itemKey}
          items={items}
          loadNext={loadNext}
          overscanBy={10}
          paginationThreshold={paginationThreshold}
          renderRow={RenderRow}
        />
      </PortfolioTableListingsTableContextProvider>
    </PortfolioTableContextProvider>
  )
}

type SkeletonProps = Pick<Props, "mode" | "pageSize" | "stickyTop">

const PortfolioTableSkeleton = ({
  mode,
  pageSize,
  stickyTop,
}: SkeletonProps) => {
  const clearSearchContext = useClearSearchContext()

  useMountEffect(() => {
    clearSearchContext()
  })

  return (
    <PortfolioTableBase
      accountAddress=""
      hasNext
      isLoadingNext
      // Does not reuse ITEM_HEIGHT_ESTIMATE, with a max value for expanded row,
      // to ensure skeletons do not render as the expanded row height.
      itemHeightEstimate={{ min: COLLAPSED_ROW_HEIGHT }}
      items={[]}
      loadNext={noop}
      mode={mode}
      pageSize={pageSize}
      stickyTop={stickyTop}
      totalItemCount={pageSize * 5}
      onSort={noop}
    />
  )
}

export const PortfolioTable = Object.assign(PortfolioTableBase, {
  Skeleton: PortfolioTableSkeleton,
})
