/**
 * @generated SignedSource<<26005bd2fd3d1c0517d907ab94961d46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableDifferenceCell_item$data = {
  readonly __typename: string;
  readonly orderData?: {
    readonly bestAskForOwner: {
      readonly __typename: "OrderV2Type";
    } | null;
    readonly bestAskV2: {
      readonly __typename: "OrderV2Type";
    } | null;
  };
  readonly pnl?: {
    readonly pnlPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  } | null;
  readonly relayId: string;
  readonly " $fragmentType": "PortfolioTableDifferenceCell_item";
};
export type PortfolioTableDifferenceCell_item$key = {
  readonly " $data"?: PortfolioTableDifferenceCell_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableDifferenceCell_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableDifferenceCell_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ESOrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestAskV2",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": "bestAskForOwner",
              "args": [
                {
                  "kind": "Variable",
                  "name": "byAddress",
                  "variableName": "identity"
                }
              ],
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestAskV2",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PnLType",
          "kind": "LinkedField",
          "name": "pnl",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentPriceInputType",
              "kind": "LinkedField",
              "name": "pnlPrice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "8ed8244fe3b83508346317e31d9eb259";

export default node;
