export type PortfolioColumn =
  | "item"
  | "listingPrice"
  | "bestOffer"
  | "floorPrice"
  | "difference"
  | "cost"
  | "options"

const SORTABLE_COLUMNS: Extract<
  PortfolioColumn,
  "listingPrice" | "bestOffer" | "floorPrice"
>[] = ["listingPrice", "bestOffer", "floorPrice"]
export type SortablePortfolioColumn = (typeof SORTABLE_COLUMNS)[number]

export const isSortablePortfolioColumn = (
  column: PortfolioColumn,
): column is SortablePortfolioColumn =>
  SORTABLE_COLUMNS.includes(column as SortablePortfolioColumn)

const MOBILE_COLUMNS: Extract<
  PortfolioColumn,
  "listingPrice" | "bestOffer" | "floorPrice" | "difference" | "cost"
>[] = ["listingPrice", "bestOffer", "floorPrice", "difference", "cost"]
export type MobilePortfolioColumn = (typeof MOBILE_COLUMNS)[number]

export const isMobilePortfolioColumn = (
  column: PortfolioColumn,
): column is MobilePortfolioColumn =>
  MOBILE_COLUMNS.includes(column as MobilePortfolioColumn)

export type TraitColumn =
  | "trait"
  | "listedCount"
  | "floorPrice"
  | "lastSale"
  | "lastSaleDate"

export type ListingsColumn =
  | "listingPrice"
  | "usdPrice"
  | "quantity"
  | "expiration"
  | "actions"

export type PortfolioViewMode = "owner" | "public"

export type PortfolioSelectedState = "none" | "some" | "all"
