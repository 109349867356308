import React from "react"
import { Flex, Media } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { AccountOrCollectionInfo } from "@/components/layout/AccountOrCollectionPage/components/AccountOrCollectionInfo"
import { Block } from "@/design-system/Block"
import { Skeleton } from "@opensea/ui-kit"
import { ProfileInfo_data$key } from "@/lib/graphql/__generated__/ProfileInfo_data.graphql"

type Props = {
  data: ProfileInfo_data$key | null
  isLoading?: boolean
}

export const ProfileInfo = ({ data: dataKey, isLoading }: Props) => {
  const data = useFragment(
    graphql`
      fragment ProfileInfo_data on AccountType {
        bio
      }
    `,
    dataKey,
  )

  if (!data?.bio) {
    return null
  }

  const shared = {
    text: data.bio,
    mobileLines: 1,
    desktopLines: 1,
  }

  if (isLoading) {
    return (
      <Flex className="flex-col">
        <Block marginTop="8px">
          <Skeleton.Line className="h-9 w-[350px]" />
        </Block>
      </Flex>
    )
  }

  return (
    <>
      <Media greaterThanOrEqual="sm">
        <Block maxWidth="900px">
          <AccountOrCollectionInfo {...shared} />
        </Block>
      </Media>
      <Media lessThan="sm">
        <AccountOrCollectionInfo {...shared} />
      </Media>
    </>
  )
}
