import { commitLocalUpdate, graphql } from "react-relay"
import { Environment } from "relay-runtime"
import {
  setLocalItemBestAsk_order$data,
  setLocalItemBestAsk_order$key,
} from "@/lib/graphql/__generated__/setLocalItemBestAsk_order.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

type SetLocalItemBestAskProps = {
  listing: setLocalItemBestAsk_order$key
}

const readLocalItemBestAskData =
  inlineFragmentize<setLocalItemBestAsk_order$data>(
    graphql`
      fragment setLocalItemBestAsk_order on OrderV2Type
      @argumentDefinitions(showOwnerActions: { type: "Boolean!" })
      @inline {
        relayId
        item {
          relayId
        }
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...PortfolioTableListingCell_bestAskV2
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...PortfolioTableListButton_bestAskV2
          @arguments(showOwnerActions: $showOwnerActions)
      }
    `,
    data => data,
  )

export const setLocalItemBestAsk = (
  environment: Environment,
  { listing: listingKey }: SetLocalItemBestAskProps,
) => {
  /**
   * Cancelling listings can take a while on the backend,
   * this leaves bestAskV2 with a stale listing. Since we have
   * a list of the users listings we can update bestAskV2 as soon
   * as the listing is cancelled.
   *
   * NOTE: This can be prone to issues since the frontend
   * is not the authoritative source of truth for bestAskV2.
   */
  const listing = readLocalItemBestAskData(listingKey)

  commitLocalUpdate(environment, store => {
    const listingRecord = store.get(listing.relayId) || null
    store
      .get(listing.item.relayId)
      ?.getLinkedRecord("orderData")
      ?.setLinkedRecord(listingRecord, "bestAskV2")
  })
}
