/**
 * @generated SignedSource<<05d762ec6dbe97c8dbd4266b89d83c6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListingsTable_data$data = {
  readonly orders: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly relayId: string;
        readonly " $fragmentSpreads": FragmentRefs<"CancelOrderButton_data" | "EditListingButton_listing" | "PortfolioTableListingsTableRow_data" | "setLocalItemBestAsk_order">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "PortfolioTableListingsTable_data";
};
export type PortfolioTableListingsTable_data$key = {
  readonly " $data"?: PortfolioTableListingsTable_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListingsTable_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showOwnerActions"
},
v1 = [
  "orders"
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "showOwnerActions",
    "variableName": "showOwnerActions"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": 5,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isExpired"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isValid"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maker"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "makerArchetype"
    },
    (v0/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v1/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v1/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PortfolioTableListingsTablePaginationQuery.graphql')
    }
  },
  "name": "PortfolioTableListingsTable_data",
  "selections": [
    {
      "alias": "orders",
      "args": [
        {
          "kind": "Variable",
          "name": "isExpired",
          "variableName": "isExpired"
        },
        {
          "kind": "Variable",
          "name": "isValid",
          "variableName": "isValid"
        },
        {
          "kind": "Variable",
          "name": "maker",
          "variableName": "maker"
        },
        {
          "kind": "Variable",
          "name": "makerArchetype",
          "variableName": "makerArchetype"
        },
        {
          "kind": "Variable",
          "name": "sortAscending",
          "variableName": "sortAscending"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        }
      ],
      "concreteType": "OrderV2TypeConnection",
      "kind": "LinkedField",
      "name": "__PortfolioTableListingsTable_orders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2TypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "setLocalItemBestAsk_order",
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "item",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PortfolioTableListingCell_bestAskV2"
                    },
                    {
                      "args": (v3/*: any*/),
                      "kind": "FragmentSpread",
                      "name": "PortfolioTableListButton_bestAskV2"
                    }
                  ],
                  "args": (v3/*: any*/),
                  "argumentDefinitions": [
                    (v0/*: any*/)
                  ]
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PortfolioTableListingsTableRow_data"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CancelOrderButton_data"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EditListingButton_listing"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fa3b22779e11c2fa614923d154bf16fc";

export default node;
