import React, { Dispatch, SetStateAction, useState } from "react"
import {
  Icon,
  SpaceBetween,
  Text,
  UnstyledButton,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { AutoHiddenBannerDismissMutation } from "@/lib/graphql/__generated__/AutoHiddenBannerDismissMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { media, themeVariant } from "@/styles/styleUtils"

export const AutoHiddenBanner = () => {
  const [show, setShow] = useState(true)
  if (!show) {
    return null
  }

  return (
    <StyledAlert>
      <SpaceBetween className="hidden w-full lg:flex">
        <Flex className="items-center">
          <BannerText showLink />
        </Flex>
        <Flex>
          <ViewItemsButton setShow={setShow} />
          <Flex className="ml-10">
            <CloseButton setShow={setShow} />
          </Flex>
        </Flex>
      </SpaceBetween>

      <SmallContainer>
        <SpaceBetween className="mb-3 items-start">
          <BannerText />
          <CloseButton setShow={setShow} />
        </SpaceBetween>
        <Flex className="items-center">
          <Flex className="mr-3">
            <ViewItemsButton setShow={setShow} />
          </Flex>
          <LearnMoreLink />
        </Flex>
      </SmallContainer>
    </StyledAlert>
  )
}

const BannerText = ({ showLink }: { showLink?: boolean }) => {
  const t = useTranslate("account")
  return (
    <Text className="mr-2 block">
      {t(
        "banner.text",
        "Some transferred items have been moved to the “hidden” tab.",
      )}{" "}
      {showLink ? <LearnMoreLink /> : null}
    </Text>
  )
}

const useOnClose = ({
  setShow,
}: {
  setShow: Dispatch<SetStateAction<boolean>>
}) => {
  const { attempt } = useToasts()
  const { mutate } = useGraphQL()
  return async () =>
    await attempt(async () => {
      setShow(false)
      await mutate<AutoHiddenBannerDismissMutation>(
        graphql`
          mutation AutoHiddenBannerDismissMutation {
            accounts {
              dismissAutoHiddenBanner {
                showAutoHiddenBanner
              }
            }
          }
        `,
        {},
        {
          shouldAuthenticate: true,
        },
      )
    })
}

const CloseButton = ({
  setShow,
}: {
  setShow: Dispatch<SetStateAction<boolean>>
}) => {
  const onClose = useOnClose({ setShow })
  return (
    <UnstyledButton onClick={onClose}>
      <Icon className="text-primary" value="close" />
    </UnstyledButton>
  )
}

const ViewItemsButton = ({
  setShow,
}: {
  setShow: Dispatch<SetStateAction<boolean>>
}) => {
  const t = useTranslate("account")
  const onClose = useOnClose({ setShow })
  return (
    <StyledButton
      href="/account?tab=private&search[isAutoHidden]=true"
      onClick={onClose}
    >
      {t("banner.viewItemsLink", "View items")}
    </StyledButton>
  )
}

const LearnMoreLink = () => {
  const t = useTranslate("account")
  return (
    <Link href="https://support.opensea.io/articles/8867101">
      {t("banner.learnMoreLink", "Learn more")}
    </Link>
  )
}

const StyledAlert = styled(Alert)`
  padding: 20px;
  border-color: transparent;
  margin-bottom: 16px;

  ${media({
    lg: css`
      padding: 12px 24px;
    `,
  })}

  ${props =>
    themeVariant({
      variants: {
        light: {
          backgroundColor: props.theme.colors.withOpacity.fog.medium,
        },
        dark: {
          backgroundColor: props.theme.colors.withOpacity.fog.light,
        },
      },
    })}
`

const StyledButton = styled(Button)`
  font-size: 14px;
  min-width: 135px;
`

const SmallContainer = styled(Block)`
  width: 100%;
  ${media({
    lg: css`
      display: none;
    `,
  })}
`
