/**
 * @generated SignedSource<<93e1fd1736117da28dc6a1e8239a14b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AutoHiddenBannerDismissMutation$variables = {};
export type AutoHiddenBannerDismissMutation$data = {
  readonly accounts: {
    readonly dismissAutoHiddenBanner: {
      readonly showAutoHiddenBanner: boolean;
    };
  };
};
export type AutoHiddenBannerDismissMutation = {
  response: AutoHiddenBannerDismissMutation$data;
  variables: AutoHiddenBannerDismissMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showAutoHiddenBanner",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AutoHiddenBannerDismissMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMutationType",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "dismissAutoHiddenBanner",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AutoHiddenBannerDismissMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMutationType",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "dismissAutoHiddenBanner",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "594f749c8969bdd15d067309ff8fcf21",
    "id": null,
    "metadata": {},
    "name": "AutoHiddenBannerDismissMutation",
    "operationKind": "mutation",
    "text": "mutation AutoHiddenBannerDismissMutation {\n  accounts {\n    dismissAutoHiddenBanner {\n      showAutoHiddenBanner\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "76affe3cf2587a92ed74cc2157d3a096";

export default node;
