/**
 * @generated SignedSource<<fbfdbd71a5a312e34e8e7acb04e12960>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileContainer_data$data = {
  readonly account: {
    readonly address: string;
    readonly bannerImageUrl: string | null;
    readonly displayName: string | null;
    readonly metadata: {
      readonly isBanned: boolean;
      readonly isDisabled: boolean;
    } | null;
    readonly user: {
      readonly publicUsername: string | null;
      readonly username: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"AccountOrCollectionPageHeader_account" | "AccountOrCollectionProfileImage_data" | "AccountTrackingContext_account" | "ApplyForVerificationBanner_data" | "ProfileActionBar_data" | "ProfileInfo_data" | "Subheader_account" | "profilePageQueries_account" | "profilePageQueries_featured" | "socialLinksData">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignAnnouncementModal_data" | "ProfileMetadata_data">;
  readonly " $fragmentType": "ProfileContainer_data";
};
export type ProfileContainer_data$key = {
  readonly " $data"?: ProfileContainer_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileContainer_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "collection"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includePrivateAssetCount"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileContainer_data",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bannerImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserType",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publicUsername",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountMetadataType",
          "kind": "LinkedField",
          "name": "metadata",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBanned",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDisabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountOrCollectionPageHeader_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileInfo_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Subheader_account"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ApplyForVerificationBanner_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileActionBar_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountOrCollectionProfileImage_data"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "socialLinksData",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountMetadataType",
              "kind": "LinkedField",
              "name": "metadata",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "instagramUsername",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "twitterUsername",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "websiteUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "connectedTwitterUsername",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "connectedInstagramUsername",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "includePrivateAssetCount",
              "variableName": "includePrivateAssetCount"
            }
          ],
          "kind": "FragmentSpread",
          "name": "profilePageQueries_account"
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "showContextMenu",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "profilePageQueries_featured"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountTrackingContext_account"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignAnnouncementModal_data"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "collection",
          "variableName": "collection"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "ProfileMetadata_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d1d7b0d40ab5aceb20af545a80df0b09";

export default node;
