import React from "react"
import { FlexEnd } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { ExpirationDate } from "@/components/orders/ExpirationDate"
import { PriceText } from "@/design-system/PriceText"
import { Table } from "@/design-system/Table"
import { PortfolioTableListingsTableRow_data$key } from "@/lib/graphql/__generated__/PortfolioTableListingsTableRow_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn, quantityDisplay } from "@/lib/helpers/numberUtils"
import { StyledTableCell } from "../styles"

type PortfolioTableListingsTableRowData =
  PortfolioTableListingsTableRow_data$key & {
    relayId: string
  }

type PortfolioTableListingsTableRowProps<
  T extends PortfolioTableListingsTableRowData,
> = {
  dataKey: T
  isFungible: boolean
  renderActions?: (order: T) => React.ReactNode
}

export const PortfolioTableListingsTableRow = <
  T extends PortfolioTableListingsTableRowData,
>({
  dataKey,
  isFungible = false,
  renderActions,
}: PortfolioTableListingsTableRowProps<T>) => {
  const data = useFragment<PortfolioTableListingsTableRow_data$key>(
    graphql`
      fragment PortfolioTableListingsTableRow_data on OrderV2Type {
        relayId
        perUnitPriceType {
          symbol
          unit
          usd
        }
        item {
          __typename
        }
        remainingQuantityType
        ...ExpirationDate_data
      }
    `,
    dataKey,
  )
  const { relayId, item, remainingQuantityType } = data

  const basePrice = bn(data.perUnitPriceType.usd)
  const currentPrice = bn(basePrice)

  if (item.__typename === "%other") {
    throw new Error("Unknown item type")
  }
  return (
    <Table.Row key={relayId}>
      <StyledTableCell>
        <PriceText
          subtleEmpty
          symbol={data.perUnitPriceType.symbol}
          unit={data.perUnitPriceType.unit}
        />
      </StyledTableCell>
      <StyledTableCell>
        <PriceText.Fiat subtleEmpty usd={currentPrice} />
      </StyledTableCell>
      {isFungible && (
        <StyledTableCell>
          {quantityDisplay(remainingQuantityType)}
        </StyledTableCell>
      )}
      <StyledTableCell>
        <ExpirationDate alwaysRelative dataKey={data} />
      </StyledTableCell>
      {renderActions && (
        <StyledTableCell>
          <FlexEnd>{renderActions(dataKey)}</FlexEnd>
        </StyledTableCell>
      )}
    </Table.Row>
  )
}
