/**
 * @generated SignedSource<<4c583a9a0f53e1e0d6bbe4bfc9277c2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableFloorPriceCell_item$data = {
  readonly __typename: "AssetBundleType";
  readonly bundleCollection: {
    readonly statsV2: {
      readonly floorPrice: {
        readonly symbol: string;
        readonly unit: string;
      } | null;
    };
  } | null;
  readonly " $fragmentType": "PortfolioTableFloorPriceCell_item";
} | {
  readonly __typename: "AssetType";
  readonly assetCollection: {
    readonly statsV2: {
      readonly floorPrice: {
        readonly symbol: string;
        readonly unit: string;
      } | null;
    };
  };
  readonly " $fragmentType": "PortfolioTableFloorPriceCell_item";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "PortfolioTableFloorPriceCell_item";
};
export type PortfolioTableFloorPriceCell_item$key = {
  readonly " $data"?: PortfolioTableFloorPriceCell_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableFloorPriceCell_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionStatsV2Type",
    "kind": "LinkedField",
    "name": "statsV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PriceType",
        "kind": "LinkedField",
        "name": "floorPrice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "symbol",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableFloorPriceCell_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "assetCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "bundleCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "5d54c428a9b0ee91264e464b8861d448";

export default node;
