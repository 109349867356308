/**
 * @generated SignedSource<<6778d3ca111358241d1baa7f4bfb5f49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Subheader_account$data = {
  readonly user: {
    readonly dateJoined: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AddressPill_data">;
  readonly " $fragmentType": "Subheader_account";
};
export type Subheader_account$key = {
  readonly " $data"?: Subheader_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"Subheader_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Subheader_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateJoined",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddressPill_data"
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "5aae5dd79a857a56d83508a3c8eae5b2";

export default node;
