import React from "react"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { DealsTabContent } from "@/features/deals/manage-deals/DealsTabContent.react"
import { DealsPageQuery } from "lib/graphql/__generated__/DealsPageQuery.graphql"
import { GraphQLInitialProps } from "lib/graphql/graphql"
import { GraphQLNextPage } from "lib/graphql/GraphQLPage.react"
import QP from "lib/qp/qp"
import { ProfileContainer } from "../../components/ProfileContainer"
import { cachePolicy, getInitialPropsAccountPageQuery } from "../utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
}

export const DealsPage: GraphQLNextPage<DealsPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
}) => {
  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="deals"
      tabContent={<DealsTabContent identity={variables.identity} />}
    />
  )
}

const query = graphql`
  query DealsPageQuery(
    $identity: IdentityInputType!
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

DealsPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
  },
  async (
    { identifier },
    context,
  ): Promise<GraphQLInitialProps<DealsPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        tab: "deals",
      })

    const { includePrivateAssetCount, identity } = variables

    const pageVariables = {
      identity,
      includePrivateAssetCount,
    }

    const appInitialProps = await getAppInitialProps(context, {
      variables: pageVariables,
      query,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      variables: pageVariables,
    }
  },
)
