/**
 * @generated SignedSource<<ba3a7cc81934aff12bc3665a947e3f8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountVerificationRequestStatus = "APPROVED" | "DENIED" | "PENDING" | "%future added value";
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ApplyForVerificationBanner_data$data = {
  readonly canViewVerificationForm: boolean;
  readonly config: Config | null;
  readonly verificationRequest: {
    readonly status: AccountVerificationRequestStatus;
  } | null;
  readonly " $fragmentType": "ApplyForVerificationBanner_data";
};
export type ApplyForVerificationBanner_data$key = {
  readonly " $data"?: ApplyForVerificationBanner_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplyForVerificationBanner_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplyForVerificationBanner_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewVerificationForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountVerificationRequestType",
      "kind": "LinkedField",
      "name": "verificationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "24724e51984d8bef4721c6a9e8c7b712";

export default node;
