import { Icon } from "@opensea/ui-kit"
import styled from "styled-components"

// TODO(Design-System - kabeh2-os): Use IconButton when it updates
// to have a transparent background variant.
export const HoverIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.secondary};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.text.primary};
  }
`
