import { ChainIdentifier } from "@/hooks/useChains/types"
import { Search } from "@/hooks/useSearch"
import { RequiredKeys } from "@/lib/helpers/type"

export type DealViewer = "maker" | "taker" | "public"
export type FinalizedDealStatus = "accepted" | "cancelled" | "expired"

export type DealStatus =
  | "creating"
  | "active"
  | "inactive"
  | FinalizedDealStatus

export type DealAssetSearchState = RequiredKeys<
  Pick<
    Search,
    | "chains"
    | "collection"
    | "collections"
    | "query"
    | "resultModel"
    | "sortAscending"
    | "sortBy"
    | "stringTraits"
    | "safelistRequestStatuses"
  >
> &
  Required<Pick<Search, "identity">>

export type AssetIdToAssetQuantityRecord = Record<string, string | undefined>

export type PaymentAssetQuantity = {
  assetRelayId: string
  quantity: string
  chain: ChainIdentifier
  symbol: string
}

export const isFinalizedDeal = (
  status: DealStatus,
): status is FinalizedDealStatus => {
  const finalizedStatuses: FinalizedDealStatus[] = [
    "accepted",
    "cancelled",
    "expired",
  ]
  return finalizedStatuses.includes(status as FinalizedDealStatus)
}
