import React, { useState, useEffect } from "react"
import { Flex, FlexEnd, MaterialIcon, Switch, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { FilterBar } from "@/components/layout/AccountOrCollectionPage/components/FilterBar"
import { Panel } from "@/components/layout/Panel"
import { Orders, OrdersType } from "@/components/orders/Orders.react"
import { SearchPills } from "@/components/search/SearchPills.react"
import { ToggleFilterButton } from "@/components/search/ToggleFilterButton.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { Search, useSearch } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSearch } from "@/lib/analytics/events/searchEvents"
import { OfferSearchQuery } from "@/lib/graphql/__generated__/OfferSearchQuery.graphql"
import { OrderSortOption } from "@/lib/graphql/__generated__/OrdersQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getFilterCount } from "../assets/AssetSearchFilter"
import OrderSearchFilter from "./OrderSearchFilter.react"
import { OrdersPanelTitle } from "./OrdersPanelTitle.react"

export type OfferSearchState = Pick<Search, "collections" | "identity">

type Props = {
  defaultState: OfferSearchState
  fixedState: Partial<OfferSearchState>
  initialState: Partial<OfferSearchState>
  isCurrentUser?: boolean
  offerSearchType: "made" | "received"
  variant?: "default" | "profile"
  phoenixSidebarOpen?: boolean
  path: string
}

export const OfferSearch = ({
  offerSearchType,
  variant = "default",
  isCurrentUser,
  phoenixSidebarOpen,
  defaultState,
  fixedState,
  initialState,
  path,
}: Props) => {
  const t = useTranslate("components")
  const [shouldFilterOffers, setShouldFilterOffers] = useState(
    offerSearchType === "received" && variant !== "profile",
  )

  const { searchState, clear, update } = useSearch({
    defaultState,
    fixedState,
    initialState,
    path,
  })

  const { collections } = searchState

  const [data] = useNoSuspenseLazyLoadQuery<OfferSearchQuery>(
    graphql`
      query OfferSearchQuery($collections: [CollectionSlug!]) {
        ...SearchPills_data @arguments(collections: $collections)
      }
    `,
    {
      collections: searchState.collections,
    },
    { skip: !searchState.collections?.length },
  )

  useEffect(() => {
    trackSearch({
      type: "OfferSearch",
      path: window.location.pathname,
      queryString: window.location.search,
      pageIndex: 0,
      itemDisplayVariant: undefined,
      ...searchState,
    })
  }, [searchState])

  const toggleOffersFilter = () => setShouldFilterOffers(prev => !prev)
  const showOfferSettings =
    isCurrentUser && variant === "profile" && offerSearchType === "received"

  const renderOffers = () => {
    const commonVariables = {
      filterByOrderRules: shouldFilterOffers,
      isBid: true,
      isExpired: false,
      makerAssetIsPayment: true,
      takerAssetCollections: collections || undefined,
      sortBy: "OPENED_AT" as OrderSortOption,
    }

    const offersMade = {
      title: isCurrentUser ? (
        <OrdersPanelTitle title={t("offerSearch.panelTitle", "Offers made")} />
      ) : (
        t("offerSearch.title", "Offers made")
      ),
      icon: "call_made" as MaterialIcon,
      variables: {
        isValid: !isCurrentUser || undefined,
        includeInvalidBids: !!isCurrentUser,
        maker: searchState.identity,
        expandedMode: true,
        includeCriteriaOrders: true,
      },
    }

    const offersReceived = {
      title: (
        <>
          {t("offerSearch.offersReceived.title", "Offers received")}
          <InfoIcon
            overrides={{
              Button: {
                style: { marginLeft: "4px" },
              },
              Icon: {
                size: 16,
              },
              Tooltip: {
                maxWidth: 280,
              },
            }}
            tooltipContent={t(
              "offerSearch.offersReceived.description",
              "Only single-item offers appear here. You can view collection offers on your item's page.",
            )}
          />
        </>
      ),
      icon: "call_received" as MaterialIcon,
      variables: {
        isValid: true,
        excludeMaker: searchState.identity,
        takerAssetIsOwnedBy: searchState.identity,
        expandedMode: true,
      },
    }

    const selectedVariant =
      offerSearchType === "received" ? offersReceived : offersMade

    return (
      <Block marginTop={showOfferSettings ? undefined : { _: "16px", lg: "0" }}>
        <Panel
          icon={selectedVariant.icon}
          id="offer-search-1"
          isContentPadded={false}
          mode="always-open"
          title={selectedVariant.title}
        >
          <Orders
            hideCta={!isCurrentUser}
            isCurrentUser={isCurrentUser}
            mode={OrdersType.expanded}
            scrollboxClassName="OfferSearch--offers-scrollbox"
            side="bid"
            variables={{
              ...commonVariables,
              ...selectedVariant.variables,
            }}
          />
        </Panel>
      </Block>
    )
  }

  const renderFilter = (renderFn?: (content: JSX.Element) => JSX.Element) => {
    return (
      <OrderSearchFilter
        clear={clear}
        renderFn={renderFn}
        setState={update}
        state={searchState}
      />
    )
  }

  const renderSearchPills = () => {
    return searchState.collections && !!searchState.collections.length ? (
      <Block marginBottom="24px" marginTop="8px">
        <SearchPills
          clear={clear}
          collectionFilter={searchState.collections}
          dataKey={data}
          update={update}
        />
      </Block>
    ) : null
  }

  const renderOfferSettings = () => (
    <FlexEnd className="mb-6 mt-4 lg:mt-0">
      <Tooltip
        content={t(
          "offerSearch.hidden.tooltip",
          "Some offers are hidden based on your configured minimum offer",
        )}
      >
        <Flex className="mr-3 items-center">
          <Text
            asChild
            className="mr-2"
            color="secondary"
            size="small"
            weight="semibold"
          >
            <label htmlFor="show-all-offers-toggle">
              {t("offerSearch.hidden.showAll", "Show all offers")}
            </label>
          </Text>
          <Switch
            checked={!shouldFilterOffers}
            id="show-all-offers-toggle"
            onCheckedChange={toggleOffersFilter}
          />
        </Flex>
      </Tooltip>
      <Tooltip
        content={t("offerSearch.settings.title", "Manage offer settings")}
      >
        <Block marginLeft="12px">
          <Button
            href="/account/settings?tab=offers"
            icon="settings"
            overrides={{
              Icon: { props: { variant: "outlined" } },
            }}
            variant="secondary"
          />
        </Block>
      </Tooltip>
    </FlexEnd>
  )

  const numFiltersApplied = getFilterCount(searchState)

  return (
    <>
      <FilterBar
        filterToggle={
          <ToggleFilterButton numFiltersApplied={numFiltersApplied} />
        }
      />

      <Flex className="w-full">
        {renderFilter()}
        <OffersContainer showLeftBorder={!!phoenixSidebarOpen}>
          {showOfferSettings && renderOfferSettings()}
          {renderSearchPills()}
          {renderOffers()}
        </OffersContainer>
      </Flex>
    </>
  )
}

const OffersContainer = styled(Block)<{
  showLeftBorder: boolean
}>`
  width: 100%;
  overflow: hidden;
  border-left: 1px solid
    ${props =>
      props.showLeftBorder
        ? props.theme.colors.components.border.level2
        : "transparent"};

  .OfferSearch--offers-scrollbox {
    max-height: calc(100vh - 400px);
  }
`
