import { useFragment } from "react-relay"
import { ProfilePageAccountFragment } from "@/features/account/queries/profilePageQueries"
import { profilePageQueries_account$key } from "@/lib/graphql/__generated__/profilePageQueries_account.graphql"

type Props = {
  accountKey: profilePageQueries_account$key | null
}

export const useProfileDeals = ({ accountKey }: Props) => {
  const accountFragment = useFragment(ProfilePageAccountFragment, accountKey)
  const dealCount = accountFragment ? accountFragment.activeDealCount : null
  return dealCount ?? undefined
}
