import React, { useMemo } from "react"
import styled from "styled-components"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { Button } from "@/design-system/Button"
import { MobileDropdown } from "@/features/account/components/MobileDropdown"
import { VariantOption } from "@/features/account/types"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { trackClickItemCardVariantToggle } from "@/lib/analytics/events/itemEvents"

type Props = {
  currentCardVariant?: AssetCardVariant
  setCurrentCardVariant: React.Dispatch<
    React.SetStateAction<AssetCardVariant | undefined>
  >
}

export const AssetCardVariantDropdown = ({
  currentCardVariant,
  setCurrentCardVariant,
}: Props) => {
  const t = useTranslate("components")
  const { isListViewEnabled, setSelectedCardVariant } =
    useAccountOrCollectionPageContext()

  const options: VariantOption[] = useMemo(() => {
    const listViewOption: VariantOption = {
      avatar: { icon: "format_list_bulleted" },
      label: t("assetCardVariantDropdown.listView", "Table"),
      value: "list-view",
    }
    return [
      ...(isListViewEnabled ? [listViewOption] : []),
      {
        avatar: { icon: "window" },
        label: t("assetCardVariantDropdown.cozy", "Grid"),
        value: "cozy",
      },
      {
        avatar: { icon: "auto_awesome_mosaic" },
        label: t("assetCardVariantDropdown.", "Gallery"),
        value: "natural",
      },
    ]
  }, [t, isListViewEnabled])

  const selectedOption = options.find(
    option => option.value === currentCardVariant,
  )
  const { isOpen, open, close } = useIsOpen()

  return (
    <>
      <StyledButton
        aria-label={t("assetCardVariantDropdown.view", "View")}
        className="h-full"
        icon={selectedOption?.avatar.icon || "grid_view"}
        variant="secondary"
        onClick={open}
      />
      <MobileDropdown
        isOpen={isOpen}
        title={t("assetCardVariantDropdown.view", "View")}
        onClose={close}
      >
        <MobileDropdown.Select
          isSelected={option => option.value === currentCardVariant}
          options={options}
          onSelect={option => {
            setCurrentCardVariant(option.value)
            setSelectedCardVariant(option.value)
            trackClickItemCardVariantToggle({ variant: option.value })
            close()
          }}
        />
      </MobileDropdown>
    </>
  )
}

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.components.background.gray1};
  border: none;
  :hover:not([disabled]) {
    border: none;
  }
`
