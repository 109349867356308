/**
 * @generated SignedSource<<5111f4d50a0861e5c2642131b0c660a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableBuyButton_asset$data = {
  readonly orderData: {
    readonly bestAskV2: {
      readonly " $fragmentSpreads": FragmentRefs<"ItemAddToCartButton_order">;
    } | null;
  };
  readonly " $fragmentType": "PortfolioTableBuyButton_asset";
};
export type PortfolioTableBuyButton_asset$key = {
  readonly " $data"?: PortfolioTableBuyButton_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableBuyButton_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableBuyButton_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": [
            {
              "condition": "showOwnerActions",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ItemAddToCartButton_order"
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "56437ab4a0aa92037117f6732ab0e4b9";

export default node;
