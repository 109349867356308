/**
 * @generated SignedSource<<26bb17835faeebcf64e9c0d49002956d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealsSearchListContent_data$data = {
  readonly account: {
    readonly address: string;
    readonly swaps: {
      readonly count: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly relayId: string;
          readonly " $fragmentSpreads": FragmentRefs<"DealCard_deal">;
        } | null;
      } | null>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DealsSubTabs_account">;
  } | null;
  readonly " $fragmentType": "DealsSearchListContent_data";
};
export type DealsSearchListContent_data$key = {
  readonly " $data"?: DealsSearchListContent_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealsSearchListContent_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "account",
  "swaps"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "side"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortAscending"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "state"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./DealsSearchListPaginationQuery.graphql')
    }
  },
  "name": "DealsSearchListContent_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        }
      ],
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": "swaps",
          "args": [
            {
              "kind": "Variable",
              "name": "side",
              "variableName": "side"
            },
            {
              "kind": "Variable",
              "name": "sortAscending",
              "variableName": "sortAscending"
            },
            {
              "kind": "Variable",
              "name": "sortBy",
              "variableName": "sortBy"
            },
            {
              "kind": "Variable",
              "name": "state",
              "variableName": "state"
            }
          ],
          "concreteType": "SwapTypeConnection",
          "kind": "LinkedField",
          "name": "__DealsSearchListContent_swaps_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SwapTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SwapType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relayId",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DealCard_deal"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DealsSubTabs_account"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1766db0f9525648cf3f11d63e7b6fde8";

export default node;
