import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react"
import { useIsLessThanBreakpoint } from "@opensea/ui-kit"
import { noop } from "lodash"
import { trackSelectPortfolioTableMobileColumn } from "@/features/account/components/PortfolioTable/analytics"
import { DESKTOP_BREAKPOINT } from "@/features/account/components/PortfolioTable/constants"
import { MobilePortfolioColumn } from "@/features/account/components/PortfolioTable/types"

type PortfolioTableContext = {
  isDesktop: boolean
  mobileColumn: MobilePortfolioColumn
  setMobileColumn: (column: MobilePortfolioColumn) => unknown
}

const DEFAULT_CONTEXT: PortfolioTableContext = {
  isDesktop: true,
  mobileColumn: "floorPrice",
  setMobileColumn: noop,
}

const PortfolioTableContext = createContext(DEFAULT_CONTEXT)

type Props = {
  children: React.ReactNode
}

export const PortfolioTableContextProvider = ({ children }: Props) => {
  const isDesktop = !useIsLessThanBreakpoint(DESKTOP_BREAKPOINT)
  const [mobileColumn, setMobileColumn] = useState<MobilePortfolioColumn>(
    DEFAULT_CONTEXT.mobileColumn,
  )

  const setMobileColumnWithTracking = useCallback(
    (column: MobilePortfolioColumn) => {
      trackSelectPortfolioTableMobileColumn({ column })
      setMobileColumn(column)
    },
    [],
  )

  const value = useMemo(
    () => ({
      isDesktop,
      mobileColumn,
      setMobileColumn: setMobileColumnWithTracking,
    }),
    [isDesktop, mobileColumn, setMobileColumnWithTracking],
  )

  return (
    <PortfolioTableContext.Provider value={value}>
      {children}
    </PortfolioTableContext.Provider>
  )
}

export const usePortfolioTableContext = () => useContext(PortfolioTableContext)
