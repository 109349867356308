import React from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { AssetSearchList } from "@/components/search/assets/AssetSearchList.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { Block } from "@/design-system/Block"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import { AccountFavorites_data$key } from "@/lib/graphql/__generated__/AccountFavorites_data.graphql"
import {
  AccountFavoritesQuery,
  AccountFavoritesQuery$variables,
} from "@/lib/graphql/__generated__/AccountFavoritesQuery.graphql"
import { graphql, getNodes } from "@/lib/graphql/graphql"

const PAGE_SIZE = 16

type BaseProps = {
  assetCardVariant?: AssetCardVariant
}

type Props = BaseProps & {
  variables: AccountFavoritesQuery$variables
}

const LazyAccountFavoritesView = ({ variables, ...rest }: Props) => {
  const data = useLazyLoadQuery<AccountFavoritesQuery>(
    graphql`
      query AccountFavoritesQuery(
        $cursor: String
        $count: Int = 16
        $identity: IdentityInputType!
      ) {
        ...AccountFavorites_data
          @arguments(cursor: $cursor, count: $count, identity: $identity)
      }
    `,
    variables,
  )

  return <AccountFavoritesView data={data} {...rest} />
}

const AccountFavoritesView = ({
  assetCardVariant,
  data: dataKey,
}: BaseProps & { data: AccountFavorites_data$key }) => {
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment AccountFavorites_data on Query
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int!" }
        identity: { type: "IdentityInputType!" }
      )
      @refetchable(queryName: "AccountFavoritesPaginationQuery") {
        account(identity: $identity) {
          user {
            favoriteAssets(first: $count, after: $cursor)
              @connection(key: "AccountFavorites_favoriteAssets") {
              edges {
                node {
                  ...AssetSearchList_data
                }
              }
            }
          }
        }
      }
    `,
    dataKey,
  )

  const assets = getNodes(data.account?.user?.favoriteAssets)

  return (
    <Block className="w-full">
      <AssetSearchList
        cardVariant={assetCardVariant}
        data={assets}
        page={{
          hasMore: () => hasNext,
          isLoading: () => isLoadingNext,
          loadMore: count => loadNextToLoadMore({ loadNext, count }),
        }}
        pageSize={PAGE_SIZE}
        showAssetMediaEditions
        variant="grid"
      />
    </Block>
  )
}

export const AccountFavorites = (props: Props) => {
  return (
    <SsrSuspense
      fallback={
        <Block className="w-full">
          <AssetSearchList
            cardVariant={props.assetCardVariant}
            data={null}
            pageSize={PAGE_SIZE}
            showAssetMediaEditions
            variant="grid"
          />
        </Block>
      }
    >
      <LazyAccountFavoritesView {...props} />
    </SsrSuspense>
  )
}
