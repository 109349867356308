import React from "react"
import { usePaginationFragment, graphql } from "react-relay"
import { CreatedCollections } from "@/features/account/components/CreatedCollections/CreatedCollections"
import { AccountCollectionsPagination_data$key } from "@/lib/graphql/__generated__/AccountCollectionsPagination_data.graphql"
import { AccountCollectionsQuery } from "@/lib/graphql/__generated__/AccountCollectionsQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"

type Props = {
  data: AccountCollectionsPagination_data$key | null
}

export const AccountCollectionsPagination = ({ data: dataKey }: Props) => {
  const { data } = usePaginationFragment<
    AccountCollectionsQuery,
    AccountCollectionsPagination_data$key
  >(
    graphql`
      fragment AccountCollectionsPagination_data on Query
      @argumentDefinitions(
        creator: { type: "IdentityInputType!" }
        cursor: { type: "String" }
        first: { type: "Int", defaultValue: 12 }
      )
      @refetchable(queryName: "AccountCollectionsPaginationQuery") {
        collectionsByCreator(
          collectionCreator: $creator
          first: $first
          after: $cursor
        ) @connection(key: "AccountCollections_collectionsByCreator") {
          edges {
            node {
              ...CreatedCollections_data
            }
          }
        }
      }
    `,
    dataKey,
  )
  if (!data) {
    return null
  }
  return (
    <CreatedCollections collections={getNodes(data.collectionsByCreator)} />
  )
}
