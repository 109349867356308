/**
 * @generated SignedSource<<308d11e547c681d2fb827e6f9800d3d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetTokenStandard = "AUCTION_HOUSE" | "BITKEEP" | "BLUR" | "CONSIDERATION" | "CRYPTOKITTIES" | "CRYPTOPUNKS" | "CUSTOM" | "DECENTRALAND" | "ELEMENT" | "ENJIN_LEGACY" | "ERC1155" | "ERC20" | "ERC721" | "ETH_BRIDGE" | "JOEPEGS" | "KNOWNORIGIN" | "LOOKSRARE" | "MAKERSPLACE" | "METAPLEX" | "SEADROP" | "SOLANA" | "SPL" | "SUPERRARE" | "TOFU" | "TRANSFER_HELPER" | "TROVE" | "UNKNOWN" | "WYVERN" | "X2Y2" | "ZERO_EX" | "%future added value";
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DealCard_deal$data = {
  readonly closedAt: string | null;
  readonly createdDate: string;
  readonly maker: {
    readonly address: string;
    readonly displayName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileImage_data">;
  };
  readonly makerAssetQuantities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly chain: {
            readonly identifier: ChainIdentifier;
          };
          readonly decimals: number | null;
          readonly symbol: string | null;
          readonly tokenStandard: AssetTokenStandard | null;
          readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "StackedAssetMedia_assets">;
        };
        readonly quantity: string;
      } | null;
    } | null>;
  };
  readonly relayId: string;
  readonly taker: {
    readonly address: string;
    readonly displayName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileImage_data">;
  };
  readonly takerAssetQuantities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly chain: {
            readonly identifier: ChainIdentifier;
          };
          readonly decimals: number | null;
          readonly symbol: string | null;
          readonly tokenStandard: AssetTokenStandard | null;
          readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "StackedAssetMedia_assets">;
        };
        readonly quantity: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DealLink_swap" | "useDealStatus_swap">;
  readonly " $fragmentType": "DealCard_deal";
};
export type DealCard_deal$key = {
  readonly " $data"?: DealCard_deal$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealCard_deal">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ProfileImage_data"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 32
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssetQuantityTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetQuantityType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tokenStandard",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "decimals",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "symbol",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChainType",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "identifier",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssetMedia_asset"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StackedAssetMedia_assets"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealCard_deal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "taker",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "AssetQuantityTypeConnection",
      "kind": "LinkedField",
      "name": "makerAssetQuantities",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "makerAssetQuantities(first:32)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "AssetQuantityTypeConnection",
      "kind": "LinkedField",
      "name": "takerAssetQuantities",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "takerAssetQuantities(first:32)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealLink_swap"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDealStatus_swap"
    }
  ],
  "type": "SwapType",
  "abstractKey": null
};
})();

(node as any).hash = "32db019de22f7bd129dc0b294015d22b";

export default node;
