import React from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import { Table } from "@/design-system/Table"
import { CancelOrderButton } from "@/features/cancel-orders/components/CancelOrderButton.react"
import { ActiveListings_data$key } from "@/lib/graphql/__generated__/ActiveListings_data.graphql"
import { ActiveListingsPaginationQuery } from "@/lib/graphql/__generated__/ActiveListingsPaginationQuery.graphql"
import { ActiveListingsQuery } from "@/lib/graphql/__generated__/ActiveListingsQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { OrdersTable, useOrdersTableHeaders } from "../OrdersTable"
import { NoListings } from "./NoListings.react"

const PAGE_SIZE = 12
const ACTIVE_LISTINGS_TABLE_MAX_HEIGHT = "calc(100vh - 460px)"

type ActiveListingsProps = {
  address: string
  collections?: ReadonlyArray<string>
}

const LazyActiveListings = ({ collections, address }: ActiveListingsProps) => {
  const initialData = useLazyLoadQuery<ActiveListingsQuery>(
    graphql`
      query ActiveListingsQuery(
        $identity: AddressScalar!
        $collections: [CollectionSlug!]
        $count: Int!
        $cursor: String
      ) {
        ...ActiveListings_data
          @arguments(
            identity: $identity
            collections: $collections
            count: $count
            cursor: $cursor
          )
      }
    `,
    {
      identity: address,
      collections,
      count: PAGE_SIZE,
    },
    { fetchPolicy: "network-only" },
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment<
      ActiveListingsPaginationQuery,
      ActiveListings_data$key
    >(
      graphql`
        fragment ActiveListings_data on Query
        @argumentDefinitions(
          identity: { type: "AddressScalar!" }
          collections: { type: "[CollectionSlug!]" }
          cursor: { type: "String" }
          count: { type: "Int!" }
        )
        @refetchable(queryName: "ActiveListingsPaginationQuery") {
          getAccount(address: $identity) {
            activeListings(
              collections: $collections
              first: $count
              after: $cursor
            ) @connection(key: "ActiveListings_activeListings") {
              edges {
                node {
                  # eslint-disable-next-line relay/unused-fields
                  id
                  ...OrdersTableRow_data
                  ...CancelOrderButton_data
                }
              }
            }
          }
        }
      `,
      initialData,
    )

  return (
    <OrdersTable
      maxHeight={ACTIVE_LISTINGS_TABLE_MAX_HEIGHT}
      orders={getNodes(data.getAccount.activeListings)}
      page={{
        hasMore: () => hasNext,
        isLoading: () => isLoadingNext,
        loadMore: count => loadNextToLoadMore({ loadNext, count }),
      }}
      renderActions={order => {
        return (
          <CancelOrderButton
            dataKey={order}
            onOrderCanceled={() => {
              refetch({}, { fetchPolicy: "network-only" })
            }}
          />
        )
      }}
      renderEmpty={() => <NoListings />}
    />
  )
}

export const ActiveListings = (props: Omit<ActiveListingsProps, "address">) => {
  const connectedAddress = useConnectedAddress()

  const headers = useOrdersTableHeaders()

  const fallback = (
    <Table.Skeleton
      headers={headers}
      maxHeight={ACTIVE_LISTINGS_TABLE_MAX_HEIGHT}
      rowHeight="55px"
    />
  )

  if (!connectedAddress) {
    return fallback
  }

  return (
    <SsrSuspense fallback={fallback}>
      <LazyActiveListings {...props} address={connectedAddress} />
    </SsrSuspense>
  )
}
