/**
 * @generated SignedSource<<f4d858b09b4eeab5aeebe553b8a6d60d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableCostCell_item$data = {
  readonly __typename: string;
  readonly lastCostEvent: {
    readonly transaction: {
      readonly blockExplorerLink: string;
    } | null;
  } | null;
  readonly pnl: {
    readonly costPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  } | null;
  readonly " $fragmentType": "PortfolioTableCostCell_item";
};
export type PortfolioTableCostCell_item$key = {
  readonly " $data"?: PortfolioTableCostCell_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableCostCell_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableCostCell_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetEventType",
      "kind": "LinkedField",
      "name": "lastCostEvent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionType",
          "kind": "LinkedField",
          "name": "transaction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "blockExplorerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PnLType",
      "kind": "LinkedField",
      "name": "pnl",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentPriceInputType",
          "kind": "LinkedField",
          "name": "costPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "c0a5e32081d390aebfa7080813299a63";

export default node;
