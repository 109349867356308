/**
 * @generated SignedSource<<c329757c06911998b64bf563f3689a15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrdersTableRow_data$data = {
  readonly id: string;
  readonly item: {
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"FloorPriceDifference_item" | "ItemCell_data">;
  };
  readonly perUnitPriceType: {
    readonly " $fragmentSpreads": FragmentRefs<"FloorPriceDifference_perUnitPrice">;
  };
  readonly remainingQuantityType: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExpirationDate_data" | "OrderPrice" | "OrderUsdPrice">;
  readonly " $fragmentType": "OrdersTableRow_data";
};
export type OrdersTableRow_data$key = {
  readonly " $data"?: OrdersTableRow_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrdersTableRow_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrdersTableRow_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderUsdPrice"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemCell_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FloorPriceDifference_item"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FloorPriceDifference_perUnitPrice"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpirationDate_data"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "7afbb642a06e59432b6fd2c2eabae8ab";

export default node;
