import React from "react"
import { FlexColumn, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import {
  StackedAssetMedia,
  StackedAssetMediaProps,
} from "components/assets/StackedAssetMedia"
import { Block } from "design-system/Block"
import { Modal } from "design-system/Modal"
import { useMultiStepFlowContext } from "design-system/Modal/MultiStepFlow.react"
import { useTranslate } from "hooks/useTranslate"
import { CancelOrdersConfirmationModal_items$key } from "lib/graphql/__generated__/CancelOrdersConfirmationModal_items.graphql"
import { OrderSide } from "lib/graphql/__generated__/OrderType.graphql"
import { getNodes } from "lib/graphql/graphql"
import { UnreachableCaseError } from "lib/helpers/type"

type Props = {
  items: CancelOrdersConfirmationModal_items$key
  renderNext: () => React.ReactNode
  side: OrderSide
  type: StackedAssetMediaProps["type"]
}

export const CancelOrdersConfirmationModal = ({
  items: itemsKey,
  renderNext,
  side,
  type = "asset",
}: Props) => {
  const t = useTranslate("orders")
  const { onNext } = useMultiStepFlowContext()
  const items = useFragment<CancelOrdersConfirmationModal_items$key>(
    graphql`
      fragment CancelOrdersConfirmationModal_items on ItemType
      @relay(plural: true) {
        __typename
        ... on AssetType {
          ...StackedAssetMedia_assets
        }
        ... on AssetBundleType {
          assetQuantities(first: 18) {
            edges {
              node {
                asset {
                  ...StackedAssetMedia_assets
                }
              }
            }
          }
        }
      }
    `,
    itemsKey,
  )

  const assets = items.map(item => {
    switch (item.__typename) {
      case "AssetType":
        return item
      case "AssetBundleType":
        return getNodes(item.assetQuantities)[0].asset
      default:
        throw new UnreachableCaseError(item.__typename as never)
    }
  })

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="text-center" paddingX="64px">
        <Block marginTop="8px">
          <StackedAssetMedia assets={assets} type={type} />
        </Block>
        <FlexColumn className="mt-8 gap-2">
          <Modal.Body.Title>
            {side === "ASK"
              ? t(
                  "cancelOrders.confirmationModal.title.listings",
                  {
                    0: "Cancel all listings?",
                    one: "Cancel listing?",
                    other: "Cancel all listings?",
                  },
                  { count: items.length },
                )
              : t(
                  "cancelOrders.confirmationModal.title.offers",
                  {
                    0: "Cancel all offers?",
                    one: "Cancel offer?",
                    other: "Cancel all offers?",
                  },
                  { count: items.length },
                )}
          </Modal.Body.Title>
          <Text.Body className="block text-secondary" size="medium">
            {side === "ASK"
              ? t(
                  "cancelOrders.confirmationModal.body.listings",
                  {
                    0: "This will cancel the selected listings.",
                    one: "This will cancel the selected listing.",
                    other: "This will cancel the selected listings.",
                  },
                  {
                    count: items.length,
                  },
                )
              : t(
                  "cancelOrders.confirmationModal.body.offers",
                  {
                    0: "This will cancel the selected offers.",
                    one: "This will cancel the selected offer.",
                    other: "This will cancel the selected offers.",
                  },
                  {
                    count: items.length,
                  },
                )}
          </Text.Body>
        </FlexColumn>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          variant="primary"
          onClick={() => onNext(renderNext)}
        >
          {t("cancelOrders.confirmationModal.cta.confirm", "Confirm")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
