import React, { ComponentProps, Suspense, useEffect, useMemo } from "react"
import { useIsHydrated } from "@opensea/ui-kit"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { AssetSearchList } from "@/components/search/assets/AssetSearchList.react"
import { Sort } from "@/components/search/assets/AssetSearchSortDropdown.react"
import {
  AssetCardVariant,
  AssetSearchViewNoResults,
} from "@/components/search/assets/AssetSearchView"
import { filterItemsWithInconsistentListings } from "@/components/search/assets/utils/filterItems"
import { useUpdateSearchContext } from "@/components/search/utils/SearchContextProvider.react"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import { PortfolioTable } from "@/features/account/components/PortfolioTable"
import {
  AccountCollectedAssetSearchListPagination_data$data,
  AccountCollectedAssetSearchListPagination_data$key,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListPagination_data.graphql"
import { AccountCollectedAssetSearchListQuery } from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import { getNodes, graphql, Node, PageProps } from "@/lib/graphql/graphql"
import { AccountCollectedAssetSearchState } from "./AccountCollectedAssetSearch.react"

export type AccountCollectedSearchItems = Node<
  AccountCollectedAssetSearchListPagination_data$data["searchItems"]
>[]

type Props = {
  accountAddress: string
  cardVariant: AssetCardVariant | undefined
  clear: () => void
  isOwnerView: boolean
  searchState: AccountCollectedAssetSearchState
  setSort: (sort: Sort) => unknown
} & Pick<ComponentProps<typeof PortfolioTable>, "stickyTop">

type PaginationProps = Props & {
  data: AccountCollectedAssetSearchListPagination_data$key | null
}

const LIST_QUERY = graphql`
  query AccountCollectedAssetSearchListQuery(
    $chains: [ChainScalar!]
    $collections: [CollectionSlug!]
    $count: Int!
    $cursor: String
    $identity: IdentityInputType!
    $numericTraits: [TraitRangeType!]
    $paymentAssets: [PaymentAssetSymbol]
    $priceFilter: PriceFilterType
    $query: String
    $resultModel: SearchResultModel
    $sortAscending: Boolean
    $sortBy: SearchSortBy
    $stringTraits: [TraitInputType!]
    $toggles: [SearchToggle!]
    $showContextMenu: Boolean!
  ) {
    ...AccountCollectedAssetSearchListPagination_data
      @arguments(
        count: $count
        cursor: $cursor
        chains: $chains
        collections: $collections
        identity: $identity
        numericTraits: $numericTraits
        paymentAssets: $paymentAssets
        priceFilter: $priceFilter
        query: $query
        resultModel: $resultModel
        sortAscending: $sortAscending
        sortBy: $sortBy
        stringTraits: $stringTraits
        toggles: $toggles
        showContextMenu: $showContextMenu
      )
  }
`

const PAGINATION_FRAGMENT = graphql`
  fragment AccountCollectedAssetSearchListPagination_data on Query
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    chains: { type: "[ChainScalar!]" }
    collections: { type: "[CollectionSlug!]" }
    identity: { type: "IdentityInputType!" }
    numericTraits: { type: "[TraitRangeType!]" }
    paymentAssets: { type: "[PaymentAssetSymbol]" }
    priceFilter: { type: "PriceFilterType" }
    query: { type: "String" }
    resultModel: { type: "SearchResultModel" }
    sortAscending: { type: "Boolean" }
    sortBy: { type: "SearchSortBy" }
    stringTraits: { type: "[TraitInputType!]" }
    toggles: { type: "[SearchToggle!]" }
    showContextMenu: { type: "Boolean!" }
  )
  @refetchable(queryName: "AccountCollectedAssetSearchListPaginationQuery") {
    queriedAt
    searchItems(
      first: $count
      after: $cursor
      chains: $chains
      collections: $collections
      identity: $identity
      numericTraits: $numericTraits
      paymentAssets: $paymentAssets
      priceFilter: $priceFilter
      querystring: $query
      resultType: $resultModel
      sortAscending: $sortAscending
      sortBy: $sortBy
      stringTraits: $stringTraits
      toggles: $toggles
    )
      @connection(
        key: "AccountCollectedAssetSearchListPagination_searchItems"
      ) {
      edges {
        node {
          # eslint-disable-next-line relay/unused-fields
          relayId
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...readItemBestAskUsdPrice_item
          ...AssetSearchList_data
            @arguments(
              identity: $identity
              showContextMenu: $showContextMenu
              showQuantity: true
              showBestAskForOwner: true
              shouldShowBestBid: true
            )
          # eslint-disable-next-line relay/must-colocate-fragment-spreads used for batch selecting
          ...useAssetSelectionStorage_item
            @arguments(
              identity: $identity
              withBestAsk: true
              withBestBid: true
            )
          ...PortfolioTable_items
            @arguments(identity: $identity, showOwnerActions: $showContextMenu)
        }
      }
      totalCount
    }
  }
`

const LazyAccountCollectedAssetSearchList = (props: Props) => {
  const data = useLazyLoadQuery<AccountCollectedAssetSearchListQuery>(
    LIST_QUERY,
    {
      ...props.searchState,
      count: GLOBAL_MAX_PAGE_SIZE,
      showContextMenu: props.isOwnerView,
    },
  )
  return <AccountCollectedAssetSearchListPagination data={data} {...props} />
}

const AccountCollectedAssetSearchListPagination = ({
  accountAddress,
  cardVariant,
  data: dataKey,
  clear,
  isOwnerView,
  searchState,
  setSort,
  stickyTop,
}: PaginationProps) => {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    AccountCollectedAssetSearchListQuery,
    AccountCollectedAssetSearchListPagination_data$key
  >(PAGINATION_FRAGMENT, dataKey)

  const page: PageProps = useMemo(
    () => ({
      loadMore: count => loadNextToLoadMore({ loadNext, count }),
      isLoading: () => isLoadingNext,
      hasMore: () => hasNext,
    }),
    [hasNext, isLoadingNext, loadNext],
  )

  const searchItems = data?.searchItems
  const totalCount = searchItems?.totalCount
  const lastUpdatedAt = data?.queriedAt

  const filteredData = useMemo(
    () =>
      filterItemsWithInconsistentListings(
        getNodes(searchItems),
        searchState,
        page,
      ),
    [page, searchItems, searchState],
  )

  const updateSearchContext =
    useUpdateSearchContext<AccountCollectedSearchItems[number]>()

  useEffect(() => {
    updateSearchContext({
      totalCount,
      lastUpdatedAt,
      items: filteredData ?? undefined,
    })
  }, [filteredData, lastUpdatedAt, totalCount, updateSearchContext])

  if (totalCount === 0) {
    return (
      <AssetSearchViewNoResults
        query={searchState.query}
        showEmptyView
        onBackClick={clear}
      />
    )
  }

  if (cardVariant === "list-view") {
    // Only show when there are no results after loading items since
    // PortfolioTable shows skeletons internally when loading.
    return (
      <PortfolioTable
        accountAddress={accountAddress}
        hasNext={hasNext}
        isLoadingNext={isLoadingNext}
        items={filteredData}
        loadNext={() => loadNext(GLOBAL_MAX_PAGE_SIZE)}
        mode={isOwnerView ? "owner" : "public"}
        pageSize={GLOBAL_MAX_PAGE_SIZE}
        selectedSort={
          searchState.sortBy && searchState.sortAscending !== undefined
            ? {
                sortBy: searchState.sortBy,
                sortAscending: searchState.sortAscending,
              }
            : undefined
        }
        stickyTop={stickyTop}
        totalItemCount={totalCount ?? 0}
        onSort={setSort}
      />
    )
  }
  return (
    <AssetSearchList
      accountAddress={accountAddress}
      cardVariant={cardVariant}
      data={filteredData}
      page={page}
      pageSize={GLOBAL_MAX_PAGE_SIZE}
      showAssetMediaEditions
      showCollectionName
      showContextMenu={isOwnerView}
      showQuantity
      variant="grid"
    />
  )
}

const AssetSearchListSkeleton = ({
  cardVariant,
  isOwnerView,
  stickyTop,
}: Pick<Props, "cardVariant" | "isOwnerView"> &
  Pick<ComponentProps<typeof PortfolioTable.Skeleton>, "stickyTop">) => {
  if (cardVariant === "list-view") {
    return (
      <PortfolioTable.Skeleton
        mode={isOwnerView ? "owner" : "public"}
        pageSize={GLOBAL_MAX_PAGE_SIZE}
        stickyTop={stickyTop}
      />
    )
  }
  return <AssetSearchList.Skeleton cardVariant={cardVariant} />
}

export const AccountCollectedAssetSearchList = ({
  accountAddress,
  cardVariant,
  clear,
  isOwnerView,
  searchState,
  setSort,
  stickyTop,
}: Props) => {
  const isHydrated = useIsHydrated()
  const skeleton = (
    <AssetSearchListSkeleton
      cardVariant={cardVariant}
      isOwnerView={isOwnerView}
      stickyTop={stickyTop}
    />
  )

  if (!isHydrated) {
    return skeleton
  }
  return (
    <Suspense fallback={skeleton}>
      <LazyAccountCollectedAssetSearchList
        accountAddress={accountAddress}
        cardVariant={cardVariant}
        clear={clear}
        isOwnerView={isOwnerView}
        searchState={searchState}
        setSort={setSort}
        stickyTop={stickyTop}
      />
    </Suspense>
  )
}
