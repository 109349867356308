import React, { useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { useFeaturedShelfCount } from "@/components/layout/AccountOrCollectionPage/hooks/useFeaturedShelfCount"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { ProfilePageFeaturedFragment } from "@/features/account/queries/profilePageQueries"
import { FeaturedShelfList } from "@/features/featured-shelf/components/FeaturedShelf.react"
import { useRouter } from "@/hooks/useRouter"
import { FeaturedAccountPageQuery } from "@/lib/graphql/__generated__/FeaturedAccountPageQuery.graphql"
import { profilePageQueries_featured$key } from "@/lib/graphql/__generated__/profilePageQueries_featured.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "./utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  assetCardVariant?: AssetCardVariant
}

export const FeaturedPage: GraphQLNextPage<FeaturedAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  assetCardVariant,
}) => {
  const router = useRouter()
  const [currentCardVariant, setCurrentCardVariant] = useState(assetCardVariant)

  const featuredKey = data?.account ?? null
  const featured = useFragment<profilePageQueries_featured$key>(
    ProfilePageFeaturedFragment,
    featuredKey,
  )
  const shelfCount = useFeaturedShelfCount({ featuredKey, isYourProfile })
  useEffect(() => {
    if (!shelfCount && featuredKey) {
      router.push(`/${identifier ?? "account"}`)
    }
  }, [identifier, router, shelfCount, featuredKey])

  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="featured"
      tabContent={
        data?.account?.relayId ? (
          <FeaturedShelfList
            accountId={data.account.relayId}
            cardVariant={currentCardVariant}
            isProfilePage
            setCardVariant={setCurrentCardVariant}
            shelves={featured?.shelves}
            showExtraMenu={isCurrentUser}
          />
        ) : null
      }
    />
  )
}

const query = graphql`
  query FeaturedAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
    account(identity: $identity) {
      relayId
      ...profilePageQueries_featured @arguments(showContextMenu: false)
    }
  }
`

FeaturedPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
  },
  async (
    { identifier },
    context,
  ): Promise<GraphQLInitialProps<FeaturedAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables, assetCardVariant } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
      })

    const { includePrivateAssetCount, identity, collection } = variables

    const pageVariables = {
      includePrivateAssetCount,
      identity,
      collection,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      assetCardVariant,
      variables: pageVariables,
    }
  },
)
