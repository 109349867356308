/**
 * @generated SignedSource<<3d34a841a529501f52189339e78466dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealsSubTabs_account$data = {
  readonly address: string;
  readonly " $fragmentSpreads": FragmentRefs<"accounts_url">;
  readonly " $fragmentType": "DealsSubTabs_account";
};
export type DealsSubTabs_account$key = {
  readonly " $data"?: DealsSubTabs_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealsSubTabs_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealsSubTabs_account",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "accounts_url",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserType",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publicUsername",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "693ca478db32d4038a11d958e840aee5";

export default node;
