/**
 * @generated SignedSource<<c22f97233998570c68e3272510c5d77b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type ProfileOffersCollectionFilterItemsLazyQuery$variables = {
  address: string;
  assetOwner: IdentityInputType;
  collections: ReadonlyArray<string>;
  count: number;
  cursor?: string | null;
  query?: string | null;
};
export type ProfileOffersCollectionFilterItemsLazyQuery$data = {
  readonly collections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProfileOffersCollectionFilterItem_collection">;
      } | null;
    } | null>;
  };
  readonly findAccount: {
    readonly offersMadeCollections: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly slug: string;
          readonly " $fragmentSpreads": FragmentRefs<"ProfileOffersCollectionFilterItem_collection">;
        } | null;
      } | null>;
    };
  } | null;
};
export type ProfileOffersCollectionFilterItemsLazyQuery = {
  response: ProfileOffersCollectionFilterItemsLazyQuery$data;
  variables: ProfileOffersCollectionFilterItemsLazyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assetOwner"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collections"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v6 = [
  {
    "kind": "Variable",
    "name": "identifier",
    "variableName": "address"
  }
],
v7 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "args": [
    {
      "kind": "Variable",
      "name": "owner",
      "variableName": "assetOwner"
    }
  ],
  "kind": "FragmentSpread",
  "name": "ProfileOffersCollectionFilterItem_collection"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v14 = [
  {
    "kind": "Variable",
    "name": "collections",
    "variableName": "collections"
  },
  (v13/*: any*/)
],
v15 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v13/*: any*/),
  (v7/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerified",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "identity",
      "variableName": "assetOwner"
    }
  ],
  "concreteType": "CollectionAssetOwnerType",
  "kind": "LinkedField",
  "name": "collectionAssetOwner",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOffers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "offersMadeValue",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        (v20/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": [
        (v20/*: any*/),
        (v19/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileOffersCollectionFilterItemsLazyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "findAccount",
        "plural": false,
        "selections": [
          {
            "alias": "offersMadeCollections",
            "args": [
              (v7/*: any*/)
            ],
            "concreteType": "CollectionTypeConnection",
            "kind": "LinkedField",
            "name": "__ProfileOffersCollectionFilterItems_offersMadeCollections_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfileOffersCollectionFilterItemsLazyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "findAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v15/*: any*/),
            "concreteType": "CollectionTypeConnection",
            "kind": "LinkedField",
            "name": "offersMadeCollections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v15/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "ProfileOffersCollectionFilterItems_offersMadeCollections",
            "kind": "LinkedHandle",
            "name": "offersMadeCollections"
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "75aaa25553fc7fee84bfb8092a56fb9f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "findAccount",
            "offersMadeCollections"
          ]
        }
      ]
    },
    "name": "ProfileOffersCollectionFilterItemsLazyQuery",
    "operationKind": "query",
    "text": "query ProfileOffersCollectionFilterItemsLazyQuery(\n  $assetOwner: IdentityInputType!\n  $count: Int!\n  $address: String!\n  $query: String\n  $cursor: String\n  $collections: [CollectionSlug!]!\n) {\n  findAccount(identifier: $address) {\n    offersMadeCollections(query: $query, first: $count, after: $cursor) {\n      edges {\n        node {\n          slug\n          ...ProfileOffersCollectionFilterItem_collection_2c1f9y\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n  collections(first: $count, collections: $collections) {\n    edges {\n      node {\n        slug\n        ...ProfileOffersCollectionFilterItem_collection_2c1f9y\n        id\n      }\n    }\n  }\n}\n\nfragment ProfileOffersCollectionFilterItem_collection_2c1f9y on CollectionType {\n  imageUrl\n  name\n  slug\n  isVerified\n  collectionAssetOwner(identity: $assetOwner) {\n    numOffers\n    offersMadeValue {\n      symbol\n      unit\n    }\n  }\n  statsV2 {\n    floorPrice {\n      unit\n      symbol\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8c1937d0719fd670de5f9399d0ea408";

export default node;
