import { Breakpoint } from "@opensea/ui-kit"
import { VirtualizedTableCellProps } from "@/design-system/VirtualizedTable/components/VirtualizedTableCell.react"
import { OffersColumn } from "./types"

export const DESKTOP_BREAKPOINT: Breakpoint = "md"
export const CHECKBOX_PADDING = 12
export const ROW_HEIGHT = 56
export const PAGE_SIZE = 10
const BASIC_COLUMN = {
  flexGrow: 1,
  justifyContent: { _: "flex-end", [DESKTOP_BREAKPOINT]: "flex-start" },
  width: "100px",
}
export const COLUMN_CELL_PROPS: Record<
  OffersColumn,
  Partial<VirtualizedTableCellProps>
> = {
  offer: {
    flexGrow: 2,
    paddingLeft: {
      _: 0,
      [DESKTOP_BREAKPOINT]: CHECKBOX_PADDING,
    },
    stickyLeft: 0,
    // Includes the checkbox and margin between the checkbox and the item name.
    width: "200px",
  },
  price: BASIC_COLUMN,
  status: BASIC_COLUMN,
  quantity: BASIC_COLUMN,
  total: BASIC_COLUMN,
  expiration: BASIC_COLUMN,
  dateCreated: BASIC_COLUMN,
}
