import React from "react"
import { Flex, FlexColumn } from "@opensea/ui-kit"
import { ActivityResetZoomButton } from "@/components/search/activity/ActivityResetZoomButton.react"
import { Block } from "@/design-system/Block"

type BaseProps = {
  header: React.ReactNode
  filter: React.ReactNode
  eventHistory: React.ReactNode
  pills: React.ReactNode
}

type PropsWithoutPriceHistory = BaseProps & {
  priceHistory?: undefined
  priceHistoryTimeRange?: undefined
}

type PropsWithPriceHistory = BaseProps & {
  priceHistory: React.ReactNode
  priceHistoryTimeRange?: React.ReactNode
}

type Props = PropsWithoutPriceHistory | PropsWithPriceHistory

export const ActivitySearchLayout = React.forwardRef<HTMLDivElement, Props>(
  function ActivitySearchLayout(
    {
      header,
      filter,
      pills,
      eventHistory,
      priceHistory,
      priceHistoryTimeRange,
    },
    ref,
  ) {
    return (
      <>
        {header}
        <Flex className="relative mt-4 h-full flex-1 sm:mt-2" ref={ref}>
          {filter}
          <FlexColumn className="mt-1 w-full overflow-x-hidden">
            {priceHistory ? (
              <>
                <Flex className="mb-6 mt-3 justify-end lg:mt-0 lg:justify-between">
                  {pills}
                  <Flex className="flex-1 items-center justify-end gap-4">
                    <Block flex={{ _: 1, lg: 0 }} minWidth="168px">
                      <ActivityResetZoomButton />
                    </Block>
                    {priceHistoryTimeRange && (
                      <Block flex={1} maxWidth={{ lg: "168px" }}>
                        {priceHistoryTimeRange}
                      </Block>
                    )}
                  </Flex>
                </Flex>
                {priceHistory}
              </>
            ) : (
              <Block marginBottom="12px">{pills}</Block>
            )}
            {eventHistory}
          </FlexColumn>
        </Flex>
      </>
    )
  },
)
