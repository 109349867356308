import React, { useEffect } from "react"
import { FlexColumn, Media, SpaceBetween, Text } from "@opensea/ui-kit"
import {
  PreloadedQuery,
  graphql,
  usePaginationFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay"
import { useUpdateEffect } from "react-use"
import Loading from "@/components/common/Loading.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import {
  ScrollingPaginator,
  loadNextToLoadMore,
} from "@/design-system/ScrollingPaginator"
import { DealsSubTabs } from "@/features/deals/components/DealsSubTabs.react"
import { useDealSearchState } from "@/features/deals/hooks/useDealSearchState"
import { DEAL_CREATE_URL } from "@/features/deals/urls"
import { useTranslate } from "@/hooks/useTranslate"
import { DealsSearchListContent_data$key } from "@/lib/graphql/__generated__/DealsSearchListContent_data.graphql"
import { DealsSearchListPaginationQuery } from "@/lib/graphql/__generated__/DealsSearchListPaginationQuery.graphql"
import {
  IdentityInputType,
  DealsSearchListQuery,
} from "@/lib/graphql/__generated__/DealsSearchListQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { display } from "@/lib/helpers/numberUtils"
import { PartiallyOptional } from "@/lib/helpers/type"
import { DealCard } from "./DealCard.react"
import { DealSideSelect } from "./DealSideSelect.react"
import { DealsNoResults } from "./DealsNoResults.react"
import { DealSortBySelect } from "./DealSortBySelect.react"
import { DealSearchStatus } from "./types"
import { getDealStateFromStatus } from "./utils"

const PAGE_SIZE = 16

const DEALS_SEARCH_LIST_QUERY = graphql`
  query DealsSearchListQuery(
    $first: Int!
    $cursor: String
    $identity: IdentityInputType!
    $state: SwapState
    $side: SwapSide
    $sortBy: SwapSortBy
    $sortAscending: Boolean
  ) {
    ...DealsSearchListContent_data
      @arguments(
        first: $first
        cursor: $cursor
        identity: $identity
        side: $side
        state: $state
        sortBy: $sortBy
        sortAscending: $sortAscending
      )
  }
`

type DealsSearchListProps = {
  identity: IdentityInputType
  status: DealSearchStatus
}

export const DealsSearchList = ({ identity, status }: DealsSearchListProps) => {
  const [queryRef, loadQuery, disposeQuery] =
    useQueryLoader<DealsSearchListQuery>(DEALS_SEARCH_LIST_QUERY)

  useEffect(() => {
    loadQuery({
      identity,
      first: PAGE_SIZE,
      sortBy: "CREATED_DATE",
      sortAscending: false,
      state: getDealStateFromStatus(status),
    })
    return () => {
      disposeQuery()
    }
  }, [identity, disposeQuery, loadQuery, status])

  return <DealsSearchListContent queryRef={queryRef ?? undefined} />
}

type DealsSearchListContentBaseProps = {
  queryRef: PreloadedQuery<DealsSearchListQuery>
}

const DealsSearchListContentBase = ({
  queryRef,
}: DealsSearchListContentBaseProps) => {
  const t = useTranslate("deals")

  const dataKey = usePreloadedQuery(DEALS_SEARCH_LIST_QUERY, queryRef)

  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment<
      DealsSearchListPaginationQuery,
      DealsSearchListContent_data$key
    >(
      graphql`
        fragment DealsSearchListContent_data on Query
        @argumentDefinitions(
          first: { type: "Int!" }
          cursor: { type: "String" }
          identity: { type: "IdentityInputType!" }
          state: { type: "SwapState" }
          side: { type: "SwapSide" }
          sortBy: { type: "SwapSortBy" }
          sortAscending: { type: "Boolean" }
        )
        @refetchable(queryName: "DealsSearchListPaginationQuery") {
          account(identity: $identity) {
            address
            swaps(
              first: $first
              after: $cursor
              side: $side
              state: $state
              sortBy: $sortBy
              sortAscending: $sortAscending
            ) @connection(key: "DealsSearchListContent_swaps") {
              count
              edges {
                node {
                  relayId
                  ...DealCard_deal
                }
              }
            }
            ...DealsSubTabs_account
          }
        }
      `,
      dataKey,
    )

  const { searchState, updateSearchState } = useDealSearchState()
  useUpdateEffect(() => {
    refetch(searchState, { fetchPolicy: "store-and-network" })
  }, [searchState])

  // Ideally use `getAccount` instead of `account` resolver, but we would need
  // to make two requests to get account using the identity from the profile.
  if (!data.account) {
    // Should never occur unless the query inputs are invalid.
    return null
  }

  const account = data.account
  const deals = getNodes(account.swaps)
  const dealCount = account.swaps.count
  const dealCountText = t(
    "manageDeals.dealCount",
    {
      "0": "0 deals",
      one: "{{dealCount}} deal",
      other: "{{dealCount}} deals",
    },
    { dealCount: display(dealCount), count: dealCount },
  )

  return (
    <FlexColumn className="flex-1 gap-6 lg:gap-8">
      <SpaceBetween className="items-center">
        <Media lessThan="lg">
          <Text.Heading size="small">{dealCountText}</Text.Heading>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Text.Heading size="medium">{dealCountText}</Text.Heading>
        </Media>
        <Media lessThan="lg">
          <Button href={DEAL_CREATE_URL} icon="add" />
        </Media>
        <Media greaterThanOrEqual="lg">
          <Flex className="gap-4" flex={1} justifyContent="flex-end">
            <DealSideSelect
              setSide={side => updateSearchState({ side })}
              side={searchState.side}
            />

            <DealSortBySelect
              setSortBy={sortBy => updateSearchState({ sortBy })}
              sortBy={searchState.sortBy}
            />
            <Button href={DEAL_CREATE_URL}>
              {t("manageDeals.makeDeal", "Make a deal")}
            </Button>
          </Flex>
        </Media>
      </SpaceBetween>
      <Media lessThan="lg">
        <Flex className="gap-3">
          <DealSideSelect
            setSide={side => updateSearchState({ side })}
            side={searchState.side}
          />

          <DealSortBySelect
            setSortBy={sortBy => updateSearchState({ sortBy })}
            sortBy={searchState.sortBy}
          />
        </Flex>
      </Media>
      <Media lessThan="lg">
        <DealsSubTabs account={account} />
      </Media>
      {deals.length ? (
        <FlexColumn asChild className="w-full gap-6 lg:gap-8">
          <ul>
            {deals.map(deal => (
              <Block as="li" className="w-full" key={deal.relayId}>
                <DealCard
                  accountAddress={account.address}
                  deal={deal}
                  key={deal.relayId}
                />
              </Block>
            ))}
          </ul>
        </FlexColumn>
      ) : (
        <DealsNoResults />
      )}
      <ScrollingPaginator
        page={{
          loadMore: count => loadNextToLoadMore({ loadNext, count }),
          hasMore: () => hasNext,
          isLoading: () => isLoadingNext,
        }}
        size={PAGE_SIZE}
      />
    </FlexColumn>
  )
}

type DealsSearchListContentProps = PartiallyOptional<
  DealsSearchListContentBaseProps,
  "queryRef"
>

const DealsSearchListContent = ({ queryRef }: DealsSearchListContentProps) => {
  const skeleton = (
    <FlexColumn className="flex-1  justify-center">
      <Loading />
    </FlexColumn>
  )

  if (queryRef === undefined) {
    return skeleton
  }

  return (
    <SsrSuspense fallback={skeleton}>
      <DealsSearchListContentBase queryRef={queryRef} />
    </SsrSuspense>
  )
}
