/**
 * @generated SignedSource<<a4837a0363c90f0007ea8c4ed7e091dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableListButton_bestAskV2$data = {
  readonly maker: {
    readonly address: string;
  };
  readonly orderType: OrderV2OrderType;
  readonly " $fragmentSpreads": FragmentRefs<"EditListingButton_listing">;
  readonly " $fragmentType": "PortfolioTableListButton_bestAskV2";
};
export type PortfolioTableListButton_bestAskV2$key = {
  readonly " $data"?: PortfolioTableListButton_bestAskV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableListButton_bestAskV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableListButton_bestAskV2",
  "selections": [
    {
      "condition": "showOwnerActions",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditListingButton_listing"
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "8704eb7091196b48caaa7edeb62c2cb0";

export default node;
