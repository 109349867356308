import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { AccountTrackingContext_account$key } from "@/lib/graphql/__generated__/AccountTrackingContext_account.graphql"
import { TrackingContextProvider } from "../TrackingContext.react"

type AccountContext = {
  relayId: string
  address: string
  displayName: string | null
}

export type AccountTrackingContext = {
  Account: AccountContext | undefined
}

type AccountTrackingContextProviderProps = {
  account: AccountTrackingContext_account$key | null
  children: React.ReactNode
}

export const AccountTrackingContextProvider = ({
  account: accountDataKey,
  children,
}: AccountTrackingContextProviderProps) => {
  const data = useFragment(
    graphql`
      fragment AccountTrackingContext_account on AccountType {
        relayId
        address
        displayName
      }
    `,
    accountDataKey,
  )

  const properties: AccountContext | undefined = useMemo(() => {
    if (!data) {
      return undefined
    }

    const { relayId, address, displayName } = data

    return {
      relayId,
      address,
      displayName,
    }
  }, [data])

  return (
    <TrackingContextProvider name="Account" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
