import React from "react"
import { AccountOffersOrderSearchState } from "@/features/account/components/AccountPage/tabs/offers/AccountOffersOrderSearch.react"
import { useTranslate } from "@/hooks/useTranslate"
import { MapNonNullable } from "@/lib/helpers/type"
import { FeaturedFilter } from "../featured-filter"
import { ProfileCollectionFilter } from "../ProfileCollectionFilter.react"
import { ProfileOffersCollectionFilterItems } from "../ProfileOffersCollectionFilterItems"
import SearchFilterDropdown from "../SearchFilterDropdown"
import { useOfferTypeItems, useOrderStatusItems } from "./utils"

type Props = {
  clear: () => unknown
  state: AccountOffersOrderSearchState
  setState: (
    state: Partial<MapNonNullable<AccountOffersOrderSearchState>>,
  ) => unknown
  renderFn?: (content: JSX.Element) => JSX.Element
}

const OfferSearchFilter = ({ clear, state, setState, renderFn }: Props) => {
  const t = useTranslate("components")
  const defaultStatusItems = useOrderStatusItems()
  const defaultTypeItems = useOfferTypeItems()

  const assetOwner = state.identity
  const address = assetOwner.address || assetOwner.username

  const content = address ? (
    <>
      <ProfileCollectionFilter
        searchCollectionItems={query => (
          <ProfileOffersCollectionFilterItems
            address={address}
            assetOwner={assetOwner}
            collectionSlugs={state.collections ?? []}
            query={query}
            selectedSlugs={state.collections ? state.collections : []}
            setSlugs={collections =>
              setState({
                collections: collections ? collections : [],
              })
            }
          />
        )}
      />
      <FeaturedFilter
        filters={state.offerTypeToggles || []}
        possibleFilterItems={defaultTypeItems}
        setFilters={offerTypeToggles => setState({ offerTypeToggles })}
        showOnMobileOnly
        title={t("orderSearchFilter.offerType.title", "Type")}
      />
      <FeaturedFilter
        filters={state.orderStatusToggles || []}
        possibleFilterItems={defaultStatusItems}
        setFilters={orderStatusToggles => setState({ orderStatusToggles })}
        showOnMobileOnly
        title={t("orderSearchFilter.status.title", "Status")}
      />
    </>
  ) : (
    <></>
  )

  if (renderFn) {
    return renderFn(content)
  }

  return (
    <SearchFilterDropdown clear={clear} fullscreenBreakpoint="lg">
      {content}
    </SearchFilterDropdown>
  )
}

export default OfferSearchFilter
