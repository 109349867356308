import React, { useEffect, useMemo } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { AssetSearchList } from "@/components/search/assets/AssetSearchList.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { AssetSearchViewNoResults } from "@/components/search/assets/AssetSearchView/AssetSearchViewNoResults.react"
import { useUpdateSearchContext } from "@/components/search/utils/SearchContextProvider.react"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import {
  AccountPrivateAssetSearchListPagination_data$data,
  AccountPrivateAssetSearchListPagination_data$key,
} from "@/lib/graphql/__generated__/AccountPrivateAssetSearchListPagination_data.graphql"
import { AccountPrivateAssetSearchListQuery } from "@/lib/graphql/__generated__/AccountPrivateAssetSearchListQuery.graphql"
import { getNodes, graphql, Node, PageProps } from "@/lib/graphql/graphql"
import { AccountPrivateAssetSearchState } from "./AccountPrivateAssetSearch.react"

export type AccountPrivateSearchItems = Node<
  AccountPrivateAssetSearchListPagination_data$data["searchItems"]
>[]

type Props = {
  cardVariant: AssetCardVariant | undefined
  clear: () => void
  searchState: AccountPrivateAssetSearchState
}

type PaginationProps = Props & {
  data: AccountPrivateAssetSearchListPagination_data$key | null
}

const LIST_QUERY = graphql`
  query AccountPrivateAssetSearchListQuery(
    $count: Int!
    $cursor: String
    $chains: [ChainScalar!]
    $collections: [CollectionSlug!]
    $identity: IdentityInputType!
    $query: String
    $sortAscending: Boolean
    $sortBy: SearchSortBy
  ) {
    ...AccountPrivateAssetSearchListPagination_data
      @arguments(
        count: $count
        cursor: $cursor
        chains: $chains
        collections: $collections
        identity: $identity
        query: $query
        sortAscending: $sortAscending
        sortBy: $sortBy
      )
  }
`

const PAGINATION_FRAGMENT = graphql`
  fragment AccountPrivateAssetSearchListPagination_data on Query
  @argumentDefinitions(
    count: { type: "Int!" }
    collections: { type: "[CollectionSlug!]" }
    cursor: { type: "String" }
    chains: { type: "[ChainScalar!]" }
    identity: { type: "IdentityInputType!" }
    query: { type: "String" }
    sortAscending: { type: "Boolean" }
    sortBy: { type: "SearchSortBy" }
    isAutoHidden: { type: "Boolean" }
  )
  @refetchable(queryName: "AccountPrivateAssetSearchListPaginationQuery") {
    queriedAt
    searchItems(
      first: $count
      after: $cursor
      chains: $chains
      collections: $collections
      identity: $identity
      querystring: $query
      sortAscending: $sortAscending
      sortBy: $sortBy
      isAutoHidden: $isAutoHidden
      isPrivate: true
    ) @connection(key: "AccountPrivateAssetSearchListPagination_searchItems") {
      edges {
        node {
          ...AssetSearchList_data
            @arguments(
              identity: $identity
              showContextMenu: true
              showQuantity: true
              showBestAskForOwner: true
            )
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...useAssetSelectionStorage_item
            @arguments(identity: $identity, withBestAsk: true)
        }
      }
      totalCount
    }
  }
`

const LazyAccountPrivateAssetSearchList = (props: Props) => {
  const data = useLazyLoadQuery<AccountPrivateAssetSearchListQuery>(
    LIST_QUERY,
    {
      ...props.searchState,
      count: GLOBAL_MAX_PAGE_SIZE,
    },
  )
  return <AccountPrivateAssetSearchListPagination data={data} {...props} />
}

const AccountPrivateAssetSearchListPagination = ({
  cardVariant,
  data: dataKey,
  clear,
  searchState,
}: PaginationProps) => {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    AccountPrivateAssetSearchListQuery,
    AccountPrivateAssetSearchListPagination_data$key
  >(PAGINATION_FRAGMENT, dataKey)

  const page: PageProps = useMemo(
    () => ({
      loadMore: count => loadNextToLoadMore({ loadNext, count }),
      isLoading: () => isLoadingNext,
      hasMore: () => hasNext,
    }),
    [hasNext, isLoadingNext, loadNext],
  )

  const searchItems = data?.searchItems
  const totalCount = searchItems?.totalCount
  const lastUpdatedAt = data?.queriedAt

  const updateSearchContext =
    useUpdateSearchContext<AccountPrivateSearchItems[number]>()

  useEffect(() => {
    updateSearchContext({
      totalCount,
      lastUpdatedAt,
      items: getNodes(searchItems),
    })
  }, [lastUpdatedAt, searchItems, totalCount, updateSearchContext])

  if (totalCount === 0) {
    return (
      <AssetSearchViewNoResults
        query={searchState.query}
        showEmptyView
        onBackClick={clear}
      />
    )
  }
  return (
    <AssetSearchList
      cardVariant={cardVariant}
      data={getNodes(searchItems)}
      page={page}
      pageSize={GLOBAL_MAX_PAGE_SIZE}
      showAssetMediaEditions
      showCollectionName
      showContextMenu
      showQuantity
      variant="grid"
    />
  )
}

export const AccountPrivateAssetSearchList = ({
  cardVariant,
  clear,
  searchState,
}: Props) => (
  <SsrSuspense
    fallback={<AssetSearchList.Skeleton cardVariant={cardVariant} />}
  >
    <LazyAccountPrivateAssetSearchList
      cardVariant={cardVariant}
      clear={clear}
      searchState={searchState}
    />
  </SsrSuspense>
)
