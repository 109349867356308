import React from "react"
import { first } from "lodash"
import { Block, BlockProps } from "@/design-system/Block"
import { ButtonProps } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { useAssetContextSelectedItems } from "@/features/account/hooks/useAssetSelection"
import { BulkAcceptOffersModal } from "@/features/bulk-accept-offer/components/BulkAcceptOffersModal"
import { trackBulkAcceptOffersFooterButtonClicked } from "@/features/bulk-accept-offer/utils/analytics"
import { useTranslate } from "@/hooks/useTranslate"
import { TriggerProps } from "./AssetsContextMenu.react"

const BULK_ACCEPT_OFFERS_SELECTION_MAX_SIZE = 30

type Props = {
  onOffersAccepted: () => void
  overrides?: {
    Block: {
      props: Partial<BlockProps>
    }
  }
} & ButtonProps &
  TriggerProps

export const AcceptOffersButton = ({
  trigger,
  onOffersAccepted,
  overrides,
  ...buttonProps
}: Props) => {
  const selectedItems = useAssetContextSelectedItems()

  const t = useTranslate("account")

  const filteredItems = selectedItems
    .filter(
      item =>
        item.bestAllTypeBid?.relayId || item.orderData?.bestBidV2?.relayId,
    )
    .slice(0, BULK_ACCEPT_OFFERS_SELECTION_MAX_SIZE)

  const chain = first(filteredItems)?.chain.identifier

  const numItems = filteredItems.length

  if (numItems === 0) {
    return null
  }

  const getDisabledReason = () => {
    const uniqueChains = new Set(
      filteredItems.map(item => item.chain.identifier),
    )

    if (uniqueChains.size > 1) {
      return t(
        "selectionFooter.acceptOffersCta.multipleChains",
        "You can only accept offers on items from the same chain.",
      )
    }

    if (numItems === 0) {
      return t(
        "selectionFooter.acceptOffersCta.noOffers",
        "No items selected have any offers.",
      )
    }

    return undefined
  }

  const disabledReason = getDisabledReason()

  // Display tooltip if there are more orders than the max limit
  const maxInfoLimit =
    filteredItems.length > BULK_ACCEPT_OFFERS_SELECTION_MAX_SIZE
      ? t(
          "selectionFooter.acceptCta.maxInfoLimit",
          "You can only accept offers on up to {{count}} items at a time.",
          { count: BULK_ACCEPT_OFFERS_SELECTION_MAX_SIZE },
        )
      : undefined

  const handleClick = () => {
    trackBulkAcceptOffersFooterButtonClicked({
      numItems: filteredItems.length,
      chain,
    })
  }

  const acceptOffersText = t(
    "selectionFooter.acceptCta.itemCount",
    {
      "0": "Accept {{count}} offers",
      one: "Accept {{count}} offer",
      other: "Accept {{count}} offers",
    },
    { count: numItems },
  )

  const renderModal = () => (
    <BulkAcceptOffersModal
      assetIds={filteredItems
        .filter(item => item.__typename === "AssetType")
        .map(item => item.relayId)}
      bundleIds={filteredItems
        .filter(item => item.__typename === "AssetBundleType")
        .map(item => item.relayId)}
      chain={chain ?? "ETHEREUM"}
      onOffersAccepted={onOffersAccepted}
    >
      {trigger ? (
        <BulkAcceptOffersModal.TriggerWrapper
          trigger={onClick =>
            trigger({
              onClick,
              icon: "price_check",
              content: acceptOffersText,
              disabled: Boolean(disabledReason),
            })
          }
        />
      ) : (
        <BulkAcceptOffersModal.Trigger
          disabled={Boolean(disabledReason)}
          variant="secondary"
          onClick={handleClick}
          {...buttonProps}
        >
          {acceptOffersText}
        </BulkAcceptOffersModal.Trigger>
      )}
    </BulkAcceptOffersModal>
  )
  return trigger ? (
    renderModal()
  ) : (
    <Tooltip content={disabledReason ?? maxInfoLimit} maxWidth={200}>
      <Block {...overrides?.Block.props}>{renderModal()}</Block>
    </Tooltip>
  )
}
