/**
 * @generated SignedSource<<2d9d585d536f3fff68acbde1ab921156>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableAcceptOfferButton_item$data = {
  readonly __typename: string;
  readonly bestAllTypeBid?: {
    readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_order">;
  } | null;
  readonly orderData?: {
    readonly bestBidV2: {
      readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_order">;
    } | null;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_asset">;
  readonly " $fragmentType": "PortfolioTableAcceptOfferButton_item";
};
export type PortfolioTableAcceptOfferButton_item$key = {
  readonly " $data"?: PortfolioTableAcceptOfferButton_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableAcceptOfferButton_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "identity",
    "variableName": "identity"
  }
],
v1 = [
  {
    "args": (v0/*: any*/),
    "kind": "FragmentSpread",
    "name": "AcceptOfferButton_order"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableAcceptOfferButton_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAllTypeBid",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "AcceptOfferButton_asset"
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ESOrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestBidV2",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "6b253f5213d5791f914c892b792bc54c";

export default node;
