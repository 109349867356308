import React, { CSSProperties, useRef } from "react"
import { classNames } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { ScrollingPaginator } from "@/design-system/ScrollingPaginator"
import { Table, TableCellProps } from "@/design-system/Table"
import { useTranslate } from "@/hooks/useTranslate"
import { PageProps } from "@/lib/graphql/graphql"
import { OrdersTableRow, OrdersTableRowData } from "./OrdersTableRow.react"

export type OrdersTableProps<T extends OrdersTableRowData> = {
  orders: T[]
  renderEmpty: () => React.ReactNode
  renderActions?: (order: T) => React.ReactNode
  page?: PageProps
  maxHeight?: CSSProperties["maxHeight"]
}

export const useOrdersTableHeaders = () => {
  const t = useTranslate("orders")

  return [
    t("orders.ordersTable.item", "Item"),
    t("orders.ordersTable.unitPrice", "Unit Price"),
    t("orders.ordersTable.floorDifference", "Floor Difference"),
    t("orders.ordersTable.expirationDate", "Expiration Date"),
    "",
  ]
}

export const OrdersTable = <T extends OrdersTableRowData>({
  orders,
  renderActions,
  renderEmpty,
  page,
  maxHeight,
}: OrdersTableProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const headers = useOrdersTableHeaders()

  if (orders.length === 0) {
    return <>{renderEmpty()}</>
  }

  return (
    <Block maxHeight={maxHeight} overflow="scroll" ref={containerRef}>
      <Table
        headers={headers}
        renderHeader={({ Header, header, index }) => {
          const isActionsColumn = index === headers.length - 1
          const stickyActionsColumnProps: TableCellProps = {
            className: classNames("sticky right-0"),
          }
          return (
            <Header
              key={index}
              {...(isActionsColumn ? stickyActionsColumnProps : undefined)}
            >
              {header}
            </Header>
          )
        }}
      >
        {orders.map(order => (
          <OrdersTableRow
            dataKey={order}
            key={order.id}
            renderActions={renderActions}
          />
        ))}
      </Table>

      {page && (
        <ScrollingPaginator
          intersectionOptions={{
            rootMargin: "72px",
            root: containerRef.current,
          }}
          page={page}
          size={6}
        />
      )}
    </Block>
  )
}
