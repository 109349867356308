import React, { useState } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { EventHistoryBase } from "@/components/events/EventHistory.react"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { ActivityTable } from "@/features/activity"
import { usePollingQuery } from "@/hooks/usePollingQuery"
import {
  AccountActivitySearchListPagination_data$data,
  AccountActivitySearchListPagination_data$key,
} from "@/lib/graphql/__generated__/AccountActivitySearchListPagination_data.graphql"
import { AccountActivitySearchListPollingQuery } from "@/lib/graphql/__generated__/AccountActivitySearchListPollingQuery.graphql"
import { AccountActivitySearchListQuery } from "@/lib/graphql/__generated__/AccountActivitySearchListQuery.graphql"
import { getNodes, graphql, Node } from "@/lib/graphql/graphql"
import { AccountActivitySearchState } from "./AccountActivitySearch.react"

const ACCOUNT_ACTIVITY_SEARCH_POLLING_INTERVAL = 30_000 // 30 seconds

type EventNode = Node<
  NonNullable<
    AccountActivitySearchListPagination_data$data["account"]
  >["activity"]
>

type Props = {
  searchState: AccountActivitySearchState
  activityTableKey: number
}

type PaginationProps = Props & {
  data: AccountActivitySearchListPagination_data$key | null
}

const LIST_QUERY = graphql`
  query AccountActivitySearchListQuery(
    $count: Int!
    $cursor: String
    $identity: IdentityInputType!
    $chains: [ChainScalar!]
    $collections: [CollectionSlug!]
    $eventTypes: [EventType!]
    $stringTraits: [TraitInputType!]
  ) {
    ...AccountActivitySearchListPagination_data
      @arguments(
        count: $count
        cursor: $cursor
        identity: $identity
        chains: $chains
        collections: $collections
        eventTypes: $eventTypes
        stringTraits: $stringTraits
      )
  }
`

const PAGINATION_FRAGMENT = graphql`
  fragment AccountActivitySearchListPagination_data on Query
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    identity: { type: "IdentityInputType!" }
    chains: { type: "[ChainScalar!]" }
    collections: { type: "[CollectionSlug!]" }
    eventTypes: { type: "[EventType!]" }
    stringTraits: { type: "[TraitInputType!]" }
  )
  @refetchable(queryName: "AccountActivitySearchListPaginationQuery") {
    account(identity: $identity) {
      activity(
        after: $cursor
        first: $count
        chains: $chains
        collections: $collections
        eventTypes: $eventTypes
        stringTraits: $stringTraits
      ) @connection(key: "AccountActivitySearchList_activity") {
        edges {
          node {
            ...EventHistory_data @arguments(showAll: true)
            eventTimestamp
          }
        }
      }
    }
  }
`

const POLLING_QUERY = graphql`
  query AccountActivitySearchListPollingQuery(
    $count: Int!
    $cursor: String
    $identity: IdentityInputType!
    $eventTimestamp_Gt: DateTime
    $chains: [ChainScalar!]
    $collections: [CollectionSlug!]
    $eventTypes: [EventType!]
    $stringTraits: [TraitInputType!]
  ) {
    account(identity: $identity) {
      activity(
        after: $cursor
        first: $count
        eventTimestamp_Gt: $eventTimestamp_Gt
        chains: $chains
        collections: $collections
        eventTypes: $eventTypes
        stringTraits: $stringTraits
      ) {
        edges {
          node {
            ...EventHistory_data @arguments(showAll: true)
            eventTimestamp
          }
        }
      }
    }
  }
`

const LazyAccountActivitySearchList = (props: Props) => {
  const data = useLazyLoadQuery<AccountActivitySearchListQuery>(LIST_QUERY, {
    ...props.searchState,
    identity: props.searchState.identity ?? {},
    count: GLOBAL_MAX_PAGE_SIZE,
  })
  return <AccountActivitySearchListPagination data={data} {...props} />
}

const AccountActivitySearchListPagination = ({
  data: dataKey,
  searchState,
  activityTableKey,
}: PaginationProps) => {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    AccountActivitySearchListQuery,
    AccountActivitySearchListPagination_data$key
  >(PAGINATION_FRAGMENT, dataKey)

  const initialEvents = getNodes(data?.account?.activity)

  const [polledEvents, setPolledEvents] = useState<EventNode[]>([])

  // Events are sorted by eventTimestamp in descending order
  const latestEventTimestamp =
    polledEvents[0]?.eventTimestamp ?? initialEvents[0]?.eventTimestamp

  usePollingQuery<AccountActivitySearchListPollingQuery>(
    POLLING_QUERY,
    {
      ...searchState,
      identity: searchState.identity ?? {},
      count: GLOBAL_MAX_PAGE_SIZE,
      eventTimestamp_Gt: latestEventTimestamp,
    },
    {
      delay: ACCOUNT_ACTIVITY_SEARCH_POLLING_INTERVAL,
      skipOnHidden: true,
      onPoll: newData => {
        const newNodes = getNodes(newData.account?.activity)
        setPolledEvents(prev => [...newNodes, ...prev])
      },
    },
  )

  return (
    <EventHistoryBase
      activityTableKey={activityTableKey}
      data={[...polledEvents, ...initialEvents]}
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      mode="full"
      noPadding
      showCollectionLinks
    />
  )
}

const AccountActivitySearchListSkeleton = () => {
  return <ActivityTable assetEvents={[]} showCollectionLinks />
}

export const AccountActivitySearchList = (props: Props) => {
  return (
    <SsrSuspense fallback={<AccountActivitySearchListSkeleton />}>
      <LazyAccountActivitySearchList {...props} />
    </SsrSuspense>
  )
}
