import React from "react"
import { Icon, UnstyledButton, Text, Flex } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { ChainLogoWithTooltip } from "@/components/common/ChainLogo"
import { CopyAddress } from "@/components/common/CopyAddress.react"
import { TextCopy } from "@/components/common/TextCopy"
import { Block } from "@/design-system/Block"
import { Dropdown } from "@/design-system/Dropdown"
import { AddressPill_data$key } from "@/lib/graphql/__generated__/AddressPill_data.graphql"
import { getChecksumAddress, truncateAddress } from "@/lib/helpers/address"
import { getDefaultChainForAddress } from "@/lib/helpers/chainUtils"

// TODO(@auster-eth): support array of address/chain in the future
type Props = {
  data: AddressPill_data$key | null
}

const Pill = ({
  value,
  chainLogo,
}: {
  value: React.ReactNode
  chainLogo: React.ReactNode
}) => (
  <Text
    asChild
    className="inline-flex h-8 items-center justify-center gap-0.5 rounded-default fill-primary py-1 pl-0 pr-2 text-primary hover:text-secondary active:text-secondary"
  >
    <div>
      {chainLogo}
      {value}
    </div>
  </Text>
)

export function AddressPill({ data: dataKey }: Props) {
  const data = useFragment(
    graphql`
      fragment AddressPill_data on AccountType {
        address
        names {
          name
          type
        }
      }
    `,
    dataKey,
  )

  if (!data || !data.address) {
    return null
  }

  const finalAddress = getChecksumAddress(data.address)
  const content = <CopyAddress address={finalAddress} />
  const chainIdentifier = getDefaultChainForAddress(data.address)
  const chainLogo = <StyledChainLogo chain={chainIdentifier} />
  const ens =
    data.names.find(({ type }) => type === "ens")?.name || data.names[0]?.name

  if (ens) {
    return (
      <Dropdown
        content={({ List, Item }) => (
          <List>
            <Item>
              <TextCopy
                ItemComponent={Item}
                chainIdentifier={chainIdentifier}
                text={ens}
              />
            </Item>
            <Item>
              <TextCopy
                ItemComponent={Item}
                chainIdentifier={chainIdentifier}
                label={truncateAddress(finalAddress)}
                text={finalAddress}
              />
            </Item>
          </List>
        )}
      >
        <Block>
          <Pill
            chainLogo={chainLogo}
            value={
              <UnstyledButton>
                {ens}
                <Flex>
                  <Icon size={16} value="expand_more" weight={600} />
                </Flex>
              </UnstyledButton>
            }
          />
        </Block>
      </Dropdown>
    )
  }

  return (
    <Block>
      <Pill chainLogo={chainLogo} value={content} />
    </Block>
  )
}

const StyledChainLogo = styled(ChainLogoWithTooltip)`
  svg {
    fill: inherit;
  }
`
