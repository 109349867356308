/* istanbul ignore file */
import React, { forwardRef, useCallback, useMemo } from "react"
import { Checkbox, Flex } from "@opensea/ui-kit"
import { useStickyTop } from "@/components/layout/AccountOrCollectionPage/hooks/useStickyTop"
import { BlockProps } from "@/design-system/Block"
import { SelectOption } from "@/design-system/Select"
import { SortDirection } from "@/design-system/Table/SortableHeader.react"
import useSortableHeaders from "@/design-system/Table/useSortableHeaders"
import { VirtualizedTable } from "@/design-system/VirtualizedTable"
import { useTranslate } from "@/hooks/useTranslate"
import { TableHeaderText } from "../PortfolioTable/components/styles"
import { CHECKBOX_PADDING, COLUMN_CELL_PROPS } from "./constants"
import { useAnalytics } from "./hooks/useAnalytics"
import { useHeaderColumns } from "./hooks/useHeaderColumns"
import { useOffersTableContext } from "./OffersTableContext.react"
import {
  MobileOffersColumn,
  OffersSelectedState,
  OffersViewMode,
  SortableOffersColumn,
  isMobileOffersColumn,
  isSortableOffersColumn,
} from "./types"

type Props = BlockProps & {
  itemCount: number
  mode: OffersViewMode
  selectedState: OffersSelectedState
  onSelectAll: (selected: boolean) => unknown
  selectedSort?: { column: SortableOffersColumn; ascending: boolean }
  onSort: (column: SortableOffersColumn, ascending: boolean) => void
}

export const OffersTableHeader = forwardRef<HTMLDivElement, Props>(
  function OffersTableHeader(
    {
      itemCount,
      mode,
      selectedState,
      onSelectAll,
      selectedSort,
      onSort,
      ...props
    }: Props,
    ref,
  ) {
    const t = useTranslate("account")
    const { isDesktop, mobileColumn, setMobileColumn } = useOffersTableContext()
    const { trackSortOffersTableColumn } = useAnalytics()

    const isOwnerMode = mode === "owner"
    const stickyTop = useStickyTop()

    const headerColumns = useHeaderColumns(itemCount)

    const desktopHeaderColumns = useMemo(() => {
      const excludedColumns = ["offer", "status"]
      return headerColumns.filter(
        header => !excludedColumns.includes(header.column),
      )
    }, [headerColumns])

    const mobileHeaderColumns: SelectOption<MobileOffersColumn>[] =
      headerColumns
        .filter(headerColumn => isMobileOffersColumn(headerColumn.column))
        .map(({ header, column }) => ({
          label: header,
          value: column as MobileOffersColumn,
        }))

    const headerOptions = useMemo(
      () =>
        desktopHeaderColumns.map(({ column, header }) => ({
          header: (
            <Flex className="items-center">
              <TableHeaderText>{header}</TableHeaderText>
            </Flex>
          ),
          ...(isSortableOffersColumn(column)
            ? {
                sort: (direction?: SortDirection) => {
                  const isDirectionAsc = direction === "asc"
                  onSort(column, isDirectionAsc)
                  trackSortOffersTableColumn({ column, isDirectionAsc })
                },
                sortIndicatorHideMode: "remove" as const,
              }
            : {}),
        })),
      [desktopHeaderColumns, onSort, trackSortOffersTableColumn],
    )

    const selectedColumnIndex = desktopHeaderColumns.findIndex(
      headerColumn => headerColumn.column === selectedSort?.column,
    )

    const sortableHeaders = useSortableHeaders(
      headerOptions,
      selectedSort && selectedColumnIndex >= 0
        ? {
            index: selectedColumnIndex >= 0 ? selectedColumnIndex : undefined,
            direction: selectedSort.ascending ? "asc" : "desc",
          }
        : undefined,
    )

    const handleSelectAll = useCallback(
      (selected: boolean) => {
        if (selectedState === "some" && selected) {
          // Deselect all when some, but not all items, are selected and the
          // user selects the checkmark.
          onSelectAll(false)
        } else {
          onSelectAll(selected)
        }
      },
      [onSelectAll, selectedState],
    )

    const offersColumn = headerColumns.find(header => header.column === "offer")
      ?.header

    return (
      <VirtualizedTable.Header
        alignItems="center"
        className="w-full"
        gap={16}
        height="52px"
        ref={ref}
        stickyTop={stickyTop}
        {...props}
      >
        <VirtualizedTable.Header.Cell {...COLUMN_CELL_PROPS["offer"]}>
          {isOwnerMode ? (
            <Flex className="h-full items-center">
              <Flex
                className="items-center"
                style={{ paddingRight: CHECKBOX_PADDING }}
              >
                <Checkbox
                  checked={
                    selectedState === "some"
                      ? "indeterminate"
                      : selectedState === "all"
                  }
                  disabled={!itemCount}
                  size="small"
                  onCheckedChange={handleSelectAll}
                />
              </Flex>
              <TableHeaderText>{offersColumn}</TableHeaderText>
            </Flex>
          ) : (
            <TableHeaderText>{offersColumn}</TableHeaderText>
          )}
        </VirtualizedTable.Header.Cell>
        {isDesktop ? (
          sortableHeaders.map((header, index) => {
            const column = desktopHeaderColumns[index].column
            const columnProps = COLUMN_CELL_PROPS[column]
            return (
              <VirtualizedTable.Header.Cell key={column} {...columnProps}>
                {header}
              </VirtualizedTable.Header.Cell>
            )
          })
        ) : (
          <VirtualizedTable.Header.Cell flexGrow={1} justifyContent="flex-end">
            <VirtualizedTable.Header.Select
              column={mobileColumn}
              options={mobileHeaderColumns}
              title={t("offersTable.header.select.title", "View")}
              onSelect={setMobileColumn}
            />
          </VirtualizedTable.Header.Cell>
        )}
      </VirtualizedTable.Header>
    )
  },
)
