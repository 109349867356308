import { DealStatus } from "@/features/deals/types"
import { useTranslate } from "@/hooks/useTranslate"

export const useDealStatusText = (status: DealStatus): string => {
  const t = useTranslate("deals")
  switch (status) {
    case "creating":
      return t("dealStatus.creating", "Creating")
    case "active":
      return t("dealStatus.active", "Active")
    case "accepted":
      return t("dealStatus.accepted", "Accepted")
    case "cancelled":
      return t("dealStatus.canceled", "Canceled")
    case "expired":
      return t("dealStatus.expired", "Expired")
    case "inactive":
      return t("dealStatus.inactive", "Inactive")
    default:
      throw new Error(`Unknown deal status: ${status}`)
  }
}
