/**
 * @generated SignedSource<<d040964358d63dba908b6a5bd8eef369>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HomePageCollectionCard_data$data = {
  readonly banner: string | null;
  readonly name: string;
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCardFooter_data" | "collection_url" | "useDropState_data">;
  readonly " $fragmentType": "HomePageCollectionCard_data";
};
export type HomePageCollectionCard_data$key = {
  readonly " $data"?: HomePageCollectionCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeWindow"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePageCollectionCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "banner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDropState_data"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "timeWindow",
          "variableName": "timeWindow"
        }
      ],
      "kind": "FragmentSpread",
      "name": "HomePageCollectionCardFooter_data"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "486889641c2c410a7dea8877df45501c";

export default node;
