import React, { useEffect, useState } from "react"
import { useUpdateEffect } from "react-use"
import { AssetCardVariantToggle } from "@/components/layout/AccountOrCollectionPage/components/AssetCardVariantToggle"
import { FilterBar } from "@/components/layout/AccountOrCollectionPage/components/FilterBar"
import AssetSearchChainDropdown from "@/components/search/assets/AssetSearchChainDropdown.react"
import AssetSearchFilter, {
  getFilterCount,
  useDefaultStatusItems,
} from "@/components/search/assets/AssetSearchFilter"
import { AssetSearchLayout } from "@/components/search/assets/AssetSearchLayout/AssetSearchLayout.react"
import { AssetSearchTraitAndQueryInput } from "@/components/search/assets/AssetSearchTraitAndQueryInput"
import {
  AssetCardVariant,
  updateCardVariantCookie,
} from "@/components/search/assets/AssetSearchView"
import { PhoenixAssetSearchSortDropdown } from "@/components/search/assets/AssetSearchView/elements"
import SearchStatusDropdown from "@/components/search/assets/SearchStatusDropdown.react"
import { SearchPills } from "@/components/search/SearchPills.react"
import { ToggleFilterButton } from "@/components/search/ToggleFilterButton.react"
import { Search, useSearch } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSearch } from "@/lib/analytics/events/searchEvents"
import { RequiredKeys } from "@/lib/helpers/type"
import { ProfileItemSelectionFooter } from "../../components/ProfileItemSelectionFooter"
import { ASSET_SEARCH_ACCOUNT_SORTS } from "../Default.react"
import {
  AccountPrivateAssetSearchList,
  AccountPrivateSearchItems,
} from "./AccountPrivateAssetSearchList.react"

export type AccountPrivateAssetSearchState = RequiredKeys<
  Pick<
    Search,
    | "chains"
    | "collection"
    | "collections"
    | "isAutoHidden"
    | "query"
    | "sortAscending"
    | "sortBy"
    | "toggles"
  >
> &
  Required<Pick<Search, "identity">>

type Props = {
  defaultState: AccountPrivateAssetSearchState
  initialState: Partial<AccountPrivateAssetSearchState>
  fixedState: Partial<AccountPrivateAssetSearchState>
  assetCardVariant: AssetCardVariant | undefined
  path: string
}

export const AccountPrivateAssetSearch = ({
  defaultState,
  initialState,
  fixedState,
  assetCardVariant: initialAssetCardVariant,
  path,
}: Props) => {
  const t = useTranslate("account")
  const defaultStatusItems = useDefaultStatusItems()

  const { searchState, clear, update } = useSearch({
    defaultState,
    initialState,
    fixedState,
    path,
  })

  const filterCount = getFilterCount(searchState, {
    includeCollectionFilter: true,
  })

  const [assetCardVariant, setAssetCardVariant] = useState(
    initialAssetCardVariant,
  )

  useUpdateEffect(
    () => updateCardVariantCookie("account", assetCardVariant),
    [assetCardVariant],
  )

  useEffect(() => {
    trackSearch({
      type: "AssetSearch",
      path: window.location.pathname,
      queryString: window.location.search,
      pageIndex: 0,
      itemDisplayVariant: assetCardVariant ?? "unset",
      ...searchState,
    })
  }, [searchState, assetCardVariant])

  return (
    <AssetSearchLayout
      assets={
        <AccountPrivateAssetSearchList
          cardVariant={assetCardVariant}
          clear={clear}
          searchState={searchState}
        />
      }
      filter={
        <AssetSearchFilter
          clear={clear}
          dataKey={null}
          includeChainFilter
          includeProfileCollectionFilter
          isPrivateTab
          setChains={chains => update({ chains })}
          setCollectionSlugs={collections =>
            update({
              collection: collections ? collections[0] : undefined,
              collections: collections ? collections : [],
            })
          }
          setIsAutoHidden={isAutoHidden => update({ isAutoHidden })}
          setToggles={toggles => update({ toggles })}
          showChainFilterOnMobileOnly
          showStatusFilterOnMobileOnly
          state={searchState}
        />
      }
      footer={
        <ProfileItemSelectionFooter<AccountPrivateSearchItems>
          itemVisibilityAction="unhide"
          reloadSearch={update}
        />
      }
      header={
        <FilterBar
          assetCardVariantToggle={
            <AssetCardVariantToggle
              currentCardVariant={assetCardVariant}
              setCurrentCardVariant={setAssetCardVariant}
            />
          }
          chainDropdown={
            <AssetSearchChainDropdown
              activeChains={searchState.chains || []}
              setChains={chains => update({ chains })}
            />
          }
          filterToggle={<ToggleFilterButton numFiltersApplied={filterCount} />}
          search={
            <AssetSearchTraitAndQueryInput
              onEnter={query => update({ query: `${query}` })}
            />
          }
          sortDropdown={
            <PhoenixAssetSearchSortDropdown
              fullWidth
              searchState={searchState}
              setSort={update}
              sortOptions={ASSET_SEARCH_ACCOUNT_SORTS}
            />
          }
          statusDropdown={
            <SearchStatusDropdown
              filters={searchState.toggles || []}
              possibleFilterItems={defaultStatusItems}
              setFilters={toggles => update({ toggles })}
              title={t("assetSearchFilter.status.title", "Status")}
            />
          }
        />
      }
      pills={
        <SearchPills
          chainFilter={searchState.chains}
          clear={clear}
          collectionFilter={searchState.collections}
          featuredFilter={searchState.toggles}
          isAutoHiddenFilter={searchState.isAutoHidden}
          queryFilter={searchState.query}
          update={update}
        />
      }
    />
  )
}
