/**
 * @generated SignedSource<<b8f0ab4c3d779208c5f8e33aa44f584b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableRow_item$data = {
  readonly __typename: string;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableBestOfferCell_item" | "PortfolioTableCostCell_item" | "PortfolioTableDifferenceCell_item" | "PortfolioTableExpandedRow_item" | "PortfolioTableFloorPriceCell_item" | "PortfolioTableItemCell_item" | "PortfolioTableListingCell_item" | "PortfolioTableOptionsCell_item">;
  readonly " $fragmentType": "PortfolioTableRow_item";
};
export type PortfolioTableRow_item$key = {
  readonly " $data"?: PortfolioTableRow_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableRow_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "kind": "Variable",
    "name": "showOwnerActions",
    "variableName": "showOwnerActions"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showOwnerActions"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableRow_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "PortfolioTableItemCell_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableFloorPriceCell_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableBestOfferCell_item"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "PortfolioTableListingCell_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableCostCell_item"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "PortfolioTableDifferenceCell_item"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "PortfolioTableOptionsCell_item"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "PortfolioTableExpandedRow_item"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "f6f9ac02db1a34aa8ecf4f0cfb1be3a9";

export default node;
