import React, { useCallback, useMemo } from "react"
import { noop } from "lodash"
import { graphql, useFragment } from "react-relay"
import { useClearSearchContext } from "@/components/search/utils/SearchContextProvider.react"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants"
import {
  TableRowRenderProps,
  VirtualizedTable,
  VirtualizedTablePaginationProps,
} from "@/design-system/VirtualizedTable"
import {
  useOfferContextActions,
  useOfferSelectionCanceledIds,
  useOfferSelectionCount,
} from "@/features/account/hooks/useOfferSelection"
import { useMountEffect } from "@/hooks/useMountEffect"
import { OrderSortOption } from "@/lib/graphql/__generated__/AccountOffersOrderSearchListQuery.graphql"
import { OffersTable_orders$key } from "@/lib/graphql/__generated__/OffersTable_orders.graphql"
import { PartiallyOptional } from "@/lib/helpers/type"
import { PAGE_SIZE, ROW_HEIGHT } from "./constants"
import { useAnalytics } from "./hooks/useAnalytics"
import { readOfferSelectionItem } from "./hooks/useOfferSelectionState"
import { OffersTableContextProvider } from "./OffersTableContext.react"
import { OffersTableHeader } from "./OffersTableHeader"
import { OffersTableRow, OffersTableRowProps } from "./OffersTableRow"
import { OffersViewMode, OrderSort, SortableOffersColumn } from "./types"

type Props = {
  orders: OffersTable_orders$key | null
  mode: OffersViewMode
  totalItemCount: number
  selectedSort?: OrderSort
  onSort: (sort: OrderSort) => unknown
} & Omit<VirtualizedTablePaginationProps, "paginationThreshold">

const COLUMN_TO_SORT_BY_MAP: Record<SortableOffersColumn, OrderSortOption> = {
  price: "PRICE",
  total: "TOTAL_PRICE",
  quantity: "REMAINING_QUANTITY",
  expiration: "CLOSED_AT",
  dateCreated: "OPENED_AT",
}

const SORT_BY_TO_COLUMN_MAP: Partial<
  Record<OrderSortOption, SortableOffersColumn>
> = {
  PRICE: "price",
  TOTAL_PRICE: "total",
  REMAINING_QUANTITY: "quantity",
  CLOSED_AT: "expiration",
  OPENED_AT: "dateCreated",
}

type Item = PartiallyOptional<OffersTableRowProps, "order"> & {
  itemKey: string | undefined
}

const RenderRow = ({ item }: TableRowRenderProps<Item>) => {
  return item.order ? (
    <OffersTableRow order={item.order} {...item} />
  ) : (
    <OffersTableRow.Skeleton mode={item.mode} />
  )
}

const OffersTableBase = ({
  orders: ordersKey,
  isLoadingNext,
  loadNext,
  hasNext,
  totalItemCount,
  selectedSort,
  onSort,
  mode,
}: Props) => {
  const orders = useFragment(
    graphql`
      fragment OffersTable_orders on OrderV2Type
      @relay(plural: true)
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        __typename
        relayId
        ...OffersTableRow_order @arguments(identity: $identity)
        ...useOfferSelectionState_order
        ...useAnalyticsOfferEvent_data
      }
    `,
    ordersKey,
  )
  const canceledOfferIds = useOfferSelectionCanceledIds()
  const selectedOfferCount = useOfferSelectionCount()
  const { onDelete, onSelect, onBatchSelect, onDeleteAll } =
    useOfferContextActions()

  const { trackSelectAllOffersTableRows, trackSelectOffersTableRow } =
    useAnalytics()
  const handleSelect = useCallback(
    (relayId: string, selected: boolean) => {
      const order = orders?.find(order => order.relayId === relayId)
      if (!order) {
        return
      }

      if (selected) {
        onSelect(readOfferSelectionItem(order))
      } else {
        onDelete([relayId])
      }
      trackSelectOffersTableRow(order, {
        selected,
        selectedCount: selectedOfferCount,
        totalItemCount,
      })
    },
    [
      onDelete,
      onSelect,
      orders,
      selectedOfferCount,
      totalItemCount,
      trackSelectOffersTableRow,
    ],
  )

  const handleSelectAll = useCallback(
    (selected: boolean) => {
      if (!orders) {
        return
      }
      if (selected) {
        onBatchSelect(orders.map(readOfferSelectionItem))
      } else {
        onDeleteAll()
      }
      trackSelectAllOffersTableRows({
        selected,
        selectedCount: selectedOfferCount,
        totalItemCount,
      })
    },
    [
      orders,
      trackSelectAllOffersTableRows,
      selectedOfferCount,
      totalItemCount,
      onBatchSelect,
      onDeleteAll,
    ],
  )

  const handleSort = useCallback(
    (column: SortableOffersColumn, ascending: boolean) => {
      onSort({
        orderSortBy: COLUMN_TO_SORT_BY_MAP[column],
        sortAscending: ascending,
      })
    },
    [onSort],
  )

  const selectedState =
    selectedOfferCount === 0
      ? "none"
      : selectedOfferCount === totalItemCount
      ? "all"
      : "some"

  const skeletonCount = useMemo(() => {
    if (!orders) {
      return GLOBAL_MAX_PAGE_SIZE
    }
    const itemCount = orders.length
    const remainingItemCount = totalItemCount - itemCount

    return hasNext ? Math.min(remainingItemCount, GLOBAL_MAX_PAGE_SIZE) : 0
  }, [orders, hasNext, totalItemCount])

  const offerKey = useCallback((item: Item | undefined) => item?.itemKey, [])

  const selectedSortColumn = selectedSort?.orderSortBy
    ? SORT_BY_TO_COLUMN_MAP[selectedSort.orderSortBy]
    : undefined
  const convertedSort =
    selectedSort && selectedSortColumn
      ? {
          column: selectedSortColumn,
          ascending: selectedSort.sortAscending,
        }
      : undefined

  const offers = useMemo(() => {
    const length: number = (orders?.length ?? 0) + skeletonCount
    return Array(length)
      .fill(undefined)
      .map((_, i) => {
        const order = orders ? orders[i] : undefined
        return {
          order,
          mode,
          showChain: selectedOfferCount > 0,
          onSelect: handleSelect,
          itemKey: order?.relayId,
        }
      })
      .filter(o => !canceledOfferIds.includes(o.order?.relayId ?? ""))
  }, [
    canceledOfferIds,
    skeletonCount,
    handleSelect,
    mode,
    orders,
    selectedOfferCount,
  ])

  if (!offers.length) {
    return null
  }

  return (
    <OffersTableContextProvider>
      <VirtualizedTable
        hasNext={hasNext}
        header={
          <OffersTableHeader
            itemCount={orders?.length ? totalItemCount : 0}
            mode={mode}
            selectedSort={convertedSort}
            selectedState={selectedState}
            onSelectAll={handleSelectAll}
            onSort={handleSort}
          />
        }
        isLoadingNext={isLoadingNext}
        itemHeightEstimate={{ min: ROW_HEIGHT }}
        itemKey={offerKey}
        items={offers}
        loadNext={loadNext}
        overscanBy={PAGE_SIZE}
        renderRow={RenderRow}
      />
    </OffersTableContextProvider>
  )
}

type SkeletonProps = Pick<Props, "mode" | "totalItemCount">
const OffersTableSkeleton = ({ mode, totalItemCount }: SkeletonProps) => {
  const clearSearchContext = useClearSearchContext()

  useMountEffect(() => {
    clearSearchContext()
  })

  return (
    <OffersTableBase
      hasNext
      isLoadingNext={false}
      loadNext={noop}
      mode={mode}
      orders={[]}
      totalItemCount={totalItemCount}
      onSort={noop}
    />
  )
}

export const OffersTable = Object.assign(OffersTableBase, {
  Skeleton: OffersTableSkeleton,
})
