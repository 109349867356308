import { useMemo } from "react"
import { differenceInHours } from "date-fns"
import { graphql, useFragment } from "react-relay"
import {
  useValidTimeRangeOptions,
  useTimeRangeState,
} from "@/components/analytics/TimeRangeSelector"
import { useActivityPriceHistoryTimeRangeOptions } from "@/components/search/activity/useActivityPriceHistoryTimeRangeOptions"
import { CHAINS_WITH_ETH_NATIVE_PAYMENT_ASSET } from "@/features/collections/components/CollectionPage/hooks/useIsAnalyticsTabEnabled"
import { useTranslate } from "@/hooks/useTranslate"
import { EventType } from "@/lib/graphql/__generated__/ItemType.graphql"
import { usePriceHistoryChartProps_collection$key } from "@/lib/graphql/__generated__/usePriceHistoryChartProps_collection.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { getBucketSize } from "./utils"

type Props = {
  collection: usePriceHistoryChartProps_collection$key | null
  eventTypes?: readonly EventType[]
}

export const usePriceHistoryChartProps = ({
  collection: collectionDataKey,
  eventTypes,
}: Props) => {
  const t = useTranslate("components")

  const collection = useFragment(
    graphql`
      fragment usePriceHistoryChartProps_collection on CollectionType {
        createdDate
        firstSale {
          eventTimestamp
        }
        defaultChain {
          identifier
        }
      }
    `,
    collectionDataKey,
  )

  const shouldRenderPriceHistory =
    eventTypes?.length === 1 &&
    eventTypes[0] === "AUCTION_SUCCESSFUL" && // only the sales filter applied
    collection &&
    CHAINS_WITH_ETH_NATIVE_PAYMENT_ASSET.includes(
      collection.defaultChain.identifier,
    )

  const firstSaleDate = useMemo(
    () =>
      collection
        ? dateFromISO8601(
            collection.firstSale?.eventTimestamp ?? collection.createdDate,
          )
        : undefined,
    [collection],
  )

  const fixedTimeRangeOptions = useActivityPriceHistoryTimeRangeOptions()
  const timeRangeOptions = useValidTimeRangeOptions({
    options: fixedTimeRangeOptions,
    minTime: firstSaleDate,
    minTimeLabel: t("analytics.dateRange.allTime", "All time"),
  })
  const [timeRange, setTimeRange] = useTimeRangeState({
    options: timeRangeOptions,
  })

  const hoursSinceFirstSale = firstSaleDate
    ? differenceInHours(new Date(), new Date(firstSaleDate), {
        roundingMethod: "ceil",
      })
    : undefined

  const isTimeRangeAllTime =
    hoursSinceFirstSale && Number(timeRange) === hoursSinceFirstSale

  // Get bucketSize from collection first sale date when time range is "All time"
  const bucketSize = isTimeRangeAllTime
    ? getBucketSize(hoursSinceFirstSale.toString())
    : timeRange
    ? getBucketSize(timeRange)
    : undefined

  return {
    shouldRenderPriceHistory,
    bucketSize,
    timeRangeOptions,
    setTimeRange,
    timeRange,
    isTimeRangeAllTime,
  }
}
