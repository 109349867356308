import { MobilePortfolioColumn } from "@/features/account/components/PortfolioTable/types"
import { getTrackingFn } from "@/lib/analytics"
import { getItemTrackingFnWithExtraParamsV2 } from "@/lib/analytics/events/itemEvents"

export type PortfolioTableButtonSource = "collapsed" | "expanded"

type RowEvent = {
  expanded: boolean
}

type SelectItemEvent = {
  selected: boolean
  selectedCount: number
  totalItemCount: number
}

type SelectColumnEvent = {
  column: MobilePortfolioColumn
}

export const trackClickPortfolioTableRow =
  getItemTrackingFnWithExtraParamsV2<RowEvent>("click portfolio table row")

export const trackSelectPortfolioTableRow =
  getItemTrackingFnWithExtraParamsV2<SelectItemEvent>(
    "select portfolio table row",
  )

export const trackSelectAllPortfolioTableRows = getTrackingFn<SelectItemEvent>(
  "select all portfolio table rows",
)

export const trackSelectPortfolioTableMobileColumn =
  getTrackingFn<SelectColumnEvent>("select portfolio table mobile column")
