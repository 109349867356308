/**
 * @generated SignedSource<<6df6ad9995b45c36cb89e8655b17ba42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountActivitySearchQuery$variables = {
  collection: string;
  collections?: ReadonlyArray<string> | null;
};
export type AccountActivitySearchQuery$data = {
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchProperties_collection" | "usePriceHistoryChartProps_collection">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SearchPills_data">;
};
export type AccountActivitySearchQuery = {
  response: AccountActivitySearchQuery$data;
  variables: AccountActivitySearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collection"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collections"
},
v2 = {
  "kind": "Variable",
  "name": "collections",
  "variableName": "collections"
},
v3 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountActivitySearchQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SearchPills_data"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "usePriceHistoryChartProps_collection"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchProperties_collection"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountActivitySearchQuery",
    "selections": [
      {
        "alias": "selectedCollections",
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "first",
            "value": 25
          },
          {
            "kind": "Literal",
            "name": "includeHidden",
            "value": true
          }
        ],
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetEventType",
            "kind": "LinkedField",
            "name": "firstSale",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventTimestamp",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChainType",
            "kind": "LinkedField",
            "name": "defaultChain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identifier",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NumericTraitTypePair",
            "kind": "LinkedField",
            "name": "numericTraits",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NumericTraitType",
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "max",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "min",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StringTraitType",
            "kind": "LinkedField",
            "name": "stringTraits",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StringTraitCountType",
                "kind": "LinkedField",
                "name": "counts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8def177b914ded9079846fd38e1822fd",
    "id": null,
    "metadata": {},
    "name": "AccountActivitySearchQuery",
    "operationKind": "query",
    "text": "query AccountActivitySearchQuery(\n  $collections: [CollectionSlug!]\n  $collection: CollectionSlug!\n) {\n  ...SearchPills_data_2Kg4Sq\n  collection(collection: $collection) {\n    ...usePriceHistoryChartProps_collection\n    ...SearchProperties_collection\n    id\n  }\n}\n\nfragment NumericTraitFilter_data on NumericTraitTypePair {\n  key\n  value {\n    max\n    min\n  }\n}\n\nfragment SearchPills_data_2Kg4Sq on Query {\n  selectedCollections: collections(first: 25, collections: $collections, includeHidden: true) {\n    edges {\n      node {\n        imageUrl\n        name\n        slug\n        id\n      }\n    }\n  }\n}\n\nfragment SearchProperties_collection on CollectionType {\n  slug\n  numericTraits {\n    key\n    value {\n      max\n      min\n    }\n    ...NumericTraitFilter_data\n  }\n  stringTraits {\n    key\n    ...StringTraitFilter_data\n  }\n}\n\nfragment StringTraitFilter_data on StringTraitType {\n  counts {\n    count\n    value\n  }\n  key\n}\n\nfragment usePriceHistoryChartProps_collection on CollectionType {\n  createdDate\n  firstSale {\n    eventTimestamp\n    id\n  }\n  defaultChain {\n    identifier\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc751bbbb86c7cfb00de9809d6faccf0";

export default node;
