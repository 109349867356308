import { useFragment } from "react-relay"
import { ProfilePageFeaturedFragment } from "@/features/account/queries/profilePageQueries"
import { profilePageQueries_featured$key } from "@/lib/graphql/__generated__/profilePageQueries_featured.graphql"

type Props = {
  featuredKey: profilePageQueries_featured$key | null
  isYourProfile: boolean
}

export const useFeaturedShelfCount = ({
  featuredKey,
  isYourProfile,
}: Props) => {
  const featuredFragment = useFragment(ProfilePageFeaturedFragment, featuredKey)
  const shelves = featuredFragment?.shelves
  if (isYourProfile) {
    return shelves?.length ?? null
  }
  return (
    shelves?.filter(shelf => shelf.shelfAssets && shelf.shelfAssets.length > 0)
      .length ?? null
  )
}
