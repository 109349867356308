/**
 * @generated SignedSource<<a9ebd7c30220bc80dd2ec4327c11e0fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableBestOfferCell_item$data = {
  readonly __typename: "AssetBundleType";
  readonly orderData: {
    readonly bestBidV2: {
      readonly priceType: {
        readonly symbol: string;
        readonly unit: string;
      };
    } | null;
  };
  readonly " $fragmentType": "PortfolioTableBestOfferCell_item";
} | {
  readonly __typename: "AssetType";
  readonly bestAllTypeBid: {
    readonly perUnitPriceType: {
      readonly symbol: string;
      readonly unit: string;
    };
  } | null;
  readonly " $fragmentType": "PortfolioTableBestOfferCell_item";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "PortfolioTableBestOfferCell_item";
};
export type PortfolioTableBestOfferCell_item$key = {
  readonly " $data"?: PortfolioTableBestOfferCell_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableBestOfferCell_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableBestOfferCell_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAllTypeBid",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPriceType",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ESOrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestBidV2",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "priceType",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "7ea67a03883a5ed361e1fdce8c6ff6ee";

export default node;
