import React from "react"
import {
  Breakpoint,
  Media,
  useIsLessThanSm,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import { isFunction } from "lodash"
import styled, { css } from "styled-components"
import { BOTTOM_ACTION_BAR_HEIGHT_PX } from "@/components/common/BottomActionBar/BottomActionBar.react"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { useSidebarContext } from "@/components/search/SidebarContext.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { MobileDropdown } from "@/features/account/components/MobileDropdown"
import { useTranslate } from "@/hooks/useTranslate"
import { trackCloseSidebarFilter } from "@/lib/analytics/events/searchEvents"

type SearchFilterDropdownProps = {
  children: ((close: () => unknown) => React.ReactNode) | React.ReactNode
  fullscreenBreakpoint?: Breakpoint
  clear: () => unknown
  topOffset?: number
}

const SearchFilterDropdown = ({
  children,
  fullscreenBreakpoint = "sm",
  clear,
  topOffset,
}: SearchFilterDropdownProps) => {
  const { setSidebarCollapsed } = useSidebarContext()
  const t = useTranslate("components")
  const {
    sidebarOpen,
    contentTopOffset,
    isMobileFilterDrawerOpen,
    setIsMobileFilterDrawerOpen,
    isFooterShown,
  } = useAccountOrCollectionPageContext()
  const isMobile = useIsLessThanSm()

  const closeFilterSidebar = () => {
    setSidebarCollapsed(true)
    trackCloseSidebarFilter()
  }

  const closeIfMobile = () => {
    if (isMobile) {
      closeFilterSidebar()
    }
  }

  return (
    <>
      <Media lessThan={fullscreenBreakpoint}>
        <MobileDropdown
          isOpen={isMobileFilterDrawerOpen}
          onClose={() => setIsMobileFilterDrawerOpen(false)}
        >
          <>
            <StyledMobileDropdownHeader>
              <MobileDropdown.Title>
                {t("search.filters", "Filters")}
              </MobileDropdown.Title>
            </StyledMobileDropdownHeader>
            <StyledMobileDropdownBody>
              <FlexColumn className="mx-1.5">
                {isFunction(children) ? children(closeIfMobile) : children}
              </FlexColumn>
            </StyledMobileDropdownBody>
            <Flex className="bottom-0 z-[1] mt-auto w-full gap-2 bg-base-1 p-4">
              <Button
                className="w-full"
                variant="secondary"
                onClick={() => {
                  clear()
                }}
              >
                {t("search.clear", "Clear")}
              </Button>
              <Button
                className="w-full"
                onClick={() => setIsMobileFilterDrawerOpen(false)}
              >
                {t("search.showResults", "Show results")}
              </Button>
            </Flex>
          </>
        </MobileDropdown>
      </Media>
      <Media greaterThanOrEqual={fullscreenBreakpoint}>
        {className => {
          return (
            <DesktopFilterContainer
              $isFooterShown={isFooterShown}
              className={className}
              isOpen={sidebarOpen}
              topOffset={topOffset ?? contentTopOffset}
            >
              <Block paddingRight="16px">
                {isFunction(children) ? children(closeIfMobile) : children}
              </Block>
            </DesktopFilterContainer>
          )
        }}
      </Media>
    </>
  )
}

export default SearchFilterDropdown

const DesktopFilterContainer = styled(Block)<{
  topOffset: number
  isOpen: boolean
  $isFooterShown: boolean
}>`
  flex-direction: column;
  position: sticky;
  top: ${props => `${props.topOffset}px`};
  height: calc(100vh - (${props => `${props.topOffset}px`}));
  /* Note(@vickyg) width is made to match collection filter panel width
  (SearchFilter profile variant) */
  width: ${props => (props.isOpen ? "425px" : 0)};
  min-width: ${props => (props.isOpen ? "350px" : undefined)};
  overflow: auto;
  //NOTE(@auster-eth): This is to align the filter text content which has 10pixels of padding
  ${props =>
    props.isOpen &&
    css`
      margin-left: -10px;
      margin-right: 16px;
    `}

  ${props =>
    props.$isFooterShown &&
    css`
      padding-bottom: ${BOTTOM_ACTION_BAR_HEIGHT_PX};
    `}
`

const StyledMobileDropdownBody = styled(MobileDropdown.Body)`
  background-color: ${props => props.theme.colors.base1};
  height: 100vh;
`

const StyledMobileDropdownHeader = styled(MobileDropdown.Header)`
  background-color: ${props => props.theme.colors.base1};
  border-top-left-radius: ${props => props.theme.borderRadius.modal};
  border-top-right-radius: ${props => props.theme.borderRadius.modal};
`
