import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { ProfileImage } from "@/components/common/ProfileImage.react"
import { PAGE_THUMBNAIL_SIZE } from "@/components/layout/AccountOrCollectionPage/constants"
import {
  CollectionProfileHeaderThumb,
  Container as CollectionProfileHeaderThumbContainer,
} from "@/features/collections/components/CollectionPage/components/CollectionProfileHeaderThumb/CollectionProfileHeaderThumb.react"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountOrCollectionProfileImage_data$key } from "@/lib/graphql/__generated__/AccountOrCollectionProfileImage_data.graphql"

type Props = {
  data: AccountOrCollectionProfileImage_data$key | null
  editable: boolean
}

export const AccountOrCollectionProfileImage = ({
  data: dataKey,
  editable,
}: Props) => {
  const t = useTranslate("phoenix")
  const profileImageData = useFragment(
    graphql`
      fragment AccountOrCollectionProfileImage_data on AccountType {
        ...ProfileImage_data
        imageUrl
        user {
          publicUsername
        }
        displayName
      }
    `,
    dataKey,
  )

  if (editable) {
    if (!profileImageData) {
      return (
        <CollectionProfileHeaderThumb
          altText={t("info.profileImageLabel", "User profile image")}
          imageUrl=""
          variant="profile"
        />
      )
    }

    return (
      <CollectionProfileHeaderThumbContainer $imageVariant="profile">
        <StyledProfileImage
          data={profileImageData}
          editable={editable}
          isDynamic
          loaderSize="large"
          size={PAGE_THUMBNAIL_SIZE}
        />
      </CollectionProfileHeaderThumbContainer>
    )
  }

  return (
    <CollectionProfileHeaderThumb
      altText={
        profileImageData?.user?.publicUsername ||
        profileImageData?.displayName ||
        t("info.profileImageLabel", "User profile image")
      }
      imageUrl={profileImageData?.imageUrl || ""}
      variant="profile"
    />
  )
}

const StyledProfileImage = styled(ProfileImage)`
  width: 100%;
  height: 100%;
`
