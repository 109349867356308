import React, { useEffect, useMemo, useState } from "react"
import {
  SortableHeader,
  SortableHeaderProps,
  SortDirection,
} from "./SortableHeader.react"

type SortState = {
  index?: number
  direction?: "asc" | "desc"
}

type SortableHeaderOptions = Omit<
  SortableHeaderProps,
  "sortDirection" | "initialSortDirection"
>

const useSortableHeaders = (
  headerOptions: SortableHeaderOptions[],
  sortState?: SortState,
) => {
  const [currentSort, setCurrentSort] = useState<SortState | undefined>(
    sortState,
  )

  // Allows the sort state to be controlled externally,
  // e.g. by a separate sort dropdown.
  useEffect(() => {
    if (
      sortState?.index !== currentSort?.index ||
      sortState?.direction !== currentSort?.direction
    ) {
      setCurrentSort(sortState)
    }
  }, [currentSort, sortState])

  return useMemo(
    () =>
      headerOptions.map(({ sort, ...restProps }, index) => {
        const handleSort = sort
          ? async (direction?: SortDirection) => {
              setCurrentSort({
                index,
                direction,
              })
              await sort(direction)
            }
          : undefined

        return (
          <SortableHeader
            initialSortDirection={sortState?.direction}
            key={index}
            sort={handleSort}
            sortDirection={
              currentSort?.index === index ? currentSort.direction : undefined
            }
            {...restProps}
          />
        )
      }),
    [
      currentSort?.direction,
      currentSort?.index,
      headerOptions,
      sortState?.direction,
    ],
  )
}

export default useSortableHeaders
