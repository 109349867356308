import React, { ComponentProps } from "react"
import { FlexColumn, Text, classNames, Skeleton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { useRafState } from "react-use"
import styled from "styled-components"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Link, LinkProps } from "@/components/common/Link"
import { PLACEHOLDER_IMAGE } from "@/constants"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import {
  DROP_STATES,
  DROP_STATES_TYPE,
  useDropState,
} from "@/features/primary-drops/hooks/useDropState"
import { HomePageCollectionCard_data$key } from "@/lib/graphql/__generated__/HomePageCollectionCard_data.graphql"
import { StatsTimeWindow } from "@/lib/graphql/__generated__/RankingsPageTopQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  getCollectionOverviewUrl,
  getCollectionUrl,
} from "@/lib/helpers/collection"
import { resizeImage } from "@/lib/helpers/urls"
import { verificationStatusHasBadge } from "@/lib/helpers/verification"
import { HomePageCollectionCardFooter } from "./HomePageCollectionCardFooter.react"

const CARD_BANNER_HEIGHT = 400
const CARD_BANNER_WIDTH = CARD_BANNER_HEIGHT * 1.5

type Props = {
  collection: HomePageCollectionCard_data$key
  volumeWindow: StatsTimeWindow
  overrideBannerUrl?: string
  shouldOpenNewTab?: boolean
  hasShadow?: boolean
} & Pick<LinkProps, "eventParams" | "eventSource">

const HomePageCollectionCardBase = ({
  collection: collectionKey,
  eventSource,
  eventParams,
  volumeWindow,
  overrideBannerUrl,
  hasShadow = true,
  shouldOpenNewTab,
}: Props) => {
  const [isHovering, setIsHovering] = useRafState(false)

  const data = useFragment(
    graphql`
      fragment HomePageCollectionCard_data on CollectionType
      @argumentDefinitions(timeWindow: { type: "StatsTimeWindow" }) {
        banner
        name
        verificationStatus
        ...useDropState_data
        ...HomePageCollectionCardFooter_data @arguments(timeWindow: $timeWindow)
        ...collection_url
      }
    `,
    collectionKey,
  )

  // Note: assumption that overrideBannerUrl will not be a gif
  const getBannerImageSource = (forceOriginal = false) =>
    overrideBannerUrl ??
    resizeImage(data.banner || PLACEHOLDER_IMAGE, {
      height: 500,
      freezeAnimation: !forceOriginal && !isHovering,
    })
  const bannerImageSource = getBannerImageSource()
  const dropState = useDropState(data)
  const treatAsDrop = (
    [DROP_STATES.UPCOMING, DROP_STATES.ACTIVE] as (DROP_STATES_TYPE | null)[]
  ).includes(dropState)

  return (
    <CardContainer
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
    >
      <Link
        eventParams={eventParams}
        eventSource={eventSource}
        href={
          treatAsDrop ? getCollectionOverviewUrl(data) : getCollectionUrl(data)
        }
        target={shouldOpenNewTab ? "_blank" : undefined}
      >
        <CardSection hasShadow={hasShadow}>
          <CardBannerContainer>
            <CardBanner
              alt={data.name}
              height={CARD_BANNER_HEIGHT}
              objectFit="cover"
              src={bannerImageSource}
              width={CARD_BANNER_WIDTH}
            />
          </CardBannerContainer>
          <HiddenImageContainer>
            <Image
              alt=""
              layout="fill"
              skipSrcset
              src={getBannerImageSource(true)}
            />
          </HiddenImageContainer>
          <CardFooterContainer>
            <FlexColumn className="w-full overflow-hidden">
              <CardTitleContainer paddingBottom="16px">
                <Text
                  asChild
                  className={classNames(
                    "items-center truncate break-all text-left font-inter",
                    "[font-variant-ligatures:no-contextual]",
                  )}
                  color="primary"
                  responsive
                  weight="semibold"
                >
                  <div>{data.name}</div>
                </Text>
                {verificationStatusHasBadge(data.verificationStatus) && (
                  <VerificationIcon
                    showTooltip={false}
                    size="small"
                    verificationStatus={data.verificationStatus}
                  />
                )}
              </CardTitleContainer>
              <HomePageCollectionCardFooter
                dataKey={data}
                volumeWindow={volumeWindow}
              />
            </FlexColumn>
          </CardFooterContainer>
        </CardSection>
      </Link>
    </CardContainer>
  )
}

const CardSkeleton = () => (
  <Skeleton className="relative h-auto p-1">
    <CardSection>
      <Skeleton.Row>
        <StyledSkeletonBanner />
      </Skeleton.Row>
      <StyledSkeletonInfo>
        <Flex alignItems="center" className="w-full" paddingBottom="38px">
          <Skeleton.Line className="h-[18px] w-[200px]" />
        </Flex>
      </StyledSkeletonInfo>
    </CardSection>
  </Skeleton>
)

export const HomePageCollectionCard = Object.assign(
  HomePageCollectionCardBase,
  {
    Skeleton: CardSkeleton,
  },
)

export const CardBannerContainer = styled(Block)`
  height: 0;
  padding-bottom: ${(2 / 3) * 100}%;
  position: relative;
`

export const CardSection = ({
  className,
  hasShadow = true,
  ...props
}: ComponentProps<typeof Block> & { hasShadow?: boolean }) => {
  return (
    <Block
      {...props}
      className={classNames(
        "rounded-xl bg-elevation-1 transition-all hover:bg-elevation-2",
        hasShadow
          ? "shadow-elevation-1 duration-200 hover:-translate-y-1 hover:shadow-elevation-2"
          : "border border-level-1 hover:border-level-3",
        className,
      )}
    />
  )
}

export const CardTitleContainer = styled(Flex)`
  align-items: center;
  justify-content: left;
  overflow: hidden;
`

const HiddenImageContainer = styled(Block)`
  position: absolute;
  width: 1px;
  height: 1px;
  visibility: hidden;
  z-index: -9999;
`

export const CardContainer = styled(Block)`
  display: inline-block;
  position: relative;
  width: 100%;
`

export const CardBanner = styled(Image).attrs({ skipSrcset: true })`
  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  border-top-left-radius: ${props => props.theme.borderRadius.large};
  border-top-right-radius: ${props => props.theme.borderRadius.large};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .Image--image {
    position: absolute;
  }
`

export const CardFooterContainer = styled(Flex)`
  padding: 16px;
  border-radius: ${props => props.theme.borderRadius.large};
  z-index: 99;
`

const StyledSkeletonBanner = styled(Skeleton.Block)`
  height: 0;
  padding-bottom: ${(3 / 4) * 100}%;
  border-top-right-radius: ${props => props.theme.borderRadius.large};
  border-top-left-radius: ${props => props.theme.borderRadius.large};
`

const StyledSkeletonInfo = styled(Skeleton)`
  justify-content: flex-end;
  flex-direction: column;
  padding: 16px;
  border-radius: ${props => props.theme.borderRadius.large};
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
`
