import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { PriceText } from "@/design-system/PriceText"
import { useCanceledListingsItemRelayIds } from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { PortfolioTableDifferenceCell_item$key } from "@/lib/graphql/__generated__/PortfolioTableDifferenceCell_item.graphql"
import { bn } from "@/lib/helpers/numberUtils"

type Status = "positive" | "negative" | "neutral"

type Props = {
  item: PortfolioTableDifferenceCell_item$key
}

export const PortfolioTableDifferenceCell = ({ item: itemKey }: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableDifferenceCell_item on ItemType
      @argumentDefinitions(identity: { type: "IdentityInputType!" }) {
        __typename
        relayId
        ... on AssetType {
          orderData {
            bestAskV2 {
              __typename
            }
            bestAskForOwner: bestAskV2(byAddress: $identity) {
              __typename
            }
          }
          pnl {
            pnlPrice {
              unit
              symbol
            }
          }
        }
      }
    `,
    itemKey,
  )

  const [canceledListingsItemRelayIds] = useCanceledListingsItemRelayIds()
  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()

  if (item.__typename !== "AssetType") {
    return <PriceText subtleEmpty symbol={undefined} unit={undefined} />
  }

  const { orderData, pnl, relayId } = item
  const bestAsk = canceledListingsItemRelayIds.includes(relayId)
    ? null
    : ownerListingPriceEnabled
    ? orderData?.bestAskForOwner
    : orderData?.bestAskV2

  if (!bestAsk?.__typename || !pnl?.pnlPrice) {
    return <PriceText subtleEmpty symbol={undefined} unit={undefined} />
  }

  const amount = bn(pnl.pnlPrice.unit)
  const status: Status = amount.gt(0)
    ? "positive"
    : amount.lt(0)
    ? "negative"
    : "neutral"

  const color =
    status === "positive"
      ? "success"
      : status === "negative"
      ? "red-3"
      : "primary"

  return (
    <PriceText
      color={color}
      positiveSign
      subtleEmpty
      symbol={pnl.pnlPrice.symbol}
      unit={pnl.pnlPrice.unit}
    />
  )
}
