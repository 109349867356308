import React from "react"
import { graphql } from "react-relay"
import { AccountFavorites } from "@/components/accounts/AccountFavorites.react"
import { getAppInitialProps } from "@/components/app/initialProps"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { FavoritesAccountPageQuery } from "@/lib/graphql/__generated__/FavoritesAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "./utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  assetCardVariant?: AssetCardVariant
}

export const FavoritesPage: GraphQLNextPage<
  FavoritesAccountPageQuery,
  Props
> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  assetCardVariant,
}) => {
  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="favorites"
      tabContent={
        <AccountFavorites
          assetCardVariant={assetCardVariant}
          variables={{ identity: variables.identity }}
        />
      }
    />
  )
}

const query = graphql`
  query FavoritesAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

FavoritesPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
  },
  async (
    { identifier },
    context,
  ): Promise<GraphQLInitialProps<FavoritesAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables, assetCardVariant } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
      })

    const { includePrivateAssetCount, identity, collection } = variables

    const pageVariables = {
      identity,
      collection,
      includePrivateAssetCount,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      assetCardVariant,
      variables: pageVariables,
    }
  },
)
