import React, { createContext, useState, useMemo, useContext } from "react"
import { noop } from "lodash"
import { TabOption } from "../PortfolioTableExpandedRow.react"

type PortfolioTableListingsTableContext = {
  selectedTabs: Map<string, TabOption>
  setSelectedTabs: React.Dispatch<React.SetStateAction<Map<string, TabOption>>>
}

const DEFAULT_CONTEXT: PortfolioTableListingsTableContext = {
  selectedTabs: new Map(),
  setSelectedTabs: noop,
}

const PortfolioTableListingsTableContext = createContext(DEFAULT_CONTEXT)
type Props = {
  children: React.ReactNode
}

export const PortfolioTableListingsTableContextProvider = ({
  children,
}: Props) => {
  const [selectedTabs, setSelectedTabs] = useState(
    DEFAULT_CONTEXT["selectedTabs"],
  )

  const value = useMemo(
    () => ({
      selectedTabs,
      setSelectedTabs,
    }),
    [selectedTabs],
  )

  return (
    <PortfolioTableListingsTableContext.Provider value={value}>
      {children}
    </PortfolioTableListingsTableContext.Provider>
  )
}

export const usePortfolioTableListingsTableContext = () =>
  useContext(PortfolioTableListingsTableContext)
