import React, { CSSProperties, useEffect } from "react"
import { Flex, type MaterialIcon } from "@opensea/ui-kit"
import { Panel } from "@/components/layout/Panel"
import { Block } from "@/design-system/Block"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { Search, useSearch } from "@/hooks/useSearch"
import { trackSearch } from "@/lib/analytics/events/searchEvents"
import { ListingSearchQuery } from "@/lib/graphql/__generated__/ListingSearchQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { SearchPills } from "../SearchPills.react"
import OrderSearchFilter from "./OrderSearchFilter.react"

export type ListingSearchState = Pick<Search, "collections" | "identity">

type Props = {
  children: (searchState: ListingSearchState) => React.ReactNode
  title: React.ReactNode
  path: string
  defaultState: ListingSearchState
  fixedState: Partial<ListingSearchState>
  initialState: Partial<ListingSearchState>
  viewContent?: React.ReactNode
  height?: CSSProperties["height"]
  panelIcon?: MaterialIcon
}

export const ListingSearch = ({
  title,
  children,
  viewContent,
  height,
  panelIcon = "info",
  defaultState,
  fixedState,
  initialState,
  path,
}: Props) => {
  const { searchState, clear, update } = useSearch({
    defaultState,
    fixedState,
    initialState,
    path,
  })

  const [data] = useNoSuspenseLazyLoadQuery<ListingSearchQuery>(
    graphql`
      query ListingSearchQuery($collections: [CollectionSlug!]) {
        ...SearchPills_data @arguments(collections: $collections)
      }
    `,
    {
      collections: searchState.collections,
    },
    { skip: !searchState.collections?.length },
  )

  useEffect(() => {
    trackSearch({
      type: "ListingSearch",
      path: window.location.pathname,
      queryString: window.location.search,
      pageIndex: 0,
      itemDisplayVariant: undefined,
      ...searchState,
    })
  }, [searchState])

  return (
    <Flex style={{ height }}>
      <OrderSearchFilter clear={clear} setState={update} state={searchState} />
      <Block flex={1} maxWidth="100%" overflow={{ md: "hidden" }}>
        {viewContent}
        <SearchPills
          clear={clear}
          collectionFilter={searchState.collections ?? []}
          dataKey={data ?? null}
          update={update}
        />
        <Block paddingBottom={{ _: "90px", md: "0" }}>
          <Panel
            icon={panelIcon}
            id="listing-search-1"
            isContentPadded={false}
            mode="always-open"
            title={title}
          >
            {children(searchState)}
          </Panel>
        </Block>
      </Block>
    </Flex>
  )
}
