import React, { ComponentProps, useState } from "react"
import { uniqBy } from "lodash"
import { GLOBAL_MAX_PAGE_SIZE } from "@/constants/index"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import {
  ProfileOffersCollectionFilterItemsLazyQuery,
  ProfileOffersCollectionFilterItemsLazyQuery$variables,
} from "@/lib/graphql/__generated__/ProfileOffersCollectionFilterItemsLazyQuery.graphql"
import { clearCache } from "@/lib/graphql/environment/middlewares/cacheMiddleware"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { ProfileCollectionSearchOptions } from "./ProfileCollectionFilter.react"
import { ProfileCollectionFilterSkeleton } from "./ProfileCollectionFilterItems"
import { ProfileOffersCollectionFilterItem } from "./ProfileOffersCollectionFilterItem.react"
import { SearchOptions } from "./SearchOptions.react"
const MAX_COLLECTIONS = 200

type ProfileOffersCollectionFilterItemsProps = {
  assetOwner: ProfileOffersCollectionFilterItemsLazyQuery$variables["assetOwner"]
  address: string
  query: string
  collectionSlugs: ProfileOffersCollectionFilterItemsLazyQuery$variables["collections"]
  selectedSlugs: ReadonlyArray<string>
  setSlugs: (slugs: ReadonlyArray<string> | undefined) => unknown
}

export const ProfileOffersCollectionFilterItems = ({
  query,
  address,
  assetOwner,
  selectedSlugs,
  setSlugs,
  collectionSlugs,
}: ProfileOffersCollectionFilterItemsProps) => {
  const [collectionCount, setCollectionCount] =
    useState<number>(GLOBAL_MAX_PAGE_SIZE)

  const [collectionData, refetch] =
    useNoSuspenseLazyLoadQuery<ProfileOffersCollectionFilterItemsLazyQuery>(
      graphql`
        query ProfileOffersCollectionFilterItemsLazyQuery(
          $assetOwner: IdentityInputType!
          $count: Int!
          $address: String!
          $query: String
          $cursor: String
          $collections: [CollectionSlug!]!
        ) {
          findAccount(identifier: $address) {
            offersMadeCollections(query: $query, first: $count, after: $cursor)
              @connection(
                key: "ProfileOffersCollectionFilterItems_offersMadeCollections"
              ) {
              edges {
                node {
                  slug
                  ...ProfileOffersCollectionFilterItem_collection
                    @arguments(owner: $assetOwner)
                }
              }
            }
          }
          collections(first: $count, collections: $collections) {
            edges {
              node {
                slug
                ...ProfileOffersCollectionFilterItem_collection
                  @arguments(owner: $assetOwner)
              }
            }
          }
        }
      `,
      {
        count: collectionCount,
        query,
        assetOwner,
        address,
        collections: collectionSlugs,
      },
    )

  if (!collectionData) {
    return <ProfileCollectionFilterSkeleton />
  }

  const collections = collectionData.findAccount?.offersMadeCollections
  const selectedCollections = collectionData.collections

  const uniqueCollections = uniqBy(
    [...getNodes(selectedCollections), ...getNodes(collections)],
    c => c.slug,
  )

  const returnedCollectionCount = uniqueCollections.length
  const maxCollectionsReached = collectionCount >= MAX_COLLECTIONS
  const canPaginate = returnedCollectionCount === collectionCount
  const isShowMoreButtonVisible = returnedCollectionCount >= 20

  const sortedCollections = [
    ...uniqueCollections.filter(c => selectedSlugs.includes(c.slug)),
    ...uniqueCollections.filter(c => !selectedSlugs.includes(c.slug)),
  ]

  const items: ComponentProps<typeof SearchOptions>["items"] =
    sortedCollections.map(collection => {
      const isSelected = selectedSlugs.includes(collection.slug)
      return {
        id: collection.slug,
        isSelected,
        label: <ProfileOffersCollectionFilterItem collection={collection} />,
        onChange: () => {
          // When an item is clicked, either remove it or add it to the selected collections.
          const newCollections = isSelected
            ? selectedSlugs.filter(c => c !== collection.slug)
            : [...selectedSlugs, collection.slug]
          setSlugs(newCollections.length ? newCollections : undefined)
        },
        collection,
      }
    })

  return (
    <ProfileCollectionSearchOptions
      isShowMoreButtonVisible={isShowMoreButtonVisible}
      items={items}
      maxCollectionsReached={maxCollectionsReached}
      showMore={() => {
        clearCache()
        setCollectionCount(collectionCount + GLOBAL_MAX_PAGE_SIZE)
        refetch({
          count: collectionCount,
        })
      }}
      showMoreDisabled={maxCollectionsReached || !canPaginate}
    />
  )
}
