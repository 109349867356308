import React, { useCallback, useEffect } from "react"
import { useCounter, useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import {
  getStartDateFromTimeRange,
  TimeRangeSelector,
} from "@/components/analytics/TimeRangeSelector"
import { CollectionPriceHistoryChart } from "@/components/assets/PriceHistoryChart/CollectionPriceHistoryChart.react"
import { usePriceHistoryChartProps } from "@/components/assets/PriceHistoryChart/usePriceHistoryChartProps.react"
import { FilterBar } from "@/components/layout/AccountOrCollectionPage/components/FilterBar"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { ChainFilter } from "@/components/search/assets/ChainFilter.react"
import { SearchProperties } from "@/components/search/assets/SearchProperties.react"
import { CollectionFilter } from "@/components/search/CollectionFilter.react"
import {
  FeaturedFilter,
  useEventFilters,
} from "@/components/search/featured-filter"
import SearchFilter from "@/components/search/SearchFilter.react"
import { SearchPills } from "@/components/search/SearchPills.react"
import { ToggleFilterButton } from "@/components/search/ToggleFilterButton.react"
import { useChart } from "@/design-system/charts/ChartPanel"
import { ActivitySearchLayout } from "@/features/activity/components/ActivitySearchLayout/ActivitySearchLayout.react"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { Search, useSearch } from "@/hooks/useSearch"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSearch } from "@/lib/analytics/events/searchEvents"
import { AccountActivitySearchQuery } from "@/lib/graphql/__generated__/AccountActivitySearchQuery.graphql"
import { AccountActivitySearchList } from "./AccountActivitySearchList.react"

export type AccountActivitySearchState = Pick<
  Search,
  | "chains"
  | "collection"
  | "collections"
  | "eventTypes"
  | "stringTraits"
  | "identity"
>

type Props = {
  defaultState: AccountActivitySearchState
  fixedState: Partial<AccountActivitySearchState>
  initialState: Partial<AccountActivitySearchState>
  variant?: "overlay" | "inline"
  path: string
}

const getFilterCount = (state: AccountActivitySearchState): number =>
  [
    ...(state.collections || []),
    ...(state.chains || []),
    ...(state.eventTypes || []),
    ...(state.stringTraits || []),
  ].length

export const AccountActivitySearch = ({
  fixedState,
  defaultState,
  initialState,
  variant,
  path,
}: Props) => {
  const t = useTranslate("components")
  const [activityTableKey, { inc: incrementActivityTableKey }] = useCounter(0)
  const { sidebarOpen } = useAccountOrCollectionPageContext()
  const { searchState, clear, update } = useSearch({
    defaultState,
    fixedState,
    initialState,
    path,
  })
  const eventFilterItems = useEventFilters()
  const filterCount = getFilterCount(searchState)
  const { reflow: reflowChart } = useChart()

  useUpdateEffect(() => {
    if (variant === "inline") {
      incrementActivityTableKey()
      reflowChart()
    }
  }, [incrementActivityTableKey, reflowChart, sidebarOpen, variant])

  useEffect(() => {
    trackSearch({
      type: "ActivityAccountSearch",
      path: window.location.pathname,
      queryString: window.location.search,
      pageIndex: 0,
      itemDisplayVariant: undefined,
      ...searchState,
    })
  }, [searchState])

  const [data] = useNoSuspenseLazyLoadQuery<AccountActivitySearchQuery>(
    graphql`
      query AccountActivitySearchQuery(
        $collections: [CollectionSlug!]
        $collection: CollectionSlug!
      ) {
        ...SearchPills_data @arguments(collections: $collections)

        collection(collection: $collection) {
          ...usePriceHistoryChartProps_collection
          ...SearchProperties_collection
        }
      }
    `,
    {
      collections: searchState.collections,
      collection: searchState.collections?.[0] ?? searchState.collection ?? "",
    },
    { skip: !searchState.collections?.length && !searchState.collection },
  )

  const setStringTrait = useCallback(
    (name: string, values?: readonly string[]) =>
      update({
        stringTraits: [
          ...(searchState.stringTraits?.filter(t => t.name !== name) ?? []),
          ...(values ? [{ name, values }] : []),
        ],
      }),
    [searchState.stringTraits, update],
  )

  const {
    shouldRenderPriceHistory,
    bucketSize,
    isTimeRangeAllTime,
    timeRange,
    timeRangeOptions,
    setTimeRange,
  } = usePriceHistoryChartProps({
    collection: data?.collection ?? null,
    eventTypes: searchState.eventTypes,
  })

  return (
    <ActivitySearchLayout
      eventHistory={
        <AccountActivitySearchList
          activityTableKey={activityTableKey}
          searchState={searchState}
        />
      }
      filter={
        <SearchFilter clear={clear} fullscreenBreakpoint="lg">
          <FeaturedFilter
            filters={searchState.eventTypes || []}
            mode="start-open"
            possibleFilterItems={eventFilterItems}
            setFilters={eventTypes => update({ eventTypes })}
            title={t("activitySearchFilter.eventType", "Event Type")}
          />
          <CollectionFilter
            filters={{ ...searchState, assetOwner: searchState.identity }}
            scopeQueryByAssetOwner={false}
            selectedSlugs={
              searchState.collections ? searchState.collections : []
            }
            setSlugs={collections => {
              update({
                collection: collections ? collections[0] : undefined,
                collections: collections ? collections : [],
              })
            }}
            sortBy="SEVEN_DAY_VOLUME"
          />
          <ChainFilter
            activeChains={searchState.chains || []}
            allowMultichain={false}
            setChains={chains => update({ chains })}
          />
          {searchState.collection && data?.collection && (
            <SearchProperties
              collection={data.collection}
              hideNumericTraits
              selectedStringTraits={searchState.stringTraits ?? undefined}
              setStringTrait={setStringTrait}
            />
          )}
        </SearchFilter>
      }
      header={
        <FilterBar
          filterToggle={<ToggleFilterButton numFiltersApplied={filterCount} />}
        />
      }
      pills={
        <SearchPills
          chainFilter={searchState.chains || []}
          clear={clear}
          collectionFilter={searchState.collections}
          dataKey={data}
          eventFilter={searchState.eventTypes}
          stringTraitFilter={searchState.stringTraits}
          update={update}
        />
      }
      priceHistory={
        shouldRenderPriceHistory &&
        // Only render this if we have one collection selected
        searchState.collections?.length === 1 &&
        searchState.collection ? (
          <CollectionPriceHistoryChart
            bucketSize={bucketSize}
            collectionSlug={searchState.collection}
            height="400px"
            startDate={getStartDateFromTimeRange(
              isTimeRangeAllTime ? undefined : timeRange,
            )}
            stringTraits={searchState.stringTraits}
          />
        ) : null
      }
      priceHistoryTimeRange={
        <TimeRangeSelector
          options={timeRangeOptions}
          setTimeRange={setTimeRange}
          timeRange={timeRange}
        />
      }
    />
  )
}
