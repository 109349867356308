import styled from "styled-components"
import { Select } from "@/design-system/Select"

export const StyledSelect = styled(Select)`
  input {
    &::placeholder {
      color: ${props => props.theme.colors.text.primary};
    }
  }
  i {
    color: ${props => props.theme.colors.text.primary} !important;
  }
`
