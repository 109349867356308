import React from "react"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import {
  OfferSearch,
  OfferSearchState,
} from "@/components/search/orders/OfferSearch.react"
import { BidsAccountPageQuery } from "@/lib/graphql/__generated__/BidsAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "./utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  searchState: OfferSearchState
}

export const BidsPage: GraphQLNextPage<BidsAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  searchState,
}) => {
  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="bids"
      tabContent={
        <OfferSearch
          defaultState={{ collections: undefined }}
          fixedState={{ identity: searchState.identity }}
          initialState={searchState}
          isCurrentUser={isCurrentUser}
          offerSearchType="received"
          path={path}
          variant="profile"
        />
      }
    />
  )
}

const query = graphql`
  query BidsAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

BidsPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
  },
  async (
    { identifier },
    context,
  ): Promise<GraphQLInitialProps<BidsAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, path, variables } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        tab: "bids",
      })

    const { includePrivateAssetCount, identity, collection, collections } =
      variables

    const pageVariables = {
      includePrivateAssetCount,
      identity,
      collection,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      variables: pageVariables,
      searchState: {
        collections,
        identity,
      },
    }
  },
)
