import React, { forwardRef, useCallback, useMemo } from "react"
import { Text, Checkbox, Flex } from "@opensea/ui-kit"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { BlockProps } from "@/design-system/Block"
import { SelectOption } from "@/design-system/Select"
import { SortDirection } from "@/design-system/Table/SortableHeader.react"
import useSortableHeaders from "@/design-system/Table/useSortableHeaders"
import { VirtualizedTable } from "@/design-system/VirtualizedTable"
import { VirtualizedTableHeaderProps } from "@/design-system/VirtualizedTable/components"
import { TableHeaderText } from "@/features/account/components/PortfolioTable/components/styles"
import {
  CHECKBOX_PADDING,
  COLUMN_CELL_PROPS,
} from "@/features/account/components/PortfolioTable/constants"
import { useHeaderColumns } from "@/features/account/components/PortfolioTable/hooks"
import {
  isMobilePortfolioColumn,
  isSortablePortfolioColumn,
  MobilePortfolioColumn,
  PortfolioSelectedState,
  PortfolioViewMode,
  SortablePortfolioColumn,
} from "@/features/account/components/PortfolioTable/types"
import { useTranslate } from "@/hooks/useTranslate"
import { usePortfolioTableContext } from "./PortfolioTableContext.react"

type Props = BlockProps & {
  itemCount: number
  mode: PortfolioViewMode
  selectedSort?: { column: SortablePortfolioColumn; ascending: boolean }
  selectedState: PortfolioSelectedState
  onSelectAll: (selected: boolean) => unknown
  onSort: (column: SortablePortfolioColumn, ascending: boolean) => void
} & Pick<VirtualizedTableHeaderProps, "stickyTop">

export const PortfolioTableHeader = forwardRef<HTMLDivElement, Props>(
  function PortfolioTableHeader(
    {
      itemCount,
      mode,
      selectedSort,
      selectedState,
      onSelectAll,
      onSort,
      ...props
    }: Props,
    ref,
  ) {
    const t = useTranslate("account")
    const { isDesktop, mobileColumn, setMobileColumn } =
      usePortfolioTableContext()
    const isOwnerMode = mode === "owner"
    const headerColumns = useHeaderColumns(itemCount, isOwnerMode)
    const mobileHeaderColumns: SelectOption<MobilePortfolioColumn>[] =
      headerColumns
        .filter(headerColumn => isMobilePortfolioColumn(headerColumn.column))
        .map(({ header, column }) => ({
          label: header,
          value: column as MobilePortfolioColumn,
        }))

    const headerOptions = useMemo(
      () =>
        headerColumns.map(({ column, header, tooltip }) => ({
          header: (
            <Flex className="items-center">
              <TableHeaderText>{header}</TableHeaderText>
              {tooltip && (
                <InfoIcon
                  overrides={{
                    Button: { style: { marginLeft: "4px" } },
                    Icon: { size: 14 },
                    Tooltip: { interactive: false },
                  }}
                  tooltipContent={tooltip}
                />
              )}
            </Flex>
          ),
          ...(isSortablePortfolioColumn(column)
            ? {
                sort: (direction?: SortDirection) =>
                  onSort(column, direction === "asc"),
                sortIndicatorHideMode: "remove" as const,
              }
            : {}),
        })),
      [headerColumns, onSort],
    )

    const selectedColumnIndex = headerColumns.findIndex(
      headerColumn => headerColumn.column === selectedSort?.column,
    )

    const sortableHeaders = useSortableHeaders(
      headerOptions,
      selectedSort && selectedColumnIndex >= 0
        ? {
            index: selectedColumnIndex >= 0 ? selectedColumnIndex : undefined,
            direction: selectedSort.ascending ? "asc" : "desc",
          }
        : undefined,
    )

    const handleSelectAll = useCallback(
      (selected: boolean) => {
        if (selectedState === "some" && selected) {
          // Deselect all when some, but not all items, are selected and the
          // user selects the checkmark.
          onSelectAll(false)
        } else {
          onSelectAll(selected)
        }
      },
      [onSelectAll, selectedState],
    )

    const itemCellHeader = isDesktop ? (
      sortableHeaders[0]
    ) : (
      <Text className="normal-case" size="small" weight="semibold">
        {headerColumns[0].header}
      </Text>
    )

    return (
      <VirtualizedTable.Header
        alignItems="center"
        className="w-full"
        gap={16}
        height={52}
        ref={ref}
        {...props}
      >
        <VirtualizedTable.Header.Cell {...COLUMN_CELL_PROPS["item"]}>
          {isOwnerMode ? (
            <Flex className="h-full items-center">
              <Flex
                className="items-center"
                style={{ paddingRight: CHECKBOX_PADDING }}
              >
                <Checkbox
                  checked={
                    selectedState === "some"
                      ? "indeterminate"
                      : selectedState === "all"
                  }
                  disabled={!itemCount}
                  size="small"
                  onCheckedChange={handleSelectAll}
                />
              </Flex>
              {itemCellHeader}
            </Flex>
          ) : (
            itemCellHeader
          )}
        </VirtualizedTable.Header.Cell>

        {isDesktop ? (
          sortableHeaders.slice(1).map((header, index) => {
            const column = headerColumns[index + 1].column
            const columnProps = COLUMN_CELL_PROPS[column]
            return (
              <VirtualizedTable.Header.Cell key={column} {...columnProps}>
                {header}
              </VirtualizedTable.Header.Cell>
            )
          })
        ) : (
          <VirtualizedTable.Header.Cell flexGrow={1} justifyContent="flex-end">
            <VirtualizedTable.Header.Select
              column={mobileColumn}
              options={mobileHeaderColumns}
              title={t("portfolioTable.header.select.title", "View")}
              onSelect={setMobileColumn}
            />
          </VirtualizedTable.Header.Cell>
        )}
      </VirtualizedTable.Header>
    )
  },
)
