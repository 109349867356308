/**
 * @generated SignedSource<<cd85736af8878965b13a0683e361ac51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountOrCollectionProfileImage_data$data = {
  readonly displayName: string | null;
  readonly imageUrl: string;
  readonly user: {
    readonly publicUsername: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileImage_data">;
  readonly " $fragmentType": "AccountOrCollectionProfileImage_data";
};
export type AccountOrCollectionProfileImage_data$key = {
  readonly " $data"?: AccountOrCollectionProfileImage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOrCollectionProfileImage_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOrCollectionProfileImage_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileImage_data"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicUsername",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "f7f94143708406bdb4252fcec1793b04";

export default node;
