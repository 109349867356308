/**
 * @generated SignedSource<<89bd43b51b15254ef6f1ba019982796f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancelOrdersConfirmationModal_items$data = ReadonlyArray<{
  readonly __typename: "AssetBundleType";
  readonly assetQuantities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
        };
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "CancelOrdersConfirmationModal_items";
} | {
  readonly __typename: "AssetType";
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  readonly " $fragmentType": "CancelOrdersConfirmationModal_items";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "CancelOrdersConfirmationModal_items";
}>;
export type CancelOrdersConfirmationModal_items$key = ReadonlyArray<{
  readonly " $data"?: CancelOrdersConfirmationModal_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelOrdersConfirmationModal_items">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "StackedAssetMedia_assets"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CancelOrdersConfirmationModal_items",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 18
            }
          ],
          "concreteType": "AssetQuantityTypeConnection",
          "kind": "LinkedField",
          "name": "assetQuantities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetQuantityTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetType",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "assetQuantities(first:18)"
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "33bfc1d1645a0329b8f9039fdac91d4d";

export default node;
