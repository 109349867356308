import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { useFeaturedShelfCount } from "@/components/layout/AccountOrCollectionPage/hooks/useFeaturedShelfCount"
import { Sort } from "@/components/search/assets/AssetSearchSortDropdown.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { ProfilePageFeaturedFragment } from "@/features/account/queries/profilePageQueries"
import { getAccountLocaleUrl } from "@/features/account/utils/path"
import { FeaturedShelfList } from "@/features/featured-shelf/components/FeaturedShelf.react"
import { DefaultAccountPageQuery } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { profilePageQueries_featured$key } from "@/lib/graphql/__generated__/profilePageQueries_featured.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../components/ProfileContainer"
import {
  AccountCollectedAssetSearch,
  AccountCollectedAssetSearchState,
} from "./collected/AccountCollectedAssetSearch.react"
import { cachePolicy, getInitialPropsAccountPageQuery } from "./utils"

export const ASSET_SEARCH_ACCOUNT_SORTS: Sort[] = [
  { sortAscending: false, sortBy: "LAST_TRANSFER_DATE" },
  { sortAscending: false, sortBy: "UNIT_PRICE" },
  { sortAscending: true, sortBy: "UNIT_PRICE" },
  { sortAscending: false, sortBy: "FLOOR_PRICE" },
  { sortAscending: false, sortBy: "BEST_BID" },
  { sortAscending: false, sortBy: "LISTING_DATE" },
  { sortAscending: false, sortBy: "CREATED_DATE" },
  { sortAscending: false, sortBy: "LAST_SALE_PRICE" },
  { sortAscending: true, sortBy: "CREATED_DATE" },
]

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  assetCardVariant?: AssetCardVariant
  searchState: AccountCollectedAssetSearchState
}

export const DefaultPage: GraphQLNextPage<DefaultAccountPageQuery, Props> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  assetCardVariant,
  searchState,
}) => {
  const { identity } = variables
  const [currentCardVariant, setCurrentCardVariant] = useState(assetCardVariant)

  const featuredKey = data?.account ?? null
  const featured = useFragment<profilePageQueries_featured$key>(
    ProfilePageFeaturedFragment,
    featuredKey,
  )
  const shelfCount = useFeaturedShelfCount({ featuredKey, isYourProfile })

  const displayFeaturedShelves =
    !isYourProfile && data?.account?.relayId && shelfCount

  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab={displayFeaturedShelves ? "featured" : "collected"}
      tabContent={
        displayFeaturedShelves ? (
          <FeaturedShelfList
            accountId={data.account.relayId}
            cardVariant={currentCardVariant}
            isProfilePage
            setCardVariant={setCurrentCardVariant}
            shelves={featured?.shelves}
            showExtraMenu={isCurrentUser}
          />
        ) : (
          <AccountCollectedAssetSearch
            assetCardVariant={assetCardVariant}
            defaultState={{
              chains: undefined,
              collection: undefined,
              collections: undefined,
              numericTraits: undefined,
              paymentAssets: undefined,
              priceFilter: undefined,
              resultModel: identifier ? undefined : "ASSETS",
              sortAscending: searchState.sortAscending,
              sortBy: searchState.sortBy,
              stringTraits: undefined,
              toggles: undefined,
              query: undefined,
              identity,
            }}
            fixedState={{
              identity,
            }}
            initialState={searchState}
            path={path}
          />
        )
      }
    />
  )
}

const query = graphql`
  query DefaultAccountPageQuery(
    $identity: IdentityInputType!
    $includePrivateAssetCount: Boolean!
    $collection: CollectionSlug
  ) {
    collectionSlug: collection(collection: $collection) {
      __typename
    }
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        includePrivateAssetCount: $includePrivateAssetCount
      )
    account(identity: $identity) {
      relayId
      ...profilePageQueries_featured @arguments(showContextMenu: false)
    }
  }
`

DefaultPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
    search: QP.Optional(QP.Search),
  },
  async (
    { identifier, search },
    ctx,
  ): Promise<GraphQLInitialProps<DefaultAccountPageQuery, Props>> => {
    const {
      isCurrentUser,
      isYourProfile,
      variables,
      path,
      assetCardVariant,
      sortBy,
      sortAscending,
    } = await getInitialPropsAccountPageQuery({
      context: ctx,
      identifier,
      tableViewSupported: true,
      search,
    })

    const {
      includePrivateAssetCount,
      identity,
      collection,
      collections,
      chains,
    } = variables

    const pageVariables = {
      identity,
      includePrivateAssetCount,
      collection,
    }

    const accountUrl = getAccountLocaleUrl(identifier, ctx.locale)

    const appInitialProps = await getAppInitialProps<DefaultAccountPageQuery>(
      ctx,
      {
        query,
        variables: pageVariables,
        cachePolicy,
        redirectUrl: data => {
          const notFound = !!(
            data !== null &&
            collection &&
            data.collectionSlug === null
          )
          return notFound ? accountUrl : null
        },
      },
    )

    const searchState: AccountCollectedAssetSearchState = {
      chains,
      collection,
      collections,
      identity,
      numericTraits: search?.numericTraits,
      paymentAssets: search?.paymentAssets,
      priceFilter: search?.priceFilter,
      query: search?.query,
      resultModel: search?.resultModel ?? (identifier ? undefined : "ASSETS"),
      sortAscending,
      sortBy,
      stringTraits: search?.stringTraits,
      toggles: search?.toggles,
    }

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      assetCardVariant,
      variables: pageVariables,
      searchState,
    }
  },
)
