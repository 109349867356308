/**
 * @generated SignedSource<<9a6f7c12b08ec5968a766b7e696af05e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Subheader_collection$data = {
  readonly owner: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentType": "Subheader_collection";
};
export type Subheader_collection$key = {
  readonly " $data"?: Subheader_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"Subheader_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Subheader_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountLink_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "a5fe11b6fad53ed83021b6d9b47798ab";

export default node;
