import React from "react"
import { graphql, useFragment } from "react-relay"
import { OpenSeaHead, pageTitle } from "@/features/seo"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { ProfileMetadata_data$key } from "@/lib/graphql/__generated__/ProfileMetadata_data.graphql"
import * as sidecar from "@/server/sidecar/config"

type ProfilePageMetadataProps = {
  data: ProfileMetadata_data$key | null
  isYourProfile: boolean
  name?: string | null
}

export const ProfilePageMetadata = ({
  data: dataKey,
  isYourProfile,
  name,
}: ProfilePageMetadataProps) => {
  const data = useFragment(
    graphql`
      fragment ProfileMetadata_data on Query
      @argumentDefinitions(
        identity: { type: "IdentityInputType!" }
        collection: { type: "CollectionSlug" }
      ) {
        account(identity: $identity) {
          address
          user {
            publicUsername
          }
        }
        collection(collection: $collection) {
          name
        }
      }
    `,
    dataKey,
  )

  const t = useTranslate("phoenix")
  const { origin, locale } = useRouter()
  const resolvedIdentifier =
    data?.account?.user?.publicUsername || name || data?.account?.address

  let canonicalUrl: string | undefined

  if (resolvedIdentifier) {
    canonicalUrl = `${origin}${
      locale === "en-US" ? "" : `/${locale}`
    }/${resolvedIdentifier}`
  }

  if (isYourProfile || !resolvedIdentifier) {
    return (
      <OpenSeaHead
        title={pageTitle(t("profile.title", "Your Profile"))}
        url={canonicalUrl}
      />
    )
  }

  const image = `${sidecar.getValue(
    "ogImagesPreviewUrl",
  )}/v1/accounts/${resolvedIdentifier}`

  const title = pageTitle(
    `${resolvedIdentifier} ${
      data?.collection
        ? data.collection.name
        : `- ${t("profile.profile", "Profile")}`
    }`,
  )

  const description = t(
    "profile.checkOut",
    "Check out {{collectionName}} NFTs on OpenSea, the largest marketplace for crypto collectibles.",
    {
      collectionName: `${resolvedIdentifier}'s ${
        data?.collection ? " " + data.collection.name : ""
      }`,
    },
    { forceString: true },
  )

  return (
    <OpenSeaHead
      description={description}
      image={image}
      title={title}
      url={canonicalUrl}
    />
  )
}
