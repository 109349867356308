import React from "react"
import { Text } from "@opensea/ui-kit"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { Link } from "@/components/common/Link"
import {
  ListingSearch,
  ListingSearchState,
} from "@/components/search/orders/ListingSearch.react"
import { OrdersPanelTitle } from "@/components/search/orders/OrdersPanelTitle.react"
import { InactiveListings } from "@/features/orders/Listings"
import { useTranslate } from "@/hooks/useTranslate"
import { ListingsInactiveAccountPageQuery } from "@/lib/graphql/__generated__/ListingsInactiveAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import { ProfileContainer } from "../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "./utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  searchState: ListingSearchState
}

export const ListingsInactivePage: GraphQLNextPage<
  ListingsInactiveAccountPageQuery,
  Props
> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  searchState,
}) => {
  const t = useTranslate("phoenix")

  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="listings_inactive"
      tabContent={
        <ListingSearch
          defaultState={{ collections: undefined }}
          fixedState={{ identity: searchState.identity }}
          height="calc(100vh - 240px)"
          initialState={searchState}
          path={path}
          title={
            <OrdersPanelTitle
              extra={
                <Link href="https://support.opensea.io/articles/8867019">
                  <Text
                    asChild
                    className="ml-4"
                    color="interactive-primary-styles"
                    size="small"
                    weight="semibold"
                  >
                    <p>{t("profile.learn.link", "Learn more")}</p>
                  </Text>
                </Link>
              }
              title={t("profile.inactiveListings.title", "Inactive listings")}
            />
          }
        >
          {searchState => <InactiveListings {...searchState} />}
        </ListingSearch>
      }
    />
  )
}

const query = graphql`
  query ListingsInactiveAccountPageQuery(
    $identity: IdentityInputType!
    $collection: CollectionSlug
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

ListingsInactivePage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
  },
  async (
    { identifier },
    context,
  ): Promise<GraphQLInitialProps<ListingsInactiveAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, variables, path } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        tab: "listings-inactive",
      })

    const { includePrivateAssetCount, identity, collection, collections } =
      variables

    const pageVariables = {
      identity,
      collection,
      includePrivateAssetCount,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      variables: pageVariables,
      searchState: {
        identity,
        collections,
      },
    }
  },
)
