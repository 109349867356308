import React from "react"
import { useIsLessThanXl } from "@opensea/ui-kit"
import { graphql } from "react-relay"
import { getAppInitialProps } from "@/components/app/initialProps"
import { SidebarContextProvider } from "@/components/search/SidebarContext.react"
import { ChartContextProvider } from "@/design-system/charts/ChartPanel"
import { ActivityAccountPageQuery } from "@/lib/graphql/__generated__/ActivityAccountPageQuery.graphql"
import { GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import QP from "@/lib/qp/qp"
import {
  AccountActivitySearch,
  AccountActivitySearchState,
} from "../components/AccountActivitySearch.react"
import { ProfileContainer } from "../components/ProfileContainer"
import { getInitialPropsAccountPageQuery, cachePolicy } from "./utils"

type Props = {
  identifier: string | undefined
  isCurrentUser: boolean
  isYourProfile: boolean
  path: string
  searchState: AccountActivitySearchState
}

export const AccountActivityPage: GraphQLNextPage<
  ActivityAccountPageQuery,
  Props
> = ({
  data,
  variables,
  identifier,
  isCurrentUser,
  isYourProfile,
  path,
  searchState,
}) => {
  const isLessThanXl = useIsLessThanXl()
  return (
    <ProfileContainer
      data={data}
      identifier={identifier}
      initialAccountOnPageLoad={variables.identity}
      initialSidebarOpen={false}
      isCurrentUser={isCurrentUser}
      isYourProfile={isYourProfile}
      name={variables.identity.name}
      tab="activity"
      tabContent={
        <SidebarContextProvider>
          <ChartContextProvider>
            <AccountActivitySearch
              defaultState={{
                chains: undefined,
                collection: undefined,
                collections: [],
                eventTypes: [],
                stringTraits: [],
              }}
              fixedState={{ identity: searchState.identity }}
              initialState={searchState}
              path={path}
              variant={isLessThanXl ? "overlay" : "inline"}
            />
          </ChartContextProvider>
        </SidebarContextProvider>
      }
    />
  )
}

const query = graphql`
  query ActivityAccountPageQuery(
    $collection: CollectionSlug
    $identity: IdentityInputType!
    $includePrivateAssetCount: Boolean!
  ) {
    ...ProfileContainer_data
      @arguments(
        identity: $identity
        collection: $collection
        includePrivateAssetCount: $includePrivateAssetCount
      )
  }
`

AccountActivityPage.getInitialProps = QP.nextParser(
  {
    identifier: QP.Optional(QP.string),
    search: QP.Optional(QP.Search),
  },
  async (
    { identifier, search },
    context,
  ): Promise<GraphQLInitialProps<ActivityAccountPageQuery, Props>> => {
    const { isCurrentUser, isYourProfile, path, variables } =
      await getInitialPropsAccountPageQuery({
        context,
        identifier,
        search,
        tab: "activity",
      })

    const { includePrivateAssetCount, identity, collection } = variables

    const pageVariables = { includePrivateAssetCount, identity, collection }

    const searchState: AccountActivitySearchState = {
      chains: search?.chains,
      collection,
      collections: search?.collections ?? (collection ? [collection] : []),
      eventTypes: search?.eventTypes,
      stringTraits: search?.stringTraits,
      identity: variables.identity,
    }

    const appInitialProps = await getAppInitialProps(context, {
      query,
      variables: pageVariables,
      cachePolicy,
    })

    return {
      ...appInitialProps,
      identifier,
      isCurrentUser,
      isYourProfile,
      path,
      variables: pageVariables,
      searchState,
    }
  },
)
